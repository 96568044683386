import React, { PureComponent } from 'react';
import { filter as filterList, map } from 'lodash';

import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
} from '@mui/material';

import { grey } from '@mui/material/colors';

import Loader, { LoaderInfo } from '../shared/Loader';
import { ModelInstanceArchiveFlag, ModelInstance } from '../../types/models';

import Flex from '../shared/Flex';

import CenteredSpinner from '../shared/CenteredSpinner';

import ModelInstancePermissionsItem from './ModelInstancePermissionsItem';

interface Props {
  clientId: number;
  modelId: number;
}

interface State {
  filter: ModelInstanceArchiveFlag;
}

const DEFAULT_MODEL_INSTANCE_FILTER: ModelInstanceArchiveFlag = 0;

class ModelInstancePermissions extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filter: DEFAULT_MODEL_INSTANCE_FILTER,
    };
  }

  public render() {
    const { clientId, modelId } = this.props;
    const needs = {
      instances: `/clients/${clientId}/models/${modelId}/model_instances_load`,
    };

    return (
      <Loader needs={needs} handleErrors={true} render={this.renderContents} />
    );
  }

  private handleFilterChange = (
    event: React.SyntheticEvent,
    filter: ModelInstanceArchiveFlag
  ) => {
    this.setState({
      filter,
    });
  };

  private renderContents = ({
    loading,
    data,
  }: LoaderInfo<{ instances: ModelInstance[] }>) => {
    const { clientId, modelId } = this.props;
    const { filter } = this.state;

    const instances: ModelInstance[] =
      filterList(data.instances, ['Archive_Flag', this.state.filter])
        .slice()
        .sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0)) || [];

    return (
      <Box sx={{ borderLeft: `1px solid ${grey[400]}`, p: 2, pb: 8 }}>
        <Flex alignItems="center">
          <Box flex="auto">
            <Typography variant="h4">Model Instances</Typography>
          </Box>
          <ToggleButtonGroup
            exclusive
            size="small"
            id="model-instance-filter"
            onChange={this.handleFilterChange}
            value={filter || DEFAULT_MODEL_INSTANCE_FILTER}
          >
            <ToggleButton value={0}>Current</ToggleButton>
            <ToggleButton value={1}>Archive</ToggleButton>
          </ToggleButtonGroup>
        </Flex>
        {loading && instances.length === 0 ? (
          <CenteredSpinner />
        ) : instances.length > 0 ? (
          <>
            {filter === 1 && (
              <Flex alignItems="center" mt={2}>
                <Alert severity="error" sx={{ width: '100%' }}>
                  Archived model instances cannot be made visible.
                </Alert>
              </Flex>
            )}
            {map(instances, (instance) => (
              <ModelInstancePermissionsItem
                key={instance.id}
                instance={instance}
                modelId={modelId}
                clientId={clientId}
                filter={filter}
              />
            ))}
          </>
        ) : (
          <Typography>This Model has no Instances</Typography>
        )}
      </Box>
    );
  };
}

export default ModelInstancePermissions;
