import React, { FunctionComponent } from 'react';

import { RequestStateType, SourceDropDown } from '../../types/models';
import PopupDialog from '../shared/PopupDialog';

interface Props {
  isOpen: boolean;
  source: Partial<SourceDropDown> | undefined;
  onClose: () => void;
  requestState?: RequestStateType;
}

const InvalidSelectionDialog: FunctionComponent<Props> = ({
  isOpen,
  source,
  onClose,
  requestState,
}) => {
  let requirements;

  if (source === 'inputs') {
    if (requestState === 'defineStandardReportInvalid') {
      requirements = 'at least an input';
    } else {
      requirements = 'at least one scenario and input';
    }
  } else if (source === 'outputs') {
    if (requestState === 'defineStandardReportInvalid') {
      requirements = 'at least an output and timescale';
    } else {
      requirements = 'at least one scenario, output, and timescale';
    }
  } else {
    requirements = 'either an input or output source';
  }

  return (
    <PopupDialog
      open={isOpen}
      close={onClose}
      title="Invalid Selection"
      hideCloseButton
      primaryButtons={[
        {
          id: 'invalid-selection-dialog-ok',
          label: 'Ok',
          onClick: onClose,
        },
      ]}
    >
      You must select {requirements} to generate a report.
    </PopupDialog>
  );
};

export default InvalidSelectionDialog;
