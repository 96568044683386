import React, { FunctionComponent } from 'react';
import { Alert, AlertTitle, Box, Button } from '@mui/material';

import { HTTPError } from '../../support/errors';
import Flex from '../shared/Flex';

interface Props {
  error: HTTPError | boolean;
  text: string;
  refresh?: () => void;
}

const ScenarioUpdateErrorCallout: FunctionComponent<Props> = ({
  error,
  text,
  refresh,
}) => {
  if (
    error instanceof HTTPError ||
    (error as any).status === 409 ||
    (error as any).status === 400
  ) {
    return (
      <Alert severity="error" icon={false} sx={{ width: '100%' }}>
        <AlertTitle>Something went wrong</AlertTitle>
        <Flex justifyContent="space-between">
          {(error as any)?.body?.message || 'Please refresh and try again.'}
          <Box display="inline" ml={2} minWidth="unset">
            <Button color="error" onClick={refresh} variant="contained">
              Dismiss
            </Button>
          </Box>
        </Flex>
      </Alert>
    );
  }
  return (
    <Alert severity="error" sx={{ width: '100%' }}>
      <AlertTitle>Something went wrong</AlertTitle>
      {text}
    </Alert>
  );
};

export default ScenarioUpdateErrorCallout;
