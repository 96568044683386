import React, {
  FunctionComponent,
  forwardRef,
  ReactNode,
  PropsWithChildren,
} from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Slide,
  DialogProps,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { camelCase, includes, map, omit } from 'lodash';
import { Breakpoint } from '@mui/system';
import { SlideProps } from '@mui/material/Slide/Slide';

export const Transition = forwardRef(
  (props: PropsWithChildren<SlideProps>, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

export interface DialogTitleProps {
  id: string;
  children?: ReactNode;
  onClose: (value: unknown) => void;
}

export const DialogTitle: FunctionComponent<
  DialogTitleProps & Pick<IPopupDialog, 'isLoading' | 'variant'>
> = (props: DialogTitleProps & Pick<IPopupDialog, 'isLoading' | 'variant'>) => {
  const { children, onClose, variant, ...other } = props;

  return (
    <MuiDialogTitle
      sx={{
        m: 0,
        px: 2,
        py: 1.25,
        background: (theme) =>
          variant === 'primary'
            ? theme.palette.primary.main
            : theme.palette.common.red,
        color: (theme) => theme.palette.common.white,
      }}
      {...omit(other, ['isLoading', 'variant'])}
    >
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h5">{children}</Typography>
        </Box>
        {onClose ? (
          <Box>
            <IconButton
              aria-label="close"
              onClick={onClose}
              disabled={props.isLoading}
            >
              <Close
                sx={{
                  color: (theme) => theme.palette.common.white,
                  ':hover': {
                    color: (theme) => theme.palette.common.white,
                  },
                }}
              />
            </IconButton>
          </Box>
        ) : null}
      </Box>
    </MuiDialogTitle>
  );
};

export interface IPrimaryDialogButtons {
  id: string;
  label: string;
  onClick: (val: any) => void;
  disabled?: boolean;
  title?: string;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

interface IPopupDialog extends Omit<DialogProps, 'open' | 'onClose'> {
  /**
   * Open the dialog
   */
  open: boolean;
  /**
   * Close the dialog
   */
  close: (value: unknown) => void;
  /**
   * Title of the dialog
   */
  title: string;
  /**
   * Content of the dialog
   */
  children: ReactNode;
  /**
   * Save Button Label
   * Defaults to Save
   */
  saveButtonLabel?: string;
  /**
   * Creates a primary Save button
   * Can be modified by passing in an array of button items
   */
  primaryButtons?: IPrimaryDialogButtons[];
  isLoading?: boolean;
  maxWidth?: Breakpoint | false;
  closeButtonLabel?: string;
  hideDialogActions?: boolean;
  variant?: 'primary' | 'error';
  hideCloseButton?: boolean;
  closeButtonClick?: () => void;
}

const PopupDialog: FunctionComponent<IPopupDialog> = ({
  open,
  close,
  title,
  children,
  saveButtonLabel = 'Save',
  closeButtonLabel = 'Cancel',
  primaryButtons = [
    {
      id: 'saveButton',
      label: saveButtonLabel,
      onClick: (e) => close(e),
      disabled: false,
      title: saveButtonLabel,
      color: 'primary',
    },
  ],
  isLoading,
  maxWidth = 'sm',
  hideDialogActions = false,
  variant = 'primary',
  hideCloseButton = false,
  closeButtonClick,
  ...rest
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      TransitionComponent={Transition}
      onClose={(event, reason) => {
        if (!includes(['backdropClick', 'escapeKeyDown'], reason)) {
          close(event);
        }
      }}
      aria-labelledby={title}
      open={open}
      maxWidth={maxWidth}
      fullWidth
      fullScreen={fullScreen}
      {...rest}
    >
      <DialogTitle
        id={`${camelCase(title)}-id`}
        onClose={close}
        isLoading={isLoading}
        variant={variant}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ mb: 1, mt: 2, pb: hideDialogActions ? 2 : 0 }}>
        <Box sx={{ py: 1 }}>{children}</Box>
      </DialogContent>
      {!hideDialogActions && (
        <DialogActions sx={{ p: 2 }}>
          {!hideCloseButton && (
            <Button
              autoFocus
              onClick={closeButtonClick ? closeButtonClick : close}
              variant="outlined"
              disabled={isLoading}
            >
              {closeButtonLabel}
            </Button>
          )}
          {map(primaryButtons, (button) => (
            <LoadingButton
              key={button.id}
              autoFocus
              onClick={button.onClick}
              variant="contained"
              loading={isLoading}
              disabled={button.disabled}
              title={button.title}
              color={button.color}
            >
              {button.label}
            </LoadingButton>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default PopupDialog;
