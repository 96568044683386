import { Box, LinearProgress } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { has, isEmpty, isFunction, isNull, map, size } from 'lodash';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';

import { GridApi, ICellRendererParams } from 'ag-grid-enterprise';

import { renderToString } from 'react-dom/server';

import OverflowTooltip from '../OverflowTooltip';

import CustomPagination from './PaginationRenderer';
import SkeletonTable from './SkeletonTable';
window.Buffer = window.Buffer || require('buffer').Buffer;

interface ITable<T> extends AgGridReactProps<T> {
  isLoading: boolean;
  isSuccess: boolean;
  id?: string;
  pagination: boolean;
  rowData?: T[] | null;
  title?: string;
  gridOptions?: GridOptions<T>;
  rowCount: number;
  onGridReady?: (params: GridReadyEvent<T>) => void;
}

export interface AutoSizeRef {
  autoSizeAll: () => void;
}

const AgGridTable = <T,>(
  props: ITable<T>,
  ref: React.ForwardedRef<AgGridReact<T>>
) => {
  const autoSizeAll = (): void => {
    if (ref != null && !isFunction(ref)) {
      const allColumnIds = map(ref.current?.api!.getColumns(), (column) => {
        return (column as any).getId();
      });
      ref.current?.api!.autoSizeColumns(allColumnIds, false);
    }
  };

  // useImperativeHandle(ref, () => ({
  //   autoSizeAll,
  // }));

  useEffect(() => {
    if (
      ref != null &&
      !isFunction(ref) &&
      !isNull(ref.current) &&
      !isEmpty(ref.current) &&
      has(ref.current, 'api')
    ) {
      setTimeout(() => autoSizeAll(), 400);
    }
  }, [ref]);

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  const defaultExcelExportParams = useMemo(() => {
    return {
      allColumns: true,
    };
  }, []);

  const { isLoading, pagination, id, isSuccess, onGridReady, ...rest } = props;

  useEffect(() => {
    if (ref != null && !isFunction(ref)) {
      if (
        isLoading &&
        !isNull(ref.current) &&
        !isEmpty(ref.current) &&
        has(ref.current, 'api')
      ) {
        (ref.current?.api as unknown as GridApi).showLoadingOverlay();
      }
      if (
        isEmpty(props.rowData) &&
        !isNull(ref.current) &&
        !isEmpty(ref.current) &&
        has(ref.current, 'api')
      ) {
        (ref.current?.api as unknown as GridApi).showNoRowsOverlay();
      }
    }
  }, [isLoading, ref, props.rowData]);

  const base64Rows = props.rowData
    ? Buffer.from(JSON.stringify(props.rowData)).toString('base64')
    : Buffer.from(JSON.stringify(props.columnDefs)).toString('base64');

  return (
    <Box style={containerStyle}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {isLoading && <LinearProgress variant="indeterminate" />}
        <Box sx={{ flex: '1 1 0px' }}>
          <Box
            className="ag-theme-alpine"
            sx={{ height: size(props.rowData) > 10 ? '800px' : '100%' }}
          >
            <Box id={id} style={gridStyle}>
              <AgGridReact<T>
                animateRows
                key={base64Rows}
                ref={ref}
                pagination={pagination}
                domLayout={size(props.rowData) > 10 ? 'normal' : 'autoHeight'}
                paginationPageSize={props.paginationPageSize || 10}
                suppressPaginationPanel
                suppressMovableColumns
                suppressContextMenu
                defaultColDef={{
                  ...props.defaultColDef,
                  resizable: false,
                  cellRenderer: OverflowTooltip,
                }}
                statusBar={{
                  statusPanels: [
                    {
                      statusPanel: (
                        params: ICellRendererParams & {
                          paginationEnabled: boolean;
                          dataCount: number;
                        }
                      ) => <CustomPagination key={base64Rows} {...params} />,
                      statusPanelParams: {
                        dataCount: props.rowCount,
                        paginationEnabled: pagination,
                      },
                    },
                  ],
                }}
                gridOptions={props.gridOptions}
                defaultExcelExportParams={defaultExcelExportParams}
                overlayLoadingTemplate={
                  '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                }
                noRowsOverlayComponent={renderToString(<SkeletonTable />)}
                onGridReady={onGridReady}
                {...rest}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const TableRef = React.forwardRef(AgGridTable);

const Table = <T,>({
  gridRef,
  ...rest
}: ITable<T> & { gridRef?: React.Ref<AgGridReact<T>> }) => (
  <TableRef {...(rest as any)} ref={gridRef as any} />
);

export default Table;
