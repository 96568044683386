import './MuiClassNameSetup';
import './support/polyfills';
import './index.scss';

import React, { useEffect } from 'react';
import { LicenseManager } from 'ag-grid-enterprise';
import { createRoot } from 'react-dom/client';
import { CssBaseline, IconButton, ThemeProvider } from '@mui/material';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import { LicenseInfo } from '@mui/x-license-pro';

import { Close } from '@mui/icons-material';

import { MsalProvider } from '@azure/msal-react';

import App from './components/shared/App';
import { theme } from './theme';
import { Transition } from './components/shared/PopupDialog';
import SetGlobalStyles from './components/shared/setGlobalStyles';
import { msalInstance } from './services/authConfig';
LicenseManager.setLicenseKey(
  process.env.REACT_APP_AG_GRID_LICENSE_KEY as string
);
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string);

const Index = () => {
  useEffect(() => {
    if (!localStorage.getItem('cloudCastAuthVersion')) {
      localStorage.clear();
      localStorage.setItem('cloudCastAuthVersion', '2');
    }
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        {SetGlobalStyles(theme)}
        <ConfirmProvider
          defaultOptions={{
            confirmationButtonProps: { autoFocus: true },
            title: '',
          }}
        >
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            action={(snackbarId) => (
              <IconButton onClick={() => closeSnackbar(snackbarId)}>
                <Close />
              </IconButton>
            )}
            TransitionComponent={Transition}
            maxSnack={1}
            preventDuplicate
            autoHideDuration={5000}
          >
            <CssBaseline />
            <App />
          </SnackbarProvider>
        </ConfirmProvider>
      </ThemeProvider>
    </MsalProvider>
  );
};

let container: HTMLElement | null = null;

document.addEventListener('DOMContentLoaded', () => {
  if (!container) {
    container = document.getElementById('root') as HTMLElement;
    const root = createRoot(container);
    root.render(<Index />);
  }
});
