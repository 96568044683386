import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { Select } from '@mui/material';

import { map } from 'lodash';

import { ICellEditorParams } from 'ag-grid-community';

import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

import { IFormatParameters } from '../../../Visualization/formatParameters';

const dropdownValues = [
  {
    name: 'None',
    value: '',
  },
  {
    name: 'On',
    value: 'On',
  },
  {
    name: 'Off',
    value: 'Off',
  },
];

export default forwardRef(
  (props: ICellEditorParams & { formatKey: IFormatParameters }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>(props.value);

    const inputHandler = (e: SelectChangeEvent): void => {
      setValue(e.target.value);
    };

    useEffect(() => {
      inputRef.current?.focus();
    }, []);

    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value;
        },
        afterGuiAttached: () => {
          setValue(props.value);
          inputRef.current?.focus();
        },
      };
    });

    return (
      <Select
        displayEmpty
        size="small"
        style={{ padding: '5px 0' }}
        type="text"
        inputRef={inputRef}
        onChange={inputHandler}
        value={value}
        fullWidth
        native
      >
        {map(dropdownValues, (i) => (
          <option key={i.value} value={i.value}>
            {i.name}
          </option>
        ))}
      </Select>
    );
  }
);
