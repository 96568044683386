import React, { FunctionComponent, useEffect } from 'react';
import {
  filter,
  map,
  find,
  isEmpty,
  some,
  sortBy,
  get,
  fromPairs,
} from 'lodash';
import {
  Button,
  MenuItem,
  TextField,
  Typography,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import * as API from '../../../../services/API';
import { Client, Profile, User, UserRole } from '../../../../types/models';
import { isTrinityUser } from '../../../../utils/user';

import { IPortfolioReportStateData } from '../../index';

import useSetState from '../../../../hooks/useSetState';

import CenteredSpinner from '../../../shared/CenteredSpinner';

import {
  PortfolioReportMembership,
  PortfolioReportMembershipRole,
} from '../../types';

import { useData } from '../../../../hooks/useData';

import { useProfile } from '../../../../hooks/useProfile';

import UsersSearchMultiSelect from './UsersSearchMultiSelect';

import PaginatedSelectedUsers from './PaginatedSelectedUsers';

export const convertArrayToObj = <T extends { id: string; visible: boolean }>(
  value: T[]
) => fromPairs(map(value, (item) => [item.id, item.visible]));

interface IReportPermissionOptions {
  label: 'Report Viewer' | 'Report Owner';
  value: PortfolioReportMembershipRole;
}

const reportPermissionOptions: IReportPermissionOptions[] = [
  { label: 'Report Viewer', value: 0 },
  { label: 'Report Owner', value: 1 },
];

interface IPortfolioReportSharingProps {
  clientId?: Client['id'];
  selectedReport?: IPortfolioReportStateData;
}

export type PortfolioReportMembershipWithClient = PortfolioReportMembership & {
  Client: Client['id'] | null;
  Display_Name: User['Display_Name'];
};

interface IPortfolioReportSharingState {
  selectedTab: string;
  selectedUsers: User[];
  description: string;
  isAccordionOpen: boolean;
  userReportSharedStatus: Record<User['id'], boolean>;
  usersPerReport: PortfolioReportMembershipWithClient[];
  reportPermissionFilter: number;
}

const initialPortfolioReportSharingState: IPortfolioReportSharingState = {
  selectedTab: 'all',
  selectedUsers: [],
  description: '',
  isAccordionOpen: true,
  userReportSharedStatus: {},
  usersPerReport: [],
  reportPermissionFilter: PortfolioReportMembershipRole.Viewer,
};

const ReportSharing: FunctionComponent<IPortfolioReportSharingProps> = (
  props: IPortfolioReportSharingProps
) => {
  const [state, setState] = useSetState<IPortfolioReportSharingState>(
    initialPortfolioReportSharingState
  );

  const profile: Profile = useProfile();

  const {
    data: UsersPerReportData,
    refresh,
    loading,
  } = useData<{
    usersPerReport?: PortfolioReportMembershipWithClient[];
  }>(
    () => ({
      usersPerReport:
        props.selectedReport?.id !== undefined
          ? `/portfolio_reports/${props.selectedReport?.id}/portfolio_report_memberships`
          : undefined,
    }),
    []
  );

  const { data } = useData<{
    usersPerClient?: User[];
    client?: Client[];
  }>(
    () => ({
      usersPerClient:
        props.clientId !== undefined ? `/users/${props.clientId}` : undefined,
      client: props.clientId !== undefined ? `/clients` : undefined,
    }),
    [],
    false
  );

  useEffect(() => {
    const selectedUsers = filter(data.usersPerClient, (item) =>
      map(UsersPerReportData.usersPerReport, 'UserID').includes(item.id)
    );
    setState({
      userReportSharedStatus: convertArrayToObj(
        map(selectedUsers, (item) => {
          const user = find(UsersPerReportData.usersPerReport, {
            UserID: item.id,
          });
          return {
            id: item.id.toString(),
            visible:
              (user && user.Role === PortfolioReportMembershipRole.Owner) ||
              false,
          };
        })
      ),
    });
  }, [data.usersPerClient, UsersPerReportData.usersPerReport]);

  useEffect(() => {
    setState({
      usersPerReport: UsersPerReportData.usersPerReport,
    });
  }, [UsersPerReportData.usersPerReport]);

  const handleChangeUser = (users: User[]): void => {
    setState({
      selectedUsers: users,
    });
  };

  const selectedUsers = (): any => {
    switch (state.selectedTab) {
      case 'all':
        return state.selectedUsers;
      case 'trinity':
        return filter(state.selectedUsers, { ClientID: null });
      case 'client':
        return filter(state.selectedUsers, { ClientID: props.clientId });
      default:
        return [];
    }
  };

  const usersList = (): User[] => {
    switch (state.selectedTab) {
      case 'all':
        return filter(
          data.usersPerClient,
          (item) => !some(state.usersPerReport, { UserID: item.id })
        );
      case 'trinity':
        return filter(
          data.usersPerClient,
          (item) =>
            item.ClientID === null &&
            !some(state.usersPerReport, { UserID: item.id })
        );
      case 'client':
        return filter(
          data.usersPerClient,
          (item) =>
            item.ClientID === props.clientId &&
            !some(state.usersPerReport, { UserID: item.id })
        );
      default:
        return [];
    }
  };

  const clientName = get(
    find(data.client, { id: props.clientId }) as Client,
    'Name'
  );

  return (
    <>
      <Grid container sx={{ pb: 2, alignItems: 'center' }} spacing={2}>
        <Grid>
          <Typography variant="h6">Share Report</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid sm={9}>
          <FormControl fullWidth>
            <UsersSearchMultiSelect
              usersList={sortBy(usersList() || [], ['Display_Name'])}
              preSelectedUsers={selectedUsers()}
              handleUsersChange={handleChangeUser}
              nonRemovableIds={[profile.User.id]}
            />
          </FormControl>
        </Grid>
        <Grid sm={2}>
          <TextField
            select
            fullWidth
            label="Access"
            size="small"
            value={state.reportPermissionFilter}
            onChange={(e) =>
              setState({
                reportPermissionFilter: Number(e.target.value),
              })
            }
            variant="outlined"
          >
            {map(reportPermissionOptions, (item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid sm={1} container justifyContent="flex-end">
          <Button
            variant="contained"
            disabled={isEmpty(state.selectedUsers)}
            onClick={async () => {
              const readOnlyUsers = filter(state.selectedUsers, {
                Role: UserRole.Client_Read_Only_User,
              });
              if (
                readOnlyUsers.length > 0 &&
                state.reportPermissionFilter === 1
              ) {
                alert(
                  `You are assigning an Owner Role to a read only user in the list. Please remove the read only user(s) "${map(
                    readOnlyUsers,
                    'Display_Name'
                  ).join(readOnlyUsers.length > 1 ? ', ' : '')}"  to proceed.`
                );
              } else {
                const result = await API.create(
                  `/portfolio_reports/${props.selectedReport?.id}/portfolio_report_memberships`,
                  {
                    data: map(state.selectedUsers, (item) => {
                      return {
                        ReportID: props.selectedReport?.id,
                        UserID: item.id,
                        Role: state.reportPermissionFilter,
                      };
                    }),
                  }
                );
                if (result) {
                  refresh();
                  setState({ selectedUsers: [] });
                }
              }
            }}
          >
            Share
          </Button>
        </Grid>
      </Grid>
      <Grid container sx={{ my: 2, alignItems: 'center' }} spacing={2}>
        <Grid>
          <Typography variant="h6">Users</Typography>
        </Grid>
        {isTrinityUser(profile.User) && (
          <Grid>
            <ToggleButtonGroup
              value={state.selectedTab}
              exclusive
              color="primary"
              size="small"
              onChange={(event: React.SyntheticEvent, selectedTab: string) =>
                setState({ selectedTab })
              }
              aria-label="Report Sharing Tabs"
            >
              <ToggleButton value="all">All</ToggleButton>
              <ToggleButton value="client">{clientName}</ToggleButton>
              <ToggleButton value="trinity">Trinity</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
      </Grid>
      {!loading && (
        <Grid container>
          <Grid sm={12} sx={{ my: 2 }}>
            {(() => {
              const usersPerReportWithClient = map(
                UsersPerReportData.usersPerReport,
                (item) => {
                  item.Client = get(
                    find(data.usersPerClient, {
                      id: item.UserID,
                    }) as User,
                    'ClientID'
                  );
                  item.Display_Name = get(
                    find(data.usersPerClient, {
                      id: item.UserID,
                    }) as User,
                    'Display_Name'
                  );
                  return item;
                }
              );

              const selectedUsersPerReportWithClient =
                (): PortfolioReportMembershipWithClient[] => {
                  switch (state.selectedTab) {
                    case 'all':
                      return usersPerReportWithClient;
                    case 'trinity':
                      return filter(usersPerReportWithClient, {
                        Client: null,
                      });
                    case 'client':
                      return filter(usersPerReportWithClient, {
                        Client: props.clientId,
                      });
                    default:
                      return [];
                  }
                };
              if (loading) {
                return <CenteredSpinner />;
              } else if (
                !isEmpty(selectedUsersPerReportWithClient()) &&
                !isEmpty(data.usersPerClient) &&
                !isEmpty(data.client)
              ) {
                return (
                  <PaginatedSelectedUsers
                    usersList={sortBy(selectedUsersPerReportWithClient(), [
                      'Display_Name',
                    ])}
                    usersPerClient={data.usersPerClient}
                    userReportSharedStatus={state.userReportSharedStatus}
                    selectedReport={props.selectedReport}
                    refresh={refresh}
                    clientName={clientName}
                  />
                );
              }
            })()}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ReportSharing;
