import React, { useContext, useEffect } from 'react';
import { format } from 'date-fns';
import { split, replace, isEqual, get } from 'lodash';
import { useLocation } from 'react-router-dom';
import { DateRange } from '@mui/x-date-pickers-pro';

import { IconButton } from '@mui/material';

import { Close } from '@mui/icons-material';

import { Timescale } from '../../../../../types/models';

import { GetParams } from '../../../../../utils/history';

import { ProfileContext } from '../../../../shared/AuthController';

import useSetState from '../../../../../hooks/useSetState';

import MonthRangePicker from './MonthRangePicker';
import WeeklyRangePicker from './WeeklyRangePicker';
import QuarterRangePicker from './QuarterRangePicker';
import YearRangePicker from './YearRangePicker';

export interface IChartDataDate {
  DateId: number;
  Date: string;
}
interface IChartRangePickerProps<T> {
  type: Timescale;
  minRange: T;
  maxRange: T;
  onHandleRangeChange: (startRange: string, endRange: string) => void;
  value: [string, string] | null;
  dates: IChartDataDate[];
}

interface IChartRangePickerState {
  valueState: any[];
}

const ChartRangePicker = <T extends { Date: string }>({
  type,
  minRange,
  maxRange,
  onHandleRangeChange,
  value,
  dates,
}: IChartRangePickerProps<T>) => {
  const minMaxRanges = (
    min: string,
    max: string
  ):
    | [Date, Date]
    | [{ month: number; year: number }, { month: number; year: number }]
    | [{ quarter: number; year: number }, { quarter: number; year: number }]
    | [number, number] => {
    switch (type) {
      case Timescale.Weekly:
        return [new Date(min), new Date(max)];
      case Timescale.Monthly:
        return [
          {
            month: Number(format(new Date(min), 'M')),
            year: Number(format(new Date(min), 'yyyy')),
          },
          {
            month: Number(format(new Date(max), 'M')),
            year: Number(format(new Date(max), 'yyyy')),
          },
        ];
      case Timescale.Quarterly:
        return [
          {
            quarter: Number(replace(split(min, ' ')[1], /[^0-9]/g, '')),
            year: Number(split(min, ' ')[0]),
          },
          {
            quarter: Number(replace(split(max, ' ')[1], /[^0-9]/g, '')),
            year: Number(split(max, ' ')[0]),
          },
        ];
      case Timescale.Yearly:
        return [Number(min), Number(max)];
    }
  };

  const profile = useContext(ProfileContext);

  const location = useLocation();
  const { search } = location;
  const params = GetParams(search);

  const clientId =
    profile && profile.User.ClientID !== null
      ? profile.User.ClientID
      : params.clientId;

  const [state, setState] = useSetState<IChartRangePickerState>({
    valueState: value
      ? value
      : minMaxRanges(get(minRange, 'Date', ''), get(maxRange, 'Date', '')),
  });

  useEffect(() => {
    if (value) {
      setState({ valueState: minMaxRanges(value[0], value[1]) });
    } else {
      setState({
        valueState: minMaxRanges(
          get(minRange, 'Date', ''),
          get(maxRange, 'Date')
        ),
      });
    }
  }, [value, type]);

  const rangePickers: Record<Timescale, JSX.Element> = {
    [Timescale.Weekly]: (
      <WeeklyRangePicker
        maxDate={
          minMaxRanges(
            get(minRange, 'Date', ''),
            get(maxRange, 'Date', '')
          )[1] as Date
        }
        minDate={
          minMaxRanges(get(minRange, 'Date', ''), maxRange?.Date)[0] as Date
        }
        onDateChange={(date) => {
          onHandleRangeChange(
            format(date[0] as Date, 'M/d/yyyy'),
            format(date[1] as Date, 'M/d/yyyy')
          );
        }}
        value={state.valueState as DateRange<Date>}
        clientId={clientId as number}
      />
    ),
    [Timescale.Monthly]: (
      <MonthRangePicker
        startYear={
          minMaxRanges(
            get(minRange, 'Date', ''),
            get(maxRange, 'Date', '')
          )[0] as {
            month: number;
            year: number;
          }
        }
        endYear={
          minMaxRanges(
            get(minRange, 'Date', ''),
            get(maxRange, 'Date', '')
          )[1] as {
            month: number;
            year: number;
          }
        }
        onChange={(startYear, endYear) => {
          onHandleRangeChange(
            `${startYear.month}/1/${startYear.year}`,
            `${endYear.month}/1/${endYear.year}`
          );
        }}
        dates={dates}
        value={state.valueState}
      />
    ),
    [Timescale.Quarterly]: (
      <QuarterRangePicker
        startYear={
          minMaxRanges(
            get(minRange, 'Date', ''),
            get(maxRange, 'Date', '')
          )[0] as {
            quarter: number;
            year: number;
          }
        }
        endYear={
          minMaxRanges(
            get(minRange, 'Date', ''),
            get(maxRange, 'Date', '')
          )[1] as {
            quarter: number;
            year: number;
          }
        }
        onChange={(startYear, endYear) =>
          onHandleRangeChange(
            `${startYear.year} Q${startYear.quarter}`,
            `${endYear.year} Q${endYear.quarter}`
          )
        }
        dates={dates}
        value={state.valueState}
      />
    ),
    [Timescale.Yearly]: (
      <YearRangePicker
        endYear={
          minMaxRanges(
            get(minRange, 'Date', ''),
            get(maxRange, 'Date', '')
          )[1] as number
        }
        startYear={
          minMaxRanges(
            get(minRange, 'Date', ''),
            get(maxRange, 'Date', '')
          )[0] as number
        }
        onChange={(startYear, endYear) =>
          onHandleRangeChange(String(startYear), String(endYear))
        }
        value={state.valueState}
      />
    ),
  };

  const showClearButton = () => {
    return !isEqual(
      state.valueState,
      minMaxRanges(get(minRange, 'Date', ''), get(maxRange, 'Date', ''))
    );
  };

  const clearDates = () => {
    setState({
      valueState: minMaxRanges(
        get(minRange, 'Date', ''),
        get(maxRange, 'Date', '')
      ),
    });
    onHandleRangeChange(get(minRange, 'Date', ''), get(maxRange, 'Date', ''));
  };

  return (
    <>
      {rangePickers[type]}
      {showClearButton() && (
        <IconButton size="small" onClick={clearDates}>
          <Close fontSize="small" />
        </IconButton>
      )}
    </>
  );
};

export default ChartRangePicker;
