import React from 'react';
import { CircularProgress } from '@mui/material';

import Flex from './Flex';

const Splash = () => (
  <Flex
    sx={{
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgress />
  </Flex>
);

export default Splash;
