import React, { FunctionComponent } from 'react';

import PopupDialog from '../../shared/PopupDialog';

interface IClearAllEditsDialogProps {
  open: boolean;
  onClose: () => void;
  onReset: () => void;
}

const ClearAllEditsDialog: FunctionComponent<IClearAllEditsDialogProps> = ({
  open,
  onClose,
  onReset,
}) => {
  return (
    <PopupDialog
      open={open}
      close={onClose}
      title="Clear All Edits"
      primaryButtons={[
        {
          id: 'resetButton',
          label: 'Confirm',
          onClick: onReset,
        },
      ]}
    >
      Are you sure you want to discard all of your changes made to this input
      and reset them to their original values?
    </PopupDialog>
  );
};

export default ClearAllEditsDialog;
