import React, { ChangeEvent, FunctionComponent, useCallback } from 'react';
import { TextField, MenuItem } from '@mui/material';

import { map } from 'lodash';

import { SourceDropDown } from '../../types/models';

interface Props {
  disabled: boolean;
  source?: SourceDropDown;
  onChange: (source: SourceDropDown) => void;
  size?: 'small' | 'medium';
}

interface SourceOption {
  title: string;
  value: SourceDropDown;
}

const SOURCES: SourceOption[] = [
  {
    title: 'Inputs',
    value: 'inputs',
  },
  {
    title: 'Outputs',
    value: 'outputs',
  },
  {
    title: 'Standard Report',
    value: 'standardReport',
  },
];

const IOSelect: FunctionComponent<Props> = ({
  disabled,
  source,
  onChange,
  size = 'medium',
}) => {
  const handleSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      onChange(event.target.value as SourceDropDown),
    [onChange]
  );

  return (
    <>
      <TextField
        disabled={disabled}
        select
        value={source}
        onChange={handleSelect}
        fullWidth
        label="Select a Source"
        size={size}
      >
        {map(SOURCES, (source) => (
          <MenuItem key={source.value} value={source.value}>
            {source.title}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default IOSelect;
