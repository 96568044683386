import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  filter as filterList,
  includes,
  indexOf,
  isEqual,
  map,
  sortBy,
  toLower,
  uniq,
} from 'lodash';

import { Box, Typography } from '@mui/material';

import { RowIdentifier } from '../../types/models';

import CheckboxCard from './CheckboxCard';
import { FixedHeader } from './FixedHeader';

import { Dispatch } from './Store';
import InputFilterSelect, { InputFilterType } from './InputFilterSelect';

interface Props {
  inputs: RowIdentifier[];
  selection: number[];
  dispatch: Dispatch;
}

const InputsColumn: FunctionComponent<Props> = ({
  inputs,
  selection,
  dispatch,
}) => {
  const [filter, setFilter] = useState<string>(InputFilterType.Category);
  const [inputSearch, setInputSearch] = useState<string>('');
  const [inputSelect, setInputSelect] = useState<boolean>(false);

  const filteredTabNames = sortBy(
    uniq(
      map(
        filterList(inputs, (i) =>
          includes(toLower(i.Name), toLower(inputSearch))
        ),
        'TabName'
      )
    )
  );
  const filteredCategoryNames = sortBy(
    uniq(
      map(
        filterList(inputs, (i) =>
          includes(toLower(i.Name), toLower(inputSearch))
        ),
        'InputCategory'
      )
    )
  );

  const handleInputSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputSearch(event.target.value);
  };

  const handleInputSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputSelect(event.target.checked);
    dispatch({
      type: 'TOGGLE_INPUTS',
      ids: map(
        filterList(inputs, (i) =>
          includes(toLower(i.Name), toLower(inputSearch))
        ),
        'id'
      ),
      value: event.target.checked,
    });
  };

  useEffect(() => {
    setInputSelect(isEqual(selection.length, inputs.length));
  }, [selection]);

  return (
    <Box>
      <FixedHeader>
        <Typography variant="subtitle1" sx={{ mt: 1 }}>
          Inputs
        </Typography>
      </FixedHeader>
      <Box pb={4} />
      <InputFilterSelect
        filter={filter}
        onFilterChange={(filter) => setFilter(filter)}
        inputSearch={inputSearch}
        handleInputSearchChange={handleInputSearchChange}
        inputSelect={inputSelect}
        handleInputSelectChange={handleInputSelectChange}
      />
      <Box pb={1.9} />
      {filter === InputFilterType.Category && (
        <Box pb={2}>
          {map(filteredCategoryNames, (categoryName) => (
            <section key={categoryName}>
              <Typography mb={2} variant="caption">
                {categoryName}
              </Typography>
              {map(
                filterList(
                  filterList(inputs, { InputCategory: categoryName }),
                  (i) => includes(toLower(i.Name), toLower(inputSearch))
                ),
                (input) => (
                  <Box key={input.id}>
                    <CheckboxCard
                      checked={indexOf(selection, input.id) !== -1}
                      onCardClick={(checked) =>
                        dispatch({
                          type: 'TOGGLE_INPUT',
                          id: input.id,
                          value: checked,
                        })
                      }
                      labelElement={input.Name}
                    />
                    <Box pb={2} />
                  </Box>
                )
              )}
            </section>
          ))}
        </Box>
      )}
      {filter === InputFilterType.TabName && (
        <Box pb={2}>
          {map(filteredTabNames, (tabName) => (
            <section key={tabName}>
              <Typography mb={2} variant="caption">
                {tabName}
              </Typography>
              {map(
                filterList(filterList(inputs, { TabName: tabName }), (i) =>
                  includes(toLower(i.Name), toLower(inputSearch))
                ),
                (input) => (
                  <Box key={input.id}>
                    <CheckboxCard
                      checked={indexOf(selection, input.id) !== -1}
                      onCardClick={(checked) =>
                        dispatch({
                          type: 'TOGGLE_INPUT',
                          id: input.id,
                          value: checked,
                        })
                      }
                      labelElement={input.Name}
                    />
                    <Box pb={2} />
                  </Box>
                )
              )}
            </section>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default InputsColumn;
