import React, { ChangeEvent, PureComponent } from 'react';
import { map, toString, size, filter } from 'lodash';
import { MenuItem, TextField } from '@mui/material';

import Loader, { LoaderInfo } from '../../../../shared/Loader';
import { Scenario } from '../../../../../types/models';

interface Props {
  large?: boolean;
  modelId: number;
  modelInstanceId: number;
  scenarioId?: number;
  onChange(scenarioId?: number): void;
  disableScenarioId?: Scenario['id'];
}

class ToScenarios extends PureComponent<Props> {
  public static defaultProps = { large: true };

  public render() {
    const { modelInstanceId } = this.props;

    const needs = {
      scenarios:
        modelInstanceId !== undefined
          ? `/instances/${modelInstanceId}/scenarios_load/finished`
          : undefined,
    };

    return <Loader needs={needs} render={this.renderContent} />;
  }

  private renderContent = ({
    loading,
    errors,
    data,
  }: LoaderInfo<{ scenarios?: Scenario[] }>) => {
    const { modelInstanceId, scenarioId } = this.props;

    let disabled = false;

    if (modelInstanceId === undefined) {
      disabled = true;
    } else if (errors.length > 0) {
      disabled = true;
    } else if (size(data.scenarios) < 1) {
      disabled = true;
    }

    return (
      <TextField
        select
        disabled={disabled || loading}
        value={toString(scenarioId)}
        label="Select To Scenario"
        fullWidth
        onChange={this.handleItemSelect}
      >
        {map(filter(data.scenarios, ['Archive_Flag', 0]), (s) => (
          <MenuItem
            key={s.id}
            value={s.id}
            disabled={s.id === this.props.disableScenarioId}
          >
            {s.Name}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  private handleItemSelect = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(Number(e.target.value));
  };
}

export default ToScenarios;
