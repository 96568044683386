import React, {
  FunctionComponent,
  MouseEvent,
  useContext,
  useEffect,
  useRef,
} from 'react';

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  every,
  filter,
  find,
  flatMap,
  get,
  includes,
  isEmpty,
  isNull,
  map,
  orderBy,
  toLower,
  uniq,
} from 'lodash';
import { format } from 'date-fns';
import {
  Box,
  Button,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AgGridReact } from 'ag-grid-react';

import { Add, MoreHoriz, PlayArrow } from '@mui/icons-material';
import { blue, green, grey } from '@mui/material/colors';
import { useSnackbar } from 'notistack';
import { ColDef } from 'ag-grid-community';
import { ICellRendererParams } from 'ag-grid-enterprise';

import * as API from '../../services/API';

import APICacheContext from '../shared/APICacheContext';
import { Model, ModelInstance, Profile, UserRole } from '../../types/models';
import './portfolioDashboard.scss';

import { parseId } from '../../utils/parsing';

import APICache from '../../services/APICache';

import StyledMenu from '../shared/StyledMenu';

import useSetState from '../../hooks/useSetState';

import FullScreenProgress from '../shared/FullScreenProgress';

import Table from '../shared/Table';

import { useWindowDimensions } from '../../hooks/useWindowDimensions';

import { useProfile } from '../../hooks/useProfile';

import { useData } from '../../hooks/useData';

import ClientIndex from '../shared/ClientIndex';

import CreateNewReportDialog from './CreateNewReportDialog';
import UpdateReportNameDialog from './UpdateReportNameDialog';
import EditPortfolioSourceType from './PortfolioReportDetails/ReportEdit/EditPortfolioSourceType';
import PortfolioReportDetails from './PortfolioReportDetails';
import DeleteReportDialog from './DeleteReportDialog';
import PortfolioReportGeneratedFileDownload from './PortfolioReportGeneratedFileDownload';
import {
  PortfolioDataSourceData,
  PortfolioReportData,
  PortfolioReportGeneratedFile,
  PortfolioReportMembershipRole,
  PortfolioReportMemberships,
  PortfolioReportPermissions,
} from './types';
import ReportTemplateDownloadDialog from './ReportTemplateDownloadDialog';

const RECENT_REPORTS_LENGTH = 10;

export const DetailRowRenderer = (
  params: ICellRendererParams<IPortfolioReportStateData>
) => {
  return (
    <Box
      px={2}
      sx={{
        '*': {
          marginTop: 1,
        },
      }}
    >
      {params.data?.Description && (
        <Grid container alignItems="center">
          <Grid md={1.5}>
            <strong>Description:</strong>
          </Grid>
          <Grid md={10.5}>
            <Typography sx={{ whiteSpace: 'normal' }}>
              {params.data?.Description}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container alignItems="center">
        <Grid md={1.5}>
          <strong>Last Updated:</strong>
        </Grid>
        <Grid md={10.5}>
          <Typography sx={{ whiteSpace: 'normal' }}>
            {format(
              new Date(params.data?.updated_at as string),
              'MM/dd/yyyy hh:mm:ss a'
            )}
          </Typography>
        </Grid>
      </Grid>
      {!isEmpty(params.data?.Models) && (
        <Grid container alignItems="center">
          <Grid md={1.5}>
            <strong>Models: </strong>
          </Grid>
          <Grid md={10.5}>
            <List disablePadding dense>
              {map(uniq(params.data?.Models), (model, index) => (
                <ListItem
                  key={`model-${index}`}
                  dense
                  disablePadding
                  disableGutters
                >
                  <ListItemText primary={model} />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const SortAlphaNum = (a: string, b: string): 1 | -1 | 0 => {
  const reA = /[^a-zA-Z]/g;
  const reN = /[^0-9]/g;
  const aA = a.replace(reA, '');
  const bA = b.replace(reA, '');
  if (aA === bA) {
    const aN = parseInt(a.replace(reN, ''), 10);
    const bN = parseInt(b.replace(reN, ''), 10);
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
};

interface ISelectedModels {
  name: Model['Name'];
  selected: boolean;
  visible: boolean;
}

type ISelectedReportType =
  | 'Recent'
  | 'Private Reports'
  | 'Shared Reports'
  | 'All Reports'
  | 'Viewer'
  | 'Owner';

export interface IPortfolioReportStateData {
  ClientID: PortfolioReportData['ClientID'];
  Name: PortfolioReportData['Name'];
  Description: PortfolioReportData['Description'];
  ReportPermission: PortfolioReportData['PermissionLevel'];
  ReportMembership: PortfolioReportMemberships['Role'];
  ReportState: PortfolioReportData['State'];
  ReportType: PortfolioReportData['Type'];
  id: PortfolioReportData['id'];
  updated_at: PortfolioReportData['updated_at'];
  updated_by: PortfolioReportData['updated_by'];
  Models: Model['Name'][];
  ModelInstances: ModelInstance['id'][];
  DataSourceID: PortfolioDataSourceData['id'];
  ModelID: PortfolioReportData['ModelID'];
  ModelInstanceID: PortfolioReportData['ModelInstanceID'];
  Metadata: any;
  ReportTemplateURL: PortfolioReportData['ReportTemplateURL'];
  ReportTemplateName: PortfolioReportData['ReportTemplateName'];
  CustomDownloadBlobName: PortfolioReportData['CustomDownloadBlobName'];
}

interface IPortfolioState {
  isCreateNewReportDialogOpen: boolean;
  reportsData: IPortfolioReportStateData[];
  originalReportsData: IPortfolioReportStateData[];
  isEditDataSourceDialogOpen: boolean;
  selectedReport?: IPortfolioReportStateData;
  selectedReportDataSource?: PortfolioDataSourceData;
  isRenameReportDialogOpen: boolean;
  isDownloadTemplateDialogOpen: boolean;
  selectedReportType: ISelectedReportType;
  isModelFilterOpen: boolean;
  selectedModels: ISelectedModels[];
  originalSelectedModels: ISelectedModels[];
  expandedRow: number[];
  redirectToReportsPage: boolean;
  isDeleteDialogOpen: boolean;
  isOnlyViewerForAllReports: boolean;
  generatedFileID?: PortfolioReportGeneratedFile['id'];
  isGeneratedFileDialogOpen: boolean;
  isAnyFieldUpdated: boolean;
  modelFilterText: string;
  isPaginationEnabled: boolean;
  runReport: boolean;
}

const initialPortfolioState: IPortfolioState = {
  isCreateNewReportDialogOpen: false,
  reportsData: [],
  originalReportsData: [],
  isEditDataSourceDialogOpen: false,
  selectedReport: undefined,
  isRenameReportDialogOpen: false,
  isDownloadTemplateDialogOpen: false,
  selectedReportType: 'Recent',
  isModelFilterOpen: false,
  selectedModels: [],
  originalSelectedModels: [],
  expandedRow: [],
  redirectToReportsPage: false,
  isDeleteDialogOpen: false,
  isOnlyViewerForAllReports: false,
  generatedFileID: undefined,
  isGeneratedFileDialogOpen: false,
  isAnyFieldUpdated: false,
  modelFilterText: '',
  isPaginationEnabled: false,
  runReport: false,
};

const getFilteredReports = (
  selectedReportType: ISelectedReportType,
  originalReportsData: IPortfolioReportStateData[]
): IPortfolioReportStateData[] => {
  switch (selectedReportType) {
    case 'Recent':
      return orderBy(originalReportsData, ['updated_at'], ['desc']).slice(
        0,
        RECENT_REPORTS_LENGTH
      );
    case 'Private Reports':
      return filter(orderBy(originalReportsData, ['updated_at'], ['desc']), {
        ReportPermission: PortfolioReportPermissions.Private,
      });
    case 'Shared Reports':
      return filter(orderBy(originalReportsData, ['updated_at'], ['desc']), {
        ReportPermission: PortfolioReportPermissions.Shared,
      });
    case 'All Reports':
      return orderBy(originalReportsData, ['updated_at'], ['desc']);
    case 'Viewer':
      return filter(orderBy(originalReportsData, ['updated_at'], ['desc']), {
        ReportMembership: PortfolioReportMembershipRole.Viewer,
      });
    case 'Owner':
      return filter(orderBy(originalReportsData, ['updated_at'], ['desc']), {
        ReportMembership: PortfolioReportMembershipRole.Owner,
      });
  }
};

const PortfolioDashboard: FunctionComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const gridRef = useRef<AgGridReact<IPortfolioReportStateData>>(null);
  const { width } = useWindowDimensions();

  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useSetState<IPortfolioState>(initialPortfolioState);

  const profile: Profile = useProfile();

  const isClientReadOnlyUser =
    profile.User.Role === UserRole.Client_Read_Only_User;
  const location = useLocation();
  const { search } = location;
  const params = (): { clientId: undefined | number } => {
    const params = new URLSearchParams(search);
    const clientId =
      profile && profile.User.ClientID !== null
        ? profile.User.ClientID
        : parseId(params.get('client'));

    return { clientId };
  };

  const apiContext: APICache | undefined = useContext(APICacheContext);

  useEffect(() => {
    setState({
      selectedModels: map(state.originalSelectedModels, (item) => {
        return {
          ...item,
          visible: includes(toLower(item.name), state.modelFilterText),
        };
      }),
    });
  }, [state.modelFilterText]);

  const { data, refresh, loading } = useData<{
    report?: PortfolioReportData[];
    models?: Model[];
  }>(
    () => ({
      report:
        params().clientId !== undefined
          ? `/clients/${params().clientId}/portfolio_reports`
          : undefined,
      models:
        params().clientId !== undefined
          ? `/clients/${params().clientId}/models`
          : undefined,
    }),
    [params().clientId]
  );

  useEffect(() => {
    if (!isEmpty(data && data.report)) {
      const reportsData: IPortfolioReportStateData[] = map(
        data && data.report,
        (item) => {
          return {
            Name: item.Name,
            Description: item.Description,
            CustomDownloadBlobName: item.CustomDownloadBlobName,
            ReportTemplateName: item.ReportTemplateName,
            ReportTemplateURL: item.ReportTemplateURL,
            ReportPermission: item.PermissionLevel,
            ReportMembership: (
              find(item.PortfolioReportMemberships, {
                UserID: profile.User.id,
              }) as PortfolioReportMemberships
            ).Role,
            ReportState: item.State,
            ReportType: item.Type,
            id: item.id,
            updated_at: item.updated_at,
            updated_by: {
              Display_Name: item.updated_by.Display_Name,
              User_Name: item.updated_by.User_Name,
            },
            Models: map(
              map(
                flatMap(item.PortfolioDataSources, 'PortfolioDataCuts'),
                'Models'
              ),
              'Name'
            ),
            ModelInstances: (() => {
              const datacuts = flatMap(
                item.PortfolioDataSources,
                'PortfolioDataCuts'
              );
              return map(
                flatMap(datacuts, 'MetaData'),
                'ModelInstance'
              ) as ModelInstance['id'][];
            })(),
            DataSourceID: item.PortfolioDataSources[0].id,
            ModelID: item.ModelID,
            ModelInstanceID: item.ModelInstanceID,
            ClientID: item.ClientID,
            Metadata: item.Metadata,
          };
        }
      );
      setState({
        reportsData: orderBy(reportsData, ['updated_at'], ['desc']),
        originalReportsData: orderBy(reportsData, ['updated_at'], ['desc']),
        selectedModels: map(
          uniq(flatMap(reportsData, 'Models')).sort(SortAlphaNum),
          (item) => {
            return {
              name: item,
              selected: true,
              visible: true,
            };
          }
        ),
        ...(!isEmpty(state.selectedReport) && {
          selectedReport: find(reportsData, {
            id: get(state.selectedReport, 'id'),
          }),
        }),
        originalSelectedModels: map(
          uniq(flatMap(reportsData, 'Models')).sort(SortAlphaNum),
          (item) => {
            return {
              name: item,
              selected: true,
              visible: true,
            };
          }
        ),
        isOnlyViewerForAllReports:
          every(reportsData, {
            ReportMembership: PortfolioReportMembershipRole.Viewer,
          }) || isClientReadOnlyUser,
      });
    } else {
      setState({
        reportsData: [],
        originalReportsData: [],
      });
    }
  }, [data.report]);

  useEffect(() => {
    const filteredReportsData = getFilteredReports(
      state.selectedReportType,
      state.originalReportsData
    );
    setState({
      reportsData: filteredReportsData,
    });
  }, [state.selectedModels]);

  const handleOpenCreateNewReport = (): void => {
    setState({ isCreateNewReportDialogOpen: true, selectedReport: undefined });
  };

  const handleCloseCreateNewReport = (): void => {
    setState({ isCreateNewReportDialogOpen: false });
  };

  const handleEditDataSource = async (
    row: IPortfolioReportStateData
  ): Promise<void> => {
    // setState({
    //   isEditDataSourceDialogOpen: true,
    //
    //   selectedReport: row,
    // });
    // await onClickReport(row);
    onClickReportDetails(row);
  };

  const handleCloseEditDataSourceModal = (): void => {
    setState({
      isEditDataSourceDialogOpen: false,
      selectedReport: undefined,
    });
  };

  const getDataSourceData = async (
    id?: PortfolioDataSourceData['id']
  ): Promise<void> => {
    try {
      const datasource = await apiContext?.load(
        [`/portfolio_datasources/${id}`],
        true
      );
      setState({ selectedReportDataSource: datasource && datasource[0] });
    } catch (e) {
      enqueueSnackbar(e as string, { variant: 'error' });
    }
  };

  // const onClickReport = (item: IPortfolioReportStateData): void => {
  //   if (state.selectedReport?.id !== item.id) {
  //     setState({
  //       selectedReport: item,
  //     });
  //     getDataSourceData(item.DataSourceID);
  //   }
  // };

  const handleOpenRenameReport = (row: IPortfolioReportStateData): void => {
    setState({
      isRenameReportDialogOpen: true,
      selectedReport: row,
    });
  };

  const handleOpenDeleteReport = (row: IPortfolioReportStateData): void => {
    setState({
      isDeleteDialogOpen: true,
      selectedReport: row,
    });
  };

  const handleOpenReportTemplateDownload = (
    row: IPortfolioReportStateData
  ): void => {
    setState({
      isDownloadTemplateDialogOpen: true,
      selectedReport: row,
    });
  };

  const handleCloseRenameReport = (): void => {
    setState({
      isRenameReportDialogOpen: false,
    });
  };

  const handleCloseReportTemplateDownload = (): void => {
    setState({
      isDownloadTemplateDialogOpen: false,
    });
  };

  const handleRunReport = async (
    report: IPortfolioReportStateData
  ): Promise<void> => {
    if (report.ReportType === 'Portfolio') {
      try {
        const result = await API.update<PortfolioReportData>(
          `/portfolio_reports/${report.id}/portfolio_report_generated_files`,
          {
            FileName: `${report.Name}_${format(
              new Date(),
              'yyyy-MM-ddTHH:MM'
            )}.xlsx`,
          }
        );
        setState({
          generatedFileID: result.id,
          isGeneratedFileDialogOpen: true,
          selectedReport: report,
        });
      } catch (e) {
        enqueueSnackbar((e as any).body.message, {
          variant: 'error',
        });
      }
    }
    if (report.ReportType !== 'Portfolio') {
      searchParams.set('reportId', report.id.toString());
      searchParams.set('runReport', 'true');
      setSearchParams(searchParams);
    }
    setState({
      redirectToReportsPage: report.ReportType !== 'Portfolio',
      selectedReport: report,
      runReport: report.ReportType !== 'Portfolio',
    });
  };

  const clientId =
    profile && profile.User.ClientID !== null
      ? profile.User.ClientID
      : parseId(searchParams.get('client'));

  const { data: selectedReport, refresh: refreshReport } = useData<{
    reportData?: IPortfolioReportStateData;
  }>(
    () => ({
      reportData:
        state.selectedReport && state.selectedReport!.id !== undefined
          ? `/clients/${clientId}/portfolio_reports/${state.selectedReport?.id}`
          : undefined,
    }),
    [state.selectedReport]
  );

  const actionsFormatter = (
    params: ICellRendererParams<IPortfolioReportStateData>
  ): JSX.Element => {
    return (
      <>
        <IconButton
          onClick={() =>
            handleRunReport(params.data as IPortfolioReportStateData)
          }
          disabled={
            isNull(params.data?.Metadata) &&
            params.data?.ReportType === 'Waterfall'
          }
          {...(isNull(params.data?.Metadata)
            ? { title: 'You cannot run a report which has no metadata.' }
            : { title: 'Run Report' })}
          sx={{
            color:
              isNull(params.data?.Metadata) &&
              params.data?.ReportType === 'Waterfall'
                ? grey[500]
                : green[500],
          }}
        >
          <PlayArrow />
        </IconButton>
        {!isClientReadOnlyUser && (
          <>
            {params.data?.ReportMembership ===
              PortfolioReportMembershipRole.Owner && (
              <PopupState
                variant="popover"
                popupId={`report-settings-menu-${params.data?.id}`}
              >
                {(actionsPopupState) => {
                  const trigger = bindTrigger(actionsPopupState);

                  return (
                    <>
                      <IconButton
                        title="More actions"
                        aria-label="More actions"
                        {...trigger}
                        onClick={(e: MouseEvent) => {
                          e.stopPropagation();
                          trigger.onClick(e);
                        }}
                      >
                        <MoreHoriz />
                      </IconButton>
                      <StyledMenu {...bindMenu(actionsPopupState)}>
                        <MenuItem
                          onClick={() => {
                            handleOpenRenameReport(
                              params.data as IPortfolioReportStateData
                            );
                          }}
                        >
                          Rename
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            actionsPopupState.close();

                            handleOpenDeleteReport(
                              params.data as IPortfolioReportStateData
                            );
                          }}
                        >
                          Delete
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            actionsPopupState.close();

                            handleEditDataSource(
                              params.data as IPortfolioReportStateData
                            );
                          }}
                        >
                          Edit
                        </MenuItem>
                        {params.data?.ReportType === 'Portfolio' && (
                          <MenuItem
                            onClick={() => {
                              actionsPopupState.close();

                              handleOpenReportTemplateDownload(
                                params.data as IPortfolioReportStateData
                              );
                            }}
                          >
                            Manage Report Template
                          </MenuItem>
                        )}
                      </StyledMenu>
                    </>
                  );
                }}
              </PopupState>
            )}
          </>
        )}
      </>
    );
  };

  const onClickReportDetails = (row: IPortfolioReportStateData): void => {
    searchParams.set('reportId', row.id.toString());
    searchParams.set('runReport', 'false');
    setSearchParams(searchParams);
    setState({ redirectToReportsPage: true, selectedReport: row });
  };

  const ModelRenderer = (
    cellRendererParams: ICellRendererParams<IPortfolioReportStateData>
  ) => {
    const modelId = cellRendererParams.data?.ModelID;
    const model = find(data.models, { id: modelId });
    return model ? model.Name : '';
  };

  const reportNameFormatter = (
    params: ICellRendererParams<IPortfolioReportStateData>
  ): JSX.Element | null => {
    if (
      isClientReadOnlyUser ||
      (params.data?.ReportMembership === PortfolioReportMembershipRole.Viewer &&
        !isClientReadOnlyUser)
    ) {
      return (
        <Typography component="span" variant="body2">
          <u style={{ color: blue[500], cursor: 'pointer' }}>{params.value}</u>
        </Typography>
      );
    } else if (!isClientReadOnlyUser) {
      if (
        params.data?.ReportMembership === PortfolioReportMembershipRole.Owner
      ) {
        return (
          <Typography
            component="span"
            variant="body2"
            title={`Navigate to ${params.value} details`}
          >
            <u
              style={{ color: blue[500], cursor: 'pointer' }}
              onClick={() =>
                onClickReportDetails(params.data as IPortfolioReportStateData)
              }
            >
              {params.value}
            </u>
          </Typography>
        );
      }
    }
    return null;
  };

  const lastRunAtFormatter = (
    params: ICellRendererParams<IPortfolioReportStateData>
  ): JSX.Element => {
    return (
      <>
        <Box>
          <Typography component="span" variant="body2">
            {format(new Date(params.value), 'MM/dd/yyyy hh:mm:ss a')}
          </Typography>
        </Box>
        <Box>
          <Tooltip title={params.data?.updated_by.User_Name as string} arrow>
            <Typography component="span" variant="body2">
              <u style={{ color: blue[500] }}>
                {params.data?.updated_by.Display_Name}
              </u>
            </Typography>
          </Tooltip>
        </Box>
      </>
    );
  };

  const lastRunByFormatter = (
    params: ICellRendererParams<IPortfolioReportStateData>
  ): JSX.Element => {
    return (
      <Tooltip title={params.data?.updated_by.User_Name as string} arrow>
        <Typography component="span" variant="body2">
          <u style={{ color: blue[500] }}>
            {params.data?.updated_by.Display_Name}
          </u>
        </Typography>
      </Tooltip>
    );
  };

  const reportColumns: ColDef<IPortfolioReportStateData>[] = [
    {
      field: 'Name',
      headerName: 'Report Name',
      sortable: true,
      cellRenderer: reportNameFormatter,
      flex: 3,
      hide: false,
    },
    {
      field: 'ReportType',
      headerName: 'Type',
      sortable: true,
      flex: 1.5,
    },
    // {
    //   field: 'Models',
    //   headerName: 'Models',
    //   cellRenderer: modelsFormatter,
    //   filter: 'agMultiColumnFilter',
    //   flex: 2,
    // },
    {
      field: 'ModelID',
      headerName: 'Model',
      cellRenderer: ModelRenderer,
      flex: 3,
    },
    {
      field: 'updated_at',
      headerName: 'Last Edited',
      cellRenderer: lastRunAtFormatter,
      sortable: true,
      flex: 3,
    },
    {
      field: 'updated_by.Display_Name',
      headerName: 'Last Run By',
      cellRenderer: lastRunByFormatter,
      sortable: true,
      flex: 3,
    },
    {
      field: 'id',
      headerName: 'Actions',
      cellRenderer: actionsFormatter,
      flex: 1.5,
    },
  ];

  const handleCloseReportDetails = (): void => {
    setState({
      redirectToReportsPage: false,
      selectedReport: undefined,
    });
  };

  const closeGeneratedFile = (): void => {
    setState({
      generatedFileID: undefined,
      isGeneratedFileDialogOpen: false,
    });
    refresh();
  };

  const getSelectedModels = (
    reports: IPortfolioReportStateData[]
  ): ISelectedModels[] => {
    return map(uniq(flatMap(reports, 'Models')).sort(SortAlphaNum), (item) => {
      return {
        name: item,
        selected: true,
        visible: true,
      };
    });
  };

  const isThereAnUnsavedComponent = (isAnyFieldUpdated: boolean) =>
    setState({ isAnyFieldUpdated });

  const handleClientChange = (id?: number) => {
    if (id === params().clientId) {
      return;
    }

    if (id === undefined) {
      searchParams.delete('client');
    } else {
      searchParams.set('client', id.toString());
    }
    searchParams.delete('model');
    searchParams.delete('instance');
    searchParams.delete('scenario');
    searchParams.delete('source');
    setSearchParams(searchParams);
  };

  useEffect(() => {
    searchParams.delete('reportId');
    searchParams.delete('runReport');
    setSearchParams(searchParams);
  }, []);

  if (params().clientId === undefined) {
    return <ClientIndex onSelect={handleClientChange} />;
  }

  if (state.redirectToReportsPage) {
    return (
      <PortfolioReportDetails
        selectedReport={state.selectedReport}
        onClose={handleCloseReportDetails}
        clientId={params().clientId}
        refreshReports={refresh}
        runReport={handleRunReport}
        generatedFileID={state.generatedFileID}
        isGeneratedFileDialogOpen={state.isGeneratedFileDialogOpen}
        onCloseGeneratedFileDialog={closeGeneratedFile}
        refreshDataSource={getDataSourceData}
      />
    );
  } else {
    return (
      <Box onClick={() => setState({ isModelFilterOpen: false })}>
        <PortfolioReportGeneratedFileDownload
          progressMsg="Preparing download"
          onClose={closeGeneratedFile}
          generatedFileId={state.generatedFileID}
          isOpen={state.isGeneratedFileDialogOpen}
          reportData={state.selectedReport}
        />
        <CreateNewReportDialog
          isOpen={state.isCreateNewReportDialogOpen}
          onClose={handleCloseCreateNewReport}
          refreshReports={refresh}
          clientId={params().clientId}
          reportNames={map(state.reportsData, 'Name')}
          reportName={
            !isEmpty(state.selectedReport)
              ? (state.selectedReport as IPortfolioReportStateData).Name
              : ''
          }
        />
        {loading ? (
          <FullScreenProgress />
        ) : (
          <>
            <Grid container>
              <Grid md={12} sm={12}>
                <CardContent>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid md={4}>
                      <Typography variant="h4">
                        {state.selectedReportType}
                      </Typography>
                      {state.selectedReportType === 'Recent' && (
                        <Typography>10 results</Typography>
                      )}
                    </Grid>
                    <Grid md={2}>
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<Add />}
                        fullWidth
                        onClick={handleOpenCreateNewReport}
                        disabled={
                          profile.User.Role === UserRole.Client_Read_Only_User
                        }
                      >
                        Create New Report
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                xs={2}
                sx={{
                  borderRadius: `0px 0px 0px 3px`,
                  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                }}
              >
                <CardContent>
                  <Typography variant={width > 1600 ? 'h5' : 'h6'}>
                    Reports
                  </Typography>
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton
                        selected={state.selectedReportType === 'Recent'}
                        onClick={() => {
                          const filteredReportsData = getFilteredReports(
                            'Recent',
                            state.originalReportsData
                          );
                          setState({
                            selectedReportType: 'Recent',
                            reportsData: filteredReportsData,
                            selectedModels:
                              getSelectedModels(filteredReportsData),
                            expandedRow: [],
                            isPaginationEnabled: false,
                          });
                        }}
                      >
                        <ListItemText primary="Recent" />
                      </ListItemButton>
                    </ListItem>
                    <Divider />

                    <ListItem disablePadding>
                      <ListItemButton
                        selected={
                          state.selectedReportType === 'Private Reports'
                        }
                        onClick={() => {
                          const filteredReportsData = getFilteredReports(
                            'Private Reports',
                            state.originalReportsData
                          );
                          setState({
                            selectedReportType: 'Private Reports',
                            reportsData: filteredReportsData,
                            selectedModels:
                              getSelectedModels(filteredReportsData),
                            expandedRow: [],
                            isPaginationEnabled: true,
                          });
                        }}
                      >
                        <ListItemText primary="Private Reports" />
                      </ListItemButton>
                    </ListItem>
                    <Divider />

                    <ListItem disablePadding>
                      <ListItemButton
                        selected={state.selectedReportType === 'Shared Reports'}
                        onClick={() => {
                          const filteredReportsData = getFilteredReports(
                            'Shared Reports',
                            state.originalReportsData
                          );
                          setState({
                            selectedReportType: 'Shared Reports',
                            reportsData: filteredReportsData,
                            selectedModels:
                              getSelectedModels(filteredReportsData),
                            expandedRow: [],
                            isPaginationEnabled: true,
                          });
                        }}
                      >
                        <ListItemText primary="Shared Reports" />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                      <ListItemButton
                        selected={state.selectedReportType === 'All Reports'}
                        onClick={() => {
                          const filteredReportsData = getFilteredReports(
                            'All Reports',
                            state.originalReportsData
                          );
                          setState({
                            selectedReportType: 'All Reports',
                            reportsData: filteredReportsData,
                            selectedModels:
                              getSelectedModels(filteredReportsData),
                            expandedRow: [],
                            isPaginationEnabled: true,
                          });
                        }}
                      >
                        <ListItemText primary="All Reports" />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </List>
                  {!state.isOnlyViewerForAllReports && (
                    <>
                      <Typography
                        variant={width > 1600 ? 'h5' : 'h6'}
                        sx={{ pt: 2 }}
                      >
                        By Permission
                      </Typography>
                      <List>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => {
                              const filteredReportsData = getFilteredReports(
                                'Viewer',
                                state.originalReportsData
                              );
                              setState({
                                selectedReportType: 'Viewer',
                                reportsData: filteredReportsData,
                                selectedModels:
                                  getSelectedModels(filteredReportsData),
                                expandedRow: [],
                                isPaginationEnabled: true,
                              });
                            }}
                            selected={state.selectedReportType === 'Viewer'}
                          >
                            <ListItemText primary="Viewer" />
                          </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem disablePadding>
                          <ListItemButton
                            selected={state.selectedReportType === 'Owner'}
                            onClick={() => {
                              const filteredReportsData = getFilteredReports(
                                'Owner',
                                state.originalReportsData
                              );
                              setState({
                                selectedReportType: 'Owner',
                                reportsData: filteredReportsData,
                                selectedModels:
                                  getSelectedModels(filteredReportsData),
                                expandedRow: [],
                                isPaginationEnabled: true,
                              });
                            }}
                          >
                            <ListItemText primary="Owner" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </>
                  )}
                </CardContent>
              </Grid>
              <Grid xs={10} sx={{ borderRadius: `0px 0px 0px 3px`, pb: 8 }}>
                {state.isPaginationEnabled && (
                  <Table
                    defaultColDef={{
                      autoHeight: true,
                      suppressMenu: true,
                    }}
                    key={state.selectedReportType}
                    gridRef={gridRef}
                    id="reports-table"
                    isLoading={false}
                    isSuccess
                    pagination={state.isPaginationEnabled}
                    rowCount={state.reportsData.length}
                    rowData={state.reportsData}
                    columnDefs={reportColumns}
                    suppressRowClickSelection
                    suppressPaginationPanel
                    masterDetail
                    gridOptions={{
                      isRowMaster: (data: IPortfolioReportStateData) =>
                        isClientReadOnlyUser ||
                        (data?.ReportMembership ===
                          PortfolioReportMembershipRole.Viewer &&
                          !isClientReadOnlyUser),
                    }}
                    detailCellRenderer={DetailRowRenderer}
                  />
                )}
                {!state.isPaginationEnabled && (
                  <Table
                    defaultColDef={{
                      autoHeight: true,
                      suppressMenu: true,
                    }}
                    gridRef={gridRef}
                    id="reports-table"
                    isLoading={false}
                    isSuccess
                    pagination={false}
                    rowCount={state.reportsData.length}
                    rowData={state.reportsData}
                    columnDefs={reportColumns}
                    suppressRowClickSelection
                    suppressPaginationPanel
                    masterDetail
                    gridOptions={{
                      isRowMaster: (data: IPortfolioReportStateData) =>
                        isClientReadOnlyUser ||
                        (data?.ReportMembership ===
                          PortfolioReportMembershipRole.Viewer &&
                          !isClientReadOnlyUser),
                    }}
                    detailCellRenderer={DetailRowRenderer}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
        {state.isEditDataSourceDialogOpen && (
          <EditPortfolioSourceType
            isOpen={state.isEditDataSourceDialogOpen}
            onClose={handleCloseEditDataSourceModal}
            selectedReportDataSourceData={state.selectedReportDataSource}
            clientId={params().clientId}
            showComponentInADialog
            selectedReport={state.selectedReport}
            refreshReports={refresh}
            refreshDataSource={getDataSourceData}
            isThereAnUnsavedComponent={isThereAnUnsavedComponent}
          />
        )}
        <UpdateReportNameDialog
          isOpen={state.isRenameReportDialogOpen}
          onClose={handleCloseRenameReport}
          reportName={
            state.selectedReport &&
            (state.selectedReport as IPortfolioReportStateData).Name
          }
          reportData={selectedReport.reportData}
          refreshReports={refresh}
          clientId={params().clientId}
          refreshReport={refreshReport}
        />
        <DeleteReportDialog
          isOpen={state.isDeleteDialogOpen}
          onClose={() => {
            setState({ isDeleteDialogOpen: false, selectedReport: undefined });
          }}
          reportData={state.selectedReport}
          refreshReports={refresh}
          clientId={params().clientId}
        />
        <ReportTemplateDownloadDialog
          open={state.isDownloadTemplateDialogOpen}
          close={handleCloseReportTemplateDownload}
          data={state.selectedReport as IPortfolioReportStateData}
          refresh={refresh}
        />
      </Box>
    );
  }
};

export default PortfolioDashboard;
