import React, { useRef, useEffect, useState, FunctionComponent } from 'react';
import { Tooltip, Typography, TypographyProps } from '@mui/material';
import { omit } from 'lodash';

interface OverflowTipProps extends TypographyProps {
  value: any;
}

const OverflowTip: FunctionComponent<OverflowTipProps> = (props) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  useEffect(() => {
    const compareSize = () => {
      if (ref.current) {
        const compare = ref.current.scrollWidth > ref.current.clientWidth;

        setTooltipEnabled(compare);
      }
    };
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => window.removeEventListener('resize', compareSize);
  }, []);

  return (
    <Tooltip arrow title={props.value} disableHoverListener={!tooltipEnabled}>
      <Typography
        {...omit(props, 'value')}
        noWrap
        ref={ref}
        variant={props.variant || 'body2'}
        sx={{ lineHeight: 'inherit' }}
      >
        {props.value}
      </Typography>
    </Tooltip>
  );
};

export default OverflowTip;
