import React, { ChangeEvent, FunctionComponent, useContext } from 'react';

import { TextField, Box, Alert, AlertTitle, Typography } from '@mui/material';

import * as API from '../../services/API';
import { Scenario } from '../../types/models';

import APICacheContext from '../shared/APICacheContext';
import useSetState from '../../hooks/useSetState';
import APICache from '../../services/APICache';
import PopupDialog from '../shared/PopupDialog';

interface Props {
  scenario: Scenario;
  isOpen: boolean;
  onCancel(): void;
  onComplete(): void;
}

interface State {
  name?: string;
  busy: boolean;
  error?: Error;
}

const INITIAL_STATE = {
  busy: false,
  name: '',
  error: undefined,
};

const DeleteScenarioDialog: FunctionComponent<Props> = ({
  scenario,
  onComplete,
  isOpen,
  onCancel,
}) => {
  const deleteScenarioDialogContext = useContext(APICacheContext);
  const [state, setState] = useSetState<State>(INITIAL_STATE);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ name: event.target.value });
  };

  const handleCancel = () => {
    onCancel();
    setState(INITIAL_STATE);
  };

  const handleConfirm = async () => {
    const { name } = state;

    if (scenario.Name !== name) {
      return;
    }

    setState({ busy: true, error: undefined });

    try {
      await API.remove(
        `/instances/${scenario.ModelInstanceID}/scenarios/${scenario.id}`
      );
    } catch (error: any) {
      setState({ busy: false, error });
      return;
    }

    await (deleteScenarioDialogContext as APICache).load(
      [`/clients`, `/instances/${scenario.ModelInstanceID}/scenarios`],
      true
    );

    onComplete();
    setState(INITIAL_STATE);
  };

  const { name, busy, error } = state;

  return (
    <PopupDialog
      open={isOpen}
      close={handleCancel}
      title="Delete Scenario?"
      isLoading={busy}
      primaryButtons={[
        {
          id: 'delete-scenario-dialog-confirm',
          label: 'Yes, delete it',
          onClick: handleConfirm,
          disabled: busy || name !== scenario.Name,
        },
      ]}
    >
      <Typography>
        This action <strong>cannot</strong> be undone. Are you absolutely sure
        you want to delete "<strong>{scenario.Name}</strong>"?
      </Typography>

      <TextField
        fullWidth
        value={name}
        label="Please type the name of this scenario to confirm."
        onChange={handleNameChange}
        sx={{ mt: 2 }}
      />

      {error && (
        <Box mt={2}>
          <Alert severity="error">
            <AlertTitle>Something went wrong</AlertTitle>
            {(error as any).body.message ||
              'Unable to delete scenario, check your connection and try again.'}
          </Alert>
        </Box>
      )}
    </PopupDialog>
  );
};

export default DeleteScenarioDialog;
