import React, { useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  Pagination,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { map } from 'lodash';
import { ICellRendererParams } from 'ag-grid-enterprise';

import useResponsive from '../../../hooks/useResponsive';

const paginationOptions = ['10', '25', '50', '100'];

function paginate(
  totalItems: number,
  currentPage: number = 1,
  pageSize: number = 10,
  maxPages: number = 10
) {
  // calculate total pages
  let totalPages = Math.ceil(totalItems / pageSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage: number;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
  } else {
    // total pages more than max so calculate start and end pages
    let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
    }
  }

  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * pageSize;

  // return object with all pager properties required by the view
  return {
    totalItems: totalItems,
    startPage: startPage + startIndex,
    endPage:
      currentPage * pageSize > totalItems ? totalItems : currentPage * pageSize,
  };
}

const CustomPagination = (
  props: ICellRendererParams & { paginationEnabled: boolean; dataCount: number }
) => {
  const { isLaptop } = useResponsive();

  const initialPageSize = props.api.paginationGetPageSize();
  const totalPages = Math.ceil(props.dataCount / initialPageSize);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState<number>(totalPages);
  const [pageSize, setPageSize] = useState<number>(initialPageSize);

  useEffect(() => props.api.paginationGoToPage(page - 1), [page]);

  useEffect(() => {
    if (props.api.paginationGetPageSize() !== pageSize) {
      props.api.paginationSetPageSize(pageSize);
      setPageCount(props.api.paginationGetTotalPages());
    }
  }, [pageSize]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(Number(event.target.value));
  };

  const paginateData = paginate(props.dataCount, page, pageSize, totalPages);

  if (props.paginationEnabled && paginateData.totalItems > 0) {
    return (
      <Stack
        direction={isLaptop ? 'row' : 'column'}
        sx={{ alignItems: 'center', my: isLaptop ? 0 : 2 }}
        spacing={2}
      >
        <Typography>
          <strong>{paginateData.startPage}</strong> -{' '}
          <strong>{paginateData.endPage}</strong> of{' '}
          <strong>{paginateData.totalItems}</strong> items
        </Typography>
        <>
          <TextField
            select
            value={pageSize.toString()}
            onChange={handleChange}
            size="small"
          >
            {map(paginationOptions, (i) => (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            ))}
          </TextField>
          {paginateData.totalItems > pageSize && (
            <Pagination
              variant="outlined"
              count={pageCount}
              defaultPage={1}
              showFirstButton
              showLastButton
              color="primary"
              onChange={(ev, pageNumber) => setPage(pageNumber)}
            />
          )}
        </>
      </Stack>
    );
  }
  return <Box />;
};

export default CustomPagination;
