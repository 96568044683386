import React, { FunctionComponent } from 'react';
import { isUndefined } from 'lodash';

import {
  GeneratedFile,
  ModelInstance,
  Scenario,
  Upload,
} from '../../types/models';

import useSetState from '../../hooks/useSetState';

import RefreshReportUploadFileDialog from './RefreshReportUploadFileDialog';
import RefreshReportValidateFileDialog from './RefreshReportValidateFileDialog';
import RefreshReportDownloadDialog from './RefreshReportDownloadDialog';

interface RefreshReportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  instanceId: number | undefined;
}

interface RefreshReportDialogState {
  panelNumber: number;
  refreshedScenarios: Scenario[] | number[];
  toBeRefreshedScenarios: Scenario[];
  generatedFileId: GeneratedFile['id'] | null;
  upload: Pick<Upload, 'id' | 'FileName'> | null;
  scenariosList: Scenario[];
  refreshedModelInstanceName?: ModelInstance['Name'];
}

const initialState: RefreshReportDialogState = {
  panelNumber: 1,
  refreshedScenarios: [],
  toBeRefreshedScenarios: [],
  generatedFileId: null,
  upload: null,
  scenariosList: [],
};

const RefreshReportDialog: FunctionComponent<RefreshReportDialogProps> = ({
  isOpen,
  onClose,
  instanceId,
}) => {
  const [state, setState] = useSetState<RefreshReportDialogState>(initialState);

  const closeDialog = () => {
    setState(initialState);
    onClose();
  };

  const handlePanelChange = (
    panelNumber: number,
    refreshedScenarios?: Scenario[] | number[],
    toBeRefreshedScenarios?: Scenario[]
  ) => {
    setState({
      panelNumber,
      ...(!isUndefined(refreshedScenarios) && { refreshedScenarios }),
      ...(!isUndefined(toBeRefreshedScenarios) && { toBeRefreshedScenarios }),
    });
  };

  const handleUpload = (
    upload: { id: number; FileName: string },
    scenariosList: Scenario[],
    refreshedModelInstanceName: ModelInstance['Name']
  ) => {
    setState({
      upload: {
        id: upload.id,
        FileName: upload.FileName,
      },
      scenariosList,
      refreshedModelInstanceName,
    });
  };

  const handleGeneratedFileId = (generatedFileId: GeneratedFile['id']) => {
    setState({ generatedFileId });
  };

  return (
    <>
      {state.panelNumber === 1 && (
        <RefreshReportUploadFileDialog
          isOpen={isOpen}
          onClose={closeDialog}
          instanceId={instanceId}
          handlePanelChange={handlePanelChange}
          handleUpload={handleUpload}
        />
      )}
      {state.panelNumber === 2 && (
        <RefreshReportValidateFileDialog
          isOpen={isOpen}
          onClose={closeDialog}
          instanceId={instanceId}
          handlePanelChange={handlePanelChange}
          refreshedScenarios={state.refreshedScenarios}
          toBeRefreshedScenarios={state.toBeRefreshedScenarios}
          upload={state.upload}
          handleGeneratedFileId={handleGeneratedFileId}
          scenarios={state.scenariosList}
          refreshedModelInstanceName={state.refreshedModelInstanceName}
        />
      )}
      {state.panelNumber === 3 && (
        <RefreshReportDownloadDialog
          isOpen={isOpen}
          onClose={closeDialog}
          generatedFileId={state.generatedFileId}
        />
      )}
    </>
  );
};

export default RefreshReportDialog;
