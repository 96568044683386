import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useContext,
} from 'react';
import { Person, Lock, Public } from '@mui/icons-material';

import { map, values, toString } from 'lodash';
import {
  Box,
  CircularProgress,
  MenuItem,
  AlertTitle,
  Alert,
  ListItemIcon,
  ListItemText,
  ListItem,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { red, green } from '@mui/material/colors';

import * as API from '../../services/API';
import { Scenario, ScenarioEditState } from '../../types/models';
import { isModelOwner } from '../../utils/profile';

import { ProfileProps, withProfile } from '../shared/AuthController';
import APICacheContext from '../shared/APICacheContext';

import APICache from '../../services/APICache';

import useSetState from '../../hooks/useSetState';

import OverflowTooltip from '../shared/OverflowTooltip';

import LastUpdated from './LastUpdated';

interface Props extends ProfileProps {
  modelId: number;
  scenario: Scenario;
}

interface State {
  busy: boolean;
  error: boolean;
}

const INITIAL_STATE: State = {
  busy: false,
  error: false,
};

interface EditState {
  value: ScenarioEditState;
  text: string;
  icon?: ReactElement;
}

const EDIT_STATES: { [key: number]: EditState } = {
  [ScenarioEditState.Locked]: {
    value: ScenarioEditState.Locked,
    text: 'Locked',
    icon: <Lock fontSize="small" sx={{ color: red[900] }} />,
  },
  [ScenarioEditState.OwnerEdit]: {
    value: ScenarioEditState.OwnerEdit,
    text: 'Owner may edit',
    icon: <Person fontSize="small" />,
  },
  [ScenarioEditState.WorldEdit]: {
    value: ScenarioEditState.WorldEdit,
    text: 'Contributors may edit',
    icon: <Public fontSize="small" sx={{ color: green[900] }} />,
  },
};

const ScenarioDetailsForm: FunctionComponent<Props> = ({
  scenario,
  profile,
  modelId,
}: Props) => {
  const scenarioDetailsFormContext = useContext(APICacheContext);
  const [state, setState] = useSetState<State>(INITIAL_STATE);

  const handleEditStateSelect = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setState({ busy: true });

    try {
      await API.update(
        `/instances/${scenario.ModelInstanceID}/scenarios/${scenario.id}`,
        {
          Edit_State: Number(event.target.value as string),
        }
      );

      await (scenarioDetailsFormContext as APICache).load(
        [
          `/clients`,
          `/instances/${scenario.ModelInstanceID}/scenarios`,
          `/instances/${scenario.ModelInstanceID}/scenarios/${scenario.id}`,
        ],
        true
      );
      setState(INITIAL_STATE);
    } catch (error) {
      setState({ ...INITIAL_STATE, error: true });
    }
  };

  const { busy, error } = state;

  const editable = profile !== undefined && isModelOwner(profile, modelId);

  return (
    <Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingTop: 1, height: '45px' }}
      >
        <Grid sm={7}>
          <OverflowTooltip variant="h5" value={scenario.Name} />
        </Grid>
        <Grid justifyContent="flex-end">
          <TextField
            select
            variant="standard"
            placeholder="Edit State"
            margin="normal"
            size="small"
            fullWidth
            onChange={handleEditStateSelect}
            disabled={busy || !editable}
            value={toString(scenario.Edit_State)}
            SelectProps={{
              renderValue: (value) => {
                const editState = EDIT_STATES[Number(value)];
                return (
                  <ListItem disablePadding sx={{ alignItems: 'center' }}>
                    <ListItemText sx={{ alignItems: 'center' }}>
                      {editState.icon} {editState.text}
                    </ListItemText>
                  </ListItem>
                );
              },
            }}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              mb: 0,
              mt: 1,
            }}
            InputLabelProps={{ shrink: false }}
            InputProps={{
              startAdornment: busy ? <CircularProgress /> : null,
              disableUnderline: true,
            }}
          >
            {map(values(EDIT_STATES), (item: EditState) => (
              <MenuItem key={item.value} value={item.value}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>{item.text}</ListItemText>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {error && (
        <Box mt={1} mb={2}>
          <Alert>
            <AlertTitle>Something went wrong</AlertTitle>
            Failed to save scenario, check your connection and try again
          </Alert>
        </Box>
      )}

      <Grid container justifyContent="space-between" alignItems="center">
        <Grid>
          <LastUpdated object={scenario} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default withProfile(ScenarioDetailsForm);
