import React, { FunctionComponent } from 'react';

import PopupDialog from '../../shared/PopupDialog';

interface IRemoveInputDialogProps {
  open: boolean;
  onClose: () => void;
  onRemove: () => void;
  hasBeenModified: boolean;
}

const RemoveInputDialog: FunctionComponent<IRemoveInputDialogProps> = ({
  open,
  onClose,
  onRemove,
  hasBeenModified,
}) => {
  return (
    <PopupDialog
      open={open}
      close={onClose}
      title="Remove Input"
      primaryButtons={[
        {
          id: 'removeButton',
          label: 'Remove',
          onClick: onRemove,
        },
      ]}
    >
      {hasBeenModified
        ? `Are you sure you want to discard all of your existing changes for this
      input and remove it from the Editor?`
        : 'Are you sure you want to remove this input from the Editor?'}
    </PopupDialog>
  );
};

export default RemoveInputDialog;
