import React, { FormEvent, FunctionComponent } from 'react';
import { format } from 'date-fns';
import { capitalize } from 'lodash';
import { Alert, Box, Button, Stack } from '@mui/material';
import { CloudDownload } from '@mui/icons-material';

import ScenarioUpdateErrorCallout from '../ScenarioManager/ScenarioUpdateErrorCallout';
import UploadFile from '../shared/UploadFile';
import { getFileExtension } from '../../utils/misc';
import { HTTPError } from '../../support/errors';
import * as API from '../../services/API';
import {
  GeneratedFile,
  ModelInstance,
  SourceDropDown,
  UploadedFile,
} from '../../types/models';

import GeneratedFileDownload from '../shared/GeneratedFileDownload';

import PopupDialog from '../shared/PopupDialog';

import useSetState from '../../hooks/useSetState';

import { GENERATED_FILES_URL } from './IOConsolidationConstants';

interface DefineStandardReportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  instanceId: number | undefined;
  rowIdentifierIds: number[];
  includeBlanksRowIdentifierIDs: number[];
  timescales: string[];
  source: SourceDropDown;
  data: {
    modelInstance?: ModelInstance;
  };
}

interface DefineStandardReportDialogState {
  file: File | null;
  fileTypeError: boolean;
  fileUploadError: HTTPError | null;
  generatedFile: GeneratedFile | null;
  isBusy: boolean;
}

const initialState: DefineStandardReportDialogState = {
  fileTypeError: false,
  file: null,
  fileUploadError: null,
  generatedFile: null,
  isBusy: false,
};

const DefineStandardReportDialog: FunctionComponent<
  DefineStandardReportDialogProps
> = ({
  isOpen,
  onClose,
  instanceId,
  rowIdentifierIds,
  includeBlanksRowIdentifierIDs,
  timescales,
  source,
  data,
}) => {
  const [state, setState] =
    useSetState<DefineStandardReportDialogState>(initialState);

  const handleFileChange = (event: FormEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;

    if (getFileExtension(files) === 'xlsm') {
      setState({ fileTypeError: false, file: files && files[0] });
    } else {
      setState({ fileTypeError: true, file: null });
    }
  };

  const closeModal = () => {
    onClose();
    setState(initialState);
  };

  const definedReportPayload = (
    FileName: string,
    ExistingReportFlag: boolean,
    UploadID?: number
  ) => {
    return {
      ModelInstanceID: instanceId,
      RowIdentifierIDs: rowIdentifierIds,
      IncludeBlanksRowIdentifierIDs: includeBlanksRowIdentifierIDs,
      Timescales: source === 'inputs' ? [] : timescales,
      ExistingReportFlag,
      Source: capitalize(source),
      FileName,
      UploadID,
    };
  };

  const handleReportRefresh = async (uploadData: UploadedFile) => {
    setState({ isBusy: true });
    if (uploadData.fileUploadData) {
      const generatedFile = await API.create<GeneratedFile>(
        GENERATED_FILES_URL.standardReport.define,
        definedReportPayload(
          uploadData.fileUploadData.FileName,
          true,
          uploadData.fileUploadData.id
        )
      );
      setState({ isBusy: false, generatedFile });
    }
  };

  const createNewReport = async () => {
    setState({ isBusy: true });
    const generatedFile = await API.create<GeneratedFile>(
      GENERATED_FILES_URL.standardReport.define,
      definedReportPayload(
        `${
          data && data.modelInstance && data.modelInstance.Name
        }_StandardReport_${format(new Date(), 'yyyy-MM-ddTHH:MM')}.xlsm`,
        false
      )
    );
    setState({ isBusy: false, generatedFile });
  };

  return (
    <PopupDialog
      open={isOpen}
      close={closeModal}
      title="Define Standard Report"
      hideDialogActions
      maxWidth="md"
    >
      {!state.fileUploadError && (
        <Alert severity="info">
          Any selected scenarios will not be included in the report definition.
        </Alert>
      )}
      {state.fileUploadError && (
        <Box mt={1}>
          <ScenarioUpdateErrorCallout
            error={state.fileUploadError}
            text="Failed to update standard report definition, please check your connection and try again."
          />
        </Box>
      )}
      {state.fileTypeError && (
        <Box mt={1}>
          <ScenarioUpdateErrorCallout
            error={state.fileTypeError}
            text="Please upload a file with a .xlsm extension"
          />
        </Box>
      )}
      {state.generatedFile && !state.isBusy && (
        <Box mt={1}>
          <GeneratedFileDownload
            onClose={closeModal}
            generatedFileId={state.generatedFile.id}
            progressMsg="Preparing download"
          />
        </Box>
      )}
      {!state.file && !state.generatedFile && (
        <Stack
          direction="row"
          spacing={2}
          sx={{ pt: 2, justifyContent: 'flex-end' }}
        >
          <Button onClick={closeModal} variant="text">
            Cancel
          </Button>
          <Box>
            <input
              type="file"
              id="uploadFile"
              accept=".xlsm"
              onChange={handleFileChange}
            />
            <Button component="label" variant="contained" htmlFor="uploadFile">
              Select file
            </Button>
          </Box>
          <Button variant="contained" size="small" onClick={createNewReport}>
            Create New
          </Button>
        </Stack>
      )}
      {state.file && !state.generatedFile && (
        <Box mt={1}>
          <UploadFile
            isBusy={state.isBusy}
            fileExtension={'.xlsm'}
            handleCancelClick={closeModal}
            uploadButtonText={'Refresh Define Standard Report'}
            uploadIcon={<CloudDownload />}
            fileUploadData={handleReportRefresh}
            uploadComment="Upload for define standard report"
            file={state.file}
            handleFileChange={handleFileChange}
          />
        </Box>
      )}
    </PopupDialog>
  );
};

export default DefineStandardReportDialog;
