import React, { FunctionComponent, useContext } from 'react';
import { format } from 'date-fns';
import { filter, find, isEmpty, replace, split } from 'lodash';
import { useLocation } from 'react-router-dom';
import { DateRange } from '@mui/x-date-pickers-pro';

import {
  ModelInstance,
  Timescale,
  XRefDate,
} from '../../../../../types/models';

import { GetParams } from '../../../../../utils/history';

import { ProfileContext } from '../../../../shared/AuthController';

import Loader, { LoaderInfo } from '../../../../shared/Loader';

import WeeklyRangePicker from './WeeklyRangePicker';
import MonthRangePicker from './MonthRangePicker';
import QuarterRangePicker from './QuarterRangePicker';
import YearRangePicker from './YearRangePicker';

export interface IChartDataDate {
  DateId: number;
  Date: string;
}
interface IChartRangePickerProps {
  timescale: Timescale;
  modelInstance: ModelInstance;
  onHandleRangeChange: (
    startRange: XRefDate['DateID'],
    endRange: XRefDate['DateID']
  ) => void;
  value: any[];
}

const WaterfallChartRangePicker: FunctionComponent<IChartRangePickerProps> = ({
  timescale,
  modelInstance,
  onHandleRangeChange,
  value,
}) => {
  const profile = useContext(ProfileContext);

  const location = useLocation();
  const { search } = location;
  const params = GetParams(search);

  const clientId =
    profile && profile.User.ClientID !== null
      ? profile.User.ClientID
      : params.clientId;

  const needs = {
    dates: modelInstance !== undefined ? `/xref_date/${clientId}` : undefined,
  };

  const renderContent = ({
    loading,
    data,
  }: LoaderInfo<{
    dates?: XRefDate[];
  }>) => {
    const dates: XRefDate[] = filter(data.dates || [], [
      'Timescale',
      timescale,
    ]);

    const minMaxRanges = (
      min: string,
      max: string
    ):
      | [Date, Date]
      | [{ month: number; year: number }, { month: number; year: number }]
      | [{ quarter: number; year: number }, { quarter: number; year: number }]
      | [number, number] => {
      switch (timescale) {
        case Timescale.Weekly:
          return [new Date(min), new Date(max)];
        case Timescale.Monthly: {
          return [
            {
              month: Number(format(new Date(min), 'M')),
              year: Number(format(new Date(min), 'yyyy')),
            },
            {
              month: Number(format(new Date(max), 'M')),
              year: Number(format(new Date(max), 'yyyy')),
            },
          ];
        }
        case Timescale.Quarterly:
          return [
            {
              quarter: Number(replace(split(min, ' ')[1], /[^0-9]/g, '')),
              year: Number(split(min, ' ')[0]),
            },
            {
              quarter: Number(replace(split(max, ' ')[1], /[^0-9]/g, '')),
              year: Number(split(max, ' ')[0]),
            },
          ];
        case Timescale.Yearly:
          return [
            Number(format(new Date(min), 'yyyy')),
            Number(format(new Date(max), 'yyyy')),
          ];
      }
    };

    if (modelInstance !== undefined && timescale && !isEmpty(dates)) {
      const startDate = (
        find(data.dates, {
          DateID: modelInstance.Start_DateID,
        }) as XRefDate
      ).Date;
      const endDate = (
        find(data.dates, {
          DateID: modelInstance.End_DateID,
        }) as XRefDate
      ).Date;
      const minDate = (
        find(data.dates, {
          DateID: value[0],
        }) as XRefDate
      ).Date;
      const maxDate = (
        find(data.dates, {
          DateID: value[1],
        }) as XRefDate
      ).Date;

      const getValue = () => {
        switch (timescale) {
          case Timescale.Weekly:
            return [new Date(minDate), new Date(maxDate)];
          case Timescale.Monthly: {
            return [
              {
                month: Number(format(new Date(minDate), 'M')),
                year: Number(format(new Date(minDate), 'yyyy')),
              },
              {
                month: Number(format(new Date(maxDate), 'M')),
                year: Number(format(new Date(maxDate), 'yyyy')),
              },
            ];
          }
          case Timescale.Quarterly:
            return [
              {
                quarter: Number(replace(split(minDate, ' ')[1], /[^0-9]/g, '')),
                year: Number(split(minDate, ' ')[0]),
              },
              {
                quarter: Number(replace(split(maxDate, ' ')[1], /[^0-9]/g, '')),
                year: Number(split(maxDate, ' ')[0]),
              },
            ];
          case Timescale.Yearly:
            return [
              Number(
                minDate.length === 4
                  ? minDate
                  : format(new Date(minDate), 'yyyy')
              ),
              Number(
                maxDate.length === 4
                  ? maxDate
                  : format(new Date(maxDate), 'yyyy')
              ),
            ];
        }
      };

      const rangePickers: Record<Timescale, JSX.Element> = {
        [Timescale.Weekly]: (
          <WeeklyRangePicker
            maxDate={minMaxRanges(startDate, endDate)[1] as Date}
            minDate={minMaxRanges(startDate, endDate)[0] as Date}
            onDateChange={(date) => {
              onHandleRangeChange(
                (
                  find(data.dates, {
                    Date: format(date[0] as Date, 'M/d/yyyy'),
                  }) as XRefDate
                ).DateID,
                (
                  find(data.dates, {
                    Date: format(date[1] as Date, 'M/d/yyyy'),
                  }) as XRefDate
                ).DateID
              );
            }}
            value={getValue() as DateRange<Date>}
            clientId={clientId as number}
          />
        ),
        [Timescale.Monthly]: (
          <MonthRangePicker
            startYear={
              minMaxRanges(startDate, endDate)[0] as {
                month: number;
                year: number;
              }
            }
            endYear={
              minMaxRanges(startDate, endDate)[1] as {
                month: number;
                year: number;
              }
            }
            onChange={(startYear, endYear) => {
              onHandleRangeChange(
                (
                  find(data.dates, {
                    Date: `${startYear.month}/1/${startYear.year}`,
                  }) as XRefDate
                ).DateID,
                (
                  find(data.dates, {
                    Date: `${endYear.month}/1/${endYear.year}`,
                  }) as XRefDate
                ).DateID
              );
            }}
            dates={dates}
            value={getValue() as { month: number; year: number }[]}
          />
        ),
        [Timescale.Quarterly]: (
          <QuarterRangePicker
            startYear={
              minMaxRanges(startDate, endDate)[0] as {
                quarter: number;
                year: number;
              }
            }
            endYear={
              minMaxRanges(startDate, endDate)[1] as {
                quarter: number;
                year: number;
              }
            }
            onChange={(startYear, endYear) =>
              onHandleRangeChange(
                (
                  find(data.dates, {
                    Date: `${startYear.year} Q${startYear.quarter}`,
                  }) as XRefDate
                ).DateID,
                (
                  find(data.dates, {
                    Date: `${endYear.year} Q${endYear.quarter}`,
                  }) as XRefDate
                ).DateID
              )
            }
            dates={dates}
            value={getValue() as { quarter: number; year: number }[]}
          />
        ),
        [Timescale.Yearly]: (
          <YearRangePicker
            endYear={minMaxRanges(startDate, endDate)[1] as number}
            startYear={minMaxRanges(startDate, endDate)[0] as number}
            onChange={(startYear, endYear) => {
              onHandleRangeChange(
                (find(data.dates, { Date: `${startYear}` }) as XRefDate).DateID,
                (find(data.dates, { Date: `${endYear}` }) as XRefDate).DateID
              );
            }}
            value={getValue() as number[]}
          />
        ),
      };

      return <>{rangePickers[timescale]}</>;
    }
    return null;
  };

  return <Loader needs={needs} render={renderContent} />;
};

export default WaterfallChartRangePicker;
