import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';

const Rule = styled('hr')(({ color }) => ({
  width: '100%',
  margin: 0,
  border: 'none',
  borderBottom: `solid 1px ${color || grey[300]}`,
}));

export default Rule;
