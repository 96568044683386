import React, {
  HTMLProps,
  FunctionComponent,
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';

import Flex from '../shared/Flex';

const FixedHeaderContext = createContext<
  ((element: HTMLElement) => () => void) | null
>(null);

interface Props extends HTMLProps<HTMLDivElement> {
  height: number;
  children: React.ReactNode;
}

export const FixedHeaderArea: FunctionComponent<Props> = ({
  height,
  children,
}) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const [headers] = useState<HTMLElement[]>([]);

  useEffect(() => {
    const element = wrapper.current;

    if (!element) {
      return;
    }

    let requestId: number | undefined;

    // Updates the transform on registered elements to keep them "stuck" within the bounds of the viewport
    // THIS FUNCTION _MUST_ BE AS FAST AS POSSIBLE
    const render = () => {
      requestId = undefined;

      const minOffset = 0;
      const currentOffset = -element.getBoundingClientRect().top;
      const maxOffset = element.clientHeight - height;

      const offset = Math.min(Math.max(minOffset, currentOffset), maxOffset);

      const transform = `translateY(${offset ? offset + 52 : offset}px)`;

      headers.forEach(
        (header: HTMLElement) => (header.style.transform = transform)
      );
    };

    // Debounce `scroll` events, so we only render once per frame
    // THIS FUNCTION _MUST_ BE AS FAST AS POSSIBLE
    const update = () => {
      requestId = requestId || window.requestAnimationFrame(render);
    };

    window.addEventListener('scroll', update);
    return () => window.removeEventListener('scroll', update);
  }, [height, headers]);

  const registerFixedHeader = (element: HTMLElement) => {
    headers.push(element);
    return () => headers.splice(headers.indexOf(element), 1);
  };

  return (
    <FixedHeaderContext.Provider value={registerFixedHeader}>
      <Flex flexDirection="column" flex="auto" ref={wrapper}>
        {children}
      </Flex>
    </FixedHeaderContext.Provider>
  );
};

export const FixedHeader: FunctionComponent<HTMLProps<HTMLDivElement>> = ({
  style = {},
  ...props
}) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const registerFixedHeader = useContext(FixedHeaderContext);

  useEffect(() => {
    if (wrapper.current && registerFixedHeader) {
      return registerFixedHeader(wrapper.current);
    }
  }, [registerFixedHeader]);

  return (
    <div
      ref={wrapper}
      {...props}
      style={{
        willChange: 'transform',
        position: 'relative',
        zIndex: 30,
        ...style,
        transform: 'translateY(0)',
      }}
    />
  );
};
