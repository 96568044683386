import React, { FunctionComponent } from 'react';
import { flatMap, map, values } from 'lodash';
import { Alert, AlertTitle, List, ListItem, ListItemText } from '@mui/material';

interface Props {
  errors: Record<string, string[]>;
}

const ErrorList: FunctionComponent<Props> = ({ errors }) => {
  return (
    <Alert icon={false} severity="error">
      <AlertTitle>Errors</AlertTitle>
      <List disablePadding dense>
        {map(
          flatMap(values(errors), (errs) => errs || []),
          (msg) => (
            <ListItem dense disablePadding key={msg}>
              <ListItemText>{msg}</ListItemText>
            </ListItem>
          )
        )}
      </List>
      <ul></ul>
    </Alert>
  );
};

export default ErrorList;
