import React, { FunctionComponent } from 'react';

import { Alert, AlertTitle } from '@mui/material';

import { GeneratedFile } from '../../types/models';

import UtilClasses from '../../assets/stylesheets/UtilClasses.module.scss';

import GeneratedFileDownload from './GeneratedFileDownload';
import PopupDialog from './PopupDialog';

interface Props {
  isOpen: boolean;
  generatedFile?: GeneratedFile;
  error?: Error;
  onClose: () => void;
}

const GenerateReportDialog: FunctionComponent<Props> = ({
  isOpen,
  generatedFile,
  error,
  onClose,
}) => {
  return (
    <PopupDialog
      open={isOpen}
      close={onClose}
      title="Generate Report"
      hideDialogActions
    >
      {error !== undefined ? (
        <>
          <Alert severity="error" className={UtilClasses.NO_MARGIN}>
            <AlertTitle>Something went wrong</AlertTitle>
            {(error as any).body.message ||
              'Please check your connection and try again'}
          </Alert>
        </>
      ) : (
        <GeneratedFileDownload
          generatedFileId={generatedFile && generatedFile.id}
          progressMsg="Please wait"
          onClose={onClose}
        />
      )}
    </PopupDialog>
  );
};

export default GenerateReportDialog;
