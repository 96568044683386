import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { isEqual } from 'lodash';

import { TextField, Typography } from '@mui/material';

import { Client } from '../../types/models';
import * as API from '../../services/API';

import PopupDialog from '../shared/PopupDialog';

import { IPortfolioReportStateData } from './index';

interface IDeleteReportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  reportData?: IPortfolioReportStateData;
  refreshReports: () => void;
  clientId?: Client['id'];
}

const reportNameStyle: React.CSSProperties = {
  wordBreak: 'break-all',
};

const DeleteReportDialog: FunctionComponent<IDeleteReportDialogProps> = (
  props: IDeleteReportDialogProps
) => {
  const [loading, isLoading] = useState<boolean>(false);
  const [reportName, setReportName] = useState<string>('');

  const deleteReport = async (): Promise<void> => {
    isLoading(true);
    await API.remove(
      `/clients/${props.clientId}/portfolio_reports/${props.reportData?.id}`
    );
    isLoading(false);
    setReportName('');
    props.onClose();
    props.refreshReports();
  };

  const onClose = () => {
    setReportName('');
    props.onClose();
  };

  return (
    <PopupDialog
      open={props.isOpen}
      close={onClose}
      title="Delete Report"
      isLoading={loading}
      primaryButtons={[
        {
          id: 'delete-report-dialog',
          label: 'Yes, Delete it',
          onClick: deleteReport,
          disabled: !isEqual(props.reportData?.Name, reportName),
        },
      ]}
    >
      <Typography>
        This action <strong>cannot</strong> be undone. Are you absolutely sure
        you want to delete "
        <strong style={reportNameStyle}>{props.reportData?.Name}</strong>"?
      </Typography>
      <TextField
        fullWidth
        label="Please type the name of this report to confirm."
        value={reportName}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setReportName(e.target.value)
        }
      />
    </PopupDialog>
  );
};

export default DeleteReportDialog;
