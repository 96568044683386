import { useEffect } from 'react';

import useSetState from './useSetState';

export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T['addEventListener']> | [string | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(
      ...(args as Parameters<HTMLElement['addEventListener']>)
    );
  }
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T['removeEventListener']> | [string | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(
      ...(args as Parameters<HTMLElement['removeEventListener']>)
    );
  }
}

export const isBrowser = typeof window !== 'undefined';
export interface State {
  x: number;
  y: number;
}

const useWindowScroll = (): State => {
  const [state, setState] = useSetState<State>({
    x: isBrowser ? window.scrollX : 0,
    y: isBrowser ? window.scrollY : 0,
  });

  useEffect(() => {
    const handler = (): void => {
      setState(() => {
        const { scrollX, scrollY } = window;
        return state.x !== scrollX || state.y !== scrollY
          ? {
              x: scrollX,
              y: scrollY,
            }
          : state;
      });
    };
    handler();

    on(window, 'scroll', handler, {
      capture: false,
      passive: true,
    });

    return () => {
      off(window, 'scroll', handler);
    };
  }, []);

  return state;
};

export default useWindowScroll;
