import * as API from './API';

export async function loadProfile() {
  let profile;
  try {
    profile = await API.load(`/profile`);
  } catch (error) {
    return;
  }

  return profile;
}
