import { useCallback, useEffect } from 'react';

import { useConfirm } from 'material-ui-confirm';
import { useBlocker } from 'react-router-dom';

export const usePrompt = async (message: string, when = true) => {
  const confirm = useConfirm();

  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);
  useCallback(async () => {
    try {
      await confirm({ description: message });
      return true;
    } catch (err) {
      return false;
    }
  }, [confirm, message]);
  useBlocker(when);
};
