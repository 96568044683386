import React, { FunctionComponent } from 'react';
import { Search } from '@mui/icons-material';
import { map, values } from 'lodash';
import {
  TextField,
  Checkbox,
  ToggleButton,
  ToggleButtonGroup,
  FormControlLabel,
  Grid,
} from '@mui/material';

export enum InputFilterType {
  Category = 'Category',
  TabName = 'Tab',
}

export interface InputFilter {
  type: InputFilterType;
  Name: string;
}

const InputFilterTypes = values(InputFilterType);

interface Props {
  filter: string;
  onFilterChange(filter: string): void;
  inputSearch: string;
  handleInputSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputSelect: boolean;
  handleInputSelectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputFilterSelect: FunctionComponent<Props> = ({
  filter,
  onFilterChange,
  inputSelect,
  handleInputSelectChange,
  handleInputSearchChange,
  inputSearch,
}) => {
  const handleInputFilterTypeChange = (
    e: React.MouseEvent<HTMLElement>,
    filterType: string
  ) => {
    if (filterType !== null) {
      onFilterChange(filterType);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{ mt: 0 }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={inputSelect}
                onChange={handleInputSelectChange}
              />
            }
            label="Select all"
          />
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            exclusive
            id="input-type-filter"
            onChange={handleInputFilterTypeChange}
            value={filter}
          >
            {map(InputFilterTypes, (inputFilter) => {
              return (
                <ToggleButton
                  key={inputFilter}
                  value={inputFilter}
                  size="small"
                >
                  {inputFilter}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <TextField
        sx={{ mt: 1.9 }}
        label="Search Inputs"
        fullWidth
        value={inputSearch}
        onChange={handleInputSearchChange}
        InputProps={{ startAdornment: <Search /> }}
      />
    </>
  );
};

export default InputFilterSelect;
