/* eslint-disable  react-hooks/exhaustive-deps */
import { useContext, useEffect, useState, useMemo } from 'react';

import APICacheContext from '../components/shared/APICacheContext';

import { Needs, Summary } from '../services/APICache';

export function useData<T>(
  getNeeds: () => Needs<T>,
  deps: any[],
  force: boolean = false
) {
  const needs = useMemo(getNeeds, deps);

  const cache = useContext(APICacheContext);
  if (cache === undefined) {
    throw Error(
      'You can not `useData()` outside an `<APICacheContext.Provider />`'
    );
  }

  const [state, setState] = useState<Summary<T>>(() => cache.summarize(needs));

  useEffect(() => {
    const update = () => setState(cache.summarize(needs));
    const paths: string[] = Object.values(needs);
    const subscription = cache.subscribe(paths, update);
    cache.load(paths, force);
    update();
    return () => subscription && subscription.cancel();
  }, [needs, cache, force]);

  return useMemo(
    () => ({
      refresh: () => cache.load(Object.values(needs), true),
      ...state,
    }),
    [cache, needs, state]
  );
}
