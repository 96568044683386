export interface IFormatOutputParameters {
  numberType: 'General' | 'Percent' | '$';
  magnitudeTruncation: 0 | 1 | 2;
  decimalPlaces: 0 | 1 | 2;
}

export interface IFormatInputParameters {
  numberType: 'Date';
  magnitudeTruncation: null;
  decimalPlaces: null;
  dateFormat: 'M/d/yyyy' | 'dd-MMM-yyyy' | 'MMM-yy';
}

export type IFormatParameters = {
  formatKey: number;
} & (IFormatOutputParameters | IFormatInputParameters);

export const formatParameters: IFormatParameters[] = [
  {
    formatKey: 0,
    numberType: 'General',
    magnitudeTruncation: 0,
    decimalPlaces: 0,
  },
  {
    formatKey: 2,
    numberType: 'Date',
    magnitudeTruncation: null,
    decimalPlaces: null,
    dateFormat: 'dd-MMM-yyyy',
  },
  {
    formatKey: 4,
    numberType: 'Date',
    magnitudeTruncation: null,
    decimalPlaces: null,
    dateFormat: 'M/d/yyyy',
  },
  {
    formatKey: 6,
    numberType: 'Date',
    magnitudeTruncation: null,
    decimalPlaces: null,
    dateFormat: 'MMM-yy',
  },
  {
    formatKey: 8,
    numberType: 'General',
    magnitudeTruncation: 0,
    decimalPlaces: 0,
  },
  {
    formatKey: 10,
    numberType: 'General',
    magnitudeTruncation: 1,
    decimalPlaces: 0,
  },
  {
    formatKey: 12,
    numberType: 'General',
    magnitudeTruncation: 2,
    decimalPlaces: 0,
  },
  {
    formatKey: 14,
    numberType: 'General',
    magnitudeTruncation: 0,
    decimalPlaces: 1,
  },
  {
    formatKey: 16,
    numberType: 'General',
    magnitudeTruncation: 1,
    decimalPlaces: 1,
  },
  {
    formatKey: 18,
    numberType: 'General',
    magnitudeTruncation: 2,
    decimalPlaces: 1,
  },
  {
    formatKey: 20,
    numberType: 'General',
    magnitudeTruncation: 0,
    decimalPlaces: 2,
  },
  {
    formatKey: 22,
    numberType: 'General',
    magnitudeTruncation: 1,
    decimalPlaces: 2,
  },
  {
    formatKey: 24,
    numberType: 'General',
    magnitudeTruncation: 2,
    decimalPlaces: 2,
  },
  {
    formatKey: 26,
    numberType: 'Percent',
    magnitudeTruncation: 0,
    decimalPlaces: 0,
  },
  {
    formatKey: 28,
    numberType: 'Percent',
    magnitudeTruncation: 0,
    decimalPlaces: 1,
  },
  {
    formatKey: 30,
    numberType: 'Percent',
    magnitudeTruncation: 0,
    decimalPlaces: 2,
  },
  {
    formatKey: 32,
    numberType: '$',
    magnitudeTruncation: 0,
    decimalPlaces: 0,
  },
  {
    formatKey: 34,
    numberType: '$',
    magnitudeTruncation: 1,
    decimalPlaces: 0,
  },
  {
    formatKey: 36,
    numberType: '$',
    magnitudeTruncation: 2,
    decimalPlaces: 0,
  },
  {
    formatKey: 38,
    numberType: '$',
    magnitudeTruncation: 0,
    decimalPlaces: 1,
  },
  {
    formatKey: 40,
    numberType: '$',
    magnitudeTruncation: 1,
    decimalPlaces: 1,
  },
  {
    formatKey: 42,
    numberType: '$',
    magnitudeTruncation: 2,
    decimalPlaces: 0,
  },
  {
    formatKey: 44,
    numberType: '$',
    magnitudeTruncation: 0,
    decimalPlaces: 2,
  },
  {
    formatKey: 46,
    numberType: '$',
    magnitudeTruncation: 1,
    decimalPlaces: 2,
  },
  {
    formatKey: 48,
    numberType: '$',
    magnitudeTruncation: 2,
    decimalPlaces: 2,
  },
];
