import React, { FunctionComponent } from 'react';

import { isUndefined, map, uniqueId } from 'lodash';

import { Alert, Box, MenuItem, TextField } from '@mui/material';

import { ReportType, SourceDropDown } from '../../types/models';
import PopupDialog from '../shared/PopupDialog';

interface Props {
  isOpen: boolean;
  source: Partial<SourceDropDown>;
  reportType?: ReportType;
  onChange(reportType: ReportType): void;
  onCancel(): void;
  onConfirm(): void;
}

const INPUT_REPORT_TYPES = [
  ReportType.Standard,
  ReportType.Comparison,
  ReportType.Differences,
];

const OUTPUT_REPORT_TYPES = [
  ReportType.Standard,
  ReportType.Comparison,
  ReportType.Unpivoted,
  ReportType.UnpivotedWithComparison,
];

const REPORT_TYPE_LABELS = {
  [ReportType.Standard]: 'Standard',
  [ReportType.Comparison]: 'Comparison',
  [ReportType.Differences]: 'Differences',
  [ReportType.Unpivoted]: 'Unpivoted',
  [ReportType.UnpivotedWithComparison]: 'Unpivoted with Comparison',
};

const ReportOptionsDialog: FunctionComponent<Props> = ({
  isOpen,
  source,
  reportType,
  onChange,
  onCancel,
  onConfirm,
}) => {
  const reportTypes =
    (source === 'inputs' && INPUT_REPORT_TYPES) ||
    (source === 'outputs' && OUTPUT_REPORT_TYPES) ||
    [];

  return (
    <PopupDialog
      open={isOpen}
      close={onCancel}
      title="Report Options"
      primaryButtons={[
        {
          id: 'report-options-dialog-confirm',
          label: 'Confirm',
          onClick: onConfirm,
          disabled: isUndefined(reportType),
        },
      ]}
    >
      <Alert severity="info">
        You can generate different report types with the same parameters.
      </Alert>
      <Box pb={2} />
      <TextField
        fullWidth
        size="small"
        label="Report Type"
        required
        aria-required
        select
        value={reportType}
        onChange={(e) => onChange(Number(e.target.value))}
      >
        {map(reportTypes, (type) => (
          <MenuItem key={uniqueId()} value={type}>
            {REPORT_TYPE_LABELS[type]}
          </MenuItem>
        ))}
      </TextField>
    </PopupDialog>
  );
};

export default ReportOptionsDialog;
