import React from 'react';

export interface IIVisualizationContextChildrenData {
  customChart: {};
  dashboard: {};
  ppt: {};
}

export interface IVisualizationContext {
  childrenData: IIVisualizationContextChildrenData;
  handleChildrenChange: (code: string, stateVal: {}) => void;
}

export const VisualizationContext =
  React.createContext<IVisualizationContext | null>(null);
