import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  ChangeEvent,
  useEffect,
} from 'react';
import { TextField } from '@mui/material';

import { ICellEditorParams } from 'ag-grid-community';

import { filter, includes, map, trim } from 'lodash';

import {
  formatParameters,
  IFormatParameters,
} from '../../../Visualization/formatParameters';

export default forwardRef(
  (props: ICellEditorParams & { formatKey: IFormatParameters }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>(props.value);

    const inputHandler = (e: ChangeEvent<HTMLInputElement>): void => {
      setValue(
        includes(
          map(filter(formatParameters, { numberType: 'General' }), 'formatKey'),
          props.formatKey.formatKey
        )
          ? e.target.value
          : e.target.value.replace(/[^0-9.]+/, '')
      );
    };

    useEffect(() => {
      inputRef.current?.focus();
      inputRef.current?.select();
    }, []);

    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value ? trim(value) : '';
        },
        afterGuiAttached: () => {
          setValue(props.value);
          inputRef.current?.focus();
          inputRef.current?.select();
        },
      };
    });

    return (
      <TextField
        size="small"
        style={{ padding: '5px 0' }}
        type="text"
        inputRef={inputRef}
        onChange={inputHandler}
        value={value}
      />
    );
  }
);
