import { IGeneratedFiles } from '../../types/models';

export const GENERATED_FILES_URL: any | IGeneratedFiles = {
  inputs: '/generated_files/input_consolidation',
  outputs: '/generated_files/output_consolidation',
  standardReport: {
    define: '/generated_files/standard_report_define',
    validate: '/uploads/standard_report_validate',
    refresh: '/generated_files/standard_report_refresh',
  },
};
