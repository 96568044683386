import React, { FunctionComponent, useEffect, ChangeEvent } from 'react';
import { isArray, join } from 'lodash';
import { Box, Button, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import * as API from '../../services/API';
import { uploadSingleFileToAzure } from '../../services/AzureUpload';

import useSetState from '../../hooks/useSetState';

import { GeneratedFile } from '../../types/models';

import FileInput from './FileInput';
import LinearProgressBar from './LinearProgressBar';

interface Props {
  fileExtension: string | string[];
  handleCancelClick(): void;
  uploadButtonText: string;
  uploadIcon: React.ReactNode;
  fileUploadData(data: any): void;
  uploadComment: string;
  file: File | null;
  handleFileChange(event: ChangeEvent<HTMLInputElement>): void;
  isBusy: boolean;
  showCommentBox?: boolean;
  disabled?: boolean;
  downloadButtonIntent?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  uploadText?: string;
}

interface State {
  isBusyState: boolean;
  totalBytes: number;
  loadedBytes: number;
  comment: string;
}

const initialState: State = {
  isBusyState: false,
  totalBytes: 0,
  loadedBytes: 0,
  comment: '',
};

const UploadFile: FunctionComponent<Props> = ({
  fileExtension,
  handleCancelClick,
  uploadButtonText,
  uploadIcon,
  fileUploadData,
  uploadComment,
  file,
  handleFileChange,
  isBusy = false,
  showCommentBox,
  disabled = false,
  downloadButtonIntent = 'primary',
  uploadText = 'Uploading',
}) => {
  const [state, setState] = useSetState(initialState);

  useEffect(() => {
    setState({ isBusyState: isBusy });
  }, [isBusy]);

  const handleProgress = ({ loadedBytes }: { loadedBytes: number }) => {
    setState({ loadedBytes });
  };

  const handleCommentChange = (event: ChangeEvent<HTMLInputElement>) =>
    setState({ comment: event.currentTarget.value });

  const handleUpload = async () => {
    await fileUploadData({
      error: null,
    });

    if (file === null) {
      return;
    }

    setState({
      isBusyState: true,
      totalBytes: file.size,
    });

    try {
      const upload = await API.create<GeneratedFile>(`/uploads`, {
        FileName: file.name,
        Comment: uploadComment,
      });

      await uploadSingleFileToAzure(upload, file, {
        onProgress: handleProgress,
      });

      await fileUploadData({
        file,
        isBusy: state.isBusyState,
        error: null,
        fileUploadData: upload,
        userComment: state.comment,
      });
    } catch (error) {
      setState({ isBusyState: false });
      await fileUploadData({
        isBusy: state.isBusyState,
        error,
      });
      return;
    }
  };

  const { isBusyState, loadedBytes, totalBytes, comment } = state;
  return (
    <Box>
      {isBusyState ? (
        <Box mt={1}>
          <Typography variant="h6">{uploadText}</Typography>
          <Box py={1}>
            <LinearProgressBar
              color={loadedBytes === totalBytes ? 'success' : 'primary'}
              value={loadedBytes && totalBytes ? loadedBytes / totalBytes : 0}
            />
          </Box>
        </Box>
      ) : (
        <>
          {showCommentBox && !isBusyState && (
            <Box mb={2}>
              <TextField
                size="small"
                fullWidth
                value={comment}
                autoFocus={true}
                onChange={handleCommentChange}
                label="Comment..."
                multiline
                rows={2}
              />
            </Box>
          )}
          <Box flex="auto">
            <FileInput
              handleChange={handleFileChange}
              file={file}
              accept={
                isArray(fileExtension)
                  ? join(fileExtension, ',')
                  : fileExtension
              }
            />
          </Box>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            sx={{ mt: 1 }}
            spacing={2}
          >
            <Grid>
              <Button variant="text" onClick={handleCancelClick} size="small">
                Cancel
              </Button>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                startIcon={uploadIcon}
                disabled={file === null || disabled}
                onClick={handleUpload}
                color={downloadButtonIntent}
                size="small"
              >
                {uploadButtonText}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default UploadFile;
