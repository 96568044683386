import React, { FunctionComponent } from 'react';
import { isEmpty, isUndefined, map, toString } from 'lodash';

import { MenuItem, TextField } from '@mui/material';

import { RowIdentifier } from '../../types/models';
import Loader, { LoaderInfo } from '../shared/Loader';
import { byName } from '../../utils/misc';

interface ISelectOutputsProps {
  modelInstanceId?: number;
  onChange: (id: RowIdentifier['id']) => void;
  size?: 'small' | 'medium';
  outputId?: number;
  outputsList?: RowIdentifier[];
  helperText?: React.ReactNode;
}

const SelectOutput: FunctionComponent<ISelectOutputsProps> = ({
  modelInstanceId,
  onChange,
  outputId,
  outputsList,
  size = 'medium',
  helperText,
}: ISelectOutputsProps) => {
  const needs = {
    rowIdentifiers:
      modelInstanceId !== undefined
        ? `/instances/${modelInstanceId}/row_identifiers`
        : undefined,
  };

  const renderContent = ({
    loading,
    errors,
    data,
  }: LoaderInfo<{ rowIdentifiers?: RowIdentifier[] }>) => {
    const rowIdentifiers: RowIdentifier[] = data.rowIdentifiers || [];
    const outputs: RowIdentifier[] = rowIdentifiers
      .filter((r) => r.Type === 'Output')
      .sort(byName);
    let disabled = false;

    if (modelInstanceId === undefined) {
      disabled = true;
    } else if (errors.length > 0) {
      disabled = true;
    } else if (outputs.length < 1) {
      disabled = true;
    }

    return (
      <TextField
        size={size}
        select
        disabled={disabled || loading}
        label="Select an Output"
        fullWidth
        value={toString(outputId)}
        onChange={(e) => onChange(Number(e.target.value))}
        helperText={helperText}
      >
        {map(
          isEmpty(outputsList) || isUndefined(outputsList)
            ? outputs || []
            : outputsList,
          (output) => (
            <MenuItem key={output.id} value={output.id}>
              {output.Name}
            </MenuItem>
          )
        )}
      </TextField>
    );
  };

  return <Loader needs={needs} render={renderContent} />;
};

export default SelectOutput;
