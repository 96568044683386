import React, { FunctionComponent } from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

import { isEmpty, map, toString } from 'lodash';

import { Model } from '../../types/models';
import { isModelMember, isModelOwner } from '../../utils/profile';
import { isAdminUser } from '../../utils/user';

import { ProfileProps, withProfile } from './AuthController';
import Loader, { LoaderInfo } from './Loader';

interface Props extends ProfileProps {
  filter?: 'default' | 'admin';
  showAll?: boolean;
  clientId?: number;
  modelId?: number;
  onChange(modelId: number): void;
  size?: 'small' | 'medium';
  hasError?: boolean;
}

const SelectModel: FunctionComponent<Props> = ({
  filter,
  showAll,
  clientId,
  modelId,
  onChange,
  size = 'medium',
  hasError,
  profile,
}) => {
  const renderContent = ({
    loading,
    errors,
    data,
  }: LoaderInfo<{ models?: Model[] }>) => {
    const models: Model[] = (data.models || [])
      .filter((model) =>
        filter === 'admin'
          ? profile &&
            (isAdminUser(profile.User) || isModelOwner(profile, model.id))
          : profile && isModelMember(profile, model.id)
      )
      .sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));

    let disabled = false;

    if (clientId === undefined) {
      disabled = true;
    } else if (errors.length > 0) {
      disabled = true;
    } else if (models.length < 1) {
      disabled = true;
    }

    const handleChange = (event: SelectChangeEvent) => {
      onChange(parseInt(event.target.value, 10));
    };

    return (
      <FormControl error={hasError} size={size} disabled={disabled} fullWidth>
        <InputLabel id="select-model-label">
          {isEmpty(models) ? 'No Models found' : 'Select a Model'}
        </InputLabel>
        <Select
          labelId="select-model-label"
          id="select-model"
          value={toString(modelId)}
          label={isEmpty(models) ? 'No Models found' : 'Select a Model'}
          onChange={handleChange}
          fullWidth
        >
          {map(models, (model) => (
            <MenuItem key={model.id} value={model.id}>
              {model.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const needs = {
    models: clientId !== undefined ? `/clients/${clientId}/models` : undefined,
  };

  return <Loader needs={needs} render={renderContent} />;
};

export default withProfile(SelectModel);
