import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import {
  camelCase,
  differenceBy,
  filter,
  groupBy,
  includes,
  isEmpty,
  isUndefined,
  keys,
  map,
  orderBy,
  reject,
  size,
  toLower,
  uniq,
  values,
} from 'lodash';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Scrollspy } from '@makotot/ghostui';
import { Search } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

import { ModelInstance, RowIdentifier } from '../../../../../types/models';
import { useData } from '../../../../../hooks/useData';
import useSetState from '../../../../../hooks/useSetState';
import PopupDialog from '../../../../shared/PopupDialog';
import ClearableTextField from '../../../../shared/ClearableTextField';
import CenteredSpinner from '../../../../shared/CenteredSpinner';

interface ISelectInputNamesModalProps {
  isOpen: boolean;
  onClose: () => void;
  modelInstanceId?: ModelInstance['id'];
  onSave: (inputs: RowIdentifier[]) => void;
  selectedInputs: RowIdentifier[];
  disabledInputs: RowIdentifier[];
}

interface State {
  category: string;
  selectedInputsValue: RowIdentifier[];
  filterInputsValue: string;
  selectedCategory: RowIdentifier['InputCategory'];
  hasVerticalScrollbar: boolean;
  showRemoveInputsModal: boolean;
}

const SelectInputsModal: FunctionComponent<ISelectInputNamesModalProps> = ({
  isOpen,
  onClose,
  modelInstanceId,
  onSave,
  selectedInputs,
  disabledInputs,
}) => {
  const { data } = useData<{
    rowIdentifiers?: RowIdentifier[];
  }>(
    () => ({
      rowIdentifiers:
        modelInstanceId !== undefined && isOpen
          ? `/instances/${modelInstanceId}/row_identifiers`
          : undefined,
    }),
    [isOpen]
  );

  const inputs = orderBy(
    filter(data.rowIdentifiers, {
      Type: 'Input',
    }),
    ['InputCategory'],
    ['asc']
  );

  const inputCategories = useMemo(
    () => uniq(map(inputs, 'InputCategory')),
    [inputs]
  );

  const [state, setState] = useSetState<State>({
    category: '',
    selectedInputsValue: selectedInputs || [],
    filterInputsValue: '',
    selectedCategory: inputCategories[0],
    hasVerticalScrollbar: false,
    showRemoveInputsModal: false,
  });

  useEffect(() => {
    setState({
      selectedInputsValue: selectedInputs,
    });
  }, [selectedInputs]);

  const filteredInputs = useMemo(
    () =>
      filter(inputs, (i) =>
        includes(toLower(i.Name), toLower(state.filterInputsValue))
      ),
    [inputs, state.filterInputsValue]
  );

  const inputCategoriesGrouped = groupBy(inputs, 'InputCategory');

  const filteredInputsGrouped = uniq(map(filteredInputs, 'InputCategory'));

  const sectionRefs = map(inputCategoriesGrouped, (_) =>
    React.createRef<HTMLUListElement>()
  );

  const onSelectInputs = (input: RowIdentifier): void => {
    if (includes(state.selectedInputsValue, input)) {
      setState({
        selectedInputsValue: reject(state.selectedInputsValue, input),
      });
    } else {
      setState({
        selectedInputsValue: [...state.selectedInputsValue, input],
      });
    }
  };

  const closeDialog = (): void => {
    setState({
      category: '',
      selectedInputsValue: [],
      filterInputsValue: '',
    });
    onClose();
  };

  const handleFilterChange = (e: string): void => {
    setState({
      filterInputsValue: e,
    });
  };

  const handleChangeCategory = (
    category: RowIdentifier['InputCategory']
  ): void => {
    setState({
      selectedCategory: category,
    });
  };

  useEffect(() => {
    const categoryItem = document.getElementById(
      camelCase(state.selectedCategory as string)
    );
    if (!categoryItem) {
      return;
    }

    categoryItem.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [state.selectedCategory]);

  const flattenedSelectedInputs: RowIdentifier[] = useMemo(
    () => values(state.selectedInputsValue),
    [state.selectedInputsValue]
  );

  useLayoutEffect(() => {
    if (isOpen && !isEmpty(inputs)) {
      const div = document.getElementById('select-input-modal-root');
      if (div instanceof HTMLElement) {
        const hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
        if (hasVerticalScrollbar && !state.hasVerticalScrollbar) {
          setState({ hasVerticalScrollbar });
        }
      }
    }
  }, [isOpen, inputs]);

  const selectAllRemaining = useCallback(() => {
    setState({
      selectedInputsValue: differenceBy(inputs, disabledInputs, 'id'),
    });
  }, [inputs, disabledInputs]);

  const selectedInputCategories = useMemo(() => {
    return groupBy(values(state.selectedInputsValue), 'InputCategory');
  }, [state.selectedInputsValue]);

  const renderPanel = (currentElementIndexInViewport?: number) => {
    return (
      <>
        <Grid md={3}>
          <Typography variant="h6">Input Categories</Typography>
          <List
            sx={{ border: `1px solid rgba(0, 0, 0, 0.12)`, my: 2 }}
            dense
            disablePadding
            data-cy="nav-wrapper"
          >
            {map(keys(inputCategoriesGrouped), (category, index) => (
              <Fragment key={category}>
                <ListItem
                  data-cy={`nav-item`}
                  disablePadding
                  secondaryAction={
                    <Tooltip
                      arrow
                      title={
                        size(selectedInputCategories[category]) > 0 && (
                          <ol>
                            {map(selectedInputCategories[category], (i) => (
                              <li key={i.id}>{i.Name}</li>
                            ))}
                          </ol>
                        )
                      }
                    >
                      <Typography
                        sx={{
                          color: (theme: Theme) => {
                            if (size(selectedInputCategories[category]) > 0) {
                              return 'black';
                            }
                            if (!includes(filteredInputsGrouped, category)) {
                              return '#D6D6D6';
                            }
                            return theme.palette.text.secondary;
                          },
                          fontWeight: () => {
                            if (size(selectedInputCategories[category]) > 0) {
                              return 600;
                            }
                          },
                        }}
                      >
                        {size(selectedInputCategories[category])}/
                        {inputCategoriesGrouped[category].length}
                      </Typography>
                    </Tooltip>
                  }
                >
                  {!isUndefined(currentElementIndexInViewport) ? (
                    <ListItemButton
                      disabled={!includes(filteredInputsGrouped, category)}
                      selected={
                        isEmpty(filteredInputsGrouped)
                          ? false
                          : currentElementIndexInViewport === index
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleChangeCategory(category);
                      }}
                      divider
                    >
                      <ListItemText primary={category} />
                    </ListItemButton>
                  ) : (
                    <ListItemButton
                      disabled={!includes(filteredInputsGrouped, category)}
                      selected={
                        (state.selectedCategory || inputCategories[0]) ===
                        category
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleChangeCategory(category);
                      }}
                      divider
                    >
                      <ListItemText primary={category} />
                    </ListItemButton>
                  )}
                </ListItem>
              </Fragment>
            ))}
          </List>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid md={8}>
          <Box sx={{ width: '100%' }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid>
                <Typography variant="h6">Inputs</Typography>
              </Grid>
              <Grid>
                <Button variant="text" onClick={selectAllRemaining}>
                  Select All Remaining Inputs
                </Button>
              </Grid>
            </Grid>
            <List
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: 400,
                '& ul': { padding: 0 },
                my: 2,
              }}
              disablePadding
              subheader={<li />}
              data-cy="section-wrapper"
              id="select-input-modal-root"
            >
              {map(keys(inputCategoriesGrouped), (category, categoryIndex) => (
                <Fragment key={category}>
                  <Box component="li" id={camelCase(category)}>
                    {!isEmpty(
                      filter(inputCategoriesGrouped[category], (i) =>
                        includes(
                          toLower(i.Name),
                          toLower(state.filterInputsValue)
                        )
                      )
                    ) ? (
                      <>
                        <Box
                          component="ul"
                          ref={sectionRefs[categoryIndex]}
                          mb={2}
                        >
                          <Typography
                            component={ListSubheader}
                            variant="subtitle1"
                            disableGutters
                          >
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid>{category}</Grid>
                            </Grid>
                          </Typography>
                          {map(
                            filter(inputCategoriesGrouped[category], (i) =>
                              includes(
                                toLower(i.Name),
                                toLower(state.filterInputsValue)
                              )
                            ),
                            (input) => (
                              <ListItem key={input.id} disableGutters>
                                <Chip
                                  label={input.Name}
                                  onClick={() => onSelectInputs(input)}
                                  variant={
                                    includes(state.selectedInputsValue, input)
                                      ? 'filled'
                                      : 'outlined'
                                  }
                                  disabled={includes(
                                    map(disabledInputs, 'id'),
                                    input.id
                                  )}
                                  sx={{
                                    color: (t) =>
                                      includes(state.selectedInputsValue, input)
                                        ? t.palette.secondary.main
                                        : t.palette.text.secondary,
                                    border: (t) =>
                                      includes(state.selectedInputsValue, input)
                                        ? `1px solid ${t.palette.secondary.main}`
                                        : `1px solid ${t.palette.divider}`,
                                    backgroundColor: (t) =>
                                      includes(state.selectedInputsValue, input)
                                        ? grey[100]
                                        : t.palette.background.paper,
                                  }}
                                />
                              </ListItem>
                            )
                          )}
                        </Box>
                        <Divider />
                      </>
                    ) : null}
                  </Box>
                </Fragment>
              ))}
            </List>
          </Box>
        </Grid>
      </>
    );
  };

  const onLoadInputs = async () => {
    onSave(state.selectedInputsValue);
  };

  return (
    <PopupDialog
      scroll="paper"
      disableScrollLock
      open={isOpen}
      close={closeDialog}
      title="Select Inputs"
      maxWidth="lg"
      primaryButtons={[
        {
          id: 'loadSelectionButton',
          label: 'Load Selection',
          onClick: onLoadInputs,
          disabled: flattenedSelectedInputs.length === 0,
        },
      ]}
    >
      {!isEmpty(inputs) ? (
        <>
          <Grid container alignItems="center">
            <Grid md={4} />
            <Grid md={3}>
              <ClearableTextField
                id="filterInputNames"
                label="Filter Inputs"
                InputProps={{
                  startAdornment: <Search />,
                }}
                size="small"
                value={state.filterInputsValue}
                onHandleChange={handleFilterChange}
                fullWidth
              />
            </Grid>
            <Grid md={5} container justifyContent="flex-end">
              <Typography>
                Total Selected :{' '}
                <Typography component="span" sx={{ fontWeight: 'bold' }}>
                  {flattenedSelectedInputs.length}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          {isEmpty(filteredInputsGrouped) ? (
            <Alert severity="error" sx={{ my: 2 }}>
              <AlertTitle>
                There are no inputs pertaining to the filter you entered.
              </AlertTitle>
            </Alert>
          ) : (
            <Grid container mt={2} justifyContent="space-between">
              {state.hasVerticalScrollbar ? (
                <Scrollspy
                  sectionRefs={sectionRefs}
                  rootSelector="#select-input-modal-root"
                >
                  {({ currentElementIndexInViewport }) =>
                    renderPanel(currentElementIndexInViewport)
                  }
                </Scrollspy>
              ) : (
                renderPanel()
              )}
            </Grid>
          )}
        </>
      ) : (
        <CenteredSpinner />
      )}
    </PopupDialog>
  );
};

export default SelectInputsModal;
