import {
  AuthenticationResult,
  BrowserCacheLocation,
  Configuration,
  EventMessage,
  EventType,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';

const CLIENT_ID = (process.env.REACT_APP_MSAL_CLIENT_ID as string) || '';
const TENANT_ID = process.env.REACT_APP_MSAL_TENANT_ID || '';
const AUTHORITY = `https://login.microsoftonline.com/${TENANT_ID}`;

const REDIRECT_URI = `${window.location.protocol}//${window.location.host}/`;

/**
 * Config object to be passed to Msal on creation
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: AUTHORITY,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: REDIRECT_URI,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false,
  },
};

/**
 *  Add here scopes for access token to be used at MS Identity Platform endpoints.
 */
export const apiRequest: RedirectRequest = {
  scopes: [(process.env.REACT_APP_API_SCOPE as string) || ''],
};

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const { account } = payload;
    msalInstance.setActiveAccount(account);
  }
});
