import React, { FormEvent, FunctionComponent, useContext } from 'react';
import { includes, isEmpty, values } from 'lodash';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { InfoOutlined, CloudUpload } from '@mui/icons-material';

import { LoadingButton } from '@mui/lab';

import { HTTPError } from '../../support/errors';
import SubmitSVG from '../../assets/images/submit.svg';
import * as API from '../../services/API';

import { getFileExtension } from '../../utils/misc';

import {
  GeneratedFile,
  Submission,
  SubmissionStatus,
  UploadedFile,
} from '../../types/models';

import APICacheContext from '../shared/APICacheContext';

import UploadFile from '../shared/UploadFile';

import { getDataAtIntervals } from '../../utils/getDataAtIntervals';

import useSetState from '../../hooks/useSetState';
import APICache from '../../services/APICache';

import Flex from '../shared/Flex';

import { useData } from '../../hooks/useData';

import ScenarioUpdateErrorCallout from './ScenarioUpdateErrorCallout';

interface Props {
  disabled?: boolean;
  instanceId: number;
  scenarioId: number;
  lockSubmissionId: number;
  refresh: () => Promise<any[]>;
}

interface State {
  error: HTTPError | null;
  isBusy: boolean;
  file: File | null;
  fileTypeError: boolean;
  generatedFile?: Submission;
  uploadedFile?: UploadedFile;
  scenarioCheckMessage: string;
  groupId?: number;
}

const INITIAL_STATE = {
  error: null,
  isBusy: false,
  file: null,
  fileTypeError: false,
  generatedFile: undefined,
  uploadedFile: undefined,
  scenarioCheckMessage: '',
  groupId: undefined,
};

const tooltipContent =
  'Submit data from an existing Excel file to this scenario';

const UploadForecast: FunctionComponent<Props> = ({
  instanceId,
  refresh,
  scenarioId,
  lockSubmissionId,
  disabled,
}) => {
  const uploadForecastContext = useContext(APICacheContext);

  const { data } = useData<{
    submissions?: Submission[];
  }>(
    () => ({
      submissions:
        instanceId !== undefined && scenarioId !== undefined
          ? `/instances/${instanceId}/scenarios/${scenarioId}/submissions`
          : undefined,
    }),
    [instanceId, scenarioId],
    true
  );

  const submissions = data.submissions || [];

  const activeSubmissionIds = values(
    submissions.reduce<{ [id: number]: number }>(
      (activeIds, submission) => ({
        ...activeIds,
        ...(submission.Status === SubmissionStatus.Finished &&
        submission.GroupRowIdentifierID !== undefined &&
        activeIds[submission.GroupRowIdentifierID] === undefined
          ? { [submission.GroupRowIdentifierID]: submission.id }
          : {}),
      }),
      {}
    )
  );

  const [state, setState] = useSetState<State>(INITIAL_STATE);

  const handleFileChange = (event: FormEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;

    if (getFileExtension(files) === 'xlsm') {
      setState({ file: files && files[0], fileTypeError: false });
    } else {
      setState({ fileTypeError: true, file: null });
    }
  };

  const handleSubmit = async (
    uploadedFileData?: UploadedFile,
    scenarioCheck: number = 1
  ) => {
    const uploadData: UploadedFile =
      uploadedFileData || (state.uploadedFile as UploadedFile);

    if (uploadData.error) {
      setState({ isBusy: false, error: uploadData.error });
      return;
    }
    if (uploadData.file === null) {
      return;
    }

    setState({
      isBusy: true,
      error: null,
      uploadedFile: uploadData,
    });

    if (uploadData.fileUploadData) {
      try {
        const generatedFile = await API.create<GeneratedFile>(
          `/instances/${instanceId}/scenarios/${scenarioId}/submissions`,
          {
            UploadID: uploadData.fileUploadData.id,
            Comment: uploadData.userComment,
            LockSubmissionID: lockSubmissionId,
            ScenarioCheck: scenarioCheck,
            GroupID: state.groupId,
          }
        );

        await (uploadForecastContext as APICache).load(
          [`/instances/${instanceId}/scenarios/${scenarioId}/submissions`],
          true
        );

        setState({
          file: null,
          scenarioCheckMessage: '',
          groupId: undefined,
        });

        if (generatedFile) {
          const generatedFileData = await getDataAtIntervals(
            `/instances/${instanceId}/scenarios/${scenarioId}/submissions/${
              generatedFile!.id
            }`,
            'Status'
          );
          setState({
            generatedFile: includes(
              [
                SubmissionStatus.FailedWithReturnFile,
                SubmissionStatus.Failed,
                SubmissionStatus.NoDataReturned,
              ],
              generatedFileData.Status
            )
              ? generatedFileData
              : null,
            isBusy: false,
            file: null,
          });
          // Refresh submissions
          await (uploadForecastContext as APICache).load(
            [`/instances/${instanceId}/scenarios/${scenarioId}/submissions`],
            true
          );
        }
      } catch (error: any) {
        if (error.status === 307) {
          setState({
            isBusy: false,
            file: null,
            generatedFile: undefined,
            scenarioCheckMessage: error?.body?.message,
            groupId: error?.body?.GroupID,
          });
        } else {
          setState({ isBusy: false, error });
        }
        return;
      }

      refresh();
    }
  };

  const handleCancelClick = () => {
    setState(INITIAL_STATE);
  };

  const {
    file,
    error,
    isBusy,
    fileTypeError,
    generatedFile,
    scenarioCheckMessage,
  } = state;

  return (
    <Grid container>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Grid sm={6}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Tooltip arrow title={tooltipContent}>
              <InfoOutlined fontSize="small" sx={{ color: '#919191' }} />
            </Tooltip>
            <Typography variant="subtitle1" color="disabled">
              Upload Forecast
            </Typography>
            <img alt="" src={SubmitSVG} />
          </Stack>
        </Grid>
        <Grid justifyContent="flex-end">
          {!file && !generatedFile && (
            <Box>
              <Button
                component="label"
                size="small"
                variant="contained"
                htmlFor="uploadFileSubmit"
                disabled={disabled || isEmpty(activeSubmissionIds)}
              >
                Select File
                <input
                  type="file"
                  id="uploadFileSubmit"
                  accept=".xlsm"
                  onChange={handleFileChange}
                />
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container>
        {error && (
          <Box mt={1} sx={{ width: '100%' }}>
            <ScenarioUpdateErrorCallout
              error={error}
              text="Submitting scenario version failed, check your connection and try again."
              refresh={() => setState({ error: null })}
            />
          </Box>
        )}
      </Grid>
      {fileTypeError && (
        <Box mt={1} sx={{ width: '100%' }}>
          <ScenarioUpdateErrorCallout
            error={fileTypeError}
            text="Please upload a file with a .xlsm extension"
          />
        </Box>
      )}
      {file && (
        <Grid container mt={2} sx={{ width: '100%' }}>
          <Grid sm={12}>
            <UploadFile
              showCommentBox
              isBusy={isBusy}
              fileExtension={'.xlsm'}
              handleCancelClick={handleCancelClick}
              uploadButtonText={'Submit'}
              uploadIcon={<CloudUpload />}
              fileUploadData={handleSubmit}
              uploadComment={`Upload for submission to ${scenarioId}`}
              file={file}
              handleFileChange={handleFileChange}
              downloadButtonIntent="primary"
            />
          </Grid>
        </Grid>
      )}
      {generatedFile &&
        generatedFile.Status === SubmissionStatus.FailedWithReturnFile && (
          <Box mt={1}>
            <Alert severity="error" icon={false}>
              <AlertTitle>File upload failed</AlertTitle>
              <Box alignItems="center">
                <Flex alignItems="center">
                  <Typography>{generatedFile.Status_Message}</Typography>
                </Flex>
                <Box>
                  <Button
                    variant="outlined"
                    onClick={() => setState({ generatedFile: undefined })}
                  >
                    Close
                  </Button>
                  <Button variant="contained" href={generatedFile.ErrorBlobURL}>
                    Download Error Report
                  </Button>
                </Box>
              </Box>
            </Alert>
          </Box>
        )}
      {generatedFile && generatedFile.Status === SubmissionStatus.Failed && (
        <Box mt={1}>
          <Alert severity="error" icon={false}>
            <AlertTitle>File upload failed</AlertTitle>
            <Box mb={1}>{generatedFile.Status_Message}</Box>
            <Button
              onClick={() => setState({ generatedFile: undefined })}
              variant="contained"
              color="error"
            >
              OK
            </Button>
          </Alert>
        </Box>
      )}
      {generatedFile &&
        generatedFile.Status === SubmissionStatus.NoDataReturned && (
          <Box mt={1}>
            <Alert severity="warning" icon={false}>
              <AlertTitle>No Data Returned</AlertTitle>
              <Box mb={1}>{generatedFile.Status_Message}</Box>
              <Button
                onClick={() => setState({ generatedFile: undefined })}
                variant="contained"
                color="error"
              >
                OK
              </Button>
            </Alert>
          </Box>
        )}
      {!!scenarioCheckMessage && (
        <Box mt={1}>
          <Alert severity="info">
            <AlertTitle>File upload warning</AlertTitle>
            <Box mb={1}>{scenarioCheckMessage}</Box>
            <Button
              onClick={() =>
                setState({
                  scenarioCheckMessage: '',
                  groupId: undefined,
                })
              }
              className="disable-btn-effect"
              disabled={state.isBusy}
              variant="outlined"
            >
              Cancel
            </Button>
            <LoadingButton
              onClick={() => handleSubmit(undefined, 0)}
              variant="contained"
              className="disable-btn-effect"
              loading={state.isBusy}
            >
              Continue
            </LoadingButton>
          </Alert>
        </Box>
      )}
    </Grid>
  );
};

export default UploadForecast;
