import React, { FunctionComponent, useEffect } from 'react';
import { compact, findIndex, map, trimEnd, trimStart, values } from 'lodash';

import * as API from '../../../../services/API';

import { REPORT_NAME_LENGTH } from '../../CreateNewReportDialog';

import { IPortfolioReportStateData } from '../../index';

import { RowIdentifier } from '../../../../types/models';

import useSetState from '../../../../hooks/useSetState';

import PopupDialog from '../../../shared/PopupDialog';
import ClearableTextField from '../../../shared/ClearableTextField';

import { IDataCutFieldsList, IDimensionsList } from './EditPortfolioSourceType';

interface IRenameDataCutDialogProps {
  isOpen: boolean;
  onClose: () => void;
  dataCut: IDataCutFieldsList;
  refreshData: () => Promise<void>;
  selectedReport?: IPortfolioReportStateData;
  dimensionsList: IDimensionsList[];
  Name: string;
  handleChangeRenameDataCut: (dataCutName: string) => void;
  datacutListTitles: string[];
  errorMessage: (e: string) => void;
}

interface IRenameDataCutDialogState {
  renamedDataCut: IDataCutFieldsList['Name'];
  loading: boolean;
}

const initialRenameDataCutDialogState: IRenameDataCutDialogState = {
  renamedDataCut: '',
  loading: false,
};

const RenameDataCutDialog: FunctionComponent<IRenameDataCutDialogProps> = (
  props: IRenameDataCutDialogProps
) => {
  const [state, setState] = useSetState<IRenameDataCutDialogState>(
    initialRenameDataCutDialogState
  );

  useEffect(() => {
    setState({
      renamedDataCut: props.Name || '',
    });
  }, [props.Name]);

  const onRenameDataCut = async (): Promise<void> => {
    if (props.dataCut.id) {
      try {
        setState({ loading: true });
        await API.update(
          `/portfolio_datasources/${props.selectedReport?.DataSourceID}/portfolio_datacuts/${props.dataCut.id}`,
          {
            Name: trimEnd(state.renamedDataCut),
            ModelID: props.dataCut.modelId.value,
            MetaData: {
              ModelInstance: props.dataCut.modelInstanceId.value,
              ModelInstanceLock: props.dataCut.modelInstanceLock.value ? 1 : 0,
              NumScenarios:
                Number(props.dataCut.numScenarios.value) === 0
                  ? null
                  : Number(props.dataCut.numScenarios.value),
              Scenarios: map(props.dataCut.scenarios.value, 'id'),
              ScenarioLock: props.dataCut.scenariosLock.value ? 1 : 0,
              RangeType: props.dataCut.rangeType.value,
              CutType: props.dataCut.cutType.value,
              ...(props.dataCut.cutType.value === 'outputs'
                ? {
                    Outputs: map(props.dataCut.outputs.value, 'Name'),
                    Timescales: props.dataCut.timescales.value,
                  }
                : { Inputs: map(props.dataCut.inputs.value, 'Name') }),
              DimensionMapping: compact(
                map(values(props.dataCut.dimensionsList.value), (item) => {
                  if (item.dimension.Name !== 'Unmapped') {
                    return {
                      sourceDimension: findIndex(props.dimensionsList, {
                        fieldValue: item.sourceDimensionName,
                      }),
                      dimension: (item.dimension as RowIdentifier).DimNumber,
                      dimensionInstances: map(
                        item.dimensionInstances.value,
                        'Name'
                      ),
                    };
                  }
                })
              ),
            },
          }
        );
        props.refreshData().then(() => {
          props.onClose();
          setState({ loading: false });
        });
      } catch (e: any) {
        setState({ loading: false });
        props.errorMessage(e.body.message);
      }
    } else {
      props.handleChangeRenameDataCut(trimEnd(state.renamedDataCut));
    }
  };

  return (
    <PopupDialog
      open={props.isOpen}
      title="Rename Data Cut"
      isLoading={state.loading}
      close={() => {
        if (!state.loading) {
          props.onClose();
          setState({
            renamedDataCut: '',
          });
        }
      }}
      primaryButtons={[
        {
          id: 'rename-data-cut-button',
          label: 'Rename',
          onClick: onRenameDataCut,
          disabled: !state.renamedDataCut || state.loading,
        },
      ]}
    >
      <ClearableTextField
        label="Rename Data Cut"
        maxLength={REPORT_NAME_LENGTH}
        id="renameDataCutInput"
        value={state.renamedDataCut}
        onHandleChange={(value: string) =>
          setState({
            renamedDataCut: trimStart(value.substring(0, REPORT_NAME_LENGTH)),
          })
        }
        onKeyDown={(e: { key: string }) => {
          if (e.key === 'Enter') {
            onRenameDataCut();
          }
        }}
      />
    </PopupDialog>
  );
};

export default RenameDataCutDialog;
