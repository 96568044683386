import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { filter, find, get, includes, isEmpty, toLower } from 'lodash';
import {
  Box,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
  FormControlLabel,
} from '@mui/material';

import { Delete, Search } from '@mui/icons-material';

import { blue } from '@mui/material/colors';

import { ColDef, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';

import { Profile, User, UserRole } from '../../../../types/models';
import * as API from '../../../../services/API';

import { useProfile } from '../../../../hooks/useProfile';

import { IPortfolioReportStateData } from '../../index';

import useSetState from '../../../../hooks/useSetState';

import Table from '../../../shared/Table';

import CenteredSpinner from '../../../shared/CenteredSpinner';

import { PortfolioReportMembershipRole } from '../../types';

import { PortfolioReportMembershipWithClient } from './index';

type ISelectedPermissionFilter = 'all' | 'viewer' | 'owner';

interface IPaginatedSelectedUsersProps {
  usersList: PortfolioReportMembershipWithClient[];
  usersPerClient?: User[];
  userReportSharedStatus: Record<number, boolean>;
  selectedReport?: IPortfolioReportStateData;
  clientName?: string;
  refresh: () => void;
}

interface IPaginatedSelectedUsersState {
  usersList: PortfolioReportMembershipWithClient[];
  originalUsersList: PortfolioReportMembershipWithClient[];
  userSearchString: string;
  loading: boolean;
  isPermissionsFilterOpen: boolean;
  selectedPermissionFilter: ISelectedPermissionFilter;
}

const initialPaginatedSelectedUsersState: IPaginatedSelectedUsersState = {
  usersList: [],
  originalUsersList: [],
  userSearchString: '',
  loading: false,
  isPermissionsFilterOpen: false,
  selectedPermissionFilter: 'all',
};

const PaginatedSelectedUsers: FunctionComponent<
  IPaginatedSelectedUsersProps
> = (props: IPaginatedSelectedUsersProps) => {
  const profile: Profile = useProfile();
  const [state, setState] = useSetState<IPaginatedSelectedUsersState>(
    initialPaginatedSelectedUsersState
  );

  useEffect(() => {
    const getUsersList = () => {
      switch (state.selectedPermissionFilter) {
        case 'all':
          return props.usersList;
        case 'owner':
          return filter(props.usersList, {
            Role: PortfolioReportMembershipRole.Owner,
          });
        case 'viewer':
          return filter(props.usersList, {
            Role: PortfolioReportMembershipRole.Viewer,
          });
        default:
          return props.usersList;
      }
    };
    setState({
      usersList: getUsersList(),
      originalUsersList: props.usersList,
    });
  }, [props.usersList]);

  useEffect(() => {
    setState({
      usersList: filter(props.usersList, (item) => {
        const user = find(props.usersPerClient, {
          id: item.UserID,
        }) as User;
        return (
          includes(
            toLower(get(user, 'Display_Name')),
            toLower(state.userSearchString)
          ) ||
          includes(
            toLower(get(user, 'User_Name')),
            toLower(state.userSearchString)
          )
        );
      }),
    });
  }, [state.userSearchString]);

  const UserNameFormatter = (
    params: ICellRendererParams<PortfolioReportMembershipWithClient>
  ): JSX.Element => {
    const user = find(props.usersPerClient, {
      id: params.value,
    }) as User;
    return (
      <u style={{ color: blue[500], width: 'fit-content' }}>
        <Tooltip title={get(user, 'User_Name')} arrow>
          <Typography sx={{ width: 'fit-content' }}>
            {get(user, 'Display_Name')}
          </Typography>
        </Tooltip>
      </u>
    );
  };

  const UserOrganizationFormatter = (
    params: ICellRendererParams<PortfolioReportMembershipWithClient>
  ): JSX.Element => {
    if (params.value !== null) {
      return <Typography>{props.clientName}</Typography>;
    }
    return <Typography>Trinity</Typography>;
  };

  const UserPermissionsFormatter = (
    params: ICellRendererParams<PortfolioReportMembershipWithClient>
  ): JSX.Element => {
    const user = find(props.usersPerClient, {
      id: params.data?.UserID,
    }) as User;

    if (!isEmpty(user)) {
      return (
        <Tooltip
          arrow
          title={(() => {
            if (user.Role === UserRole.Client_Read_Only_User) {
              return 'You cannot assign an owner role to a Read Only User.';
            } else if (user.id === profile.User.id) {
              return 'You cannot change yourself to a Viewer role.';
            }
            return '';
          })()}
        >
          <FormControlLabel
            control={
              <Switch
                disabled={
                  user.id === profile.User.id ||
                  user.Role === UserRole.Client_Read_Only_User
                }
                title={
                  user.id === profile.User.id
                    ? 'You cannot change yourself to a Viewer role.'
                    : user.Role === UserRole.Client_Read_Only_User
                      ? 'You cannot assign an owner role to a Read Only User'
                      : ''
                }
                size="medium"
                checked={props.userReportSharedStatus[user.id]}
                onChange={async () => {
                  setState({ loading: true });
                  try {
                    await API.update(
                      `/portfolio_reports/${props.selectedReport?.id}/portfolio_report_memberships/${params.data?.id}`,
                      {
                        Role: !props.userReportSharedStatus[user.id]
                          ? PortfolioReportMembershipRole.Owner
                          : PortfolioReportMembershipRole.Viewer,
                      }
                    );
                    props.refresh();
                  } catch (e) {}
                  setState({ loading: false });
                }}
              />
            }
            label="Report Owner"
          />
        </Tooltip>
      );
    }
    return <p />;
  };

  const UserPermissionsFilterFormatter = (
    params: ICellRendererParams<PortfolioReportMembershipWithClient>
  ) => {
    const [value, setValue] = useState<string>('');

    useEffect(() => {
      if (params.value === '(Select All)') {
        setValue(params.value);
      } else if (params.value === '0') {
        setValue('Report Viewer');
      } else {
        setValue(`Report Owner`);
      }
    }, []);

    return <Box>{value}</Box>;
  };

  const UserDeleteFormatter = (
    params: ICellRendererParams<PortfolioReportMembershipWithClient>
  ) => {
    const user = find(props.usersPerClient, {
      id: params.data?.UserID,
    }) as User;
    if (!isEmpty(user)) {
      return (
        <IconButton
          disabled={user.id === profile.User.id}
          onClick={async () => {
            try {
              setState({ loading: true });
              await API.remove(
                `/portfolio_reports/${props.selectedReport?.id}/portfolio_report_memberships/${params.data?.id}`
              );
              setState({ loading: false });
              props.refresh();
            } catch (e) {
              setState({ loading: false });
            }
          }}
          title="Delete User"
          size="small"
          color="error"
        >
          <Delete fontSize="small" />
        </IconButton>
      );
    }
    return <p />;
  };

  const userSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const userSearchString = e.target.value;
    setState({ userSearchString });
  }, []);

  const columns: ColDef[] = [
    {
      field: 'UserID',
      headerName: 'User Name',
      cellRenderer: UserNameFormatter,
    },
    {
      field: 'Client',
      headerName: 'Organization',
      cellRenderer: UserOrganizationFormatter,
    },
    {
      field: 'Role',
      headerName: 'Permission',
      cellRenderer: UserPermissionsFormatter,
      filter: 'agSetColumnFilter',
      filterParams: {
        cellRenderer: UserPermissionsFilterFormatter,
        cellRendererParams: { isFilterRenderer: true },
      },
    },
    {
      field: 'ReportID',
      headerName: 'Action',
      cellRenderer: UserDeleteFormatter,
    },
  ];

  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
  }, []);

  return (
    <>
      <TextField
        type="search"
        size="small"
        fullWidth
        sx={{ mb: 2 }}
        InputProps={{
          startAdornment: <Search />,
        }}
        label="Search user"
        onChange={userSearchChange}
      />
      {!state.loading ? (
        <Table<PortfolioReportMembershipWithClient>
          id="users-table"
          isSuccess
          isLoading={false}
          rowData={state.usersList}
          rowCount={state.usersList.length}
          columnDefs={columns}
          onGridReady={onGridReady}
          noRowsOverlayComponent={() =>
            !state.loading && isEmpty(state.usersList) ? (
              'No users found'
            ) : (
              <CenteredSpinner />
            )
          }
          onFirstDataRendered={(params) => params.api.sizeColumnsToFit()}
          defaultColDef={{
            floatingFilter: true,
          }}
          pagination
        />
      ) : (
        <CenteredSpinner />
      )}
    </>
  );
};

export default PaginatedSelectedUsers;
