import React, { FunctionComponent, useEffect } from 'react';
import {
  Button,
  Container,
  MenuItem,
  Slide,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { find, get, head, includes, indexOf, map, trimStart } from 'lodash';

import {
  formatParameters,
  IFormatOutputParameters,
} from '../../../Visualization/formatParameters';
import { truncationTypes } from '../../../Visualization/visualizationConstants';
import useSetState from '../../../../hooks/useSetState';
import { Timescale } from '../../../../types/models';

const CHART_PREFIX_SUFFIX_TEXT_MAX_LENGTH = 10;

export const dateFormats = (timescale: Timescale): string[] => {
  switch (timescale) {
    case Timescale.Weekly:
      return dailyDateFormats;
    default:
      return monthlyDateFormats;
  }
};

export const dailyDateFormats: string[] = [
  'M/d/yyyy',
  'd/M/yyyy',
  'yyyy/d/M',
  'yyyy/M/d',
  'M-d-yyyy',
  'd-M-yyyy',
  'yyyy-d-M',
  'yyyy-M-d',
];
export const monthlyDateFormats: string[] = [
  ...dailyDateFormats,
  `MMM yyyy`,
  `MMMM yyyy`,
];

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
];

interface IFormatChartProps {
  open: boolean;
  handleCloseEditChartPanel: () => void;
  formatKey: number;
  timescale: Timescale;
  yAxisTitle: string;
  prefix: string;
  suffix: string;
  magnitudeTruncation: number;
  decimalPlaces: number;
  chartDateType: string;
  handleSaveFormatChartPanel: (data: {
    dateFormat: string;
    truncation: number;
    decimalPlaces: number;
    yAxisTitle: string;
    prefix: string;
    suffix: string;
  }) => void;
}

interface IFormatChartState {
  dateFormat: string;
  truncation: number;
  yAxisTitle: string;
  decimalPlaces: number;
  prefix: string;
  suffix: string;
}

const FormatChartPanel: FunctionComponent<IFormatChartProps> = ({
  open,
  handleCloseEditChartPanel,
  formatKey,
  timescale,
  yAxisTitle,
  handleSaveFormatChartPanel,
  prefix,
  suffix,
  magnitudeTruncation,
  decimalPlaces,
  chartDateType,
}) => {
  const [state, setState] = useSetState<IFormatChartState>({
    dateFormat: chartDateType,
    truncation: magnitudeTruncation,
    yAxisTitle,
    decimalPlaces,
    prefix,
    suffix,
  });

  useEffect(() => {
    setState({
      prefix,
      suffix,
      dateFormat: chartDateType,
      truncation: magnitudeTruncation,
      yAxisTitle,
      decimalPlaces,
    });
  }, [
    formatKey,
    yAxisTitle,
    prefix,
    suffix,
    magnitudeTruncation,
    decimalPlaces,
    chartDateType,
  ]);

  return (
    <Slide direction="right" in={open} mountOnEnter unmountOnExit>
      <Container
        maxWidth={false}
        sx={{
          mb: 2,
          mt: 1,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 1 }}
        >
          <Grid xs={6}>
            <Typography variant="h6">Format Chart</Typography>
          </Grid>
          <Grid
            xs={6}
            justifyContent="flex-end"
            container
            spacing={2}
            sx={{ my: 1 }}
          >
            <Button
              variant="outlined"
              sx={{ mr: 2 }}
              onClick={handleCloseEditChartPanel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                handleSaveFormatChartPanel({
                  dateFormat:
                    state.dateFormat ||
                    (head(dateFormats(timescale)) as string),
                  truncation: state.truncation,
                  decimalPlaces: state.decimalPlaces,
                  yAxisTitle: state.yAxisTitle,
                  prefix: state.prefix,
                  suffix: state.suffix,
                })
              }
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid xs={6}>
            <TextField
              fullWidth
              label="Number Type"
              disabled
              value={get(
                find(formatParameters, {
                  formatKey,
                }) as IFormatOutputParameters,
                'numberType'
              )}
            />
          </Grid>
          {includes(
            [Timescale.Weekly, Timescale.Monthly],
            timescale as Timescale
          ) && (
            <Grid xs={6}>
              <TextField
                select
                fullWidth
                label="Date Format"
                value={state.dateFormat}
                onChange={(e) => setState({ dateFormat: e.target.value })}
              >
                {map(dateFormats(timescale), (format) => (
                  <MenuItem key={format} value={format}>
                    {format}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid xs={6}>
            <TextField
              disabled={
                get(
                  find(formatParameters, {
                    formatKey,
                  }) as IFormatOutputParameters,
                  'numberType'
                ) === 'Percent'
              }
              fullWidth
              size="small"
              label="Truncation"
              select
              value={truncationTypes[state.truncation as number]}
              onChange={(e) =>
                setState({
                  truncation: indexOf(truncationTypes, e.target.value),
                })
              }
            >
              {map(truncationTypes, (i) => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid xs={6}>
            <TextField
              fullWidth
              label="Chart Y-Axis Label"
              value={state.yAxisTitle}
              onChange={(e) => setState({ yAxisTitle: e.target.value })}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid xs={6}>
            <Typography id="decimal-places-slider" gutterBottom>
              Decimal Places
            </Typography>
            <Slider
              min={0}
              max={2}
              value={state.decimalPlaces}
              onChange={(e, value) =>
                setState({ decimalPlaces: value as number })
              }
              marks={marks}
              valueLabelDisplay="auto"
            />
          </Grid>
          <Grid xs={6}>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <TextField
                  label="Prefix"
                  fullWidth
                  value={state.prefix}
                  onChange={(e) =>
                    setState({
                      prefix: trimStart(
                        e.target.value.substring(
                          0,
                          CHART_PREFIX_SUFFIX_TEXT_MAX_LENGTH
                        )
                      ),
                    })
                  }
                />
              </Grid>
              <Grid xs={6}>
                <TextField
                  label="Suffix"
                  fullWidth
                  value={state.suffix}
                  onChange={(e) => setState({ suffix: e.target.value })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Slide>
  );
};

export default FormatChartPanel;
