import React, { FunctionComponent, useEffect } from 'react';
import { map, range, replace, some, split } from 'lodash';

import {
  CalendarMonth,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';

import { Box, Button, IconButton, Select } from '@mui/material';

import { XRefDate } from '../../../types/models';
import useSetState from '../../../hooks/useSetState';

import StyledPopper from '../../shared/StyledPopper';

interface IQuarterSelectorProps
  extends Pick<IQuarterPickerProps, 'dates' | 'minYear' | 'maxYear'> {
  quarter?: number;
  year: number;
  onSelectYear: (quarter: number, year: number) => void;
}

interface IQuarterSelectorState {
  yearState: number;
  quarterState?: number;
}

const QuarterSelector: FunctionComponent<IQuarterSelectorProps> = ({
  quarter,
  year,
  onSelectYear,
  dates,
  minYear,
  maxYear,
}: IQuarterSelectorProps) => {
  const [state, setState] = useSetState<IQuarterSelectorState>({
    yearState: year,
    quarterState: quarter,
  });

  useEffect(() => {}, [state]);

  const styles: Record<string, React.CSSProperties> = {
    yearNav: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 1px 5px',
      justifyContent: 'center',
    },
    yearButton: {
      flex: '1',
      textAlign: 'center',
      margin: '0 2px',
    },
    yearButtonText: {
      verticalAlign: 'middle',
      fontWeight: 'bold',
    },
    quarterContainer: {
      display: 'inline-block',
      padding: '1px',
      boxSizing: 'border-box',
    },
    quarterButton: {
      lineHeight: '3em',
      textAlign: 'center',
      width: '100%',
      minWidth: '4em',
    },
  };

  const yearsRange: number[] = range(minYear, maxYear + 1);

  const onPrevYear = (): void => {
    setState({ yearState: state.yearState - 1 });
  };

  const onNextYear = (): void => {
    setState({ yearState: state.yearState + 1 });
  };

  const onSelect = (quarter: number): void => {
    setState({ quarterState: quarter });
    onSelectYear(quarter, state.yearState);
  };

  const renderQuarter = (quarterItem: number): JSX.Element => {
    const selected: boolean =
      quarter === quarterItem && year === state.yearState;

    return (
      <Box
        className="quarter-container"
        style={styles.quarterContainer}
        key={quarterItem}
      >
        <Button
          size="small"
          variant={selected ? 'contained' : 'outlined'}
          onClick={() => onSelect(quarterItem)}
          sx={{ m: 0.25 }}
          disabled={
            !some(dates, { Date: `${state.yearState} Q${quarterItem}` })
          }
        >
          {`Q${quarterItem}`}
        </Button>
      </Box>
    );
  };

  const quarters: JSX.Element[] = [];
  for (let i = 1; i <= 4; i++) {
    quarters.push(renderQuarter(i));
  }

  return (
    <Box>
      <Box className="quarter-year-nav" style={styles.yearNav}>
        <IconButton
          size="small"
          onClick={onPrevYear}
          disabled={state.yearState <= minYear}
        >
          <KeyboardArrowLeft fontSize="small" />
        </IconButton>
        <Select
          native
          size="small"
          value={state.yearState}
          onChange={(e) => {
            (e as any).stopPropagation();
            setState({ yearState: Number(e.target.value) });
          }}
        >
          {map(yearsRange, (yearItem) => (
            <option key={yearItem} value={yearItem}>
              {yearItem}
            </option>
          ))}
        </Select>
        <IconButton
          size="small"
          onClick={onNextYear}
          disabled={state.yearState >= maxYear}
        >
          <KeyboardArrowRight fontSize="small" />
        </IconButton>
      </Box>
      <Box style={styles.quarters}>{quarters}</Box>
    </Box>
  );
};

interface IQuarterPickerProps {
  minYear: number;
  maxYear: number;
  onChange: (quarter: number | undefined, year: number) => void;
  dates: XRefDate[];
  value?: string;
}

interface IQuarterPickerState {
  selectedQuarter?: number;
  selectedYear: number;
  isPopOverOpen: boolean;
}

const QuarterPicker: FunctionComponent<IQuarterPickerProps> = ({
  minYear,
  maxYear,
  onChange,
  dates,
  value,
}: IQuarterPickerProps) => {
  const [state, setState] = useSetState<IQuarterPickerState>({
    selectedQuarter: value
      ? Number(replace(split(value, ' ')[1], /[^0-9]/g, ''))
      : undefined,
    selectedYear: value ? Number(value.substring(0, 4)) : minYear,
    isPopOverOpen: false,
  });

  useEffect(() => {
    if (value) {
      setState({
        selectedQuarter: Number(replace(split(value, ' ')[1], /[^0-9]/g, '')),
        selectedYear: Number(value.substring(0, 4)),
      });
    }
  }, [value]);

  const onSelectQuarter = (quarter: number, year: number): void => {
    setState({
      selectedQuarter: quarter,
      selectedYear: year,
      isPopOverOpen: false,
    });
  };

  const handleClose = (): void => {
    onChange(state.selectedQuarter, state.selectedYear);
  };

  return (
    <StyledPopper
      id="quarter-picker"
      onClose={handleClose}
      content={() => (
        <QuarterSelector
          quarter={state.selectedQuarter}
          year={state.selectedYear}
          onSelectYear={onSelectQuarter}
          dates={dates}
          minYear={minYear}
          maxYear={maxYear}
        />
      )}
    >
      <Button
        endIcon={<CalendarMonth />}
        fullWidth
        variant="contained"
        onClick={() => setState({ isPopOverOpen: true })}
      >
        {value === undefined
          ? 'Select a date'
          : `Q${state.selectedQuarter}-${state.selectedYear}`}
      </Button>
    </StyledPopper>
  );
};

export default QuarterPicker;
