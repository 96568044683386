import useMediaQuery from '@mui/material/useMediaQuery';

export interface IBreakpointsType {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
  isLargerDesktop: boolean;
}

const useResponsive = (): IBreakpointsType => {
  return {
    isMobile: useMediaQuery('(max-width: 576px)'),
    isTablet: useMediaQuery('(min-width:768px)'),
    isLaptop: useMediaQuery('(min-width: 992px)'),
    isDesktop: useMediaQuery('(min-width: 1200px)'),
    isLargerDesktop: useMediaQuery('(min-width: 1400px)'),
  };
};

export default useResponsive;
