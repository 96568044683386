import React, { MouseEvent, PureComponent } from 'react';

import { Card, CardActionArea, CardContent, Typography } from '@mui/material';

import { grey } from '@mui/material/colors';

import { Client } from '../../types/models';

import LastUpdated from '../ScenarioManager/LastUpdated';

interface Props {
  client: Client;
  onClick: (id: number) => void;
}

class ClientCard extends PureComponent<Props> {
  public render() {
    const { client } = this.props;

    return (
      <Card sx={{ height: '100%' }}>
        <CardActionArea sx={{ height: '100%' }} onClick={this.handleClick}>
          <CardContent>
            <Typography variant="subtitle2" display="block" gutterBottom>
              {client.Name}
            </Typography>
            <Typography
              component="span"
              variant="caption"
              sx={{ color: grey[600] }}
            >
              <LastUpdated object={client} />
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  private handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.onClick(this.props.client.id);
  };
}

export default ClientCard;
