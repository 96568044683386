import React, { FormEvent, PureComponent, ChangeEvent } from 'react';

import { Alert, AlertTitle, Box, TextField, Typography } from '@mui/material';

import * as API from '../../services/API';
import { Scenario, Submission } from '../../types/models';

import APICacheContext from '../shared/APICacheContext';
import ErrorList from '../shared/ErrorList';

import PopupDialog from '../shared/PopupDialog';

import SelectScenario from './SelectScenario';

interface Props {
  modelId: number;
  instanceId: number;
  scenario: Scenario;
  submission?: Submission;
  isOpen: boolean;
  onCancel(): void;
  onComplete(): void;
}

interface State {
  busy: boolean;
  toScenarioId?: number;
  comment: string;
  formErrors: {
    toScenarioId?: string[];
  };
  error?: Error;
}

const INITIAL_STATE = {
  busy: false,
  toScenarioId: undefined,
  comment: '',
  formErrors: {},
  error: undefined,
};

class TransferSubmissionDialog extends PureComponent<Props, State> {
  public static contextType = APICacheContext;
  public state: State = INITIAL_STATE;

  public render() {
    const { modelId, instanceId, scenario, isOpen } = this.props;
    const { busy, toScenarioId, comment, formErrors, error } = this.state;

    return (
      <PopupDialog
        open={isOpen}
        close={this.handleCancel}
        title="Transfer Submission"
        isLoading={busy}
        primaryButtons={[
          {
            id: 'transferSubmission',
            label: 'Confirm',
            onClick: this.handleSubmit,
            disabled: busy || toScenarioId === undefined,
          },
        ]}
      >
        <Typography>
          This will duplicate the submission and copy it to a new submission
          created for the selected scenario.
        </Typography>

        <Box my={2}>
          <SelectScenario
            large={false}
            modelId={modelId}
            modelInstanceId={instanceId}
            fromScenarioId={scenario.id}
            toScenarioId={toScenarioId}
            onChange={this.handleScenarioChange}
          />
        </Box>

        <Box mb={2}>
          <TextField
            label="Comment..."
            fullWidth
            value={comment}
            autoFocus={true}
            onChange={this.handleCommentChange}
            disabled={toScenarioId === undefined}
            multiline
            rows={2}
          />
        </Box>

        {Object.values(formErrors).some(
          (errors) => Array.isArray(errors) && errors.length > 0
        ) && (
          <Box mt={2}>
            <ErrorList errors={formErrors} />
          </Box>
        )}

        {error && (
          <Box mt={2}>
            <Alert icon={false} severity="error">
              <AlertTitle>Something went wrong</AlertTitle>
              {(error as any).body.message ||
                'Unable to transfer, check your connection and verify that the scenario you are trying to transfer to still exists.'}
            </Alert>
          </Box>
        )}
      </PopupDialog>
    );
  }

  private handleScenarioChange = (toScenarioId?: number) => {
    this.setState({ toScenarioId });
  };

  private handleCancel = () => {
    this.props.onCancel();
    this.setState(INITIAL_STATE);
  };

  private handleCommentChange = (event: ChangeEvent<HTMLInputElement>) =>
    this.setState({ comment: event.currentTarget.value });

  private handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ error: undefined });

    const { instanceId, scenario, submission } = this.props;
    const { toScenarioId, comment } = this.state;

    this.setState({ busy: true, error: undefined });

    if (submission === undefined) {
      this.setState({
        busy: true,
        error: new Error('Could not find submission'),
      });
      return;
    }

    if (toScenarioId === undefined) {
      this.setState({
        busy: true,
        error: new Error('Requested scenario could not be found'),
      });
      return;
    }

    let newSubmission: Submission;

    try {
      newSubmission = await API.create(
        `/instances/${instanceId}/scenarios/${toScenarioId}/submission_transfer`,
        {
          Source_ScenarioID: scenario.id,
          Source_SubmissionID: submission.id,
          Comment: comment,
        }
      );
    } catch (error: any) {
      this.setState({ busy: false, error });
      return;
    }

    this.props.onComplete();
    this.setState(INITIAL_STATE);

    return newSubmission;
  };
}

export default TransferSubmissionDialog;
