import { User, UserRole } from '../types/models';

export function isAdminUser(user: User) {
  return user.Role === UserRole.Trinity_Admin_User;
}

export function isTrinityUser(user: User) {
  return [UserRole.Trinity_Admin_User, UserRole.Trinity_User].includes(
    user.Role
  );
}

export function isClientUser(user: User) {
  return !isTrinityUser(user);
}

export function isReadOnlyUser(user: User) {
  return user.Role === UserRole.Client_Read_Only_User;
}

export function isReadWriteUser(user: User) {
  return !isReadOnlyUser(user);
}

export function filterUser(user: User, filter: string, clientId?: number) {
  switch (filter) {
    case 'trinity':
      return isTrinityUser(user);
    case 'client':
      return (
        isClientUser(user) &&
        (clientId === undefined || clientId === user.ClientID)
      );
    default:
      return true;
  }
}
