import { trimStart, trim, trimEnd, find } from 'lodash';
import React, { ChangeEvent, FunctionComponent, useEffect } from 'react';

import { TextField } from '@mui/material';

import { useLocation } from 'react-router-dom';

import * as API from '../../services/API';

import { Client, Profile } from '../../types/models';

import useSetState from '../../hooks/useSetState';

import PopupDialog from '../shared/PopupDialog';

import { useProfile } from '../../hooks/useProfile';

import { parseId } from '../../utils/parsing';

import { REPORT_DESCRIPTION_LENGTH } from './CreateNewReportDialog';

import { IPortfolioReportStateData } from './index';

interface IUpdateReportDescriptionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  reportDescription: string | null;
  reportData?: IPortfolioReportStateData;
  refreshReports: () => any;
  refreshReport: () => any;
  clientId?: Client['id'];
}

interface IUpdateReportDescriptionDialogState {
  reportDescription: string;
  onFocusedInput?: string;
  loading: boolean;
}

const initialState: IUpdateReportDescriptionDialogState = {
  reportDescription: '',
  onFocusedInput: 'updateReportDescription',
  loading: false,
};

const UpdateReportDescriptionDialog: FunctionComponent<
  IUpdateReportDescriptionDialogProps
> = (props: IUpdateReportDescriptionDialogProps) => {
  const [state, setState] =
    useSetState<IUpdateReportDescriptionDialogState>(initialState);

  useEffect(() => {
    if (props.reportDescription) {
      setState({ reportDescription: props.reportDescription });
    }
  }, [props.reportDescription, props.isOpen]);

  const handleChangeUpdateReportDescription = (
    e: ChangeEvent<HTMLInputElement>
  ): void =>
    setState({
      reportDescription: trimStart(
        e.target.value.substring(0, REPORT_DESCRIPTION_LENGTH)
      ),
    });

  const location = useLocation();
  const { search } = location;

  const profile: Profile = useProfile();

  const params = (): { clientId: undefined | number } => {
    const params = new URLSearchParams(search);
    const clientId =
      profile && profile.User.ClientID !== null
        ? profile.User.ClientID
        : parseId(params.get('client'));

    return { clientId };
  };

  const onRename = async (): Promise<void> => {
    try {
      setState({ loading: true });
      const reports = await API.load(
        `/clients/${params().clientId}/portfolio_reports`
      );
      const selectedReport = find(reports, { id: props.reportData?.id });
      const result = await API.put(
        `/clients/${props.clientId}/portfolio_reports/${props.reportData?.id}`,
        {
          ...selectedReport,
          Description: trimEnd(state.reportDescription),
        }
      );
      if (result) {
        props.refreshReports().then(() => {
          props.onClose();
          props.refreshReport();
          setState(initialState);
        });
      }
    } catch (e) {
      setState({ loading: false });
    }
  };

  return (
    <PopupDialog
      open={props.isOpen}
      close={() => {
        if (!state.loading) {
          props.onClose();
        }
      }}
      title="Update Report Description"
      isLoading={state.loading}
      primaryButtons={[
        {
          id: 'updateReportDescription',
          label: 'Update',
          onClick: onRename,
          disabled:
            !state.reportDescription ||
            props.reportDescription === trim(state.reportDescription) ||
            state.loading,
        },
      ]}
    >
      <TextField
        label="New Report Description"
        helperText={
          state.onFocusedInput === 'updateReportDescription' &&
          `${
            REPORT_DESCRIPTION_LENGTH - state.reportDescription.length
          } characters left`
        }
        onChange={handleChangeUpdateReportDescription}
        minRows={3}
        multiline
        value={state.reportDescription}
        onFocus={() => {
          setState({
            onFocusedInput: 'updateReportDescription',
          });
        }}
        onBlur={() => {
          setState({
            onFocusedInput: 'undefined',
          });
        }}
        fullWidth
      />
    </PopupDialog>
  );
};

export default UpdateReportDescriptionDialog;
