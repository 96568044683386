import {
  AppBar,
  Box,
  Divider,
  Link,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import useResponsive from '../../../hooks/useResponsive';

import StyledFooterLogo from './FooterLogo';

const Footer = () => {
  const { isLaptop } = useResponsive();
  return (
    <footer>
      <AppBar sx={{ top: 'auto', bottom: 0, backgroundColor: '#EEEEEE' }}>
        <Toolbar variant="dense">
          <Grid
            container
            spacing={{ xs: 1, sm: 2, md: 4 }}
            sx={{ alignItems: 'center', width: '100%' }}
          >
            <Grid xs={12} sm={6}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  justifyContent: isLaptop ? 'left' : 'center',
                  '& hr': {
                    mx: 1,
                  },
                }}
              >
                <StyledFooterLogo />
                <Divider orientation="vertical" flexItem />
                <Typography variant="body1">
                  &copy; &nbsp;
                  {new Date().getFullYear()} Trinity. All rights reserved.
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} sm={6}>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  justifyContent: isLaptop ? 'flex-end' : 'center',
                }}
              >
                <Link
                  href="https://trinitylifesciences.com/privacy-policy/"
                  underline="always"
                >
                  <Typography variant="body1">Privacy Policy</Typography>
                </Link>
                <Link
                  href="https://trinitylifesciences.com/about/"
                  underline="always"
                >
                  <Typography variant="body1">About</Typography>
                </Link>
                <Link
                  href="https://trinitylifesciences.com/contact/"
                  underline="always"
                >
                  <Typography variant="body1">Contact</Typography>
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </footer>
  );
};

export default Footer;
