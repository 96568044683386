import React, { FunctionComponent } from 'react';

import { map } from 'lodash';

import { Box, Stack, Typography } from '@mui/material';

import { FilterAlt, FilterAltOff } from '@mui/icons-material';

import { byName } from '../../utils/misc';
import { RowIdentifier } from '../../types/models';

import Flex from '../shared/Flex';

import OverflowTooltip from '../shared/OverflowTooltip';

import CheckboxCard, { CheckboxCardProps } from './CheckboxCard';
import { FixedHeader } from './FixedHeader';

import { Dispatch } from './Store';

const blanksLabel = '(Blanks)';

interface DimensionColumnProps {
  dimension: RowIdentifier;
  instances: RowIdentifier[];
  selection: number[];
  includeBlanks: boolean;
  allowBlanks: boolean;
  dispatch: Dispatch;
}

interface HighlightOnFilterCheckboxCardProps
  extends Omit<CheckboxCardProps, 'cardContentStyle'>,
    Partial<DimensionColumnProps> {
  isFiltered: boolean;
}

const HighlightOnFilterCheckboxCard = (
  props: HighlightOnFilterCheckboxCardProps
) => {
  const instanceIds = map(props.instances, 'id');
  return (
    <CheckboxCard
      {...props}
      backgroundColor="none"
      styleProp={{
        cursor: 'pointer',
        ...{
          ...(props.isFiltered && props.checked
            ? {
                boxShadow: `0 0 0 1px rgba(138,155,168, 0.6), 0 0 0 rgba(138,155,168, 0), 0 1px 1px rgba(138,155,168, 0.3)`,
              }
            : {}),
        },
        ...{
          ...(props.isFiltered && !props.checked
            ? {
                color: `rgba(92, 112, 128, 0.6)`,
              }
            : {}),
        },
        ...{
          ...(!props.checked &&
          instanceIds.some((instanceId) =>
            props.selection!.includes(instanceId)
          ) &&
          props.label === blanksLabel
            ? {
                color: `rgba(92, 112, 128, 0.6)`,
              }
            : {}),
        },
      }}
    />
  );
};

const DimensionColumn: FunctionComponent<DimensionColumnProps> = ({
  dimension,
  instances,
  selection,
  includeBlanks,
  allowBlanks,
  dispatch,
}) => {
  const isFiltered =
    includeBlanks || instances.some((i) => selection.indexOf(i.id) !== -1);

  const isSelected = (instance: RowIdentifier) =>
    selection.indexOf(instance.id) !== -1;

  const isNotSelected = (instance: RowIdentifier) =>
    selection.indexOf(instance.id) === -1;

  return (
    <Box>
      <FixedHeader>
        <OverflowTooltip
          variant="subtitle1"
          value={dimension.Name}
          sx={{ mt: 1 }}
        />
      </FixedHeader>
      <Box pb={5} />
      <Typography
        component={Flex}
        mb={2}
        color={(t) =>
          isFiltered ? t.palette.common.muted : t.palette.common.disabled
        }
      >
        <Box mr={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            {isFiltered ? (
              <FilterAlt fontSize="small" />
            ) : (
              <FilterAltOff fontSize="small" />
            )}
            <Typography>{isFiltered ? 'Filtered' : 'Unfiltered'}</Typography>
          </Stack>
        </Box>
      </Typography>
      <Box pb={1} />
      {map(instances.sort(byName), (instance) => (
        <section key={instance.id}>
          <HighlightOnFilterCheckboxCard
            instances={instances}
            selection={selection}
            checked={selection.indexOf(instance.id) !== -1 || !isFiltered}
            onCardClick={(checked) => {
              if (
                (includeBlanks || !allowBlanks) &&
                instances.filter(isNotSelected).length === 1 &&
                checked
              ) {
                dispatch({
                  type: 'RESET_DIMENSION',
                  id: dimension.id,
                  instanceIds: instances.map((i) => i.id),
                });
              } else {
                dispatch({
                  type: 'TOGGLE_DIMENSION_INSTANCE',
                  id: instance.id,
                  value: isFiltered ? checked : true,
                });
              }
            }}
            label={instance.Name}
            isFiltered={selection.indexOf(instance.id) !== -1 || isFiltered}
          />
          <Box pb={2} />
        </section>
      ))}
      {allowBlanks && (
        <HighlightOnFilterCheckboxCard
          instances={instances}
          selection={selection}
          checked={includeBlanks || !isFiltered}
          onCardClick={(checked) => {
            if (instances.every(isSelected) && checked) {
              dispatch({
                type: 'RESET_DIMENSION',
                id: dimension.id,
                instanceIds: instances.map((i) => i.id),
              });
            } else {
              dispatch({
                type: 'TOGGLE_DIMENSION_BLANKS',
                id: dimension.id,
                value: isFiltered ? checked : true,
              });
            }
          }}
          label={blanksLabel}
          isFiltered={includeBlanks}
        />
      )}
    </Box>
  );
};

export default DimensionColumn;
