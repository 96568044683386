import React from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';

import OutputsPanel from '../ScenarioManager/EditAssumptionsDetail/OutputsPanel';
import ClientIndex from '../shared/ClientIndex';
import { GetParams } from '../../utils/history';
import { useProfile } from '../../hooks/useProfile';

const CustomChart = () => {
  const location = useLocation();
  const profile = useProfile();

  const [searchParams, setSearchParams] = useSearchParams();

  let { clientId } = GetParams(location.search);

  clientId = clientId || profile.User.ClientID || undefined;

  const handleClientChange = (id?: number) => {
    if (id === clientId) {
      return;
    }

    if (id === undefined) {
      searchParams.delete('client');
    } else {
      searchParams.set('client', id.toString());
    }
    searchParams.delete('model');
    searchParams.delete('instance');
    searchParams.delete('scenario');
    searchParams.delete('source');
    setSearchParams(searchParams);
  };

  if (clientId === undefined) {
    return <ClientIndex onSelect={handleClientChange} />;
  }
  return <OutputsPanel inContainer showScenarios />;
};

export default CustomChart;
