import React, { FunctionComponent } from 'react';

import Monitor from '../../shared/Monitor';

import { Submission, SubmissionStatus } from '../../../types/models';

interface Props {
  instanceId: number;
  scenarioId: number;
  submissions: Submission[];
  refresh: () => void;
}

const SubmissionMonitor: FunctionComponent<Props> = ({
  instanceId,
  scenarioId,
  submissions,
  refresh,
}) => {
  const activeSubmission =
    submissions.length > 0
      ? submissions.reduce((s, m) => (s.id >= m.id ? s : m))
      : undefined;

  if (activeSubmission === undefined) {
    return null;
  }

  if (
    [SubmissionStatus.Queued, SubmissionStatus.Started].includes(
      activeSubmission.Status
    )
  ) {
    return (
      <Monitor
        path={`/instances/${instanceId}/scenarios/${scenarioId}/submissions/${activeSubmission.id}`}
        object={activeSubmission}
        onChange={refresh}
      />
    );
  }

  return null;
};

export default SubmissionMonitor;
