import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  find,
  includes,
  isEmpty,
  isUndefined,
  map,
  reduce,
  some,
  values,
} from 'lodash';

import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';

import { useSnackbar } from 'notistack';

import {
  CheckCircle,
  Clear,
  Error,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';

import * as API from '../../services/API';

import {
  GeneratedFile,
  SubmissionStatus,
  Upload,
  UploadMultipleToAzure,
} from '../../types/models';

import { uploadMultipleFilesToAzure } from '../../services/AzureUpload';

import { getDataAtIntervals } from '../../utils/getDataAtIntervals';

import { containsOnly } from '../../utils/misc';

import useSetState from '../../hooks/useSetState';

import { IUploadData, UploadContext } from './UploadFilesContext';
import Flex from './Flex';
import ExcelIcon from './ExcelIcon';

interface IFileProgress {
  file: UploadMultipleToAzure['fileData'];
  loadedBytes: number;
  status: SubmissionStatus;
  error: string;
  uploadId: Upload['id'];
}

interface State {
  isFileUploadsPanelExpanded: boolean;
  displayFileUploadsPanel: boolean;
  showCloseButton: boolean;
}

export const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number }
) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export const FloatingPanelContainer = styled(Box)`
  max-height: 300px;
  bottom: 120px;
  opacity: 1;
  left: auto;
  right: 24px;
  position: fixed;
  float: right;
`;

export const FloatingPanelHeaderContainer = styled(Box)`
  width: 500px;
`;

export const FloatingPanelHeaderLayoutContainer = styled(Flex)`
  border-radius: 3px 3px 0 0;
  background-color: #323232;
  border: 1px transparent solid;
  bottom: 24px;
  height: 52px;
  line-height: normal;
  alignitems: center;
  color: white;
`;

export const FloatingPanelHeaderLayoutTextContainer = styled(Box)`
  flex: 0 1 auto;
  cursor: default;
  font-weight: inherit;
  overflow: hidden;
  padding: 0 24px;
  white-space: nowrap;
  width: 500px;
  height: 52px;
  line-height: 52px;
`;

export const FloatingPanelHeaderLayoutTextIconsContainer = styled(Box)`
  flex: none;
  float: right;
  height: auto;
  margin-left: auto;
  padding: 0;
  line-height: 52px;
`;

const MultipleFilesUploadingPanel: FunctionComponent = () => {
  const [state, setState] = useSetState<State>({
    isFileUploadsPanelExpanded: true,
    displayFileUploadsPanel: true,
    showCloseButton: false,
  });

  const { enqueueSnackbar } = useSnackbar();

  const [uploadedFilesProgress, setUploadFilesProgress] = useState<
    Record<string, IFileProgress> | undefined
  >(undefined);

  const uploadContext = useContext(UploadContext);
  const { fileData, files } = uploadContext?.fileUploadData as IUploadData;

  const handleProgress = (
    loadedBytes: number,
    fileData: UploadMultipleToAzure['fileData'],
    uploadId: Upload['id']
  ): void => {
    setUploadFilesProgress((oldState) => {
      return {
        ...oldState,
        [uploadId]: {
          file: fileData,
          loadedBytes,
          status:
            loadedBytes === 1
              ? SubmissionStatus.Started
              : SubmissionStatus.Queued,
          error: '',
          uploadId,
        },
      };
    });
  };

  const individualCallback = async (
    fileData: UploadMultipleToAzure
  ): Promise<void> => {
    try {
      const data = await API.create<GeneratedFile>(
        uploadContext?.fileUploadData.fileProcessingURL as string,
        {
          ModelInstanceID: uploadContext?.fileUploadData.modelInstanceId,
          UploadID: fileData.id,
        }
      );
      if (data) {
        const fileResponse: Upload = await getDataAtIntervals(
          `/uploads/${data.id}`,
          'Status'
        );
        if (fileResponse.Status === SubmissionStatus.Failed) {
          setUploadFilesProgress((oldState) => {
            return {
              ...oldState,
              [fileResponse.id]: {
                ...(oldState as Record<string, IFileProgress>)[fileResponse.id],
                status: SubmissionStatus.Failed,
                error: fileResponse.Status_Message,
                uploadId: fileResponse.id,
              },
            };
          });
        } else if (fileResponse.Status === SubmissionStatus.NoDataReturned) {
          setUploadFilesProgress((oldState) => {
            return {
              ...oldState,
              [fileResponse.id]: {
                ...(oldState as Record<string, IFileProgress>)[fileResponse.id],
                status: SubmissionStatus.NoDataReturned,
                error: fileResponse.Status_Message,
                uploadId: fileResponse.id,
              },
            };
          });
        } else if (fileResponse.Status === SubmissionStatus.Finished) {
          setUploadFilesProgress((oldState) => {
            return {
              ...oldState,
              [fileResponse.id]: {
                ...(oldState as Record<string, IFileProgress>)[fileResponse.id],
                status: SubmissionStatus.Finished,
                error: fileResponse.Status_Message,
                uploadId: fileResponse.id,
              },
            };
          });
        }
      }
    } catch (e: any) {
      setState({
        showCloseButton: false,
        displayFileUploadsPanel: false,
      });
      enqueueSnackbar(e.body.message, { variant: 'error' });
      uploadContext?.handleDataChange([], [], undefined, '', false);
    }
  };

  useEffect(() => {
    if (!isEmpty(files)) {
      setUploadFilesProgress(undefined);
      setState({
        displayFileUploadsPanel: true,
        isFileUploadsPanelExpanded: true,
      });
      (async () => {
        const result: Omit<UploadMultipleToAzure, 'modelInstanceId'>[] = map(
          fileData,
          (i) => {
            return {
              ...i,
              fileData: find(files, (j) => j.name === i.FileName),
            };
          }
        );
        await uploadMultipleFilesToAzure(
          result,
          handleProgress,
          individualCallback
        );
      })();
    }
  }, [fileData]);

  const findStatusCount = (status: SubmissionStatus): number =>
    reduce(
      values(uploadedFilesProgress),
      (n, i) => n + +(i.status === status),
      0
    );

  useEffect(() => {
    if (
      containsOnly(
        [
          SubmissionStatus.Failed,
          SubmissionStatus.Finished,
          SubmissionStatus.NoDataReturned,
        ],
        map(values(uploadedFilesProgress), 'status')
      )
    ) {
      setState({ showCloseButton: true });
      uploadContext?.isFilesProcessingDone(true);
    } else {
      setState({ showCloseButton: false });
    }
  }, [uploadedFilesProgress]);

  return (
    <>
      {!isEmpty(fileData) && state.displayFileUploadsPanel && (
        <FloatingPanelContainer>
          <FloatingPanelHeaderContainer>
            <FloatingPanelHeaderLayoutContainer>
              <FloatingPanelHeaderLayoutTextContainer>
                {(() => {
                  if (uploadedFilesProgress) {
                    if (
                      some(
                        values(uploadedFilesProgress),
                        (item) => item.status === SubmissionStatus.Started
                      )
                    ) {
                      return `${fileData.length} files processing`;
                    } else if (
                      some(values(uploadedFilesProgress), (item) =>
                        includes(
                          [
                            SubmissionStatus.Failed,
                            SubmissionStatus.Finished,
                            SubmissionStatus.NoDataReturned,
                          ],
                          item.status
                        )
                      )
                    ) {
                      return (
                        <Typography noWrap sx={{ lineHeight: 'inherit' }}>
                          {findStatusCount(SubmissionStatus.Finished)} file(s)
                          succeeded,{' '}
                          {findStatusCount(SubmissionStatus.Failed) +
                            findStatusCount(
                              SubmissionStatus.NoDataReturned
                            )}{' '}
                          file(s) failed
                        </Typography>
                      );
                    } else return `${fileData.length} files uploading`;
                  }
                })()}
              </FloatingPanelHeaderLayoutTextContainer>
              <Box style={{ flex: '0 0 auto' }}>
                <FloatingPanelHeaderLayoutTextIconsContainer>
                  <IconButton
                    sx={{
                      height: '40px',
                      width: '40px',
                      margin: '0 4px',
                    }}
                    onClick={() =>
                      setState({
                        isFileUploadsPanelExpanded:
                          !state.isFileUploadsPanelExpanded,
                      })
                    }
                  >
                    {state.isFileUploadsPanelExpanded ? (
                      <ExpandMore sx={{ color: 'white' }} />
                    ) : (
                      <ExpandLess sx={{ color: 'white' }} />
                    )}
                  </IconButton>
                  {state.showCloseButton && (
                    <IconButton
                      sx={{
                        height: '40px',
                        width: '40px',
                        margin: '0 4px',
                        color: 'white',
                      }}
                      onClick={() => {
                        setState({
                          showCloseButton: false,
                          displayFileUploadsPanel: false,
                        });
                        uploadContext?.handleDataChange(
                          [],
                          [],
                          undefined,
                          '',
                          false
                        );
                      }}
                    >
                      <Clear />
                    </IconButton>
                  )}
                </FloatingPanelHeaderLayoutTextIconsContainer>
              </Box>
            </FloatingPanelHeaderLayoutContainer>
          </FloatingPanelHeaderContainer>
          <Box>
            <Collapse in={state.isFileUploadsPanelExpanded}>
              <Box
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                  border: `1px solid #323232`,
                }}
              >
                <List
                  sx={{
                    width: '498px',
                    maxHeight: '300px',
                    overflow: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  {map(fileData, (i) => {
                    return (
                      <>
                        <ListItem
                          key={i.id}
                          secondaryAction={
                            <>
                              {!isUndefined(uploadedFilesProgress) && (
                                <>
                                  {(() => {
                                    switch (
                                      (
                                        uploadedFilesProgress as Record<
                                          string,
                                          IFileProgress
                                        >
                                      )[i.id] &&
                                      (
                                        uploadedFilesProgress as Record<
                                          string,
                                          IFileProgress
                                        >
                                      )[i.id].status
                                    ) {
                                      case SubmissionStatus.Finished:
                                      case SubmissionStatus.NoDataReturned:
                                        return <CheckCircle color="success" />;
                                      case SubmissionStatus.Failed:
                                        return (
                                          <Tooltip
                                            title={
                                              (
                                                uploadedFilesProgress as Record<
                                                  string,
                                                  IFileProgress
                                                >
                                              )[i.id].error
                                            }
                                            arrow
                                          >
                                            <Error color="error" />
                                          </Tooltip>
                                        );
                                      case SubmissionStatus.Started:
                                        return <CircularProgress size={25} />;
                                      case SubmissionStatus.Queued:
                                        return (
                                          <CircularProgressWithLabel
                                            value={
                                              (uploadedFilesProgress as any)[
                                                i.id
                                              ]
                                                ? (
                                                    uploadedFilesProgress as any
                                                  )[i.id].loadedBytes
                                                : 0
                                            }
                                          />
                                        );
                                    }
                                  })()}
                                </>
                              )}
                            </>
                          }
                        >
                          <ListItemIcon>
                            <ExcelIcon />
                          </ListItemIcon>
                          <ListItemText>
                            <Tooltip arrow title={i.FileName}>
                              <Typography noWrap>{i.FileName}</Typography>
                            </Tooltip>
                          </ListItemText>
                        </ListItem>
                        <Divider />
                      </>
                    );
                  })}
                </List>
              </Box>
            </Collapse>
          </Box>
        </FloatingPanelContainer>
      )}
    </>
  );
};

export default MultipleFilesUploadingPanel;
