import React from 'react';

import { CircularProgress } from '@mui/material';

import Flex from './Flex';

const CenteredSpinner = () => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <CircularProgress />
    </Flex>
  );
};

export default CenteredSpinner;
