import { Guid } from 'src/types/guid';
import {
  Client,
  GeneratedFile,
  Model,
  ModelInstance,
  RowIdentifier,
  Scenario,
  Timescale,
  User,
  UserDisplayInfo,
} from 'src/types/models';

export enum PortfolioReportTypes {
  Portfolio = 'Portfolio',
  // StandardReport = 'Standard Report',
  Waterfall = 'Waterfall',
  // DASHBOARD = 'Dashboard',
}

export interface PortfolioReportMemberships {
  Role: PortfolioReportMembershipRole;
  UserID: User['id'];
}

export interface PortfolioReportData {
  /**
   * Unique Id of the dashboard created in BI solution
   */
  ClientID: Client['id'];
  /**
   * Report description
   */
  Description: string | null;
  Metadata: null | any;
  ModelID: Model['id'];
  ModelInstanceID: ModelInstance['id'];
  /**
   * Name of the report
   */
  Name: string;
  PermissionLevel: number;
  PortfolioDataSources: {
    PortfolioDataCuts: PortfolioReportDatacut[];
    Status: PortfolioDataSourceData['Status'];
    id: PortfolioDataSourceData['id'];
  }[];
  PortfolioReportMemberships: PortfolioReportMemberships[];
  /**
   * Indicates if a report is available to the broader viewer group or just the owner group
   * 0: Edit
   * 1: Published
   */
  State: 0 | 1;
  /**
   * Enumerator denoting the report type.
   * Excel
   * Dashboard
   */
  Type: PortfolioReportTypes;
  /**
   * Unique Report Id
   */
  id: number;
  deleted_at: string | null;
  deleted_by: User['id'] | null;
  /**
   * Last time when dashboard/report was refreshed/edited
   */
  updated_at: string;
  /**
   * User id of user who updated the report last
   */
  updated_by: UserDisplayInfo;
  updated_by_id: User['id'];
  ReportTemplateName: string;
  ReportTemplateURL: string;
  CustomDownloadBlobName: string;
}

export enum PortfolioDataSourceStatusEnum {
  QUEUED = 0,
  STARTED = 1,
  FINISHED = 2,
  FAILED = -1,
  UNREFRESHED = -3,
}

export enum PortfolioDataSourceDataFinalizedFlag {
  NotFinalized = 0,
  Finalized = 1,
}

export interface PortfolioDataSourceData {
  Finalized: PortfolioDataSourceDataFinalizedFlag;
  ReportID: PortfolioReportData['id'];
  id: number;
  BI_DatasetID: Guid | null;
  Status: PortfolioDataSourceStatusEnum;
  Status_Message: string | null;
  refreshed_at: string | null;
}

export interface PortfolioReportDimension {
  DataSourceID: number;
  DimNumber: number;
  Name: string;
  id: number;
}

interface PortfolioReportDatacutMetaDataDimensions {
  dimension: RowIdentifier['DimNumber'];
  dimensionInstances: RowIdentifier['Name'][];
  sourceDimension: number;
}

interface PortfolioReportDatacutMetaData {
  CutType: 'inputs' | 'outputs';
  ModelInstance: ModelInstance['id'];
  ModelInstanceLock: 0 | 1;
  NumScenarios: number;
  RangeType: 'max' | 'equal';
  ScenarioLock: 0 | 1;
  Timescales?: Timescale[];
  Scenarios: Array<Scenario['id']>;
  Outputs?: Array<RowIdentifier['Name']>;
  Inputs?: Array<RowIdentifier['Name']>;
  Dimensions: PortfolioReportDatacutMetaDataDimensions[];
}

export interface PortfolioReportDatacut {
  DataSourceID: number;
  ModelID: Model['id'];
  Name: string;
  id: number;
  updated_at: string;
  updated_by_id: User['id'];
  MetaData: PortfolioReportDatacutMetaData;
}

export enum PortfolioReportMembershipRole {
  Viewer = 0,
  Owner = 1,
}

export enum PortfolioReportPermissions {
  Private = 0,
  Shared = 1,
}

export interface PortfolioReportMembership {
  ReportID: PortfolioReportData['id'];
  Role: PortfolioReportMembershipRole;
  UserID: User['id'];
  id: number;
}

export interface PortfolioReportGeneratedFile extends GeneratedFile {
  ReportID: PortfolioReportData['id'];
}
