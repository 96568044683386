import React, { FunctionComponent, useContext } from 'react';

import { every, some } from 'lodash';

import { Box, Card, CardContent } from '@mui/material';

import {
  RowIdentifier,
  Scenario,
  ScenarioEditState,
  ScenarioArchiveFlag,
  DefaultModelFiles,
  Submission,
  SubmissionStatus,
  ModelInstance,
} from '../../../types/models';
import { isModelMember, isModelOwner } from '../../../utils/profile';
import { isTrinityUser } from '../../../utils/user';

import { ProfileProps, withProfile } from '../../shared/AuthController';

import Loader, { LoaderInfo } from '../../shared/Loader';

import APICacheContext from '../../shared/APICacheContext';

import APICache from '../../../services/APICache';

import DownloadForecast from '../DownloadForecast';
import ScenarioDetailsForm from '../ScenarioDetailsForm';
import UploadForecast from '../UploadForecast';

import CenteredSpinner from '../../shared/CenteredSpinner';

import EditAssumptions from '../EditAssumptions';

import ScenarioVersions from './ScenarioVersions';

interface Props extends ProfileProps {
  modelId: number;
  instanceId: number;
  scenarioId: number;
}

const ScenarioDetails: FunctionComponent<Props> = ({
  scenarioId,
  profile,
  modelId,
  instanceId,
}) => {
  const scenarioDetailsContext = useContext(APICacheContext);

  const needs = {
    scenario: `/instances/${instanceId}/scenarios/${scenarioId}`,
    groups: `/instances/${instanceId}/row_identifiers?type=Group`,
    defaultModelFiles: `/default_model_files/${instanceId}`,
    submissions: `/instances/${instanceId}/scenarios/${scenarioId}/submissions`,
    modelInstance: `/instances/${instanceId}`,
  };

  const refresh = (): Promise<any[]> => {
    return (scenarioDetailsContext as APICache).load(
      [
        `/instances/${instanceId}/scenarios/${scenarioId}/submissions`,
        `/instances/${instanceId}/scenarios/${scenarioId}`,
        `/instances/${instanceId}/row_identifiers?type=Group`,
        `/instances/${instanceId}/scenarios/${scenarioId}/submissions`,
      ],
      true
    );
  };

  const renderContent = ({
    data,
  }: LoaderInfo<{
    scenario: Scenario;
    groups: RowIdentifier[];
    defaultModelFiles: DefaultModelFiles[];
    submissions: Submission[];
    modelInstance: ModelInstance;
  }>) => {
    const { scenario, groups, defaultModelFiles, submissions, modelInstance } =
      data;

    let canSubmit = false;
    switch (scenario !== undefined && scenario.Edit_State) {
      case ScenarioEditState.Locked: {
        canSubmit = false;
        break;
      }
      case ScenarioEditState.OwnerEdit: {
        // OwnerEdit should be called "Trinity User or Model Owner"
        canSubmit =
          profile !== undefined &&
          (isTrinityUser(profile.User)
            ? isModelMember(profile, modelId)
            : isModelOwner(profile, modelId));
        break;
      }
      case ScenarioEditState.WorldEdit: {
        canSubmit = profile !== undefined && isModelMember(profile, modelId);
        break;
      }
      // no default
    }
    // Only allow submitting if scenario is unarchived
    if (scenario !== undefined) {
      canSubmit =
        canSubmit && scenario.Archive_Flag === ScenarioArchiveFlag.Current;
    }

    if (scenario === undefined) {
      return (
        <Box p={3}>
          <CenteredSpinner />
        </Box>
      );
    }

    return (
      <Box sx={{ mb: 2, pb: 3 }}>
        <Box mb={2}>
          <ScenarioDetailsForm modelId={modelId} scenario={scenario} />
        </Box>
        <Card>
          <CardContent>
            {some(submissions, { Status: SubmissionStatus.Finished }) &&
              modelInstance?.IsCalculationEnabled && (
                <Box m={1} py={1}>
                  <EditAssumptions disabled={!canSubmit} />
                </Box>
              )}
            <Box m={1} py={1}>
              <DownloadForecast
                instanceId={instanceId}
                scenarioId={scenarioId}
                lockSubmissionId={scenario.LockSubmissionID}
                refresh={refresh}
                doesInstanceHaveGroups={!every(groups, { GroupID: 0 })}
                defaultModelFiles={defaultModelFiles}
              />
            </Box>
            {scenario.Archive_Flag === ScenarioArchiveFlag.Current && (
              <Box m={1} py={1}>
                <UploadForecast
                  disabled={!canSubmit}
                  instanceId={instanceId}
                  scenarioId={scenarioId}
                  lockSubmissionId={scenario.LockSubmissionID}
                  refresh={refresh}
                />
              </Box>
            )}
          </CardContent>
        </Card>
        <Box>
          <ScenarioVersions
            modelId={modelId}
            instanceId={instanceId}
            scenario={scenario}
            canSubmit={canSubmit}
            groups={data.groups}
          />
        </Box>
      </Box>
    );
  };

  if (instanceId) {
    return (
      <Loader
        needs={needs}
        handleErrors={true}
        render={renderContent}
        force={true}
      />
    );
  }
  return null;
};

export default withProfile(ScenarioDetails);
