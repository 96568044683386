import React, { FunctionComponent } from 'react';

import PopupDialog from '../shared/PopupDialog';

import { IChartTab } from './visualizationConstants';

interface IRemoveChartDialogProps {
  open: boolean;
  close: () => void;
  onRemove: (eventKey: IChartTab['eventKey']) => void;
  chartTabData: IChartTab;
}

const RemoveChartDialog: FunctionComponent<IRemoveChartDialogProps> = ({
  open,
  close,
  onRemove,
  chartTabData,
}) => {
  return (
    <PopupDialog
      title="Remove Chart"
      open={open}
      close={close}
      primaryButtons={[
        {
          id: 'removeButton',
          label: 'Remove',
          onClick: () => {
            close();
            onRemove(chartTabData?.eventKey);
          },
        },
      ]}
    >
      Are you sure you want to remove <b>"{chartTabData?.title}"</b>?{' '}
    </PopupDialog>
  );
};

export default RemoveChartDialog;
