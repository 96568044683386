import { PureComponent } from 'react';

import isEqual from 'lodash/isEqual';

import * as API from '../../services/API';

interface Props {
  path: string;
  object: any;
  onChange(): void;
}

class Monitor extends PureComponent<Props> {
  private timeout?: number;

  public componentDidMount() {
    this.tick();
  }

  public componentWillUnmount() {
    window.clearTimeout(this.timeout);
  }

  public render() {
    return null;
  }

  private tick = async () => {
    const { path, object, onChange } = this.props;

    try {
      const result = await API.load(path);

      if (!isEqual(result, object)) {
        onChange();
      }
    } finally {
      this.timeout = window.setTimeout(this.tick, 2500);
    }
  };
}

export default Monitor;
