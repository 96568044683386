import React, { FunctionComponent } from 'react';

import { Typography, TypographyProps } from '@mui/material';

import { UserDisplayInfo } from '../../types/models';

interface Props extends TypographyProps {
  className?: string;
  user?: UserDisplayInfo;
}

const UserLabel: FunctionComponent<Props> = ({ user, className, ...props }) => {
  const displayName = user ? user.Display_Name : 'Unknown';
  const extendedName = user
    ? `${user.Display_Name} <${user.User_Name}>`
    : 'Unkonwn';

  return (
    <Typography
      color={(t) => (!user ? t.palette.common.disabled : undefined)}
      title={extendedName}
      {...props}
    >
      {displayName}
    </Typography>
  );
};

export default UserLabel;
