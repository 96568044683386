import React, { FunctionComponent, useEffect } from 'react';
import { range } from 'lodash';
import { Box, Button, Chip, Typography } from '@mui/material';
import { ArrowRightAlt, CalendarMonth } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';

import StyledPopper from '../../../../shared/StyledPopper';
import useSetState from '../../../../../hooks/useSetState';

interface IYearSelectorProps
  extends Pick<IYearRangePickerPickerProps, 'startYear' | 'endYear'> {
  year: number;
  onSelectYear: (year: number) => void;
  title: string;
  minYear?: number;
}

interface IYearSelectorState {
  yearState: number;
}

const YearSelector: FunctionComponent<IYearSelectorProps> = ({
  year,
  onSelectYear,
  startYear,
  endYear,
  title,
  minYear,
}: IYearSelectorProps) => {
  const [state, setState] = useSetState<IYearSelectorState>({
    yearState: year,
  });

  useEffect(() => {
    onSelectYear(state.yearState);
  }, [state]);

  const styles: Record<string, React.CSSProperties> = {
    yearContainer: {
      display: 'inline-block',
      padding: '1px',
      boxSizing: 'border-box',
    },
  };

  const yearsRange: number[] = range(startYear, endYear + 1);

  const onSelect = (year: number): void => {
    setState({ yearState: year });
  };

  const renderYear = (year: number): JSX.Element => {
    const selected = year === state.yearState;

    return (
      <Box className="year-container" style={styles.yearContainer} key={year}>
        <Button
          size="small"
          variant={selected ? 'contained' : 'outlined'}
          onClick={() => onSelect(year)}
          sx={{ m: 0.25 }}
          disabled={year < minYear!}
        >
          {year}
        </Button>
      </Box>
    );
  };

  const years: JSX.Element[] = [];
  for (let i = 0; i < yearsRange.length; i++) {
    years.push(renderYear(yearsRange[i]));
  }

  return (
    <>
      <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Box className="years-grid">{years}</Box>
    </>
  );
};

interface IYearRangePickerPickerProps {
  startYear: number;
  endYear: number;
  onChange: (startYear: number, endYear: number) => void;
  value: number[];
}

interface IYearRangePickerPickerState {
  startState: number;
  endState: number;
}

const YearRangePicker: FunctionComponent<IYearRangePickerPickerProps> = ({
  startYear,
  endYear,
  onChange,
  value,
}: IYearRangePickerPickerProps) => {
  const [state, setState] = useSetState<IYearRangePickerPickerState>({
    startState: startYear,
    endState: endYear,
  });

  useEffect(() => {
    if (typeof value[0] === 'number' && typeof value[1] === 'number') {
      setState({
        startState: value[0],
        endState: value[1],
      });
    } else {
      setState({
        startState: startYear,
        endState: endYear,
      });
    }
  }, [value]);

  const onSetStart = (startState: number): void => {
    setState({ startState });
  };

  const onSetEnd = (endState: number): void => {
    setState({ endState });
  };

  const handleClose = (): void => {
    onChange(state.startState, state.endState);
  };

  return (
    <StyledPopper
      onClose={handleClose}
      content={() => (
        <Grid container spacing={2}>
          <Grid md={6}>
            <YearSelector
              year={state.startState}
              onSelectYear={onSetStart}
              startYear={startYear}
              endYear={endYear}
              title="Start Year"
            />
          </Grid>
          <Grid md={6}>
            <YearSelector
              year={state.endState}
              onSelectYear={onSetEnd}
              startYear={startYear}
              endYear={endYear}
              title="End Year"
              minYear={state.startState}
            />
          </Grid>
        </Grid>
      )}
      id="year-range-picker-popper"
    >
      <Button startIcon={<CalendarMonth />} variant="outlined" size="small">
        <Chip size="small" className="mr-1" label={state.startState} />
        <ArrowRightAlt sx={{ color: '#c3ced5' }} fontSize="small" />
        <Chip size="small" className="ml-1" label={state.endState} />
      </Button>
    </StyledPopper>
  );
};

export default YearRangePicker;
