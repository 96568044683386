import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';

import { useMsal } from '@azure/msal-react';

import Logo from '../../assets/images/logo_colored.svg';
import backgroundImage from '../../assets/images/login_bg.png';

const LoginBackground = styled(Grid)(
  ({ theme }) => `
  background: url(${backgroundImage});
  min-height: 100vh;
  background-color: ${theme.palette.primary.main};
  background-size: cover;
`
);

const Login = () => {
  const { instance } = useMsal();

  const handleLogin = async (): Promise<void> => {
    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
      await instance.loginRedirect();
    }
  };

  return (
    <LoginBackground container alignItems="center" justifyContent="center">
      <Grid xs="auto">
        <Card sx={{ maxWidth: 345 }}>
          <CardContent>
            <Box textAlign="center">
              <Box component="img" src={Logo} />
              <Box my={2}>
                <Typography align="center">
                  This site uses your organizational username and password. Sign
                  in with your credentials.
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  endIcon={<ArrowForward />}
                  onClick={handleLogin}
                  sx={{ borderRadius: '30px' }}
                >
                  Sign In
                </Button>
              </Box>
              <Box my={2}>
                <Typography variant="caption">
                  Don't have access to Trinty CloudCast?
                </Typography>
              </Box>
              <Button variant="outlined" sx={{ borderRadius: '30px' }}>
                Contact Us
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </LoginBackground>
  );
};

export default Login;
