import { trimEnd, trimStart, trim, find } from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { useLocation } from 'react-router-dom';

import * as API from '../../services/API';

import { Client, Profile } from '../../types/models';

import PopupDialog from '../shared/PopupDialog';

import ClearableTextField from '../shared/ClearableTextField';

import useSetState from '../../hooks/useSetState';

import { parseId } from '../../utils/parsing';

import { useProfile } from '../../hooks/useProfile';

import { REPORT_NAME_LENGTH } from './CreateNewReportDialog';

import { IPortfolioReportStateData } from './index';

interface IUpdateReportNameDialogProps {
  isOpen: boolean;
  onClose: () => void;
  reportName?: string;
  reportData?: IPortfolioReportStateData;
  refreshReports: () => any;
  refreshReport: () => any;
  clientId?: Client['id'];
}

interface IUpdateReportNameDialogState {
  reportName: string;
  onFocusedInput?: string;
  loading: boolean;
}

const initialState: IUpdateReportNameDialogState = {
  reportName: '',
  onFocusedInput: 'updateReport',
  loading: false,
};

const UpdateReportNameDialog: FunctionComponent<
  IUpdateReportNameDialogProps
> = (props: IUpdateReportNameDialogProps) => {
  const [state, setState] =
    useSetState<IUpdateReportNameDialogState>(initialState);

  const updateReportInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (props.reportName) {
      setState({ reportName: props.reportName });
    }
  }, [props.reportName, props.isOpen]);

  const handleChangeUpdateReport = useCallback((value: string) => {
    setState({
      reportName: trimStart(value.substring(0, REPORT_NAME_LENGTH)),
    });
  }, []);

  const location = useLocation();
  const { search } = location;

  const profile: Profile = useProfile();

  const params = (): { clientId: undefined | number } => {
    const params = new URLSearchParams(search);
    const clientId =
      profile && profile.User.ClientID !== null
        ? profile.User.ClientID
        : parseId(params.get('client'));

    return { clientId };
  };

  const onRename = async (): Promise<void> => {
    try {
      setState({ loading: true });
      const reports = await API.load(
        `/clients/${params().clientId}/portfolio_reports`
      );
      const selectedReport = find(reports, { id: props.reportData?.id });
      const result = await API.put(
        `/clients/${props.clientId}/portfolio_reports/${props.reportData?.id}`,
        {
          ...selectedReport,
          Name: trimEnd(state.reportName),
        }
      );
      if (result) {
        props.refreshReports().then(() => {
          props.onClose();
          props.refreshReport();
          setState(initialState);
        });
      }
    } catch (e) {
      setState({ loading: false });
    }
  };

  return (
    <PopupDialog
      open={props.isOpen}
      close={() => {
        if (!state.loading) {
          props.onClose();
        }
      }}
      isLoading={state.loading}
      title="Update Report"
      primaryButtons={[
        {
          id: 'renameReportNameDialog',
          label: 'Update',
          onClick: onRename,
          disabled:
            !state.reportName ||
            props.reportName === trim(state.reportName) ||
            state.loading,
        },
      ]}
    >
      <ClearableTextField
        size="small"
        label="New Report Name"
        maxLength={REPORT_NAME_LENGTH}
        inputRef={updateReportInputRef}
        id="updateReportInput"
        onHandleChange={handleChangeUpdateReport}
        value={state.reportName}
        onFocus={() => {
          setState({
            onFocusedInput: 'updateReport',
          });
        }}
        onBlur={() => {
          setState({
            onFocusedInput: 'undefined',
          });
        }}
      />
    </PopupDialog>
  );
};

export default UpdateReportNameDialog;
