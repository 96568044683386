import React, { FunctionComponent, useCallback, useState } from 'react';
import { omit, trimStart } from 'lodash';

import {
  IconButton,
  InputAdornment,
  OutlinedTextFieldProps,
  TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';

type ICharactersCount =
  | {
      showCharactersCount: true;
      charactersCountText?: string | React.ReactNode;
    }
  | { showCharactersCount?: false; charactersCountText?: never };

interface IClearableInputProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  id: string;
  onHandleChange: (value: string) => void;
  rightElement?: JSX.Element;
  hasError?: boolean;
  errorMessage?: string;
  maxLength?: number;
  handleFocus?: () => void;
  handleBlur?: () => void;
}

type ClearableTextFieldProps = IClearableInputProps & ICharactersCount;

const ClearableTextField: FunctionComponent<ClearableTextFieldProps> = (
  props: ClearableTextFieldProps
) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const clearInput = (): void => {
    props.onHandleChange('');
  };

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      props.onHandleChange(
        props.maxLength
          ? trimStart(e.target.value.substring(0, props.maxLength))
          : e.target.value
      );
    },
    []
  );

  return (
    <TextField
      fullWidth
      {...omit(props, [
        'onHandleChange',
        'rightElement',
        'showCharactersCount',
        'charactersCountText',
        'hasError',
        'errorMessage',
        'handleFocus',
        'handleBlur',
      ])}
      onFocus={() => {
        setIsFocused(true);
        if (props.handleFocus) {
          props.handleFocus();
        }
      }}
      onBlur={() => {
        setIsFocused(false);
        if (props.handleBlur) {
          props.handleBlur();
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {props.rightElement}
            <IconButton
              aria-label="clear input"
              onClick={clearInput}
              edge="end"
            >
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={handleChange}
      error={props.hasError}
      helperText={(() => {
        if (props.maxLength && isFocused) {
          return `${
            props.maxLength - (props.value as string).length
          } characters left`;
        }
        if (props.hasError) {
          return props.errorMessage;
        }
      })()}
    />
  );
};

const MemoizedClearableTextField = React.memo(ClearableTextField);

export default MemoizedClearableTextField;
