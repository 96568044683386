import React, { FunctionComponent } from 'react';

import PopupDialog from '../../shared/PopupDialog';

interface IRemoveInputNamesDialogProps {
  open: boolean;
  onClose: () => void;
  onRemove: () => void;
}

const RemoveInputNamesDialog: FunctionComponent<
  IRemoveInputNamesDialogProps
> = ({ open, onClose, onRemove }) => {
  return (
    <PopupDialog
      open={open}
      close={onClose}
      title="Remove Inputs"
      primaryButtons={[
        {
          id: 'removeButton',
          label: 'Confirm',
          onClick: onRemove,
        },
      ]}
    >
      You have deselected one or more inputs that were previously loaded in the
      Editor. By clicking Confirm, all of your existing changes for the
      deselected inputs will be discarded. Are you sure?
    </PopupDialog>
  );
};

export default RemoveInputNamesDialog;
