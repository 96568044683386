import React, { FunctionComponent, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import RouteParams, { IRouteParams } from './RouteParams';

interface IProtectedRoute {
  path: IRouteParams['pathName'];
  label: IRouteParams['label'];
  callback: (path: string, label: string) => void;
}

const ProtectedRoute: FunctionComponent<IProtectedRoute> = (
  props: IProtectedRoute
) => {
  useEffect(() => {
    props.callback(props.path, props.label);
  }, []);
  return <Navigate to={RouteParams().scenarioManagerRoute.pathName} replace />;
};

export default ProtectedRoute;
