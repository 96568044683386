import React, { FunctionComponent, useEffect } from 'react';
import { map, range, some } from 'lodash';
import { format } from 'date-fns';

import { Box, Button, IconButton, Select } from '@mui/material';

import {
  CalendarMonth,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';

import { monthNames } from '../visualizationConstants';
import { XRefDate } from '../../../types/models';
import useSetState from '../../../hooks/useSetState';
import StyledPopper from '../../shared/StyledPopper';

interface IMonthSelectorProps
  extends Pick<IMonthPickerProps, 'dates' | 'minYear' | 'maxYear'> {
  month?: number;
  year: number;
  onSelectYear: (month: number, year: number) => void;
}

interface IMonthSelectorState {
  yearState: number;
  monthState?: number;
}

const MonthSelector: FunctionComponent<IMonthSelectorProps> = ({
  month,
  year,
  onSelectYear,
  dates,
  minYear,
  maxYear,
}: IMonthSelectorProps) => {
  const [state, setState] = useSetState<IMonthSelectorState>({
    yearState: year,
    monthState: month,
  });

  const styles: Record<string, React.CSSProperties> = {
    yearNav: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 1px 5px',
      justifyContent: 'center',
    },
    yearButton: {
      flex: '1',
      textAlign: 'center',
      margin: '0 2px',
    },
    yearButtonText: {
      verticalAlign: 'middle',
      fontWeight: 'bold',
    },
    monthContainer: {
      display: 'inline-block',
      padding: '1px',
      boxSizing: 'border-box',
    },
    monthButton: {
      lineHeight: '3em',
      textAlign: 'center',
      width: '100%',
      minWidth: '4em',
    },
  };

  const yearsRange: number[] = range(minYear, maxYear + 1);

  const onPrevYear = (): void => {
    setState({ yearState: state.yearState - 1 });
  };

  const onNextYear = (): void => {
    setState({ yearState: state.yearState + 1 });
  };

  const onSelect = (month: number): void => {
    setState({ monthState: month });
    onSelectYear(month, state.yearState);
  };

  const renderMonth = (monthItem: number): JSX.Element => {
    const selected: boolean = month === monthItem && year === state.yearState;

    return (
      <Box
        className="month-container"
        style={styles.monthContainer}
        key={monthItem}
      >
        <Button
          size="small"
          variant={selected ? 'contained' : 'outlined'}
          onClick={() => onSelect(monthItem)}
          disabled={!some(dates, { Date: `${monthItem}/1/${state.yearState}` })}
          sx={{ m: 0.25 }}
        >
          {monthNames[monthItem]}
        </Button>
      </Box>
    );
  };

  const months: JSX.Element[] = [];
  for (let i = 1; i <= 12; i++) {
    months.push(renderMonth(i));
  }

  return (
    <Box>
      <Box className="month-year-nav" style={styles.yearNav}>
        <IconButton
          size="small"
          onClick={onPrevYear}
          disabled={state.yearState <= minYear}
        >
          <KeyboardArrowLeft fontSize="small" />
        </IconButton>
        <Select
          native
          size="small"
          value={state.yearState}
          onChange={(e) => {
            (e as any).stopPropagation();
            setState({ yearState: Number(e.target.value) });
          }}
        >
          {map(yearsRange, (yearItem) => (
            <option key={yearItem} value={yearItem}>
              {yearItem}
            </option>
          ))}
        </Select>
        <IconButton
          size="small"
          onClick={onNextYear}
          disabled={state.yearState >= maxYear}
        >
          <KeyboardArrowRight fontSize="small" />
        </IconButton>
      </Box>
      <Box>{months}</Box>
    </Box>
  );
};

interface IMonthPickerProps {
  minYear: number;
  maxYear: number;
  onChange: (month: number | undefined, year: number) => void;
  dates: XRefDate[];
  value?: string;
}

interface IMonthPickerState {
  selectedMonth?: number;
  selectedYear: number;
  isPopOverOpen: boolean;
}

const MonthPicker: FunctionComponent<IMonthPickerProps> = ({
  minYear,
  maxYear,
  onChange,
  dates,
  value,
}: IMonthPickerProps) => {
  const [state, setState] = useSetState<IMonthPickerState>({
    selectedMonth: value ? Number(format(new Date(value), 'M')) : undefined,
    selectedYear: value ? Number(format(new Date(value), 'yyyy')) : minYear,
    isPopOverOpen: false,
  });

  useEffect(() => {
    if (value) {
      setState({
        selectedMonth: Number(format(new Date(value), 'M')),
        selectedYear: Number(format(new Date(value), 'yyyy')),
      });
    }
  }, [value]);

  const onSelectMonth = (month: number, year: number): void => {
    setState({
      selectedMonth: month,
      selectedYear: year,
      isPopOverOpen: false,
    });
  };

  const handleClose = (): void => {
    onChange(state.selectedMonth, state.selectedYear);
  };

  return (
    <StyledPopper
      id="month-picker"
      onClose={handleClose}
      content={() => (
        <MonthSelector
          month={state.selectedMonth}
          year={state.selectedYear}
          onSelectYear={onSelectMonth}
          dates={dates}
          minYear={minYear}
          maxYear={maxYear}
        />
      )}
    >
      <Button
        endIcon={<CalendarMonth />}
        fullWidth
        variant="contained"
        onClick={() => setState({ isPopOverOpen: true })}
      >
        {value === undefined
          ? 'Select a date'
          : `${monthNames[state.selectedMonth as number]}-${
              state.selectedYear
            }`}
      </Button>
    </StyledPopper>
  );
};

export default MonthPicker;
