import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Checkbox,
  ClickAwayListener,
  Container,
  Fab,
  Fade,
  FormControlLabel,
  FormGroup,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import domtoimage from 'dom-to-image';
import Grid from '@mui/material/Unstable_Grid2';
import {
  clamp,
  countBy,
  find,
  flatten,
  groupBy,
  head,
  isEmpty,
  keys,
  last,
  map,
  orderBy,
  padStart,
  reduce,
  replace,
  reverse,
  round,
  size,
  uniq,
  values,
} from 'lodash';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { BarChart, Download } from '@mui/icons-material';
import { format } from 'date-fns';
import SvgIcon from '@mui/material/SvgIcon';

import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { styled } from '@mui/material/styles';

import { Dictionary } from 'highcharts';

import { Timescale } from '../../../../types/models';

import ExcelIcon from '../../../shared/ExcelIcon';

import LineChart, { colors } from './Charts/LineChart';

import CSVIcon from './OutputsTable/CSVIcon';

import OutputsTable from './OutputsTable';

import ChartRangePicker from './RangePickers/ChartRangePicker';
import { getColors } from './Charts/colors';

import ColumnChart from './Charts/ColumnChart';

import StackedColumnChart from './Charts/StackedColumnChart';

import StackedPercentColumnChart from './Charts/StackedPercentColumnChart';

import { IChartData, IChartDataDate, IChartDataSeries } from './index';

const filteredItems = <T extends { id: number }>(items: T[]): T[] =>
  items.filter(
    (item, index, self) => index === self.findIndex((t) => t.id === item.id)
  );

interface IGenerateChartPanelProps {
  chartData: IChartData;
  refreshedChartData: IChartData;
  timescale: Timescale;
  formatKey?: number;
  yAxisTitle: string;
  chartTitle: string;
  prefix: string;
  suffix: string;
  magnitudeTruncation: number;
  outputId?: number;
  modelInstanceId?: number;
  decimalPlaces: number;
  chartDateType: string;
  selectedChartRange: [string, string] | null;
  selectedChartType: string;
  selectedChartDataType: string;
  handleChangeChartRange: (startRange: string, endRange: string) => void;
  handleChangeChartType: (chartType: string) => void;
  handleChangeChartDataType: (chartDataType: string) => void;
  handleLegendChange: (id: number, visible: boolean) => void;
  isPanelCollapsed: boolean;
  showScenarios: boolean;
}

const StyledAvatar = styled(Avatar)(() => ({
  backgroundColor: 'white',
  boxShadow: `0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)`,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#d8d8d8',
  },
}));

export const chartTypes = [
  {
    id: 'line',
    label: 'Line Chart',
  },
  {
    id: 'column',
    label: 'Column Chart',
  },
  {
    id: 'stackedColumn',
    label: 'Stacked Column Chart',
  },
  {
    id: 'stackedPercentColumn',
    label: 'Stacked Percent Column Chart',
  },
];

interface IChartDataTypes {
  id: 'all' | 'preview' | 'published';
  label: string;
  icon: JSX.Element | null;
}

export const chartDataTypes: IChartDataTypes[] = [
  {
    id: 'all',
    label: 'All Data',
    icon: null,
  },
  {
    id: 'preview',
    label: 'Recalculated Data',
    icon: (
      <SvgIcon fontSize="small" viewBox="0 0 30 12">
        <line x1="0" y1="3" x2="30" y2="3" stroke="black" strokeDasharray="4" />
      </SvgIcon>
    ),
  },
  {
    id: 'published',
    label: 'Original Data',
    icon: (
      <SvgIcon fontSize="small" viewBox="0 0 30 12">
        <line x1="0" y1="1" x2="30" y2="1" stroke="black" />
      </SvgIcon>
    ),
  },
];

export interface OutputsTableRef {
  exportToExcel: () => void;
  exportToCSV: () => void;
}

const GenerateChartPanel: FunctionComponent<IGenerateChartPanelProps> = ({
  timescale,
  chartData,
  formatKey,
  yAxisTitle,
  prefix,
  suffix,
  magnitudeTruncation,
  outputId,
  modelInstanceId,
  decimalPlaces,
  chartDateType,
  chartTitle,
  selectedChartRange,
  handleChangeChartRange,
  refreshedChartData,
  selectedChartType,
  handleChangeChartType,
  handleLegendChange,
  isPanelCollapsed,
  showScenarios,
  selectedChartDataType,
  handleChangeChartDataType,
}) => {
  const printChartRef = useRef<HTMLDivElement>();

  const outputsTableRef = useRef<OutputsTableRef>(null);

  const scenariosCount = size(
    countBy(refreshedChartData.PublishedData, 'scenario')
  );

  const [groupedSeries, setGroupedSeries] = useState<
    Dictionary<
      {
        dataType: string;
        scenario: string;
        segment: string;
        name: string;
        values: number[];
        id: number;
        output?: string | undefined;
        visible: boolean;
      }[]
    >
  >({});

  useEffect(() => {
    if (selectedChartDataType === 'all') {
      setGroupedSeries(
        scenariosCount <= 1
          ? groupBy(
              [
                ...map(chartData.PreviewData, (i) => ({
                  ...i,
                  dataType: 'preview',
                })),
                ...map(chartData.PublishedData, (i) => ({
                  ...i,
                  dataType: 'published',
                })),
              ],
              (i) => i.segment.split(',')[0]
            )
          : groupBy(
              [
                ...map(chartData.PreviewData, (i) => ({
                  ...i,
                  dataType: 'preview',
                })),
                ...map(chartData.PublishedData, (i) => ({
                  ...i,
                  dataType: 'published',
                })),
              ],
              'scenario'
            )
      );
    } else if (selectedChartDataType === 'published') {
      setGroupedSeries(
        scenariosCount <= 1
          ? groupBy(
              [
                ...map(chartData.PublishedData, (i) => ({
                  ...i,
                  dataType: 'published',
                })),
              ],
              (i) => i.segment.split(',')[0]
            )
          : groupBy(
              [
                ...map(chartData.PublishedData, (i) => ({
                  ...i,
                  dataType: 'published',
                })),
              ],
              'scenario'
            )
      );
    } else if (selectedChartDataType === 'preview') {
      setGroupedSeries(
        scenariosCount <= 1
          ? groupBy(
              [
                ...map(chartData.PreviewData, (i) => ({
                  ...i,
                  dataType: 'preview',
                })),
              ],
              (i) => i.segment.split(',')[0]
            )
          : groupBy(
              [
                ...map(chartData.PreviewData, (i) => ({
                  ...i,
                  dataType: 'preview',
                })),
              ],
              'scenario'
            )
      );
    }
  }, [selectedChartDataType, chartData]);

  const seriesWithColors = reduce(
    keys(groupedSeries),
    (a, c, idx) => {
      a[c] = map(groupedSeries[c], (j, jidx, arr) => {
        const color = getColors(arr.length);
        return {
          ...j,
          color:
            scenariosCount <= 1
              ? colors[j.id - 1]
              : (color && (color.get(idx) as string[]))[jidx % arr.length],
        };
      });
      return a;
    },
    {} as Record<
      string,
      (IChartDataSeries & {
        color: string;
        dataType: string;
      })[]
    >
  );

  const uniqueSegments = uniq(map(chartData.PublishedData, 'segment'));

  const segmentsWithColors = reduce(
    uniqueSegments,
    (a, c, idx) => {
      a[c] = colors[idx];
      return a;
    },
    {} as Record<string, string>
  );

  let segmentCounter: Record<string, number> = {}; // Counter to track segment occurrences

  const adjustColor = (color: string, amount: number) => {
    // Validate the amount range
    amount = clamp(amount, -1, 1);

    if (amount === 0) {
      return color;
    }

    // Remove the "#" prefix from the hex color code
    color = replace(color, '#', '');

    // Convert the hex color code to RGB values
    let r = parseInt(color.substring(0, 2), 16);
    let g = parseInt(color.substring(2, 4), 16);
    let b = parseInt(color.substring(4, 6), 16);

    // Calculate the adjusted values
    r = round(r + amount * (amount < 0 ? r : 255 - r));
    g = round(g + amount * (amount < 0 ? g : 255 - g));
    b = round(b + amount * (amount < 0 ? b : 255 - b));

    // Convert the adjusted RGB values back to hex format
    // Return the adjusted color
    return (
      '#' +
      padStart(r.toString(16), 2, '0') +
      padStart(g.toString(16), 2, '0') +
      padStart(b.toString(16), 2, '0')
    );
  };

  const groupedSeriesListWithColors = map(
    orderBy(flatten(values(seriesWithColors)), ['segment'], ['asc']),
    (obj, idx) => {
      const alphaValues = [
        ...(scenariosCount === 1 ? [0] : []),
        ...(scenariosCount === 2 ? reverse([0, 0.2]) : []),
        ...(scenariosCount === 3 ? reverse([-0.2, 0, 0.2]) : []),
      ];
      // s values for France segments
      const segment = obj.segment;
      const color = segmentsWithColors[segment];

      if (!segmentCounter[segment]) {
        segmentCounter = {
          ...segmentCounter,
          [segment]: 0,
        };
      }

      const segmentCount = segmentCounter[segment];

      const alphaIndex = segmentCount % alphaValues.length; // Adjust alpha index calculation
      const alphaValue = alphaValues[alphaIndex];
      segmentCounter[segment]++;

      return {
        ...obj,
        color: scenariosCount > 1 ? adjustColor(color, alphaValue) : color,
      };
    }
  );

  const groupedSeriesWithColors =
    scenariosCount <= 1
      ? groupBy(groupedSeriesListWithColors, (i) => i.segment.split(',')[0])
      : groupBy(groupedSeriesListWithColors, 'scenario');

  const groupedSeriesListColors = values(groupedSeriesWithColors);

  const base64Rows = Buffer.from(
    JSON.stringify([
      ...chartData.PublishedData,
      ...chartData.PreviewData,
      Date.now(),
    ])
  ).toString('base64');

  return (
    <Container
      maxWidth={false}
      sx={{
        width: '100%',
        background: 'white',
      }}
    >
      <Box ref={printChartRef}>
        <Grid container justifyContent="space-between" sx={{ py: 2 }}>
          <Grid sm={6}>
            <Typography variant="h6" id="chartTitle">
              {chartTitle}
            </Typography>
          </Grid>
          <Grid
            sm={5}
            container
            justifyContent="flex-end"
            className="ignore-png"
          >
            <Grid container spacing={1.25}>
              {!showScenarios && !isEmpty(chartData.PreviewData) && (
                <Grid>
                  <Select
                    size="small"
                    fullWidth
                    value={selectedChartDataType}
                    onChange={(event: SelectChangeEvent) =>
                      handleChangeChartDataType(event.target.value as string)
                    }
                    renderValue={(value) =>
                      (find(chartDataTypes, { id: value }) as IChartDataTypes)
                        .label
                    }
                  >
                    {map(chartDataTypes, (type) => (
                      <MenuItem key={type.id} value={type.id}>
                        <ListItemIcon>{type.icon}</ListItemIcon>
                        <ListItemText>{type.label}</ListItemText>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
              <Grid>
                <Select
                  size="small"
                  fullWidth
                  value={selectedChartType}
                  onChange={(event: SelectChangeEvent) =>
                    handleChangeChartType(event.target.value as string)
                  }
                >
                  {map(chartTypes, (type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid>
                <PopupState
                  variant="popper"
                  popupId="outputs-panel-download-actions"
                >
                  {(popupState) => (
                    <>
                      <Fab
                        {...bindHover(popupState)}
                        size="small"
                        color="primary"
                        aria-label="downloadChart"
                        sx={{
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                          color: (theme) => theme.palette.primary.main,
                          border: `1px solid transparent`,
                          '&:hover': {
                            backgroundColor: (theme) =>
                              theme.palette.primary.main,
                            color: 'white',
                          },
                        }}
                      >
                        <Download />
                      </Fab>
                      <Popper {...bindPopper(popupState)} transition>
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} timeout={350}>
                            <Paper
                              sx={{
                                background: 'transparent',
                                boxShadow: 'none',
                              }}
                            >
                              <ClickAwayListener
                                onClickAway={() => {
                                  popupState.close();
                                }}
                              >
                                <Stack
                                  sx={{
                                    '*': {
                                      my: 1,
                                    },
                                  }}
                                  direction="column"
                                >
                                  <Tooltip
                                    title="Download Chart Image"
                                    arrow
                                    placement="left"
                                  >
                                    <StyledAvatar
                                      onClick={async () => {
                                        const dataUrl = await domtoimage.toPng(
                                          printChartRef.current as HTMLDivElement,
                                          {
                                            filter: (node) => {
                                              if (
                                                (
                                                  node.parentElement as HTMLElement
                                                ).classList
                                              )
                                                return !(
                                                  node.parentElement as HTMLElement
                                                ).classList.contains(
                                                  'ignore-png'
                                                );
                                              return true;
                                            },
                                          }
                                        );
                                        const link =
                                          document.createElement('a');
                                        link.download = `${chartTitle}-${format(
                                          new Date(),
                                          'MM/dd/yyyy_HH:mm:ss'
                                        )}`;
                                        link.href = dataUrl;
                                        link.click();
                                      }}
                                    >
                                      <BarChart sx={{ color: '#0009' }} />
                                    </StyledAvatar>
                                  </Tooltip>
                                  <Tooltip
                                    title="Download CSV Data"
                                    arrow
                                    placement="left"
                                  >
                                    <StyledAvatar
                                      onClick={() =>
                                        outputsTableRef.current?.exportToCSV()
                                      }
                                    >
                                      <CSVIcon sx={{ color: '#0009' }} />
                                    </StyledAvatar>
                                  </Tooltip>
                                  <Tooltip
                                    title="Download Excel Data"
                                    arrow
                                    placement="left"
                                  >
                                    <StyledAvatar
                                      onClick={() =>
                                        outputsTableRef.current?.exportToExcel()
                                      }
                                    >
                                      <ExcelIcon />
                                    </StyledAvatar>
                                  </Tooltip>
                                </Stack>
                              </ClickAwayListener>
                            </Paper>
                          </Fade>
                        )}
                      </Popper>
                    </>
                  )}
                </PopupState>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {formatKey !== undefined && selectedChartType === 'line' && (
          <LineChart
            chartTitle={chartTitle}
            formatKey={formatKey}
            prefix={prefix}
            suffix={suffix}
            truncation={magnitudeTruncation}
            decimalPlaces={decimalPlaces}
            data={chartData}
            timeScaleType={timescale}
            dateFormat={chartDateType}
            yAxisTitle={yAxisTitle}
            isPanelCollapsed={isPanelCollapsed}
            showScenarios={showScenarios}
            selectedChartDataType={selectedChartDataType}
            groupedChartData={groupedSeriesListWithColors}
          />
        )}
        {formatKey !== undefined && selectedChartType === 'column' && (
          <ColumnChart
            chartTitle={chartTitle}
            formatKey={formatKey}
            prefix={prefix}
            suffix={suffix}
            truncation={magnitudeTruncation}
            decimalPlaces={decimalPlaces}
            data={chartData}
            timeScaleType={timescale}
            dateFormat={chartDateType}
            yAxisTitle={yAxisTitle}
            isPanelCollapsed={isPanelCollapsed}
            showScenarios={showScenarios}
            selectedChartDataType={selectedChartDataType}
            groupedChartData={groupedSeriesListWithColors}
          />
        )}
        {formatKey !== undefined && selectedChartType === 'stackedColumn' && (
          <StackedColumnChart
            chartTitle={chartTitle}
            formatKey={formatKey}
            prefix={prefix}
            suffix={suffix}
            truncation={magnitudeTruncation}
            decimalPlaces={decimalPlaces}
            data={chartData}
            timeScaleType={timescale}
            dateFormat={chartDateType}
            yAxisTitle={yAxisTitle}
            isPanelCollapsed={isPanelCollapsed}
            showScenarios={showScenarios}
            selectedChartDataType={selectedChartDataType}
            groupedChartData={groupedSeriesListWithColors}
          />
        )}
        {formatKey !== undefined &&
          selectedChartType === 'stackedPercentColumn' && (
            <StackedPercentColumnChart
              chartTitle={chartTitle}
              formatKey={formatKey}
              prefix={prefix}
              suffix={suffix}
              truncation={magnitudeTruncation}
              decimalPlaces={decimalPlaces}
              data={chartData}
              timeScaleType={timescale}
              dateFormat={chartDateType}
              yAxisTitle={yAxisTitle}
              isPanelCollapsed={isPanelCollapsed}
              showScenarios={showScenarios}
              selectedChartDataType={selectedChartDataType}
              groupedChartData={groupedSeriesListWithColors}
            />
          )}
        <Grid container spacing={2}>
          <Grid xs={9}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {(() => {
                if (scenariosCount <= 1) {
                  return map(groupedSeriesListColors, (item, index) => {
                    return map(filteredItems(item), (serie, i) => {
                      return (
                        <Grid xs={3} key={`${base64Rows}${index}${i}`}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id={`series${serie.id - 1}`}
                                checked={serie.visible}
                                sx={{
                                  color: serie.color,
                                  '&.Mui-checked': {
                                    color: serie.color,
                                  },
                                }}
                                onChange={(e) =>
                                  handleLegendChange(serie.id, e.target.checked)
                                }
                                size="small"
                              />
                            }
                            disableTypography
                            label={
                              <Typography sx={{ color: serie.color }}>
                                {serie.segment}
                              </Typography>
                            }
                          />
                        </Grid>
                      );
                    });
                  });
                }
                return map(
                  keys(groupedSeriesWithColors),
                  (item, index, arr) => {
                    return (
                      <Grid
                        xs={(() => {
                          if (arr.length === 1) {
                            return 12;
                          } else if (arr.length === 2) {
                            return 6;
                          }
                          return 4;
                        })()}
                        key={item}
                      >
                        <Typography variant="h6">{item}</Typography>
                        {map(groupedSeriesWithColors[item], (serie, i) => {
                          return (
                            <FormGroup key={`${base64Rows}${index}${i}`}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    id={`series${serie.id - 1}`}
                                    checked={serie.visible}
                                    sx={{
                                      color: serie.color,
                                      '&.Mui-checked': {
                                        color: serie.color,
                                      },
                                    }}
                                    onChange={(e) =>
                                      handleLegendChange(
                                        serie.id,
                                        e.target.checked
                                      )
                                    }
                                    size="small"
                                  />
                                }
                                label={
                                  <Typography sx={{ color: serie.color }}>
                                    {serie.segment}
                                  </Typography>
                                }
                              />
                            </FormGroup>
                          );
                        })}
                      </Grid>
                    );
                  }
                );
              })()}
            </Grid>
          </Grid>
          <Grid
            xs={3}
            justifyContent="flex-end"
            container
            className="ignore-png"
          >
            <Grid>
              <ChartRangePicker<IChartDataDate>
                type={timescale}
                minRange={head(refreshedChartData.Dates) as IChartDataDate}
                maxRange={last(refreshedChartData.Dates) as IChartDataDate}
                onHandleRangeChange={handleChangeChartRange}
                value={selectedChartRange}
                dates={refreshedChartData.Dates}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <OutputsTable
        ref={outputsTableRef}
        chartData={refreshedChartData}
        formatKey={formatKey as number}
        title={chartTitle}
        prefix={prefix}
        suffix={suffix}
        magnitudeTruncation={magnitudeTruncation}
        decimalPlaces={decimalPlaces}
        outputId={outputId}
        modelInstanceId={modelInstanceId}
        chartDateType={chartDateType}
        timescale={timescale}
        showScenarios={showScenarios}
      />
    </Container>
  );
};

export default GenerateChartPanel;
