import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  alpha,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  chain,
  compact,
  differenceWith,
  filter,
  find,
  findIndex,
  get,
  head,
  includes,
  isEmpty,
  isEqual,
  isString,
  isUndefined,
  last,
  map,
  reduce,
  size,
  some,
} from 'lodash';
import {
  CellClassParams,
  CellEditorSelectorResult,
  CellStyle,
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  ColumnMenuTab,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GetMainMenuItemsParams,
  GridReadyEvent,
  ICellEditorParams,
  MenuItemDef,
  RowDataUpdatedEvent,
} from 'ag-grid-community';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import {
  Close,
  FilterAlt,
  FilterAltOff,
  InfoOutlined,
} from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';

import useDynamicCallback from 'src/hooks/useDynamicCallback';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';

import { format, getMonth, getYear, isBefore, nextDay } from 'date-fns';

import Table from '../../shared/Table';

import useSetState from '../../../hooks/useSetState';

import {
  formatParameters,
  IFormatParameters,
} from '../../Visualization/formatParameters';

import { colors } from '../../../theme';

import StyledPopper from '../../shared/StyledPopper';

import { ModelInstance, Timescale } from '../../../types/models';

import DateEditor from './Editors/DateEditor';

import ClearAllEditsDialog from './ClearAllEditsDialog';

import TextEditor from './Editors/TextEditor';

import PercentTextEditor from './Editors/PercentTextEditor';

import ValuesCellRenderer from './CellRenderers/ValuesCellRenderer';

import EditedCellsRenderer from './CellRenderers/EditedCellsRenderer';

import EventOnOffDropdownEditor from './Editors/EventOnOffDropdownEditor';

import { IInputsResponse, IModifiedRowData } from './index';

const upTakeEventAssumptionsFormatKey = (
  timescale?: Timescale
): Record<string, IFormatParameters | undefined> => ({
  'Start Date': find(formatParameters, {
    formatKey: includes([Timescale.Monthly, Timescale.Weekly], timescale)
      ? 4
      : 0,
  }),
  'Peak Value': find(formatParameters, { formatKey: 28 }),
  'Years to Peak': find(formatParameters, { formatKey: 0 }),
  'Curve Type': find(formatParameters, { formatKey: 14 }),
  'Start Month': find(formatParameters, { formatKey: 0 }),
  'Event On/Off': find(formatParameters, { formatKey: 0 }),
});

interface Props {
  selectedInput?: IInputsResponse;
  inputName: string;
  getModifiedRowData: (data: IModifiedRowData) => void;
  resetRowData: (inputName: string) => void;
  modelInstance?: ModelInstance;
  modifiedRowData: IModifiedRowData[];
}

interface State {
  rowData: any[];
  columnDefs: ColDef[];
  showOriginalValues: boolean;
  originalRowData: any[];
  displayClearAllEditsModal: boolean;
  startDateFilter: Date | string;
  endDateFilter: Date | string;
}

const InputTabPanel: FunctionComponent<Props> = ({
  selectedInput,
  inputName,
  getModifiedRowData,
  resetRowData,
  modelInstance,
  modifiedRowData,
}) => {
  const [state, setState] = useSetState<State>({
    rowData: [],
    columnDefs: [],
    showOriginalValues: false,
    originalRowData: [],
    displayClearAllEditsModal: false,
    startDateFilter: '',
    endDateFilter: '',
  });

  const gridRef = useRef<AgGridReact<(typeof state.rowData)[0]>>(null);

  const showDatePickers = !includes(
    ['Uptake & Event Assumptions', 'Single Value Assumptions'],
    selectedInput?.TabName
  );

  const getFormatKey = useCallback(
    (params: ICellEditorParams | ICellRendererParams): IFormatParameters => {
      if (selectedInput?.TabName === 'Uptake & Event Assumptions') {
        return upTakeEventAssumptionsFormatKey(modelInstance?.Timescale)[
          (params.colDef as ColDef).field as string
        ] as IFormatParameters;
      }
      return selectedInput?.FormatKey as IFormatParameters;
    },
    [selectedInput, modelInstance]
  );

  const cellEditorSelector = useDynamicCallback(
    (params: ICellEditorParams): CellEditorSelectorResult => {
      const formatKey = getFormatKey(params);
      const lengthAfterDecimals =
        params.value && params.value.split('.').length > 1
          ? params.value.split('.')[1].length
          : 0;
      if (get(formatKey, 'numberType') === 'Date') {
        return {
          component: DateEditor,
          params: { formatKey },
        };
      }
      if (get(formatKey, 'numberType') === 'Percent') {
        return {
          component: PercentTextEditor,
          params: {
            formatKey,
            value: (() => {
              if (params.value === '') {
                return '';
              }
              if (Number(params.value) < 0.01 && Number(params.value) > -0.01) {
                return params.value;
              }
              return (Number(params.value) * 100).toFixed(
                lengthAfterDecimals < 2 ? 0 : lengthAfterDecimals - 2
              );
            })(),
          },
        };
      }
      if (
        selectedInput?.TabName === 'Uptake & Event Assumptions' &&
        (params.colDef as ColDef).field === 'Event On/Off'
      ) {
        return {
          component: EventOnOffDropdownEditor,
          params: { formatKey },
        };
      }
      return {
        component: TextEditor,
        params: { formatKey },
      };
    }
  );

  const setStartAndEndDateFilters = useCallback(() => {
    const date = (field: string) => {
      if (isQuarterlyTab || isOtherAssumptionsTab) {
        return field;
      }
      if (isYearlyTab) {
        return nextDay(new Date(field), 1);
      }
      return new Date(field);
    };

    const getValue = (key: string) => {
      const stored = sessionStorage[`columnState-inputName-${inputName}`];
      if (!stored) {
        return '';
      }
      return JSON.parse(stored)[key];
    };

    const startField =
      getValue('startField') || head(selectedInput?.ValueHeaders);
    const endField = getValue('endField') || last(selectedInput?.ValueHeaders);

    return {
      startDateFilter: showDatePickers ? date(startField as string) : '',
      endDateFilter: showDatePickers ? date(endField as string) : '',
    };
  }, [inputName, selectedInput, showDatePickers]);

  function getMainMenuItems(
    params: GetMainMenuItemsParams
  ): (string | MenuItemDef)[] {
    const menuItems: (MenuItemDef | string)[] = [];
    const itemsToExclude = ['separator', 'pinSubMenu', 'valueAggSubMenu'];
    params.defaultItems.forEach((item) => {
      if (itemsToExclude.indexOf(item) < 0) {
        menuItems.push(item);
      }
    });
    return menuItems;
  }

  const getColumns = useCallback(
    (isEditable: boolean): (ColDef | ColGroupDef)[] => {
      return compact([
        {
          headerName: '',
          field: 'Input_RowID',
          cellRenderer: 'editedCellRenderer',
          pinned: 'left' as 'left',
          width: 50,
          suppressMenu: true,
          suppressNavigable: true,
        },
        ...map(selectedInput?.DimensionHeaders, (i) => {
          return {
            headerName: i.Name,
            field: i.Name,
            pinned: 'left' as 'left',
            editable: false,
            menuTabs: ['generalMenuTab', 'filterMenuTab'] as ColumnMenuTab[],
            filter: 'agSetColumnFilter',
            filterParams: {
              excelMode: 'windows',
            },
            suppressNavigable: true,
            resizable: true,
          };
        }),
        ...map(selectedInput?.ValueHeaders, (i, idx) => {
          return {
            headerName: i,
            field: i,
            editable: isEditable,
            cellEditorSelector,
            suppressMenu: true,
            cellRenderer: 'valuesCellRenderer',
            pinned: null,
          };
        }),
        isEditable
          ? { field: 'updatedCells', hide: true, pinned: 'right' as 'right' }
          : null,
      ]);
    },
    [selectedInput]
  );

  useEffect(() => {
    if (!isUndefined(selectedInput)) {
      const rowData = map(selectedInput?.Series, (s) => {
        return reduce(
          selectedInput?.ValueHeaders,
          (o, k, i) => ({
            ...o,
            Input_RowID: (s as any).Input_RowID,
            Group: (s as any).Group,
            formatKey: selectedInput?.FormatKey,
            TabName: selectedInput?.TabName,
            updatedCells: [],
            ...reduce(
              selectedInput?.DimensionHeaders,
              (p, q) => ({
                ...p,
                [q.Name]: (s as any)[q.Field],
              }),
              {} as Record<string, string | null>
            ),
            [k]: s.Values[i],
          }),
          {} as Record<string, any>
        );
      });

      setState({
        rowData,
        originalRowData: structuredClone(rowData),
        columnDefs: sessionStorage[`columnState-inputName-${inputName}`]
          ? get(
              JSON.parse(sessionStorage[`columnState-inputName-${inputName}`]),
              'columns',
              []
            )
          : getColumns(true),
        ...setStartAndEndDateFilters(),
      });
    }
  }, [selectedInput, inputName]);

  const onCellValueChanged = useDynamicCallback(
    (params: CellValueChangedEvent): void => {
      const { data, colDef, newValue, oldValue, node, api } = params;
      if (colDef.field !== 'updatedCells' && newValue !== oldValue) {
        const { originalRowData } = state;
        const field = colDef.field as string;

        let originalValue = originalRowData.find(
          (i) => i.Input_RowID === data.Input_RowID
        )[field];
        if (!originalValue) {
          originalValue = '';
        }
        const updatedCells = data.updatedCells.slice();

        if (originalValue !== newValue) {
          if (updatedCells.indexOf(field) === -1) {
            updatedCells.push(field);
          }
        } else {
          const index = updatedCells.indexOf(field);
          if (index !== -1) {
            updatedCells.splice(index, 1);
          }
        }

        node.setDataValue('updatedCells', updatedCells);
        api.refreshCells();
        api.redrawRows();

        // refreshing the view
        api.setColumnDefs(api.getColumnDefs() as (ColDef | ColGroupDef)[]);

        const getAllRows = () => {
          let rowData: any[] = [];
          api.forEachNode((node) => rowData.push(node.data));
          return rowData;
        };

        const columnValues = chain(state.columnDefs)
          .reject({ pinned: 'left' })
          .reject({ field: 'updatedCells' })
          .map('headerName')
          .value() as string[];

        getModifiedRowData({
          Input: selectedInput?.Name as string,
          Data: map(getAllRows(), (i) => {
            return {
              Input_RowID: i.Input_RowID,
              Group: i.Group,
              Values: map(columnValues, (j) => get(i, j)),
            };
          }),
          isDirty: some(getAllRows(), (i) => i.updatedCells.length > 0),
        });

        setState({
          columnDefs: getColumns(true),
        });

        sessionStorage.setItem(
          `gridState-inputName-${inputName}-modified`,
          JSON.stringify(getAllRows())
        );
      }
    }
  );

  const cellStyle = (params: CellClassParams): CellStyle | null | undefined => {
    let { field } = params.colDef;
    let { updatedCells } = params.data;
    if (includes(updatedCells, field)) {
      return {
        background: alpha(colors.secondary, 0.5),
      };
    }
    return null;
  };

  const onFirstDataRendered = useDynamicCallback(
    (params: FirstDataRenderedEvent): void => {
      const gridStateModifiedKey = `gridState-inputName-${inputName}-modified`;
      const filterStateKey = `filterState-inputName-${inputName}`;
      const columnStateKey = `columnState-inputName-${inputName}`;

      const gridStateModified = sessionStorage.getItem(gridStateModifiedKey);
      if (gridStateModified) {
        params.api.setRowData(JSON.parse(gridStateModified));
        params.api.redrawRows();
      }

      const filterState = sessionStorage.getItem(filterStateKey);
      if (filterState) {
        gridRef.current &&
          gridRef.current.api.setFilterModel(JSON.parse(filterState));
      }

      const columnState = sessionStorage.getItem(columnStateKey);
      if (columnState) {
        setState({
          ...setStartAndEndDateFilters(),
          columnDefs: JSON.parse(columnState).columns,
        });
      }
    }
  );

  const onGridReady = useDynamicCallback((params: GridReadyEvent): void => {
    const gridStateModified = `gridState-inputName-${inputName}-modified`;
    const filterState = `filterState-inputName-${inputName}`;
    const columnState = `columnState-inputName-${inputName}`;

    const gridStateModifiedValue = sessionStorage.getItem(gridStateModified);
    if (gridStateModifiedValue) {
      params.api.setRowData(JSON.parse(gridStateModifiedValue));
      params.api.setColumnDefs(
        params.api.getColumnDefs() as (ColDef | ColGroupDef)[]
      );
      params.api.redrawRows();
    }

    const filterStateValue = sessionStorage.getItem(filterState);
    if (filterStateValue) {
      gridRef.current &&
        gridRef.current.api.setFilterModel(JSON.parse(filterStateValue));
    }

    const columnStateValue = sessionStorage.getItem(columnState);
    if (columnStateValue) {
      setState({
        columnDefs: JSON.parse(columnStateValue).columns,
        ...setStartAndEndDateFilters(),
      });
    }
  });

  const onOriginalGridReady = useDynamicCallback((): void => {
    if (sessionStorage[`filterState-inputName-${inputName}`]) {
      (gridRef.current as AgGridReact).api.setFilterModel(
        JSON.parse(sessionStorage[`filterState-inputName-${inputName}`])
      );
    }
    if (sessionStorage[`columnState-inputName-${inputName}`]) {
      setState({
        columnDefs: JSON.parse(
          sessionStorage[`columnState-inputName-${inputName}`]
        ).columns,
        ...setStartAndEndDateFilters(),
      });
    }
  });

  useEffect(() => {
    if (sessionStorage[`gridState-inputName-${inputName}-modified`]) {
      setState({
        rowData: JSON.parse(
          sessionStorage[`gridState-inputName-${inputName}-modified`]
        ),
      });
    }
    if (sessionStorage[`columnState-inputName-${inputName}`]) {
      setState({
        columnDefs: JSON.parse(
          sessionStorage[`columnState-inputName-${inputName}`]
        ).columns,
        ...setStartAndEndDateFilters(),
      });
    }
  }, []);

  useEffect(() => {
    const columns = (
      gridRef.current as AgGridReact
    ).api?.getColumnDefs() as ColDef[];
    const middleColumns = filter(columns, { pinned: null });
    const startIndex = findIndex(middleColumns, (i) => i.field === startField);
    const endIndex = findIndex(middleColumns, (i) => i.field === endField);

    const middleColumnsFiltered = middleColumns.slice(startIndex, endIndex + 1);
    const otherColumnsFiltered = differenceWith(
      middleColumns,
      middleColumnsFiltered,
      (a, b) => a.field === b.field
    );

    if (gridRef.current && showDatePickers) {
      const { columnApi, api } = gridRef.current as AgGridReact;
      if (columnApi) {
        columnApi.setColumnsVisible(
          map(otherColumnsFiltered, 'field') as string[],
          false
        );
        columnApi.setColumnsVisible(
          map(middleColumnsFiltered, 'field') as string[],
          true
        );
        const columnObj = {
          columns: api.getColumnDefs(),
          startField,
          endField,
          startIndex,
          endIndex,
        };
        sessionStorage.setItem(
          `columnState-inputName-${inputName}`,
          JSON.stringify(columnObj)
        );
      }
    }
  }, [state.startDateFilter, state.endDateFilter, gridRef.current, inputName]);

  const onFilterChanged = useDynamicCallback(
    (params: FilterChangedEvent): void => {
      const filterState = params.api.getFilterModel();
      sessionStorage.setItem(
        `filterState-inputName-${inputName}`,
        JSON.stringify(filterState)
      );
    }
  );

  const onRowDataUpdated = useDynamicCallback(
    (params: RowDataUpdatedEvent): void => {
      if (sessionStorage[`filterState-inputName-${inputName}`]) {
        params.api.setFilterModel(
          JSON.parse(sessionStorage[`filterState-inputName-${inputName}`])
        );
      }
    }
  );

  const clearTimeFilter = useCallback(() => {
    let startDate, endDate;

    const valueColumns = selectedInput?.ValueHeaders;
    const firstValueColumn = head(valueColumns);
    const lastValueColumn = last(valueColumns);

    if (isQuarterlyTab || isOtherAssumptionsTab) {
      startDate = firstValueColumn;
      endDate = lastValueColumn;
    } else if (isYearlyTab) {
      startDate = nextDay(new Date(firstValueColumn as string), 1);
      endDate = nextDay(new Date(lastValueColumn as string), 1);
    } else {
      startDate = new Date(firstValueColumn as string);
      endDate = new Date(lastValueColumn as string);
    }
    const columns = (
      gridRef.current as AgGridReact
    ).api?.getColumnDefs() as ColDef[];
    const middleColumns = filter(columns, { pinned: null });
    gridRef.current &&
      (gridRef.current as AgGridReact).columnApi &&
      (gridRef.current as AgGridReact).columnApi.setColumnsVisible(
        map(middleColumns, 'field') as string[],
        true
      );
    sessionStorage.removeItem(`columnState-inputName-${inputName}`);

    setState({
      startDateFilter: startDate,
      endDateFilter: endDate,
    });
  }, [selectedInput, gridRef.current, inputName]);

  const originalValueColumns = filter(state.columnDefs, {
    pinned: null,
  });

  const isWeeklyTab = includes(selectedInput?.TabName, 'Weekly');
  const isQuarterlyTab = includes(selectedInput?.TabName, 'Quarterly');
  const isMonthlyTab = includes(selectedInput?.TabName, 'Monthly');
  const isYearlyTab = includes(selectedInput?.TabName, 'Yearly');
  const isOtherAssumptionsTab = includes(selectedInput?.TabName, 'Other');

  const startField = state.startDateFilter
    ? isQuarterlyTab || isOtherAssumptionsTab
      ? (state.startDateFilter as string)
      : isYearlyTab
        ? format(
            nextDay(
              isString(state.startDateFilter)
                ? new Date(state.startDateFilter)
                : (state.startDateFilter as Date),
              1
            ),
            'yyyy'
          )
        : format(
            isString(state.startDateFilter)
              ? new Date(state.startDateFilter)
              : (state.startDateFilter as Date),
            'M/d/yyyy'
          )
    : format(new Date(), 'M/d/yyyy');

  const endField = state.endDateFilter
    ? isQuarterlyTab || isOtherAssumptionsTab
      ? (state.endDateFilter as string)
      : isYearlyTab
        ? format(
            nextDay(
              isString(state.endDateFilter)
                ? new Date(state.endDateFilter)
                : (state.endDateFilter as Date),
              1
            ),
            'yyyy'
          )
        : format(
            isString(state.endDateFilter)
              ? new Date(state.endDateFilter)
              : (state.endDateFilter as Date),
            'M/d/yyyy'
          )
    : format(new Date(), 'M/d/yyyy');

  const startIndex = findIndex(originalValueColumns, { field: startField });

  const endIndex = findIndex(originalValueColumns, { field: endField });

  const startFilterYear = getYear(
    isString(state.startDateFilter)
      ? new Date(state.startDateFilter)
      : state.startDateFilter
  );

  const minDate =
    isQuarterlyTab || isOtherAssumptionsTab
      ? head(selectedInput?.ValueHeaders)
      : isYearlyTab
        ? nextDay(new Date(head(selectedInput?.ValueHeaders) as string), 1)
        : new Date(head(selectedInput?.ValueHeaders) as string);

  const maxDate =
    isQuarterlyTab || isOtherAssumptionsTab
      ? last(selectedInput?.ValueHeaders)
      : isYearlyTab
        ? nextDay(new Date(last(selectedInput?.ValueHeaders) as string), 1)
        : new Date(last(selectedInput?.ValueHeaders) as string);

  const startFilter = useMemo(() => {
    if (isQuarterlyTab) {
      return (
        <TextField
          fullWidth
          variant="outlined"
          sx={{ pr: 2 }}
          size="small"
          select
          value={state.startDateFilter}
          onChange={(e) => {
            setState({
              startDateFilter: e.target.value,
            });
          }}
          label="Start Date"
        >
          {map(originalValueColumns, (value) => (
            <MenuItem key={value.field} value={value.field}>
              {value.headerName}
            </MenuItem>
          ))}
        </TextField>
      );
    }
    if (isOtherAssumptionsTab) {
      return (
        <TextField
          fullWidth
          variant="outlined"
          sx={{ pr: 2 }}
          size="small"
          select
          value={state.startDateFilter}
          onChange={(e) => {
            setState({
              startDateFilter: e.target.value,
            });
          }}
          label="Start"
        >
          {map(originalValueColumns, (value) => (
            <MenuItem key={value.field} value={value.field}>
              {value.headerName}
            </MenuItem>
          ))}
        </TextField>
      );
    }
    if (isWeeklyTab) {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker<Date>
            label="Start Date"
            views={['year', 'month', 'day']}
            value={state.startDateFilter as Date}
            onChange={(newValue) => {
              if (newValue instanceof Date && !isNaN(newValue.valueOf())) {
                setState({
                  startDateFilter: newValue || '',
                });
              }
            }}
            format="M/d/yyyy"
            minDate={minDate as Date}
            maxDate={maxDate as Date}
            shouldDisableDate={(date) => {
              return !includes(
                map(originalValueColumns, 'field'),
                format(new Date(date), 'M/d/yyyy')
              );
            }}
          />
        </LocalizationProvider>
      );
    }
    if (isMonthlyTab) {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker<Date>
            label="Start Date"
            views={['year', 'month']}
            value={state.startDateFilter as Date}
            onChange={(newValue) => {
              if (newValue instanceof Date && !isNaN(newValue.valueOf())) {
                setState({
                  startDateFilter: newValue || '',
                });
              }
            }}
            format="M/d/yyyy"
            openTo="year"
            // mask="__/__/____"
            // disableMaskedInput={false}
            minDate={minDate as Date}
            maxDate={maxDate as Date}
            shouldDisableDate={(date) => {
              return !includes(
                map(originalValueColumns, 'field'),
                format(new Date(date), 'M/d/yyyy')
              );
            }}
          />
        </LocalizationProvider>
      );
    }
    if (isYearlyTab) {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker<Date>
            label="Start Date"
            views={['year']}
            value={state.startDateFilter as Date}
            onChange={(newValue) => {
              setState({
                startDateFilter: newValue || '',
              });
            }}
            openTo="year"
            format="yyyy"
            minDate={minDate as Date}
            maxDate={maxDate as Date}
          />
        </LocalizationProvider>
      );
    }
  }, [selectedInput?.TabName, originalValueColumns, state.startDateFilter]);

  const endFilter = useMemo(() => {
    const formatDate = (date: Date) => format(date, 'M/d/yyyy');

    if (isQuarterlyTab) {
      return (
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          onChange={(e) => {
            setState({
              endDateFilter: e.target.value,
            });
          }}
          select
          disabled={!state.startDateFilter}
          value={state.endDateFilter}
          label="End Date"
          error={startIndex > endIndex}
        >
          {map(originalValueColumns, (value, idx) => (
            <MenuItem
              key={value.field}
              value={value.field}
              disabled={startIndex > idx}
            >
              {value.headerName}
            </MenuItem>
          ))}
        </TextField>
      );
    }
    if (isOtherAssumptionsTab) {
      return (
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          onChange={(e) => {
            setState({
              endDateFilter: e.target.value,
            });
          }}
          select
          disabled={!state.startDateFilter}
          value={state.endDateFilter}
          label="End"
          error={startIndex > endIndex}
        >
          {map(originalValueColumns, (value, idx) => (
            <MenuItem
              key={value.field}
              value={value.field}
              disabled={startIndex > idx}
            >
              {value.headerName}
            </MenuItem>
          ))}
        </TextField>
      );
    }
    if (isWeeklyTab) {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker<Date>
            label="End Date"
            views={['year', 'month', 'day']}
            minDate={minDate as Date}
            maxDate={maxDate as Date}
            value={state.endDateFilter as Date}
            onChange={(newValue) => {
              if (newValue instanceof Date && !isNaN(newValue.valueOf())) {
                setState({
                  endDateFilter: newValue || '',
                });
              }
            }}
            format="M/d/yyyy"
            shouldDisableMonth={(month) => {
              const selectedYear = getYear(month);
              const selectedMonth = getMonth(month);
              const startMonth = getMonth(state.startDateFilter as Date);
              return (
                selectedYear <= startFilterYear && selectedMonth < startMonth
              );
            }}
            shouldDisableYear={(year) =>
              startFilterYear > getYear(new Date(year))
            }
            shouldDisableDate={(date) => {
              const dateFormat = formatDate(date);
              return (
                !includes(map(originalValueColumns, 'field'), dateFormat) ||
                startIndex >
                  findIndex(originalValueColumns, { field: dateFormat })
              );
            }}
          />
        </LocalizationProvider>
      );
    }
    if (isMonthlyTab) {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker<Date>
            label="End Date"
            views={['year', 'month']}
            minDate={minDate as Date}
            maxDate={maxDate as Date}
            value={state.endDateFilter as Date}
            onChange={(newValue) => {
              if (newValue instanceof Date && !isNaN(newValue.valueOf())) {
                setState({
                  endDateFilter: newValue || '',
                });
              }
            }}
            format="M/d/yyyy"
            openTo="year"
            // mask="__/__/____"
            // disableMaskedInput={false}
            shouldDisableMonth={(month) => {
              return isBefore(new Date(month), new Date(state.startDateFilter));
            }}
            shouldDisableYear={(year) =>
              startFilterYear > getYear(new Date(year))
            }
            shouldDisableDate={(date) => {
              const dateFormat = formatDate(date);
              return (
                !includes(map(originalValueColumns, 'field'), dateFormat) ||
                startIndex >
                  findIndex(originalValueColumns, { field: dateFormat })
              );
            }}
          />
        </LocalizationProvider>
      );
    }
    if (isYearlyTab) {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker<Date>
            label="End Date"
            views={['year']}
            minDate={minDate as Date}
            maxDate={maxDate as Date}
            value={state.endDateFilter as Date}
            onChange={(newValue) => {
              setState({
                endDateFilter: newValue || '',
              });
            }}
            openTo="year"
            shouldDisableYear={(year) =>
              startIndex >
              findIndex(originalValueColumns, { field: format(year, 'yyyy') })
            }
          />
        </LocalizationProvider>
      );
    }
  }, [
    selectedInput?.TabName,
    originalValueColumns,
    state.startDateFilter,
    state.endDateFilter,
  ]);

  const showClearButton = useCallback(() => {
    if (isQuarterlyTab || isOtherAssumptionsTab) {
      return (
        isEqual(state.startDateFilter, minDate) &&
        isEqual(state.endDateFilter, maxDate)
      );
    }
    return (
      isEqual(state.startDateFilter, minDate) &&
      isEqual(state.endDateFilter, maxDate)
    );
  }, [
    state.startDateFilter,
    state.endDateFilter,
    selectedInput?.TabName,
    minDate,
    maxDate,
  ]);

  return (
    <Box>
      <ClearAllEditsDialog
        open={state.displayClearAllEditsModal}
        onClose={() =>
          setState({
            displayClearAllEditsModal: false,
          })
        }
        onReset={() => {
          setState({
            rowData: structuredClone(state.originalRowData),
            columnDefs: getColumns(true),
            displayClearAllEditsModal: false,
            showOriginalValues: false,
          });
          sessionStorage.removeItem(
            `gridState-inputName-${inputName}-modified`
          );
          resetRowData(inputName);
        }}
      />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Grid md={3}>
          <Typography variant="h6">
            {selectedInput?.Name}{' '}
            <StyledPopper
              useContentPadding={false}
              content={() => (
                <List dense>
                  <ListItem dense>
                    <ListItemText>
                      <strong>Category: </strong> {selectedInput?.InputCategory}
                    </ListItemText>
                  </ListItem>
                  <ListItem dense>
                    <ListItemText>
                      <strong>Tab: </strong> {selectedInput?.TabName}
                    </ListItemText>
                  </ListItem>
                </List>
              )}
              id="1"
            >
              <InfoOutlined color="primary" fontSize="small" />
            </StyledPopper>
          </Typography>
        </Grid>
        {showDatePickers && !isEmpty(state.columnDefs) && (
          <Grid md={6}>
            <Grid container alignItems="center" spacing={1}>
              <Grid sm={0.5}>
                {!showClearButton() ? (
                  <FilterAlt color="primary" titleAccess="Filter applied" />
                ) : (
                  <FilterAltOff
                    sx={{ color: '#C6C6C6' }}
                    titleAccess="No filters applied"
                  />
                )}
              </Grid>
              <Grid sm={4.5} sx={{ alignItems: 'center' }}>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  {startFilter}
                </Stack>
              </Grid>
              <Grid sm={4.5} sx={{ alignItems: 'center' }}>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  {endFilter}
                </Stack>
              </Grid>
              {!showClearButton() && (
                <Grid sm={1}>
                  <Tooltip arrow title="Clear">
                    <IconButton onClick={clearTimeFilter}>
                      <Close />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        <Grid md={3}>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <FormControlLabel
              control={
                <Switch
                  checked={state.showOriginalValues}
                  onChange={(e, value) => {
                    (gridRef.current as AgGridReact).api.redrawRows();
                    (gridRef.current as AgGridReact).api.refreshCells();
                    (gridRef.current as AgGridReact).api.setColumnDefs(
                      getColumns(!value)
                    );
                    if (!value) {
                      if (
                        sessionStorage[
                          `gridState-inputName-${inputName}-modified`
                        ]
                      ) {
                        (gridRef.current as AgGridReact).api.setRowData(
                          JSON.parse(
                            sessionStorage[
                              `gridState-inputName-${inputName}-modified`
                            ]
                          )
                        );
                        (gridRef.current as AgGridReact).api.setColumnDefs(
                          (
                            gridRef.current as AgGridReact
                          ).api.getColumnDefs() as (ColDef | ColGroupDef)[]
                        );
                        (gridRef.current as AgGridReact).api.redrawRows();
                      }
                      if (
                        sessionStorage[`filterState-inputName-${inputName}`]
                      ) {
                        (gridRef.current as AgGridReact).api.setFilterModel(
                          JSON.parse(
                            sessionStorage[`filterState-inputName-${inputName}`]
                          )
                        );
                      }
                    } else {
                      (gridRef.current as AgGridReact).api.setRowData(
                        state.originalRowData
                      );
                      if (
                        sessionStorage[`filterState-inputName-${inputName}`]
                      ) {
                        (gridRef.current as AgGridReact).api.setFilterModel(
                          JSON.parse(
                            sessionStorage[`filterState-inputName-${inputName}`]
                          )
                        );
                      }
                    }
                    setState({
                      showOriginalValues: value,
                      columnDefs: getColumns(!value),
                    });
                  }}
                />
              }
              label="Show Original Values"
            />
            <Button
              variant="text"
              onClick={() => {
                setState({
                  displayClearAllEditsModal: true,
                });
              }}
              disabled={
                !get(
                  find(modifiedRowData, {
                    Input: inputName,
                  }) as IModifiedRowData,
                  'isDirty',
                  false
                )
              }
            >
              Clear All Edits
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {state.showOriginalValues ? (
        <Table<(typeof state.rowData)[0]>
          gridRef={gridRef}
          isLoading={false}
          isSuccess
          rowCount={size(state.originalRowData)}
          rowData={state.originalRowData}
          pagination={false}
          columnDefs={state.columnDefs}
          defaultColDef={{
            cellStyle,
          }}
          suppressMenuHide
          enableRangeSelection
          suppressMultiRangeSelection
          onGridReady={onOriginalGridReady}
          onFilterChanged={onFilterChanged}
          suppressRowVirtualisation
          getRowId={(data) => {
            return (data as any).data.Input_RowID;
          }}
          components={{
            editedCellRenderer: EditedCellsRenderer,
            valuesCellRenderer: (params: ICellRendererParams) => (
              <ValuesCellRenderer
                {...params}
                formatKey={getFormatKey(params).formatKey}
              />
            ),
          }}
        />
      ) : (
        <Table<(typeof state.rowData)[0]>
          isLoading={false}
          gridRef={gridRef}
          suppressScrollOnNewData
          isSuccess
          onGridReady={onGridReady}
          rowCount={size(state.rowData)}
          onFirstDataRendered={onFirstDataRendered}
          rowData={state.rowData}
          pagination={false}
          columnDefs={state.columnDefs}
          onCellValueChanged={onCellValueChanged}
          getRowId={(data) => {
            return (data as any).data.Input_RowID;
          }}
          enableRangeSelection
          suppressMultiRangeSelection
          gridOptions={{
            suppressLastEmptyLineOnPaste: true,
            processCellFromClipboard: function (params: any) {
              var formateKeyType;
              formateKeyType = find(formatParameters, {
                formatKey:
                  selectedInput?.TabName === 'Uptake & Event Assumptions'
                    ? getFormatKey(params.column).formatKey
                    : getFormatKey(params).formatKey,
              });
              // Validating if the paste operation is happening in the restricted column
              if (formateKeyType && formateKeyType.numberType === 'Date') {
                // Prevent pasting into the restricted column
                console.log(
                  'Pasting into restricted column not allowed in column:',
                  params.column.getColId()
                );
                const colId = params.column.getColId();
                return params.node.data[colId]; // Return same old value to prevent the paste operation and retain the existing value.
              } else {
                return params.value; // Allow pasting into other columns
              }
            },
          }}
          suppressMenuHide
          defaultColDef={{
            cellStyle,
          }}
          onFilterChanged={onFilterChanged}
          onRowDataUpdated={onRowDataUpdated}
          stopEditingWhenCellsLoseFocus
          getMainMenuItems={getMainMenuItems}
          suppressRowVirtualisation
          components={{
            editedCellRenderer: EditedCellsRenderer,
            valuesCellRenderer: (params: ICellRendererParams) => (
              <ValuesCellRenderer
                {...params}
                formatKey={getFormatKey(params).formatKey}
              />
            ),
          }}
        />
      )}
    </Box>
  );
};

export default InputTabPanel;
