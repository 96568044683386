import React, { FunctionComponent } from 'react';
import { Draggable } from 'react-beautiful-dnd';

interface DraggableTabProps {
  value: string;
  index: number;
  child: JSX.Element;
}

const DraggableTab: FunctionComponent<DraggableTabProps> = (props) => {
  return (
    <Draggable
      draggableId={`${props.index}`}
      index={props.index}
      disableInteractiveElementBlocking
    >
      {(draggableProvided) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
        >
          {React.cloneElement(props.child, {
            ...props,
            ...draggableProvided.dragHandleProps,
          })}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableTab;
