import { grey } from '@mui/material/colors';
import React from 'react';

import Flex from '../../shared/Flex';

const Placeholder = () => (
  <Flex
    minHeight="70vh"
    flex="auto"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    children="No scenario selected yet"
    sx={{
      border: `solid 1px ${grey[300]}`,
      color: (t) => t.palette.common.disabled,
    }}
  />
);

export default Placeholder;
