import React, { FunctionComponent, useEffect } from 'react';
import {
  filter,
  map,
  some,
  isEmpty,
  sortBy,
  differenceBy,
  includes,
  toString,
} from 'lodash';
import { Alert, Box, Divider, Typography } from '@mui/material';

import {
  GeneratedFile,
  ModelInstance,
  Scenario,
  ScenarioArchiveFlag,
  Upload,
} from '../../types/models';

import * as API from '../../services/API';

import useSetState from '../../hooks/useSetState';

import PopupDialog from '../shared/PopupDialog';

import Flex from '../shared/Flex';

import ScenariosMultiSelect from '../Visualization/ScenariosMultiSelect';

import CenteredSpinner from '../shared/CenteredSpinner';

import { GENERATED_FILES_URL } from './IOConsolidationConstants';

interface RefreshReportValidateFileDialogProps {
  isOpen: boolean;
  onClose: () => void;
  instanceId: number | undefined;
  toBeRefreshedScenarios: Scenario[];
  refreshedScenarios: Scenario[] | number[];
  handlePanelChange: (
    panelNumber: number,
    refreshedScenarios: Scenario[] | number[],
    toBeRefreshedScenarios: Scenario[]
  ) => void;
  upload: Pick<Upload, 'id' | 'FileName'> | null;
  handleGeneratedFileId: (generatedFileId: number) => void;
  scenarios: Scenario[];
  refreshedModelInstanceName?: ModelInstance['Name'];
}

interface RefreshReportValidateFileDialogState {
  selectedScenarios: Scenario[];
  scenarioNotPresentInList: Scenario[] | number[];
  scenarioType: string;
  refreshedScenarios: Scenario[] | number[];
  invalidSelectedScenarios: boolean;
}

const initialRefreshReportValidateFileDialogState: RefreshReportValidateFileDialogState =
  {
    selectedScenarios: [],
    scenarioNotPresentInList: [],
    scenarioType: toString(ScenarioArchiveFlag.Current),
    refreshedScenarios: [],
    invalidSelectedScenarios: false,
  };

const RefreshReportValidateFileDialog: FunctionComponent<
  RefreshReportValidateFileDialogProps
> = ({
  isOpen,
  onClose,
  instanceId,
  handlePanelChange,
  toBeRefreshedScenarios,
  upload,
  handleGeneratedFileId,
  refreshedScenarios,
  scenarios,
  refreshedModelInstanceName,
}) => {
  const [state, setState] = useSetState<RefreshReportValidateFileDialogState>(
    initialRefreshReportValidateFileDialogState
  );

  useEffect(() => {
    const scenariosList = filter(scenarios, (scenario: Scenario) =>
      includes(refreshedScenarios as number[], scenario.id)
    );

    const scenarioNotPresentInList = filter(
      refreshedScenarios,
      (refreshedScenario) =>
        !some(
          scenariosList,
          (scenario: Scenario) => refreshedScenario === scenario.id
        )
    );
    setState({
      selectedScenarios: !isEmpty(toBeRefreshedScenarios)
        ? toBeRefreshedScenarios
        : differenceBy(scenariosList, scenarioNotPresentInList, 'id'),
      refreshedScenarios,
      scenarioNotPresentInList: isEmpty(scenariosList)
        ? refreshedScenarios
        : filter(scenarios, (scenario: Scenario) =>
            includes(scenarioNotPresentInList, scenario.id)
          ),
    });
  }, [toBeRefreshedScenarios, refreshedScenarios]);

  useEffect(() => {
    if (!isEmpty(state.selectedScenarios)) {
      setState({ invalidSelectedScenarios: false });
    }
  }, [state.selectedScenarios]);

  const handleScenarioChange = (scenario: Scenario[]) => {
    setState({ selectedScenarios: sortBy(scenario, ['Name']) });
  };

  const onBack = () => {
    handlePanelChange(1, state.refreshedScenarios, []);
  };

  const onRefresh = async () => {
    if (isEmpty(state.selectedScenarios)) {
      setState({ invalidSelectedScenarios: true });
      return;
    }
    const selectedScenarioIds = sortBy(map(state.selectedScenarios, 'id'));
    const generatedFile = await API.create<GeneratedFile>(
      GENERATED_FILES_URL.standardReport.refresh,
      {
        ModelInstanceID: instanceId,
        ScenarioIDs: selectedScenarioIds,
        UploadID: upload && upload.id,
        FileName: upload && upload.FileName,
      }
    );
    handleGeneratedFileId(generatedFile.id);
    handlePanelChange(3, [], []);
  };

  const scenariosByType: Scenario[] = sortBy(
    filter(
      scenarios || [],
      (s) => s.Archive_Flag === Number(state.scenarioType)
    ),
    ['Name']
  );

  const closeModal = () => {
    onClose();
    setState(initialRefreshReportValidateFileDialogState);
  };

  return (
    <PopupDialog
      open={isOpen}
      close={closeModal}
      title="Refresh an Existing Report"
      closeButtonLabel="Back"
      closeButtonClick={onBack}
      primaryButtons={[
        {
          id: 'cancel',
          label: 'Cancel',
          onClick: closeModal,
        },
        {
          id: 'refresh',
          label: 'Refresh',
          onClick: onRefresh,
          disabled: isEmpty(scenarios),
        },
      ]}
    >
      {state.invalidSelectedScenarios && (
        <Flex alignItems="center" as="label" mt={2}>
          <Alert severity="error">
            You cannot refresh a file without selecting any scenarios. Please
            select some scenarios to refresh the file.
          </Alert>
        </Flex>
      )}
      {!isEmpty(refreshedScenarios) && (
        <Flex alignItems="center" as="label" mt={2}>
          <Alert severity="info">
            This report was last refreshed using{' '}
            <u>{refreshedModelInstanceName}</u>{' '}
            {refreshedModelInstanceName ? 'with' : ''} data for{' '}
            <u>{refreshedScenarios.length} scenarios.</u>
          </Alert>
        </Flex>
      )}
      {!isEmpty(state.scenarioNotPresentInList) && (
        <Flex alignItems="center" as="label" mt={2}>
          <Alert severity="warning">
            There {state.scenarioNotPresentInList.length === 1 ? 'is' : 'are'}{' '}
            {state.scenarioNotPresentInList.length} scenario(s) present in the
            report but not in the scenario list. Only the scenarios present in
            the scenario list can be added to refresh the file.
          </Alert>
        </Flex>
      )}
      {isEmpty(state.refreshedScenarios) ? (
        <Typography>
          The report has never been refreshed – please select the number of
          scenarios that should be included in the report.
        </Typography>
      ) : (
        <Typography>
          Changing this may break formulas in the workbook.
        </Typography>
      )}
      <Divider sx={{ my: 2 }} />
      <Flex alignItems="center">
        <Box flex="auto">
          <Typography variant="h6">Select scenario(s)</Typography>
        </Box>
      </Flex>
      {isEmpty(scenarios) ? (
        <CenteredSpinner />
      ) : scenariosByType.length > 0 ? (
        <Flex mt={2}>
          <ScenariosMultiSelect
            scenariosList={scenariosByType}
            handleScenarioChange={handleScenarioChange}
            preSelectedScenarios={state.selectedScenarios}
            modelInstanceId={instanceId}
            size="small"
          />
        </Flex>
      ) : (
        <Typography>
          This model instance has no scenarios of this type
        </Typography>
      )}
    </PopupDialog>
  );
};

export default RefreshReportValidateFileDialog;
