import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import {
  find,
  groupBy,
  includes,
  map,
  mapValues,
  toString,
  isEmpty,
} from 'lodash';
import { format } from 'date-fns';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  alpha,
  Paper,
} from '@mui/material';
import { renderToString } from 'react-dom/server';
import { useTheme } from '@mui/material/styles';

import { Timescale } from '../../../../../types/models';
import { truncationValues } from '../../../../Visualization/visualizationConstants';
import {
  formatParameters,
  IFormatOutputParameters,
} from '../../../../Visualization/formatParameters';

import { IChartData, IChartDataSeries } from '../index';

highchartsAccessibility(Highcharts);

window.Buffer = window.Buffer || require('buffer').Buffer;

Highcharts.AST.allowedTags.push('Box');
Highcharts.AST.allowedAttributes.push('data-html2canvas-ignore');

export const colors = [
  '#27aae1',
  '#9c27b0',
  '#e91e63',
  '#43a047',
  '#0d47a1',
  '#ba68c8',
  '#ff9800',
  '#00bfa5',
  '#304ffe',
  '#613aa5',
  '#ffcea3',
  '#cddc39',
  '#607d8b',
  '#cc79a7',
  '#795548',
];

interface ILineChartProps extends HighchartsReact.Props {
  chartTitle: string;
  yAxisTitle: string;
  prefix: string;
  suffix: string;
  formatKey: number;
  truncation: number;
  decimalPlaces: number;
  data: IChartData;
  timeScaleType: Timescale;
  dateFormat: string;
  isPanelCollapsed: boolean;
  showScenarios: boolean;
  selectedChartDataType: string;
  groupedChartData: (IChartDataSeries & { color: string; dataType: string })[];
}

const LineChart: FunctionComponent<ILineChartProps> = ({
  chartTitle,
  yAxisTitle,
  prefix,
  suffix,
  formatKey,
  truncation,
  decimalPlaces,
  data,
  timeScaleType,
  dateFormat,
  isPanelCollapsed,
  showScenarios,
  selectedChartDataType,
  groupedChartData,
}) => {
  const theme = useTheme();
  const [options, setOptions] = useState<Highcharts.Options>({
    title: {
      text: '',
    },
  });
  // const [selectAllLegends, setSelectAllLegends] = useState<boolean>(true);
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const formatType = useMemo(
    () =>
      (
        find(formatParameters, {
          formatKey,
        }) as IFormatOutputParameters
      ).numberType,
    [formatKey]
  );

  useEffect(() => {
    setOptions({
      chart: {
        zooming: {
          type: 'x',
          resetButton: {
            position: {
              align: 'left', // by default
              verticalAlign: 'top', // by default
              x: -10,
              y: 10,
            },
          },
        },
        type: 'line',
        height: '500px',
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      subtitle: {
        text: '',
      },
      yAxis: {
        title: {
          useHTML: true,
          text: `<div style="font-size: 13px">${yAxisTitle}</div>`,
        },
        labels: {
          formatter: function () {
            const self = this;
            return `${prefix}${Highcharts.numberFormat(
              (formatType === 'Percent'
                ? (self.value as number) * 100
                : (self.value as number)) /
                truncationValues[toString(truncation)],
              decimalPlaces,
              '.'
            )
              .toString()
              .replaceAll(' ', '')
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${suffix}`;
          },
          style: {
            fontSize: '13px',
          },
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: map(data.Dates, (item) =>
          includes([Timescale.Weekly, Timescale.Monthly], timeScaleType)
            ? format(new Date(item.Date), dateFormat)
            : item.Date
        ),
        labels: {
          style: {
            fontSize: '13px',
          },
        },
      },
      plotOptions: {
        series: {
          boostThreshold: 2000,
          label: {
            enabled: false,
            connectorAllowed: false,
          },
          marker: {
            enabled: data.Dates.length === 1,
          },
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          const series = this.series.chart.series as any[];
          const point = this.point;
          const groupedScenario = groupBy(series, 'userOptions.scenario');
          const chartData = mapValues(groupedScenario, (itemByScenario) => {
            return map(itemByScenario, (serie) => {
              return {
                name: serie.name,
                color: serie.color,
                dataType: serie.userOptions.dataType,
                value: `${prefix}${Highcharts.numberFormat(
                  (formatType === 'Percent'
                    ? Number(serie.data[point.index].y) * 100
                    : Number(serie.data[point.index].y)) /
                    truncationValues[toString(truncation)],
                  decimalPlaces,
                  '.'
                )
                  .toString()
                  .replaceAll(' ', '')
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${suffix}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                ),
                pointElement: serie.data[point.index],
              };
            });
          });
          const groupedDataByName: Record<string, any[]> = {};
          for (const key in chartData) {
            const groupedData: Record<string, any> = {};
            const dataArray = chartData[key];
            for (const item of dataArray) {
              if (!groupedData[item.name]) {
                groupedData[item.name] = {
                  name: item.name,
                  color: item.color,
                  pointElement: item.pointElement,
                  preview: 0,
                  published: 0,
                };
              }
              groupedData[item.name][item.dataType] = item.value;
            }
            groupedDataByName[key] = Object.values(groupedData);
          }

          const mappedData = map(groupedDataByName, (v, k) => {
            return (
              <Box key={k}>
                <b>{k}</b>
                <TableContainer>
                  <Table size="small" aria-label="chart tooltip table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Segment</TableCell>
                        {!showScenarios &&
                          !isEmpty(data.PreviewData) &&
                          selectedChartDataType !== 'published' && (
                            <TableCell>Recalculated Data</TableCell>
                          )}
                        {selectedChartDataType !== 'preview' && (
                          <TableCell>Original Data</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map(v, (serie) => {
                        return (
                          <TableRow
                            key={point.index}
                            style={{
                              backgroundColor:
                                (point.series.userOptions as any).scenario ===
                                  k && point.series.name === serie.name
                                  ? alpha(serie.color, 0.6)
                                  : 'transparent',
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <span>{serie.name}</span>
                            </TableCell>
                            {!showScenarios &&
                              !isEmpty(data.PreviewData) &&
                              selectedChartDataType !== 'published' && (
                                <TableCell>{serie.preview}</TableCell>
                              )}
                            {selectedChartDataType !== 'preview' && (
                              <TableCell>{serie.published}</TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            );
          });
          return renderToString(
            <Box component={Paper} elevation={24}>
              <b>{this.x}</b>
              <Box>{mappedData}</Box>
            </Box>
          );
        },
      },
      series: map(groupedChartData, (item) => {
        return {
          name: item.segment,
          data: structuredClone(item.values),
          type: 'line',
          id: toString(item.id),
          lineWidth: 3,
          color: item.color,
          scenario: item.scenario,
          visible: item.visible,
          dashStyle: item.dataType === 'preview' ? 'ShortDash' : 'Solid',
          dataType: item.dataType,
        };
      }),
    });
  }, [
    chartTitle,
    yAxisTitle,
    prefix,
    suffix,
    formatKey,
    truncation,
    decimalPlaces,
    data,
    timeScaleType,
    dateFormat,
    selectedChartDataType,
    groupedChartData,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (chartRef.current && chartRef.current?.chart) {
        (chartRef.current as HighchartsReact.RefObject).chart.reflow();
      }
    }, theme.transitions.duration.leavingScreen);
  }, [isPanelCollapsed, chartRef.current]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartRef}
      immutable
    />
  );
};

export default LineChart;
