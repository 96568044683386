import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';
import { format } from 'date-fns';
import { ICellEditorParams } from 'ag-grid-community';
import { Input } from '@mui/material';

import { IFormatParameters } from '../../../Visualization/formatParameters';

export default forwardRef(
  (props: ICellEditorParams & { formatKey: IFormatParameters }, ref) => {
    const [month, date, year] = props.value
      ? props.value.split('/')
      : [null, null, null];

    const [selectedDate, setSelectedDate] = useState<string>(
      props.value && year && month && date
        ? `${year}-${month.length === 1 ? `0${month}` : month}-${
            date.length === 1 ? `0${date}` : date
          }`
        : ''
    );

    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
      inputRef && inputRef.current && inputRef.current?.focus();
      inputRef && inputRef.current && inputRef.current?.showPicker();
    }, []);

    function handleDateChange(d: string | null) {
      setSelectedDate(d || '');
    }

    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          let dateString = '';
          if (selectedDate) {
            dateString = format(
              new Date(
                new Date(selectedDate).valueOf() +
                  new Date(selectedDate).getTimezoneOffset() * 60 * 1000
              ),
              'M/d/yyyy'
            );
          }
          return dateString || '';
        },
        isCancelAfterEnd: () => {
          return false;
        },
        afterGuiAttached: () => {
          if (!props.value) {
            return;
          }
          const [, day, month, year] = props.value.match(
            /(\d{2})\/(\d{2})\/(\d{4})/
          );
          let selectedDate = new Date(year, month - 1, day);
          setSelectedDate(format(selectedDate, 'yyyy-MM-dd'));
        },
      };
    });

    return (
      <Input
        inputRef={inputRef}
        fullWidth
        type="date"
        value={selectedDate || ''}
        onChange={(e) => {
          handleDateChange(e.target.value);
        }}
      />
    );
  }
);
