import { unique } from '../../utils/misc';

interface ResetAction {
  type: 'RESET';
}

interface ToggleScenarioAction {
  type: 'TOGGLE_SCENARIO';
  id: number;
  value: boolean;
}

interface ToggleOutputAction {
  type: 'TOGGLE_OUTPUT';
  id: number;
  value: boolean;
}

interface ToggleOutputsAction {
  type: 'TOGGLE_OUTPUTS';
  ids: number[];
  value: boolean;
}

interface ToggleInputAction {
  type: 'TOGGLE_INPUT';
  id: number;
  value: boolean;
}

interface ToggleInputsAction {
  type: 'TOGGLE_INPUTS';
  ids: number[];
  value: boolean;
}

interface ToggleTimescaleAction {
  type: 'TOGGLE_TIMESCALE';
  timescale: string;
  value: boolean;
}

interface ToggleDimensionAction {
  type: 'TOGGLE_DIMENSION_BLANKS';
  id: number;
  value: boolean;
}

interface ToggleDimensionInstanceAction {
  type: 'TOGGLE_DIMENSION_INSTANCE';
  id: number;
  value: boolean;
}

interface ResetDimensionAction {
  type: 'RESET_DIMENSION';
  id: number;
  instanceIds: number[];
}

type Action =
  | ResetAction
  | ToggleScenarioAction
  | ToggleOutputAction
  | ToggleOutputsAction
  | ToggleInputAction
  | ToggleInputsAction
  | ToggleTimescaleAction
  | ToggleDimensionAction
  | ToggleDimensionInstanceAction
  | ResetDimensionAction;

export interface State {
  scenarios: number[];
  outputs: number[];
  inputs: number[];
  timescales: string[];
  dimensionInstances: number[];
  dimensionBlanks: number[];
}

export type Dispatch = (action: Action) => void;

export const INITIAL_STATE: State = {
  scenarios: [],
  outputs: [],
  inputs: [],
  timescales: [],
  dimensionInstances: [],
  dimensionBlanks: [],
};

function toggle<T>(list: T[], item: T, value: boolean) {
  if (value) {
    return [item].concat(list).filter(unique).sort();
  } else {
    return list.filter((i) => i !== item);
  }
}

function toggleAll<T>(list: T[], items: T[], value: boolean) {
  if (value) {
    return ([] as T[]).concat(list).concat(items).filter(unique).sort();
  } else {
    return list.filter((i) => !items.includes(i));
  }
}

export function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'RESET': {
      return INITIAL_STATE;
    }
    case 'TOGGLE_SCENARIO': {
      return {
        ...state,
        scenarios: toggle(state.scenarios, action.id, action.value),
      };
    }
    case 'TOGGLE_OUTPUT': {
      return {
        ...state,
        outputs: toggle(state.outputs, action.id, action.value),
      };
    }
    case 'TOGGLE_OUTPUTS': {
      return {
        ...state,
        outputs: toggleAll(state.outputs, action.ids, action.value),
      };
    }
    case 'TOGGLE_INPUT': {
      return {
        ...state,
        inputs: toggle(state.inputs, action.id, action.value),
      };
    }
    case 'TOGGLE_INPUTS': {
      return {
        ...state,
        inputs: toggleAll(state.inputs, action.ids, action.value),
      };
    }
    case 'TOGGLE_TIMESCALE': {
      return {
        ...state,
        timescales: toggle(state.timescales, action.timescale, action.value),
      };
    }
    case 'TOGGLE_DIMENSION_INSTANCE': {
      return {
        ...state,
        dimensionInstances: toggle(
          state.dimensionInstances,
          action.id,
          action.value
        ),
      };
    }
    case 'TOGGLE_DIMENSION_BLANKS': {
      return {
        ...state,
        dimensionBlanks: toggle(state.dimensionBlanks, action.id, action.value),
      };
    }
    case 'RESET_DIMENSION': {
      return {
        ...state,
        dimensionBlanks: toggle(state.dimensionBlanks, action.id, false),
        dimensionInstances: toggleAll(
          state.dimensionInstances,
          action.instanceIds,
          false
        ),
      };
    }
    default:
      throw new Error(`Unknown action`);
  }
}
