import React, { createRef, FunctionComponent, useEffect } from 'react';
import { trimStart } from 'lodash';

import useSetState from '../../hooks/useSetState';
import ClearableTextField from '../shared/ClearableTextField';
import PopupDialog from '../shared/PopupDialog';

interface IEditChartTitleDialogProps {
  open: boolean;
  close: () => void;
  onSave: (editedChartTitle: string) => void;
  chartTitle: string;
}

interface IEditChartTitleDialogState {
  editedChartTitle: string;
  onFocusedInput: string | undefined;
}

const initialState: IEditChartTitleDialogState = {
  editedChartTitle: '',
  onFocusedInput: undefined,
};

const EditChartTitleDialog: FunctionComponent<IEditChartTitleDialogProps> = ({
  open,
  close,
  onSave,
  chartTitle,
}: IEditChartTitleDialogProps) => {
  const chartTitleInputRef = createRef<HTMLInputElement>();

  const [state, setState] =
    useSetState<IEditChartTitleDialogState>(initialState);

  useEffect(() => {
    if (open) {
      setState({
        editedChartTitle: chartTitle,
      });
    }
  }, [chartTitle, open]);

  return (
    <PopupDialog
      title="Edit Chart Title"
      open={open}
      close={() => {
        close();
        setState({
          editedChartTitle: '',
        });
      }}
      primaryButtons={[
        {
          id: 'saveButton',
          label: 'Save',
          onClick: () => {
            close();
            onSave(state.editedChartTitle);
            setState({
              editedChartTitle: '',
            });
          },
        },
      ]}
    >
      <ClearableTextField
        label="New Title"
        id="editedChartTitle"
        inputRef={chartTitleInputRef}
        value={state.editedChartTitle}
        onHandleChange={(value) =>
          setState({
            editedChartTitle: trimStart(value),
          })
        }
        onFocus={() => {
          setState({
            onFocusedInput: 'editedChartTitle',
          });
        }}
        onBlur={() => {
          setState({
            onFocusedInput: undefined,
          });
        }}
      />
    </PopupDialog>
  );
};

export default EditChartTitleDialog;
