import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ExcelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 384 512" xmlSpace="preserve" {...props}>
      <path
        d="M64 0C28.7 0 0 28.7 0 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zm192 0v128h128L256 0zM155.7 250.2l36.3 51.9 36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9 10.8 7.6 13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4l46.3-66.2-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.5 5.8z"
        style={{
          fill: '#128945',
        }}
      />
    </SvgIcon>
  );
};

export default ExcelIcon;
