import React, { FunctionComponent } from 'react';
import { map } from 'lodash';
import { Box, Breadcrumbs, Button, Link, Typography } from '@mui/material';
import { KeyboardBackspace } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export interface IBreadcrumb {
  text: React.ReactNode;
  url?: string;
  onClick?: () => void;
}

interface IBreadcrumbsProps {
  crumbs: IBreadcrumb[];
}

const NavBreadCrumbs: FunctionComponent<IBreadcrumbsProps> = ({ crumbs }) => {
  const navigate = useNavigate();

  return (
    <Box role="presentation">
      <Breadcrumbs aria-label="breadcrumb" separator="|">
        {map(crumbs, (crumb, index, arr) => {
          return (
            <Box key={`crumb-${index}`}>
              {crumb.url ? (
                <Link
                  sx={{ textTransform: 'none' }}
                  component={Button}
                  onClick={() => {
                    if (crumb.url) {
                      navigate(crumb.url);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  <Typography>
                    {index === 0 && <KeyboardBackspace />}
                    {crumb.text}
                  </Typography>
                </Link>
              ) : (
                <Typography>{crumb.text}</Typography>
              )}
            </Box>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default NavBreadCrumbs;
