import React, { FunctionComponent } from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { Box, Typography } from '@mui/material';

interface INonIdealStateProps {
  height?: number;
  /** The name of a Blueprint icon or a JSX Element (such as `<Spinner/>`) to render above the title. */
  icon?: JSX.Element;

  /**
   * How large the icon visual should be.
   *
   */
  iconSize?: SvgIconProps['fontSize'] | number;

  /** The title of the non-ideal state. */
  title?: React.ReactNode;
  description?: React.ReactNode;
  action?: JSX.Element;
  iconFontSize?: number;
}

const NonIdealState: FunctionComponent<INonIdealStateProps> = ({
  icon,
  iconSize = 20,
  title,
  description,
  action,
  iconFontSize,
  height = 50,
}) => {
  const maybeRenderVisual = () => {
    if (icon == null) {
      return undefined;
    } else {
      return React.cloneElement(icon, {
        sx: {
          fontSize: iconFontSize ? `${iconFontSize}rem` : '4rem',
          color: '#9DA9B3',
        },
      });
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: `${height}vh`,
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        background: '#f5f8fa',
      }}
    >
      {maybeRenderVisual()}
      <Typography variant="subtitle1">{title}</Typography>
      {description && <Typography>{description}</Typography>}
      <Box sx={{ my: 2 }}>{action}</Box>
    </Box>
  );
};

export default NonIdealState;
