import React, { FunctionComponent } from 'react';
import { map } from 'lodash';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from '@mui/icons-material';
import { isEqual } from 'lodash';

import { Autocomplete, Checkbox, TextField, Typography } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { ModelInstance, RowIdentifier } from '../../types/models';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IOutputsMultiSelectProps {
  /**
   * List of outputs to be populated
   */
  outputsList: RowIdentifier[];
  /**
   * List of outputs selected
   */
  preSelectedOutputs?: RowIdentifier[];
  /**
   * The onChange handler
   * @param output
   */
  handleOutputChange: (output: RowIdentifier[]) => void;
  /**
   * The modelInstance
   * This component will be disabled until a modelInstance is passed in
   */
  modelInstance?: ModelInstance;
  size?: 'small' | 'medium';
  hasError?: boolean;
}

const OutputsMultiSelect: FunctionComponent<IOutputsMultiSelectProps> = ({
  outputsList,
  preSelectedOutputs,
  handleOutputChange,
  modelInstance,
  size = 'medium',
  hasError,
}) => {
  return (
    <Autocomplete
      multiple
      value={preSelectedOutputs}
      onChange={(event, value) => handleOutputChange(value)}
      disableCloseOnSelect
      options={outputsList || []}
      getOptionLabel={(option) => option.Name}
      isOptionEqualToValue={(option, value) => isEqual(option, value)}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option.Name, inputValue, {
          insideWords: true,
          findAllOccurrences: true,
        });
        const parts = parse(option.Name, matches);
        return (
          <Typography component="li" {...props} noWrap>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {map(parts, (part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                }}
              >
                {part.text}
              </span>
            ))}
          </Typography>
        );
      }}
      disabled={modelInstance === undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select outputs"
          fullWidth
          size={size}
          error={hasError}
        />
      )}
    />
  );
};

export default OutputsMultiSelect;
