import React, { FunctionComponent } from 'react';

import { format, parseISO } from 'date-fns';
import { Typography } from '@mui/material';

interface Props {
  object: { updated_at: string; updated_by?: { Display_Name: string } };
}

const LastUpdated: FunctionComponent<Props> = ({ object }) => {
  const updatedAt = parseISO(object.updated_at);
  const updatedBy = object.updated_by
    ? object.updated_by.Display_Name
    : 'unknown';

  return (
    <Typography variant="caption">
      Updated on {format(updatedAt, 'MMM. d, yyyy')} at{' '}
      {format(updatedAt, 'hh:mm a')} by {updatedBy}
    </Typography>
  );
};

export default LastUpdated;
