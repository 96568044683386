import React, { ChangeEvent, FunctionComponent } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { map } from 'lodash';

import { ModelInstance, Timescale } from '../../types/models';
import { getTimescalesForModelInstance } from '../../utils/timescales';

interface ISelectTimescaleProps {
  modelInstance?: ModelInstance;
  onChange: (timescale: Timescale) => void;
  size?: 'small' | 'medium';
  timescale?: Timescale;
}

const SelectTimescale: FunctionComponent<ISelectTimescaleProps> = ({
  modelInstance,
  onChange,
  size = 'medium',
  timescale,
}: ISelectTimescaleProps) => {
  const handleItemSelect = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value as Timescale);
  };

  const timescales: Timescale[] = modelInstance
    ? getTimescalesForModelInstance(modelInstance as ModelInstance)
    : [];

  let disabled: boolean = false;

  if (modelInstance === undefined) {
    disabled = true;
  } else if (timescales.length < 1) {
    disabled = true;
  }

  return (
    <TextField
      select
      fullWidth
      label="Select a Timescale"
      size={size}
      disabled={disabled}
      id="select-timescale"
      value={timescale === undefined ? '' : timescale}
      onChange={handleItemSelect}
    >
      {map(timescales, (t) => (
        <MenuItem key={t} value={t}>
          {t}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectTimescale;
