import React, { FunctionComponent } from 'react';
import { map, sortBy, uniq, uniqueId } from 'lodash';
import { format } from 'date-fns';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { Edit } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';

import UpdateReportNameDialog from '../UpdateReportNameDialog';

import UpdateReportDescriptionDialog from '../UpdateReportDescriptionDialog';

import useSetState from '../../../hooks/useSetState';

import { IPortfolioReportDetailsProps } from './index';

type IReportPropertiesProps = Pick<
  IPortfolioReportDetailsProps,
  'selectedReport' | 'refreshReports' | 'clientId'
> & {
  refreshReport: () => void;
};

interface IReportPropertiesState {
  isRenameReportDialogOpen: boolean;
  isRenameReportDescriptionDialogOpen: boolean;
}

const initialReportPropertiesState: IReportPropertiesState = {
  isRenameReportDialogOpen: false,
  isRenameReportDescriptionDialogOpen: false,
};

const descriptionStyle: React.CSSProperties = {
  wordBreak: 'break-word',
};

const ReportProperties: FunctionComponent<IReportPropertiesProps> = (
  props: IReportPropertiesProps
) => {
  const [state, setState] = useSetState<IReportPropertiesState>(
    initialReportPropertiesState
  );

  const handleOpenRenameReport = (): void => {
    setState({
      isRenameReportDialogOpen: true,
    });
  };

  const handleOpenRenameReportDescription = (): void => {
    setState({ isRenameReportDescriptionDialogOpen: true });
  };

  const handleCloseRenameReport = (): void => {
    setState({
      isRenameReportDialogOpen: false,
    });
  };

  const handleCloseRenameReportDescription = (): void => {
    setState({
      isRenameReportDescriptionDialogOpen: false,
    });
  };

  return (
    <>
      <UpdateReportNameDialog
        isOpen={state.isRenameReportDialogOpen}
        onClose={handleCloseRenameReport}
        refreshReports={props.refreshReports}
        refreshReport={props.refreshReport}
        reportName={props.selectedReport!.Name}
        reportData={props.selectedReport}
        clientId={props.clientId}
      />
      {state.isRenameReportDescriptionDialogOpen && (
        <UpdateReportDescriptionDialog
          isOpen={state.isRenameReportDescriptionDialogOpen}
          onClose={handleCloseRenameReportDescription}
          refreshReports={props.refreshReports}
          reportDescription={props.selectedReport!.Description}
          reportData={props.selectedReport}
          clientId={props.clientId}
          refreshReport={props.refreshReport}
        />
      )}
      <Grid container sx={{ mb: 2 }}>
        <Grid md={2}>
          <strong>Name: </strong>
        </Grid>
        <Grid md={10}>
          {props.selectedReport?.Name}{' '}
          <IconButton
            size="small"
            title="Edit Report Name"
            onClick={handleOpenRenameReport}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 2 }}>
        <Grid md={2}>
          <strong>Description: </strong>
        </Grid>
        <Grid md={10} style={descriptionStyle}>
          {props.selectedReport?.Description}{' '}
          <IconButton
            title="Edit Report Description"
            size="small"
            onClick={handleOpenRenameReportDescription}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 2 }}>
        <Grid md={2}>
          <strong>Models: </strong>
        </Grid>
        <Grid md={10}>
          <List>
            {map(sortBy(uniq(props.selectedReport?.Models)), (model) => (
              <ListItem disablePadding key={uniqueId()}>
                <ListItemText>{model}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 2 }}>
        <Grid md={2}>
          <strong>Last Updated: </strong>
        </Grid>
        <Grid md={10}>
          {format(
            new Date(props.selectedReport?.updated_at as string),
            'MM/dd/yyyy hh:mm:ss a'
          )}
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 2 }}>
        <Grid md={2}>
          <strong>Last Edited By: </strong>
        </Grid>
        <Grid md={10}>
          <u style={{ color: blue[500] }}>
            <Tooltip
              arrow
              title={props.selectedReport?.updated_by.User_Name as string}
            >
              <Typography sx={{ width: 'fit-content' }}>
                {props.selectedReport?.updated_by.Display_Name}
              </Typography>
            </Tooltip>
          </u>
        </Grid>
      </Grid>
    </>
  );
};

export default ReportProperties;
