import React, { FunctionComponent } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { includes, map } from 'lodash';

import {
  Autocomplete,
  TextField,
  Checkbox,
  ListItem,
  createFilterOptions,
  Box,
} from '@mui/material';

import {
  CheckBoxOutlineBlank,
  CheckBox as CheckBoxIcon,
} from '@mui/icons-material';

import { User } from '../../../../types/models';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IUsersSearchMultiSelectProps {
  /**
   * List of users to be populated
   */
  usersList: User[];
  /**
   * List of users selected
   */
  preSelectedUsers?: User[];
  /**
   * The onChange handler
   * Need to use the callback with handleChangeMultiSelect in misc.ts with the first parameter being the callback value
   * and the second parameter being the state value to get updated
   * @param user
   */
  handleUsersChange: (user: User[]) => void;
  large?: boolean;
  hasError?: boolean;
  nonRemovableIds: User['id'][];
}

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: User) => option.Display_Name + option.User_Name,
});

const UsersSearchMultiSelect: FunctionComponent<
  IUsersSearchMultiSelectProps
> = ({ usersList, preSelectedUsers, handleUsersChange, nonRemovableIds }) => {
  return (
    <Autocomplete
      size="small"
      multiple
      value={preSelectedUsers}
      renderInput={(params) => <TextField {...params} label="Select users" />}
      onChange={(e, value) => handleUsersChange(value)}
      options={usersList}
      disableCloseOnSelect
      getOptionLabel={(option) => option.Display_Name}
      filterOptions={filterOptions}
      renderOption={(props, user, { selected, inputValue }) => {
        const matches = match(user.Display_Name, inputValue);
        const parts = parse(user.Display_Name, matches);
        return (
          <ListItem {...props} secondaryAction={user.User_Name}>
            <Checkbox
              disabled={includes(nonRemovableIds, user.id)}
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <Box>
              {map(parts, (part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </Box>
          </ListItem>
        );
      }}
    />
  );
};

export default UsersSearchMultiSelect;
