import React, { PureComponent } from 'react';
import { filter, map } from 'lodash';
import Grid from '@mui/material/Unstable_Grid2';

import { Client } from '../../types/models';
import { isModelMemberInClient } from '../../utils/profile';

import { ProfileProps, withProfile } from './AuthController';
import ClientCard from './ClientCard';
import Loader, { LoaderInfo } from './Loader';

interface OwnProps {
  onSelect: (id: number) => void;
}

type Props = OwnProps & ProfileProps;

class ClientIndex extends PureComponent<Props> {
  private needs = { clients: `/clients` };

  public render() {
    return (
      <Loader
        needs={this.needs}
        handleErrors={true}
        handleLoading={true}
        render={this.renderContents}
      />
    );
  }

  private renderContents = ({ data }: LoaderInfo<{ clients: Client[] }>) => {
    const { profile } = this.props;

    const clients = filter(
      data.clients || [],
      (client) =>
        profile !== undefined && isModelMemberInClient(profile, client.id)
    ).sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));

    return (
      <Grid container spacing={2}>
        {map(clients, (client) => (
          <Grid xs={12} md={3} key={client.id}>
            <ClientCard client={client} onClick={this.props.onSelect} />
          </Grid>
        ))}
      </Grid>
    );
  };
}

export default withProfile(ClientIndex);
