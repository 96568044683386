import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import {
  differenceBy,
  intersectionBy,
  isEmpty,
  map,
  reject,
  toLower,
  uniqueId,
} from 'lodash';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  IconButton,
  Link,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Close, Delete, InsertDriveFile } from '@mui/icons-material';

import { enqueueSnackbar } from 'notistack';

import NonIdealState from '../shared/NonIdealState';
import PopupDialog from '../shared/PopupDialog';
import * as API from '../../services/API';

import useSetState from '../../hooks/useSetState';
import OverflowTooltip from '../shared/OverflowTooltip';

import { getFormattedFileSize } from '../../utils/misc';

import { uploadSingleFileToAzure } from '../../services/AzureUpload';
import LinearProgressBar from '../shared/LinearProgressBar';

import { IPortfolioReportStateData } from './index';

interface ReportTemplateDownloadDialogProps {
  open: boolean;
  close: () => void;
  data: IPortfolioReportStateData;
  refresh: () => void;
}

interface ReportTemplateDownloadDialogState {
  displayRejectedFilesCallout: boolean;
  showRejectedFilesList: boolean;
  isBusyState: boolean;
  totalBytes: number;
  loadedBytes: number;
}

const ReportTemplateDownloadDialog: FunctionComponent<
  ReportTemplateDownloadDialogProps
> = ({ open, close, data, refresh }) => {
  const [fileList, setFileList] = useState<FileWithPath[]>([]);
  const [fileRejectionsList, setFileRejectionsList] = useState<FileRejection[]>(
    []
  );
  const [duplicateFilesList, setDuplicateFilesList] = useState<FileWithPath[]>(
    []
  );

  const [state, setState] = useSetState<ReportTemplateDownloadDialogState>({
    displayRejectedFilesCallout: false,
    showRejectedFilesList: false,
    isBusyState: false,
    totalBytes: 0,
    loadedBytes: 0,
  });

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
      const duplicateFiles = intersectionBy(acceptedFiles, fileList, (i) =>
        toLower(i.name)
      );
      const difference = differenceBy(acceptedFiles, duplicateFiles, (i) =>
        toLower(i.name)
      );
      const acceptedFilesList = !isEmpty(difference) ? difference : [];
      if (!isEmpty(duplicateFiles)) {
        setDuplicateFilesList([...duplicateFilesList, ...duplicateFiles]);
      }
      const files = [...acceptedFilesList];
      setFileList(files);
      setFileRejectionsList((oldState) => [...oldState, ...fileRejections]);
    },
    [fileList, duplicateFilesList]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
    },
    maxFiles: 1,
    onDrop,
  });

  useEffect(() => {
    if (!isEmpty(fileRejectionsList)) {
      setState({ displayRejectedFilesCallout: true });
    }
  }, [fileRejectionsList]);

  const removeFile = (file: FileWithPath): void => {
    setFileList((oldStateVal) => reject(oldStateVal, { name: file.name }));
  };

  const deleteFile = async () => {
    await API.update(
      `/clients/${data.ClientID}/portfolio_reports/${data.id}/portfolio_template_delete`,
      {}
    );
    enqueueSnackbar('Report template deleted successfully', {
      variant: 'success',
    });
    close();
    refresh();
  };

  const handleProgress = ({ loadedBytes }: { loadedBytes: number }) => {
    setState({ loadedBytes });
  };

  const uploadFile = async () => {
    const result: { BlobURL: string } = await API.create(
      `/clients/${data.ClientID}/portfolio_reports/${data.id}/portfolio_template_upload`,
      { filename: fileList[0].name }
    );
    if (result) {
      setState({
        isBusyState: true,
      });
      await uploadSingleFileToAzure(result, fileList[0], {
        onProgress: handleProgress,
      });
      await API.create(
        `/clients/${data.ClientID}/portfolio_reports/${data.id}/portfolio_template_upload_confirm`,
        { filename: fileList[0].name }
      );
      setState({
        isBusyState: false,
        loadedBytes: 0,
      });
      enqueueSnackbar('Report template uploaded successfully', {
        variant: 'success',
      });
      setFileList([]);
      refresh();
      close();
    }
  };

  if (open) {
    return (
      <PopupDialog
        open={open}
        close={close}
        title="Upload Report Template"
        maxWidth="md"
        primaryButtons={[
          {
            id: 'uploadFile',
            label: 'Upload',
            disabled: isEmpty(fileList),
            onClick: () => uploadFile(),
          },
        ]}
      >
        {state.isBusyState ? (
          <Box py={1}>
            <LinearProgressBar
              color={
                state.loadedBytes === state.totalBytes ? 'success' : 'primary'
              }
              value={
                state.loadedBytes && state.totalBytes
                  ? state.loadedBytes / state.totalBytes
                  : 0
              }
            />
          </Box>
        ) : (
          <>
            <Button component={Link} href={data.ReportTemplateURL}>
              Download Default Template
            </Button>
            <Box p={2} my={1} style={{ border: `2px dashed ${grey[500]}` }}>
              <Box
                {...getRootProps({ className: 'dropzone' })}
                sx={{ cursor: 'pointer', textAlign: 'center' }}
              >
                <input {...getInputProps()} accept=".xlsm" />
                <NonIdealState
                  height={20}
                  icon={<InsertDriveFile />}
                  title="Drop file here or click to upload"
                  description={
                    <Typography variant="body2">
                      Only *.xlsm files will be accepted
                    </Typography>
                  }
                />
              </Box>
            </Box>
            {!isEmpty(fileRejectionsList) &&
              state.displayRejectedFilesCallout && (
                <Alert
                  severity="warning"
                  action={
                    <IconButton
                      onClick={() => {
                        setState({ displayRejectedFilesCallout: false });
                        setFileRejectionsList([]);
                      }}
                    >
                      <Close />
                    </IconButton>
                  }
                >
                  <AlertTitle>Rejected file(s)</AlertTitle>
                  <Box>
                    The following file(s) could not be uploaded because they do
                    not have a ‘.xlsm’ extension.
                  </Box>
                  <Button
                    onClick={() =>
                      setState({
                        showRejectedFilesList: !state.showRejectedFilesList,
                      })
                    }
                  >
                    {state.showRejectedFilesList
                      ? 'Hide File(s)'
                      : 'Show File(s)'}
                  </Button>
                  <Collapse in={state.showRejectedFilesList}>
                    <Box
                      sx={{
                        width: '100%',
                        maxHeight: 300,
                        bgcolor: 'background.paper',
                      }}
                    >
                      <List>
                        {map(fileRejectionsList, (item) => {
                          return (
                            <ListItem key={uniqueId()}>
                              {item.file.name}
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  </Collapse>
                </Alert>
              )}
            {!isEmpty(fileList) ? (
              <Box>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>File Name</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {fileList[0].name}
                        </TableCell>
                        <TableCell>
                          <OverflowTooltip
                            value={getFormattedFileSize(fileList[0].size)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() =>
                              removeFile(fileList[0] as FileWithPath)
                            }
                            color="error"
                            sx={{ textOverflow: 'initial' }}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              <Box>
                {data.CustomDownloadBlobName && (
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>File Name</TableCell>
                          <TableCell> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {data.CustomDownloadBlobName}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={deleteFile}
                              color="error"
                              sx={{ textOverflow: 'initial' }}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            )}
          </>
        )}
      </PopupDialog>
    );
  }
  return null;
};

export default ReportTemplateDownloadDialog;
