import React, { useContext } from 'react';

import { IOutputsPanelState } from './OutputsPanel';

import { IModifiedRowData } from './index';

export interface EditAssumptionsContext {
  assumptionsData: IModifiedRowData[];
  outputsChartData: IOutputsPanelState | undefined;
  handleAssumptionsDataChange: (data: IModifiedRowData) => void;
  handleChartDataChange: (data: IOutputsPanelState) => void;
  handleOutputsPreview: () => void;
  disableRecalculateButton: () => void;
}

const defaultContextValues: EditAssumptionsContext = {
  assumptionsData: [],
  outputsChartData: undefined,
  handleAssumptionsDataChange: () => {},
  handleChartDataChange: () => {},
  handleOutputsPreview: () => {},
  disableRecalculateButton: () => {},
};

export const EditAssumptionsDetailContext =
  React.createContext<EditAssumptionsContext | null>(null);

export function useEditAssumptions(
  isContextUsed: boolean
): EditAssumptionsContext {
  const context = useContext(EditAssumptionsDetailContext);

  if (!context && isContextUsed) {
    throw new Error(
      'useEditAssumptions must be used within a EditAssumptionsDetailContext Provider'
    );
  }

  return context || defaultContextValues;
}
