import React, { ChangeEvent, PureComponent } from 'react';
import { isFunction } from 'lodash';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { CloudDownload } from '@mui/icons-material';

import { getFileExtension } from '../../utils/misc';
import UploadFile from '../shared/UploadFile';
import { UploadedFile } from '../../types/models';

interface Props {
  isFileAdded?: Function;
  file: File | null;
  getUploadedFileDetails(upload: any): void;
  hasFileExtensionError(bool: Boolean): void;
  hasFileUploadError(error: string | null): void;
  disabled: boolean;
}

interface State {
  fileTypeError: boolean;
  isBusy: boolean;
  file: File | null;
}

const INITIAL_STATE = {
  isBusy: false,
  file: null,
  fileTypeError: false,
};

class ReportOptionsExcelRefresh extends PureComponent<Props, State> {
  static defaultProps: Props;

  public state: State = INITIAL_STATE;

  public render() {
    const { isBusy, file } = this.state;
    return (
      <Box sx={{ width: '100%' }}>
        {!file && (
          <Grid container alignItems="center" sx={{ mt: 2, width: '100%' }}>
            <Grid container justifyContent="flex-end" sx={{ width: '100%' }}>
              <input
                type="file"
                id="uploadFile"
                accept=".xlsm"
                onChange={this.handleFileChange}
              />
              <Button
                component="label"
                size="small"
                variant="contained"
                htmlFor="uploadFile"
              >
                Select file
              </Button>
            </Grid>
          </Grid>
        )}
        {file && (
          <Box mt={1} sx={{ width: '100%' }}>
            <UploadFile
              isBusy={isBusy}
              fileExtension={'.xlsm'}
              handleCancelClick={this.handleCancelClick}
              uploadButtonText={'Refresh Report'}
              uploadIcon={<CloudDownload />}
              fileUploadData={this.handleReportRefresh}
              uploadComment="Upload for report refresh"
              file={file}
              handleFileChange={this.handleFileChange}
              disabled={this.props.disabled}
            />
          </Box>
        )}
      </Box>
    );
  }

  public componentDidMount() {
    this.setState({
      file: this.props.file,
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (prevState.file !== this.state.file) {
      isFunction(this.props.isFileAdded) &&
        this.props.isFileAdded(this.state.file);
    }

    if (prevProps.file !== this.props.file) {
      this.setState({
        file: this.props.file,
      });
    }
  }

  private handleCancelClick = () => {
    const { hasFileUploadError, hasFileExtensionError } = this.props;
    hasFileUploadError(null);
    hasFileExtensionError(false);
    this.setState(INITIAL_STATE);
  };

  private handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (getFileExtension(files) === 'xlsm') {
      this.props.hasFileExtensionError(false);
      this.setState({ file: files && files[0], fileTypeError: false });
    } else {
      this.props.hasFileExtensionError(true);
      this.setState({ fileTypeError: true, file: null });
    }
  };

  private handleReportRefresh = async (uploadData: UploadedFile) => {
    const { getUploadedFileDetails, hasFileUploadError } = this.props;

    if (uploadData.error && uploadData.fileUploadData.Status === -1) {
      hasFileUploadError(uploadData.fileUploadData.Status_Message as string);
      return;
    }
    hasFileUploadError(null);
    if (uploadData.file === null) {
      return;
    }
    this.setState({ isBusy: true });
    if (uploadData.fileUploadData) {
      getUploadedFileDetails(uploadData);
      this.setState({ isBusy: false });
    }
  };
}

export default ReportOptionsExcelRefresh;
