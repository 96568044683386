import React, { FunctionComponent, useEffect } from 'react';
import { range, some, map, compact, isEmpty } from 'lodash';

import {
  Box,
  Button,
  Chip,
  Typography,
  IconButton,
  Select,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  ArrowBackIos,
  ArrowRightAlt,
  CalendarMonth,
  ArrowForwardIos,
} from '@mui/icons-material';

import useSetState from '../../../../../hooks/useSetState';
import StyledPopper from '../../../../shared/StyledPopper';
import { IChartDataDate } from '../index';
import { XRefDate } from '../../../../../types/models';

const monthNames: string[] = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

interface IMonthSelectorProps
  extends Pick<IMonthRangePickerProps, 'startYear' | 'endYear' | 'dates'> {
  month: number;
  year: number;
  onSelectYear: (val: any) => void;
  title: string;
  minDate?: { month: number; year: number };
}

interface IMonthSelectorState {
  yearState: number;
  monthState: number;
}

const MonthSelector: FunctionComponent<IMonthSelectorProps> = ({
  month,
  year,
  onSelectYear,
  startYear,
  endYear,
  dates,
  title,
  minDate,
}: IMonthSelectorProps) => {
  const [state, setState] = useSetState<IMonthSelectorState>({
    yearState: year,
    monthState: month,
  });

  useEffect(() => {
    onSelectYear({
      month: state.monthState,
      year: state.yearState,
    });
  }, [state]);

  const styles: Record<string, React.CSSProperties> = {
    yearNav: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 1px 5px',
      justifyContent: 'center',
    },
    yearButton: {
      flex: '1',
      textAlign: 'center',
      margin: '0 2px',
    },
    yearButtonText: {
      verticalAlign: 'middle',
      fontWeight: 'bold',
    },
    monthContainer: {
      display: 'inline-block',
      padding: '1px',
      boxSizing: 'border-box',
    },
    monthButton: {
      lineHeight: '3em',
      textAlign: 'center',
      width: '100%',
      minWidth: '4em',
    },
  };

  const yearsRange: number[] = range(startYear.year, endYear.year + 1);

  const onPrevYear = (): void => {
    setState({ yearState: state.yearState - 1 });
  };

  const onNextYear = (): void => {
    setState({ yearState: state.yearState + 1 });
  };

  const onSelect = (month: number): void => {
    setState({ monthState: month });
  };

  const renderMonth = (monthItem: number): JSX.Element => {
    const selected: boolean = month === monthItem && year === state.yearState;

    return (
      <Box
        className="month-container"
        style={styles.monthContainer}
        key={monthItem}
      >
        <Button
          size="small"
          variant={selected ? 'contained' : 'outlined'}
          sx={{ m: 0.25 }}
          onClick={() => onSelect(monthItem)}
          disabled={
            !some(dates, { Date: `${monthItem}/1/${state.yearState}` }) ||
            (minDate &&
              (state.yearState < minDate.year ||
                (state.yearState === minDate.year &&
                  monthItem < minDate.month)))
          }
        >
          {monthNames[monthItem]}
        </Button>
      </Box>
    );
  };

  const months: JSX.Element[] = [];
  for (let i = 1; i <= 12; i++) {
    months.push(renderMonth(i));
  }

  return (
    <Box>
      <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Box className="month-year-nav" style={styles.yearNav}>
        <IconButton
          size="small"
          onClick={onPrevYear}
          disabled={state.yearState <= startYear.year}
        >
          <ArrowBackIos fontSize="small" />
        </IconButton>
        <Select
          native
          size="small"
          value={state.yearState}
          onChange={(e) => {
            (e as any).stopPropagation();
            setState({ yearState: Number(e.target.value) });
          }}
        >
          {map(yearsRange, (yearItem) => (
            <option
              key={yearItem}
              value={yearItem}
              {...(minDate?.year && { disabled: yearItem < minDate?.year })}
            >
              {yearItem}
            </option>
          ))}
        </Select>
        <IconButton
          size="small"
          onClick={onNextYear}
          disabled={state.yearState >= endYear.year}
        >
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      </Box>
      <Box>{months}</Box>
    </Box>
  );
};

interface IMonthRangePickerProps {
  startYear: { month: number; year: number };
  endYear: { month: number; year: number };
  onChange: (
    startYear: { month: number; year: number },
    endYear: { month: number; year: number }
  ) => void;
  dates: IChartDataDate[] | XRefDate[];
  value: { month: number; year: number }[];
}

interface IMonthRangePickerState {
  startState: { month: number; year: number };
  endState: { month: number; year: number };
}

const MonthRangePicker: FunctionComponent<IMonthRangePickerProps> = ({
  startYear,
  endYear,
  onChange,
  dates,
  value,
}: IMonthRangePickerProps) => {
  const [state, setState] = useSetState<IMonthRangePickerState>({
    startState: startYear,
    endState: endYear,
  });

  useEffect(() => {
    if (!isEmpty(compact(value))) {
      setState({
        startState: value[0],
        endState: value[1],
      });
    }
  }, [value]);

  const onSetStart = (startState: { month: number; year: number }): void => {
    setState({ startState });
  };

  const onSetEnd = (endState: { month: number; year: number }): void => {
    setState({ endState });
  };

  const handleClose = (): void => {
    onChange(state.startState, state.endState);
  };

  return (
    <StyledPopper
      onClose={handleClose}
      content={() => (
        <Grid container spacing={2}>
          <Grid md={6}>
            <MonthSelector
              month={state.startState.month}
              year={state.startState.year}
              onSelectYear={onSetStart}
              startYear={startYear}
              endYear={endYear}
              dates={dates}
              title="Start Date"
            />
          </Grid>
          <Grid md={6}>
            <MonthSelector
              month={state.endState.month}
              year={state.endState.year}
              onSelectYear={onSetEnd}
              startYear={startYear}
              endYear={endYear}
              dates={dates}
              title="End Date"
              minDate={state.startState}
            />
          </Grid>
        </Grid>
      )}
      id="month-range-picker-popper"
    >
      <Button startIcon={<CalendarMonth />} variant="outlined" size="small">
        <Chip
          size="small"
          className="mr-1"
          label={`${monthNames[state.startState.month]}-${
            state.startState.year
          }`}
        />
        <ArrowRightAlt sx={{ color: '#c3ced5' }} fontSize="small" />
        <Chip
          size="small"
          className="ml-1"
          label={`${monthNames[state.endState.month]}-${state.endState.year}`}
        />
      </Button>
    </StyledPopper>
  );
};

export default MonthRangePicker;
