import React from 'react';

import { Navigate, Routes, Route } from 'react-router-dom';
import { map } from 'lodash';

import { useSnackbar } from 'notistack';

import { Container } from '@mui/material';

import EditAssumptionsDetail from '../ScenarioManager/EditAssumptionsDetail';

import OutputsPanel from '../ScenarioManager/EditAssumptionsDetail/OutputsPanel';

import RouteParams from './RouteParams';
import ProtectedRoute from './ProtectedRoute';
import Unauthorized from './Unauthorized';

const RoutesComponent = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleInvalidRoute = (path: string, label: string) => {
    enqueueSnackbar(
      `Your company admin for CloudCast has not given you permission to
                access the ${label} page. Please reach out to them
                if you think you should have access.`,
      { variant: 'error' }
    );
  };

  return (
    <>
      <Routes>
        {map(RouteParams().routes, (route) => {
          if (!route.protected) {
            return (
              <Route
                key={route.pathName}
                path={`${route.pathName}`}
                element={route.component}
              />
            );
          }
          return (
            <ProtectedRoute
              path={`${route.pathName}`}
              label={route.label}
              key={route.pathName}
              callback={handleInvalidRoute}
            />
          );
        })}
        <Route
          path="/scenario-manager/assumptions"
          element={<EditAssumptionsDetail />}
        />
        <Route
          path="/scenario-manager/assumptions/outputs"
          element={
            <Container maxWidth={false}>
              <OutputsPanel inContainer />
            </Container>
          }
        />
        <Route path="/unauthorized" element={<Unauthorized />} />

        <Route
          path="*"
          element={
            <Navigate to={RouteParams().scenarioManagerRoute.pathName} />
          }
        />
      </Routes>
    </>
  );
};

export default RoutesComponent;
