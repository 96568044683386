import React, { FunctionComponent, useEffect } from 'react';
import { range, head, last } from 'lodash';

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import {
  CalendarMonth,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';

import useSetState from '../../../hooks/useSetState';
import StyledPopper from '../../shared/StyledPopper';

const numberPerPage = 16;

interface IYearSelectorProps
  extends Pick<IYearRangePickerPickerProps, 'minYear' | 'maxYear'> {
  year?: number;
  onSelectYear: (year: number) => void;
}

interface IYearSelectorState {
  yearState?: number;
  currentPage: number;
  begin: number;
  end: number;
}

const YearSelector: FunctionComponent<IYearSelectorProps> = ({
  year,
  onSelectYear,
  minYear,
  maxYear,
}: IYearSelectorProps) => {
  const [state, setState] = useSetState<IYearSelectorState>({
    yearState: year,
    currentPage: 1,
    begin: 0,
    end: numberPerPage,
  });

  const styles: Record<string, React.CSSProperties> = {
    yearContainer: {
      display: 'inline-block',
      padding: '1px',
      boxSizing: 'border-box',
    },
    yearButton: {
      lineHeight: '3em',
      textAlign: 'center',
      width: '100%',
      minWidth: '4em',
    },
  };

  const yearsRange: number[] = range(minYear, maxYear + 1);

  const getNumberOfPages = (): number => {
    return Math.ceil(yearsRange.length / numberPerPage);
  };

  const onSelect = (year: number): void => {
    setState({ yearState: year });
    onSelectYear(year);
  };

  const renderYear = (year: number): JSX.Element => {
    const selected: boolean = year === state.yearState;

    return (
      <Box className="year-container" style={styles.yearContainer} key={year}>
        <Button
          size="small"
          variant={selected ? 'contained' : 'outlined'}
          onClick={() => onSelect(year)}
          sx={{ m: 0.25 }}
        >
          {year}
        </Button>
      </Box>
    );
  };

  useEffect(() => {
    setState({
      begin: (state.currentPage - 1) * numberPerPage,
      end: (state.currentPage - 1) * numberPerPage + numberPerPage,
    });
  }, [state.currentPage]);

  useEffect(() => {
    if (state.yearState) {
      const yearIndex = yearsRange.findIndex(
        (item) => item === state.yearState
      );
      setState({
        begin: yearIndex - (yearIndex % numberPerPage),
        end: yearIndex - (yearIndex % numberPerPage) + numberPerPage,
        currentPage: Math.floor(yearIndex / numberPerPage) + 1,
      });
    }
  }, [state.yearState]);

  const years: JSX.Element[] = [];
  for (let i = 0; i < yearsRange.length; i++) {
    years.push(renderYear(yearsRange[i]));
  }

  const onPrevYear = (): void => {
    setState({ currentPage: state.currentPage - 1 });
  };

  const onNextYear = (): void => {
    setState({ currentPage: state.currentPage + 1 });
  };

  const slicedYears: JSX.Element[] = years.slice(state.begin, state.end);

  return (
    <Box>
      <Stack
        style={styles.yearNav}
        direction="row"
        sx={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <IconButton
          size="small"
          onClick={onPrevYear}
          disabled={state.currentPage === 1}
        >
          <KeyboardArrowLeft fontSize="small" />
        </IconButton>
        <Typography>{`${(head(slicedYears) as any).key} - ${
          (last(slicedYears) as any).key
        }`}</Typography>
        <IconButton
          size="small"
          onClick={onNextYear}
          disabled={state.currentPage === getNumberOfPages()}
        >
          <KeyboardArrowRight fontSize="small" />
        </IconButton>
      </Stack>
      <Box className="years-grid">{slicedYears}</Box>
    </Box>
  );
};

interface IYearRangePickerPickerProps {
  minYear: number;
  maxYear: number;
  onChange: (selectedYear: number | undefined) => void;
  value?: string;
}

interface IYearRangePickerPickerState {
  selectedYear?: number;
  isPopOverOpen: boolean;
}

const YearRangePicker: FunctionComponent<IYearRangePickerPickerProps> = ({
  minYear,
  maxYear,
  onChange,
  value,
}: IYearRangePickerPickerProps) => {
  const [state, setState] = useSetState<IYearRangePickerPickerState>({
    selectedYear: value ? Number(value) : undefined,
    isPopOverOpen: false,
  });

  useEffect(() => {
    if (value) {
      setState({ selectedYear: Number(value) });
    }
  }, [value]);

  const onSelectYear = (year: number): void => {
    setState({
      selectedYear: year,
      isPopOverOpen: false,
    });
  };

  const handleClose = (): void => {
    onChange(state.selectedYear);
  };

  return (
    <StyledPopper
      id="year-picker-popper"
      content={() => (
        <YearSelector
          year={state.selectedYear}
          onSelectYear={onSelectYear}
          minYear={minYear}
          maxYear={maxYear}
        />
      )}
      onClose={handleClose}
    >
      <Button
        endIcon={<CalendarMonth />}
        fullWidth
        variant="contained"
        onClick={() => setState({ isPopOverOpen: true })}
      >
        {value === undefined ? 'Select a year' : state.selectedYear}
      </Button>
    </StyledPopper>
  );
};

export default YearRangePicker;
