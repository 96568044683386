import React, { FunctionComponent, useEffect, useContext } from 'react';
import { flatten, uniq, values, omit, sortBy } from 'lodash';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import ScenarioUpdateErrorCallout from '../ScenarioManager/ScenarioUpdateErrorCallout';

import * as API from '../../services/API';

import {
  GeneratedFile,
  ModelInstance,
  Scenario,
  SubmissionStatus,
  Upload,
  UploadedFile,
} from '../../types/models';

import {
  getDataAtIntervals,
  clearGetDataAtIntervals,
} from '../../utils/getDataAtIntervals';

import APICacheContext from '../shared/APICacheContext';

import PopupDialog from '../shared/PopupDialog';

import useSetState from '../../hooks/useSetState';

import LinearProgressBar from '../shared/LinearProgressBar';

import ReportOptionsExcelRefresh from './ReportOptionsExcelRefresh';
import { GENERATED_FILES_URL } from './IOConsolidationConstants';

interface RefreshReportUploadFileDialogProps {
  isOpen: boolean;
  onClose: () => void;
  instanceId: number | undefined;
  handlePanelChange: (
    panelNumber: number,
    refreshedScenarios: Scenario[] | number[],
    toBeRefreshedScenarios: Scenario[]
  ) => void;
  handleUpload: (
    upload: Pick<Upload, 'id' | 'FileName'>,
    scenariosList: Scenario[],
    refreshedModelInstanceName: ModelInstance['Name']
  ) => void;
}

interface RefreshReportUploadFileDialogState {
  file?: File | null;
  fileTypeError?: boolean;
  fileUploadError?: string | null;
  isBusy: boolean;
}

const initialState: RefreshReportUploadFileDialogState = {
  fileTypeError: false,
  file: null,
  fileUploadError: null,
  isBusy: false,
};

const RefreshReportUploadFileDialog: FunctionComponent<
  RefreshReportUploadFileDialogProps
> = ({ isOpen, onClose, instanceId, handlePanelChange, handleUpload }) => {
  const apiContext = useContext(APICacheContext);

  const [state, setState] =
    useSetState<RefreshReportUploadFileDialogState>(initialState);

  useEffect(() => {
    setState({ fileUploadError: null });
  }, [state.file]);

  const closeModal = () => {
    onClose();
    setState(initialState);
    clearGetDataAtIntervals();
  };

  const isFileAdded = (file: File) => {
    setState({ file });
  };

  const reportFileDetails = async (uploadData: UploadedFile) => {
    setState({ isBusy: true });
    const validatedFile = await API.create<GeneratedFile>(
      GENERATED_FILES_URL.standardReport.validate,
      {
        UploadID: uploadData.fileUploadData.id,
        FileName: uploadData.fileUploadData.FileName,
        ModelInstanceID: instanceId,
      }
    );
    if (validatedFile.Status === SubmissionStatus.Queued) {
      const uploadedFileData = await getDataAtIntervals(
        `/uploads/${validatedFile.id}`,
        'Status'
      );
      if (
        uploadedFileData &&
        uploadedFileData.Status === SubmissionStatus.Failed
      ) {
        setState({
          fileUploadError: uploadedFileData.Status_Message,
          isBusy: false,
        });
      } else if (
        uploadedFileData &&
        uploadedFileData.Status === SubmissionStatus.NoDataReturned
      ) {
        setState({
          fileUploadError: uploadedFileData.Status_Message,
          isBusy: false,
        });
      } else if (uploadedFileData.Status === SubmissionStatus.Finished) {
        const scenariosJSON = JSON.parse(uploadedFileData.Status_Message);
        const scenariosOnlyJSON = omit(scenariosJSON, ['ModelInstanceName']);

        const scenariosList = await apiContext!.load(
          [`/instances/${instanceId}/scenarios_load/finished`],
          true
        );

        const scenariosArray: number[] = flatten(values(scenariosOnlyJSON));

        const uniqueScenariosList = sortBy(uniq(scenariosArray));

        handleUpload(
          {
            id: validatedFile.id,
            FileName: validatedFile.FileName,
          },
          scenariosList[0],
          scenariosJSON['ModelInstanceName']
        );
        setState({ isBusy: false });
        handlePanelChange(2, uniqueScenariosList, []);
      }
    }
  };

  const handleFileExtensionError = (hasError: boolean) => {
    setState({ fileTypeError: hasError });
  };

  const handleFileUploadError = (error: string | null) => {
    setState({ fileUploadError: error });
  };

  return (
    <PopupDialog
      open={isOpen}
      close={closeModal}
      title="Refresh an Existing Report"
      hideDialogActions
    >
      {!state.isBusy && (
        <>
          {state.fileUploadError && (
            <Box mt={1}>
              <ScenarioUpdateErrorCallout
                error={Boolean(state.fileUploadError)}
                text={
                  state.fileUploadError
                    ? state.fileUploadError
                    : 'Report refresh failed, check your connection and try again.'
                }
              />
            </Box>
          )}
          {state.fileTypeError && (
            <Box mt={1}>
              <ScenarioUpdateErrorCallout
                error={state.fileTypeError}
                text="Please upload a file with a .xlsm extension"
              />
            </Box>
          )}
        </>
      )}
      <Grid container sx={{ width: '100%' }}>
        {state.isBusy ? (
          <Grid sm={12}>
            <Typography variant="h6">
              Loading Report Scenario Settings....
            </Typography>
            <Box my={2}>
              <LinearProgressBar color="success" />
            </Box>
          </Grid>
        ) : (
          <Grid
            container={!state.file}
            alignItems="end"
            justifyContent={!state.file ? 'flex-end' : 'space-between'}
            sx={{ width: '100%' }}
          >
            {!state.file && (
              <Grid>
                <Button variant="text" onClick={closeModal} sx={{ mr: 2 }}>
                  Cancel
                </Button>
              </Grid>
            )}
            <Grid container sx={state.file ? { width: '100%' } : {}}>
              <ReportOptionsExcelRefresh
                isFileAdded={isFileAdded}
                file={state.file}
                getUploadedFileDetails={reportFileDetails}
                hasFileExtensionError={handleFileExtensionError}
                hasFileUploadError={handleFileUploadError}
                disabled={state.isBusy}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </PopupDialog>
  );
};

export default RefreshReportUploadFileDialog;
