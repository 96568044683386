import { Box, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';

import CheckboxCard from './CheckboxCard';
import { FixedHeader } from './FixedHeader';

import { Dispatch } from './Store';

interface Props {
  timescales: string[];
  selection: string[];
  dispatch: Dispatch;
}

const TimescalesColumn: FunctionComponent<Props> = ({
  timescales,
  selection,
  dispatch,
}) => {
  return (
    <Box>
      <FixedHeader>
        <Typography variant="subtitle1" sx={{ mt: 1 }}>
          Timescales
        </Typography>
      </FixedHeader>
      <Box pb={2} />
      <Box style={{ paddingBottom: '4.5rem' }} />
      {timescales.map((timescale, index, array) => (
        <Box key={timescale}>
          <CheckboxCard
            checked={selection.indexOf(timescale) !== -1}
            onCardClick={(checked) =>
              dispatch({
                type: 'TOGGLE_TIMESCALE',
                timescale,
                value: checked,
              })
            }
            label={timescale}
          />
          {index < array.length - 1 && <Box pb={2} />}
        </Box>
      ))}
    </Box>
  );
};

export default TimescalesColumn;
