import React, { useContext } from 'react';
import { CardContent, Container } from '@mui/material';

import { ReactComponent as ScenarioManagerIcon } from '../../assets/images/scenario.svg';
import { ReactComponent as ExtractIcon } from '../../assets/images/extract.svg';
import { ReactComponent as ReportsIcon } from '../../assets/images/reports.svg';
import { ReactComponent as CustomChartIcon } from '../../assets/images/custom_chart.svg';
import { ReactComponent as AdminIcon } from '../../assets/images/admin.svg';
import { isTrinityUser } from '../../utils/user';
import { filterSearch } from '../../utils/misc';

import ScenarioManager from '../ScenarioManager';
import Extracts from '../Extracts';
import AdminTools from '../Admin';

import { Profile } from '../../types/models';

import CustomChart from '../CustomChart';

import Reports from '../Reports';

import { ProfileContext } from './AuthController';

export interface IRouteParams {
  pathName: string;
  search: string;
  label: string;
  component: JSX.Element;
  protected: boolean;
  exact: boolean;
  id: string;
  icon: JSX.Element;
}

const RouteParams = (
  search?: string
): { routes: IRouteParams[]; scenarioManagerRoute: IRouteParams } => {
  const profile = useContext(ProfileContext);
  if (profile === undefined) {
    throw Error('Profile is required to render NavigationBar');
  }

  const showForAdmin = isTrinityUser((profile as Profile).User);

  const scenarioManagerRoute: IRouteParams = {
    id: 'scenarioManager',
    pathName: '/scenario-manager',
    search: filterSearch(search, ['client', 'model', 'instance', 'scenario']),
    label: 'Scenario Manager',
    component: (
      <Container maxWidth={false} id="scenarioManager">
        <CardContent>
          <ScenarioManager />
        </CardContent>
      </Container>
    ),
    icon: <ScenarioManagerIcon />,
    protected: false,
    exact: true,
  };

  const routes: IRouteParams[] = [
    scenarioManagerRoute,
    {
      id: 'extract',
      pathName: '/extract',
      search: filterSearch(search, ['client', 'model', 'instance']),
      label: 'Extract',
      component: (
        <Container maxWidth={false} id="extract">
          <CardContent>
            <Extracts />
          </CardContent>
        </Container>
      ),
      protected: (profile as Profile).User.Extras_Flag === 1,
      exact: true,
      icon: <ExtractIcon />,
    },
    {
      id: 'reports',
      pathName: '/reports',
      search: filterSearch(search, ['client', 'model', 'instance']),
      label: 'Reports',
      component: (
        <Container maxWidth={false} id="reports">
          <CardContent>
            <Reports />
          </CardContent>
        </Container>
      ),
      protected: (profile as Profile).User.Extras_Flag === 1,
      exact: true,
      icon: <ReportsIcon />,
    },
    {
      id: 'customChart',
      pathName: '/customChart',
      search: filterSearch(search, ['client', 'model', 'instance']),
      label: 'Custom Chart ',
      component: (
        <Container maxWidth={false} id="customChart">
          <CardContent>
            <CustomChart />
          </CardContent>
        </Container>
      ),
      protected: (profile as Profile).User.Extras_Flag === 1,
      exact: false,
      icon: <CustomChartIcon />,
    },
  ];

  if (showForAdmin) {
    routes.push({
      id: 'admin',
      pathName: '/admin',
      search: filterSearch(search, ['client', 'model']),
      label: 'Admin Tools',
      component: (
        <Container maxWidth={false} id="admin">
          <CardContent>
            <AdminTools />
          </CardContent>
        </Container>
      ),
      protected: false,
      exact: true,
      icon: <AdminIcon />,
    });
  }

  return { routes, scenarioManagerRoute };
};

export default RouteParams;
