import { ModelInstance, Scenario } from '../types/models';

export const WEB_ASSUMPTIONS_POST = (
  instanceId: ModelInstance['id'],
  scenarioId: Scenario['id']
) => `/instances/${instanceId}/scenarios/${scenarioId}/web_assumptions_load`;

export const WEB_ASSUMPTIONS_LOAD = (
  instanceId: ModelInstance['id'],
  scenarioId: Scenario['id'],
  refreshId: number
) =>
  `/instances/${instanceId}/scenarios/${scenarioId}/web_assumptions/${refreshId}`;

export const WEB_ASSUMPTIONS_PUBLISH = (
  instanceId: ModelInstance['id'],
  scenarioId: Scenario['id']
) => `/instances/${instanceId}/scenarios/${scenarioId}/web_assumptions_publish`;

export const WEB_ASSUMPTIONS_SANDBOX_PREVIEW = (
  instanceId: ModelInstance['id'],
  scenarioId: Scenario['id']
) =>
  `/instances/${instanceId}/scenarios/${scenarioId}/web_assumptions_sandbox_refresh`;
