import React, { ChangeEvent, FunctionComponent } from 'react';
import {
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from '@mui/material';

interface IFileInputProps {
  file?: File | null;
  accept?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const FileInput: FunctionComponent<IFileInputProps> = ({
  handleChange,
  file,
  accept,
}) => {
  const renderFileInput = () => (
    <Button variant="text" component="label" size="small">
      Browse
      <input
        hidden
        accept={accept || '*'}
        multiple
        type="file"
        onChange={handleChange}
      />
    </Button>
  );

  return (
    <FormControl sx={{ width: '100%' }}>
      <Tooltip title={file && file.name} arrow>
        <OutlinedInput
          value={file && file.name}
          placeholder="Select a file"
          fullWidth
          onChange={handleChange}
          aria-describedby="file-upload-input"
          endAdornment={
            <InputAdornment position="end">{renderFileInput()}</InputAdornment>
          }
          size="small"
          sx={{
            '& .TrinityInputBase-input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
          readOnly
        />
      </Tooltip>
    </FormControl>
  );
};

export default FileInput;
