import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  reduce,
  map,
  size,
  toString,
  find,
  filter,
  includes,
  uniq,
  isEmpty,
  orderBy,
} from 'lodash';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import Highcharts from 'highcharts';
import { format } from 'date-fns';

import { RowNode } from 'ag-grid-enterprise';

import Grid from '@mui/material/Unstable_Grid2';

import {
  ColDef,
  GetMainMenuItemsParams,
  MenuItemDef,
  RowGroupOpenedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';

import Table from '../../../../shared/Table';

import { truncationValues } from '../../../../Visualization/visualizationConstants';
import {
  formatParameters,
  IFormatOutputParameters,
} from '../../../../Visualization/formatParameters';

import { useData } from '../../../../../hooks/useData';
import OverflowTooltip from '../../../../shared/OverflowTooltip';

import { IChartData } from '../index';

import {
  ModelInstance,
  RowIdentifier,
  Timescale,
} from '../../../../../types/models';

import { OutputsTableRef } from '../GenerateChartPanel';

interface IOutputsTableProps {
  timescale: Timescale;
  chartDateType: string;
  chartData: IChartData;
  formatKey: number;
  title: string;
  prefix: string;
  suffix: string;
  decimalPlaces: number;
  magnitudeTruncation: number;
  outputId?: RowIdentifier['id'];
  modelInstanceId?: ModelInstance['id'];
  showScenarios: boolean;
}

const OutputsTable = forwardRef<OutputsTableRef, IOutputsTableProps>(
  (
    {
      chartData,
      formatKey,
      title,
      prefix,
      suffix,
      magnitudeTruncation,
      decimalPlaces,
      outputId,
      modelInstanceId,
      chartDateType,
      timescale,
      showScenarios,
    },
    ref
  ) => {
    const [showOriginalData, setShowOriginalData] = useState(
      isEmpty(chartData.PreviewData)
    );
    const [rowData, setRowData] = useState<any[]>([]);
    const { data } = useData<{
      rowIdentifiers?: RowIdentifier[];
    }>(
      () => ({
        rowIdentifiers:
          modelInstanceId !== undefined
            ? `/instances/${modelInstanceId}/row_identifiers`
            : undefined,
      }),
      []
    );

    const outputs = useMemo(
      () => filter(data.rowIdentifiers, { Type: 'Output' }),
      [modelInstanceId, data]
    );
    const gridRef = useRef<AgGridReact>(null);

    const formatType = useMemo(
      () =>
        (
          find(formatParameters, {
            formatKey,
          }) as IFormatOutputParameters
        ).numberType,
      [formatKey]
    );

    const scenarios = uniq(map(chartData.PublishedData, 'scenario'));

    const autoGroupColumnDef = useMemo<ColDef>(() => {
      return {
        headerName: 'Scenario',
        cellRendererParams: {
          suppressCount: true,
          checkbox: false,
        },
        pinned: 'left',
      };
    }, []);

    const [columns, setColumns] = useState<ColDef[]>([
      {
        headerName: 'Segment',
        field: 'segment',
        pinned: 'left',
        cellRenderer: OverflowTooltip,
      },
      ...map(chartData.Dates, (date) => {
        return {
          headerName: includes([Timescale.Weekly, Timescale.Monthly], timescale)
            ? format(new Date(date.Date), chartDateType)
            : date.Date,
          field: date.Date,
          cellRenderer: (params: ValueFormatterParams) => {
            if (!(params.node as RowNode).group) {
              return `${prefix}${Highcharts.numberFormat(
                (formatType === 'Percent'
                  ? Number(params.value) * 100
                  : Number(params.value)) /
                  truncationValues[toString(magnitudeTruncation)],
                decimalPlaces,
                '.'
              )
                .toString()
                .replaceAll(' ', '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${suffix}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              );
            }
          },
        };
      }),
    ]);

    const exportColumns = useMemo(
      () => [
        {
          headerName: 'Segment',
          field: 'segment',
        },
        {
          headerName: 'Data Version',
          field: 'DataVersion',
        },
        ...map(chartData.Dates, (date) => {
          return {
            headerName: includes(
              [Timescale.Weekly, Timescale.Monthly],
              timescale
            )
              ? format(new Date(date.Date), chartDateType)
              : date.Date,
            field: date.Date,
            cellRenderer: (params: ValueFormatterParams) => {
              if (!(params.node as RowNode).group) {
                return `${prefix}${Highcharts.numberFormat(
                  (formatType === 'Percent'
                    ? Number(params.value) * 100
                    : Number(params.value)) /
                    truncationValues[toString(magnitudeTruncation)],
                  decimalPlaces,
                  '.'
                )
                  .toString()
                  .replaceAll(' ', '')
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${suffix}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                );
              }
            },
          };
        }),
      ],
      []
    );

    useEffect(() => {
      if (scenarios.length > 1) {
        setColumns((prevColumns) => {
          return [
            {
              field: 'scenario',
              rowGroup: true,
              hide: true,
              pinned: 'left',
            },
            ...prevColumns,
          ];
        });
      }
    }, []);

    useEffect(() => {
      setRowData(
        map(
          showOriginalData ? chartData.PublishedData : chartData.PreviewData,
          (series) => {
            return {
              scenario: series.scenario,
              segment: series.segment,
              ...reduce(
                chartData.Dates,
                (a: Record<string, number>, c, idx) => {
                  a[c.Date] = series.values[idx];
                  return a;
                },
                {}
              ),
            };
          }
        )
      );
    }, [chartData, showOriginalData]);

    const exportRowData = useMemo(() => {
      return orderBy(
        [
          ...map(chartData.PreviewData, (series) => {
            return {
              DataVersion: 'Recalculated',
              id: series.id,
              scenario: series.scenario,
              segment: series.segment,
              ...reduce(
                chartData.Dates,
                (a: Record<string, number>, c, idx) => {
                  a[c.Date] = series.values[idx];
                  return a;
                },
                {}
              ),
            };
          }),
          ...map(chartData.PublishedData, (series) => {
            return {
              DataVersion: 'Original',
              id: series.id,
              scenario: series.scenario,
              segment: series.segment,
              ...reduce(
                chartData.Dates,
                (a: Record<string, number>, c, idx) => {
                  a[c.Date] = series.values[idx];
                  return a;
                },
                {}
              ),
            };
          }),
        ],
        ['segment', 'id']
      );
    }, [chartData]);

    const exportToCSV = (): void => {
      gridRef.current!.api.exportDataAsCsv({
        fileName: `${title}-${format(new Date(), 'MM/dd/yyyy_HH:mm:ss')}`,
      });
    };

    const exportToExcel = (): void => {
      gridRef.current!.api.exportDataAsExcel({
        fileName: `${title}-${format(new Date(), 'MM/dd/yyyy_HH:mm:ss')}`,
        sheetName: find(outputs, {
          id: outputId,
        })?.Name,
        author: 'Trinity LifeSciences',
      });
    };

    useImperativeHandle(ref, () => ({
      exportToCSV,
      exportToExcel,
    }));

    function getMainMenuItems(
      params: GetMainMenuItemsParams
    ): (string | MenuItemDef)[] {
      const menuItems: (MenuItemDef | string)[] = [];
      const itemsToExclude = ['separator', 'pinSubMenu', 'valueAggSubMenu'];
      params.defaultItems.forEach((item) => {
        if (itemsToExclude.indexOf(item) < 0) {
          menuItems.push(item);
        }
      });
      return menuItems;
    }

    const onRowGroupOpened = (params: RowGroupOpenedEvent) => {
      params.columnApi.autoSizeAllColumns();
    };

    return (
      <Box sx={{ mt: 2 }}>
        {!showScenarios && !isEmpty(chartData.PreviewData) && (
          <Grid container justifyContent="flex-end">
            <FormControlLabel
              control={
                <Switch
                  checked={showOriginalData}
                  onChange={(e) => setShowOriginalData(e.target.checked)}
                />
              }
              label="Show Original Values"
            />
          </Grid>
        )}
        <Box sx={{ display: 'none' }}>
          <Table
            gridRef={gridRef}
            isLoading={false}
            isSuccess
            pagination={false}
            rowCount={size(rowData)}
            columnDefs={exportColumns}
            rowData={exportRowData}
          />
        </Box>
        <Table
          gridRef={gridRef}
          isLoading={false}
          isSuccess
          pagination={false}
          rowCount={size(rowData)}
          columnDefs={columns}
          rowData={rowData}
          getMainMenuItems={getMainMenuItems}
          autoGroupColumnDef={autoGroupColumnDef}
          groupDisplayType="singleColumn"
          suppressColumnVirtualisation
          onRowGroupOpened={onRowGroupOpened}
          onGridReady={(params) => params.columnApi.autoSizeAllColumns()}
          onFirstDataRendered={(params) =>
            params.columnApi.autoSizeAllColumns()
          }
        />
      </Box>
    );
  }
);

export default OutputsTable;
