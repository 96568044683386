import React, { FunctionComponent, ReactNode } from 'react';
import { styled } from '@mui/material/styles';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
  Typography,
} from '@mui/material';

import Flex from '../shared/Flex';

export interface CheckboxCardProps {
  label?: string;
  checked: boolean;
  labelElement?: ReactNode;
  onCardClick: (checked: boolean) => void;
  showCheckBox?: boolean;
  highlightOnFilter?: boolean;
  styleProp?: object;
  children?: React.ReactNode;
  backgroundColor?: string;
  cardContentStyle?: React.CSSProperties;
}

const CheckboxCard: FunctionComponent<CheckboxCardProps> = ({
  label,
  checked,
  children,
  labelElement,
  onCardClick,
  showCheckBox = false,
  styleProp,
}) => {
  return (
    <Card
      sx={(theme) => ({
        ...(!showCheckBox && {
          backgroundColor: checked ? 'rgb(245, 248, 250)' : '#FFFFFF',
          border: checked ? `1px solid ${theme.palette.secondary.main}` : '',
          color: !checked ? `rgba(92, 112, 128, 0.6)` : '',
        }),
        ...styleProp,
        cursor: 'pointer',
      })}
    >
      <CardContent
        onClick={() => onCardClick(!checked)}
        sx={{
          '&:last-child': {
            padding: 2,
          },
        }}
      >
        <Flex>
          <Box flex="auto" alignItems="center">
            {showCheckBox ? (
              <FormControlLabel
                control={<Checkbox checked={checked} />}
                label={
                  <Typography sx={{ fontSize: '14px' }}>
                    {label || ''}
                  </Typography>
                }
              />
            ) : (
              <Typography
                sx={{
                  fontSize: '14px',
                  wordBreak: 'break-word',
                  whiteSpace: 'initial',
                }}
              >
                {label ? label : labelElement}
              </Typography>
            )}
          </Box>
          {children}
        </Flex>
      </CardContent>
    </Card>
  );
};

export default styled(CheckboxCard)`
  position: relative;
`;
