import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import QueryString from 'query-string';
import { FileWithPath } from 'react-dropzone';
import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Compare, Help } from '@mui/icons-material';

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';

import APICache from '../../services/APICache';
import * as API from '../../services/API';

import {
  IIVisualizationContextChildrenData,
  VisualizationContext,
} from '../Visualization/VisualizationContext';

import { ModelInstance, Upload } from '../../types/models';

import useSetState from '../../hooks/useSetState';

import AuthController from './AuthController';
import APICacheContext from './APICacheContext';
import RoutesComponent from './Routes';
import { IUploadData, UploadContext } from './UploadFilesContext';
import MultipleFilesUploadingPanel from './MultipleFilesUploadingPanel';
import ErrorBoundary from './ErrorBoundary';
import Header from './Header';
import GoToTop from './GoToTop';
import Footer from './Footer';
import Login from './Login';

const cache = new APICache(API.load);

interface IRouteComponentState {
  visualization: {
    childrenData: IIVisualizationContextChildrenData;
  };
  fileUploadData: IUploadData;
}

const initialRouteComponentState: IRouteComponentState = {
  visualization: {
    childrenData: {
      customChart: {},
      dashboard: {},
      ppt: {},
    },
  },
  fileUploadData: {
    fileData: [],
    files: [],
    modelInstanceId: undefined,
    fileProcessingURL: '',
    isFilesProcessingDone: false,
  },
};

const RouteComponent: FunctionComponent = () => {
  const [state, setState] = useSetState<IRouteComponentState>(
    initialRouteComponentState
  );

  useEffect(() => {
    setState({
      fileUploadData: {
        fileData: [],
        files: [],
        modelInstanceId: undefined,
        fileProcessingURL: '',
        isFilesProcessingDone: false,
      },
    });
  }, []);

  const handleVisualizationChildrenChange = (code: string, stateVal: {}) => {
    setState({
      visualization: {
        childrenData: {
          ...state.visualization.childrenData,
          [code]: stateVal,
        },
      },
    });
  };

  const visualizationContextValue = {
    childrenData: state.visualization.childrenData,
    handleChildrenChange: handleVisualizationChildrenChange,
  };

  const handleUploadDataChange = (
    uploadData: Upload[],
    files: FileWithPath[],
    modelInstanceId: ModelInstance['id'] | undefined,
    fileProcessingURL: string,
    isFilesProcessingDone: boolean
  ) => {
    setState({
      fileUploadData: {
        fileData: uploadData,
        files,
        modelInstanceId: modelInstanceId as ModelInstance['id'],
        fileProcessingURL,
        isFilesProcessingDone: isFilesProcessingDone,
      },
    });
  };

  const isFilesProcessingDone = (val: boolean) => {
    setState({
      fileUploadData: {
        ...state.fileUploadData,
        isFilesProcessingDone: val,
      },
    });
  };

  const fileUploadContextValue = {
    fileUploadData: {
      fileData: state.fileUploadData.fileData,
      files: state.fileUploadData.files,
      modelInstanceId: state.fileUploadData.modelInstanceId,
      fileProcessingURL: state.fileUploadData.fileProcessingURL,
      isFilesProcessingDone: state.fileUploadData.isFilesProcessingDone,
    },
    handleDataChange: handleUploadDataChange,
    isFilesProcessingDone: isFilesProcessingDone,
  };

  return (
    <ErrorBoundary>
      <AuthController>
        <APICacheContext.Provider value={cache}>
          <VisualizationContext.Provider value={visualizationContextValue}>
            <UploadContext.Provider value={fileUploadContextValue}>
              <Header
                additionalMenuItems={[
                  <Divider />,
                  <ListItem disablePadding>
                    <ListItemButton
                      component="a"
                      href={process.env.REACT_APP_FORECASTING_HELP}
                      target="__blank"
                    >
                      <ListItemIcon>
                        <Help />
                      </ListItemIcon>
                      <ListItemText primary="Help" />
                    </ListItemButton>
                  </ListItem>,
                  <Divider />,
                  <ListItem disablePadding>
                    <ListItemButton
                      component="a"
                      href={process.env.REACT_APP_MDA_URL}
                      target="__blank"
                    >
                      <ListItemIcon>
                        <Compare />
                      </ListItemIcon>
                      <ListItemText primary="Model Design Application" />
                    </ListItemButton>
                  </ListItem>,
                ]}
              />

              <RoutesComponent />
              <MultipleFilesUploadingPanel />
            </UploadContext.Provider>
          </VisualizationContext.Provider>
        </APICacheContext.Provider>
        <GoToTop />
        <Footer />
      </AuthController>
    </ErrorBoundary>
  );
};

const EdgeRouteComponent: FunctionComponent = () => {
  const [state, setState] = useSetState<IRouteComponentState>(
    initialRouteComponentState
  );

  useEffect(() => {
    setState({
      fileUploadData: {
        fileData: [],
        files: [],
        modelInstanceId: undefined,
        fileProcessingURL: '',
        isFilesProcessingDone: false,
      },
    });
  }, []);
  const handleVisualizationChildrenChange = (code: string, stateVal: {}) => {
    setState({
      visualization: {
        childrenData: {
          ...state.visualization.childrenData,
          [code]: stateVal,
        },
      },
    });
  };

  const visualizationContextValue = {
    childrenData: state.visualization.childrenData,
    handleChildrenChange: handleVisualizationChildrenChange,
  };

  const handleUploadDataChange = (
    uploadData: Upload[],
    files: FileWithPath[],
    modelInstanceId: ModelInstance['id'] | undefined,
    fileProcessingURL: string,
    isFilesProcessingDone: boolean
  ) => {
    setState({
      fileUploadData: {
        fileData: uploadData,
        files,
        modelInstanceId: modelInstanceId as ModelInstance['id'],
        fileProcessingURL,
        isFilesProcessingDone: isFilesProcessingDone,
      },
    });
  };

  const isFilesProcessingDone = (val: boolean) => {
    setState({
      fileUploadData: {
        ...state.fileUploadData,
        isFilesProcessingDone: val,
      },
    });
  };

  const fileUploadContextValue = {
    fileUploadData: {
      fileData: state.fileUploadData.fileData,
      files: state.fileUploadData.files,
      modelInstanceId: state.fileUploadData.modelInstanceId,
      fileProcessingURL: state.fileUploadData.fileProcessingURL,
      isFilesProcessingDone: state.fileUploadData.isFilesProcessingDone,
    },
    handleDataChange: handleUploadDataChange,
    isFilesProcessingDone: isFilesProcessingDone,
  };

  return (
    <ErrorBoundary>
      <AuthController>
        <APICacheContext.Provider value={cache}>
          <VisualizationContext.Provider value={visualizationContextValue}>
            <UploadContext.Provider value={fileUploadContextValue}>
              <Header />
              <RoutesComponent />
              <MultipleFilesUploadingPanel />
            </UploadContext.Provider>
          </VisualizationContext.Provider>
        </APICacheContext.Provider>
        <GoToTop />
        <Footer />
      </AuthController>
    </ErrorBoundary>
  );
};

const LoginWithToken: () => JSX.Element | null = () => {
  const location = useLocation();
  const [token, setToken] = useState<string>('');
  useEffect(() => {
    const authToken: QueryString.ParsedQuery = QueryString.parse(
      location.search
    );
    localStorage.setItem('msal.idtoken', authToken.token as string);
    setToken(authToken.token as string);
  }, [location.search]);

  if (token) {
    return <EdgeRouteComponent />;
  } else {
    localStorage.removeItem('msal.idtoken');
  }
  return null;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/login/*" element={<LoginWithToken />} />
      <Route path="*" element={<RouteComponent />} />
    </>
  )
);

const App: FunctionComponent = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <RouterProvider router={router} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
};

export default App;
