import React, { FunctionComponent } from 'react';
import { eachDayOfInterval, format } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro';
import { filter, includes, isEmpty, map, last, head, every } from 'lodash';

import { ArrowRightAlt, CalendarMonth } from '@mui/icons-material';

import { Button, Chip } from '@mui/material';

import { Timescale, XRefDate } from '../../../../../types/models';
import { useData } from '../../../../../hooks/useData';
import useSetState from '../../../../../hooks/useSetState';
import StyledPopper from '../../../../shared/StyledPopper';

interface IDateRangePickerProps {
  maxDate: Date;
  minDate: Date;
  onDateChange: (dates: DateRange<Date>) => void;
  value: DateRange<Date>;
  clientId: number;
}

interface IWeeklyRangePickerState {
  dates: DateRange<Date>;
}

const WeeklyRangePicker: FunctionComponent<IDateRangePickerProps> = ({
  maxDate,
  minDate,
  onDateChange,
  value,
  clientId,
}: IDateRangePickerProps) => {
  const [state, setState] = useSetState<IWeeklyRangePickerState>({
    dates: value,
  });

  const { data } = useData<{
    dates?: XRefDate[];
  }>(
    () => ({
      dates: `/xref_date/${clientId}`,
    }),
    [clientId]
  );
  // const [state, setState] = useSetState<IDateRangePickerState>({
  //   date: !isEmpty(compact(value))
  //     ? map(value, (item) => new Date(item))
  //     : [minDate, maxDate],
  // });

  const handleDateChange = (date: DateRange<Date>): void => {
    setState({ dates: date });
  };

  const handleClose = (): void => {
    if (every(state.dates, (i) => i !== null)) {
      onDateChange(state.dates);
    }
  };

  const dates: XRefDate[] = filter(data.dates || [], [
    'Timescale',
    Timescale.Weekly,
  ]);

  const datesOnly = map(dates, 'Date');

  const dateRanges = !isEmpty(datesOnly)
    ? map(
        eachDayOfInterval({
          start: new Date(head(datesOnly) as string),
          end: new Date(last(datesOnly) as string),
        }),
        (date) => format(date, 'M/d/yyyy')
      )
    : [];

  const disabledDates = filter(dateRanges, (i) => !includes(datesOnly, i));

  return (
    <StyledPopper
      onClose={handleClose}
      content={() => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDateRangePicker
            displayStaticWrapperAs="desktop"
            value={state.dates}
            onChange={handleDateChange}
            minDate={minDate}
            maxDate={maxDate}
            shouldDisableDate={(date) => {
              return includes(disabledDates, format(date, 'M/d/yyyy'));
            }}
          />
        </LocalizationProvider>
      )}
      id="weekly-range-picker"
    >
      <Button startIcon={<CalendarMonth />} variant="outlined" size="small">
        <Chip
          size="small"
          label={
            !state.dates[0]
              ? 'From'
              : typeof state.dates[0] === 'string'
                ? state.dates[0]
                : format(state.dates[0] as Date, 'M/d/yyyy')
          }
        />
        <ArrowRightAlt sx={{ color: '#c3ced5' }} fontSize="small" />
        <Chip
          size="small"
          label={
            !state.dates[1]
              ? 'To'
              : typeof state.dates[1] === 'string'
                ? state.dates[1]
                : format(state.dates[1] as Date, 'M/d/yyyy')
          }
        />
      </Button>
    </StyledPopper>
  );
};

export default WeeklyRangePicker;
