import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AssumptionsUploadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 403 331.1"
      sx={{
        enableBackground: 'new 0 0 403 331.1',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.st1{fill:#e5e9f1}.st2{fill:#fff}.st3{fill:#304659}'}</style>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1={205.769}
        y1={337.552}
        x2={205.769}
        y2={6.42}
        gradientTransform="matrix(1 0 0 -1 0 337.551)"
      >
        <stop
          offset={0}
          style={{
            stopColor: '#fbfdfe',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#f0f5fa',
          }}
        />
      </linearGradient>
      <path
        d="M94.8 331.1h222c6.1 0 12-2.3 16.5-6.4 18.7-17.3 33.7-38.3 44-61.6 10.3-23.3 15.7-48.5 15.9-74C394.1 85.5 309.9.2 206.2 0 102.5-.2 18.4 83.7 18.4 187.4c0 25.8 5.3 51.3 15.6 75 10.3 23.6 25.4 44.9 44.4 62.4 4.4 4.1 10.3 6.4 16.4 6.3z"
        style={{
          fill: 'url(#SVGID_1_)',
        }}
      />
      <path
        className="st1"
        d="M108.5 29.9C125.7 16.2 147 8.7 169 8.7c25.7 0 50.4 10.2 68.6 28.4a96.91 96.91 0 0 1 26.7 50.4c1.9-.2 3.8-.3 5.7-.3 7.6 0 15.1 1.5 22.1 4.4 7 2.9 13.4 7.2 18.7 12.5 5.4 5.4 9.6 11.7 12.5 18.7a57.71 57.71 0 0 1 0 44.2c-2.9 7-7.2 13.4-12.5 18.7-5.4 5.4-11.7 9.6-18.7 12.5-7 2.9-14.5 4.4-22.1 4.4H62.9c-8.4 0-16.6-1.7-24.2-5.1-7.6-3.4-14.5-8.3-20.1-14.5-5.6-6.2-9.9-13.5-12.5-21.4S2.5 145.3 3.3 137c.8-8.3 3.3-16.4 7.4-23.6 4.1-7.3 9.7-13.6 16.4-18.6 6.7-5 14.4-8.5 22.5-10.4 8.1-1.9 16.6-2 24.8-.4 4.8-21.3 16.9-40.4 34.1-54.1z"
      />
      <path
        className="st2"
        d="M270.8 324.3H141.9c-3.2 0-6.2-1.3-8.4-3.5s-3.5-5.3-3.5-8.4V106.7c0-3.2 1.3-6.2 3.5-8.4s5.3-3.5 8.4-3.5h88.4l52.4 52.4v165.2c0 3.2-1.3 6.2-3.5 8.4s-5.3 3.5-8.4 3.5z"
      />
      <path
        className="st3"
        d="M282.7 147.2h-40.4c-1.6 0-3.1-.3-4.6-.9-1.4-.6-2.8-1.5-3.9-2.6s-2-2.4-2.6-3.9c-.6-1.4-.9-3-.9-4.6V94.8l52.4 52.4z"
      />
      <path
        className="st1"
        d="M205.3 134.7h-49.9c-2.5 0-4.6 2-4.6 4.6s2 4.6 4.6 4.6h49.9c2.5 0 4.6-2 4.6-4.6s-2.1-4.6-4.6-4.6zM256.1 165.6H155.4c-2.5 0-4.6 2-4.6 4.6 0 2.5 2 4.6 4.6 4.6h100.7c2.5 0 4.6-2 4.6-4.6 0-2.6-2-4.6-4.6-4.6zM256.1 196.4H155.4c-2.5 0-4.6 2-4.6 4.6 0 2.5 2 4.6 4.6 4.6h100.7c2.5 0 4.6-2 4.6-4.6 0-2.5-2-4.6-4.6-4.6zM256.1 227.3H155.4c-2.5 0-4.6 2-4.6 4.6 0 2.5 2 4.6 4.6 4.6h100.7c2.5 0 4.6-2 4.6-4.6 0-2.6-2-4.6-4.6-4.6zM256.5 258.1H155.4c-2.5 0-4.6 2-4.6 4.6 0 2.5 2 4.6 4.6 4.6h101.1c2.5 0 4.6-2 4.6-4.6 0-2.5-2.1-4.6-4.6-4.6z"
      />
      <path
        className="st3"
        d="M180.3 315.5c23.9 0 43.3-19.4 43.3-43.3s-19.4-43.3-43.3-43.3-43.3 19.4-43.3 43.3c.1 23.9 19.5 43.3 43.3 43.3z"
      />
      <path
        className="st2"
        d="m197 265.5-14.2-14.2c-.8-.8-1.8-1.2-2.9-1.2-1.1 0-2.1.4-2.9 1.2l-14.2 14.2c-.4.4-.7.8-.9 1.3-.2.5-.3 1-.3 1.6 0 .5.1 1.1.3 1.6.2.5.5 1 .9 1.3.4.4.8.7 1.3.9.5.2 1 .3 1.6.3.5 0 1.1-.1 1.6-.3.5-.2 1-.5 1.3-.9l7.2-7.2v27.7c0 1.1.4 2.1 1.2 2.9.8.8 1.8 1.2 2.9 1.2 1.1 0 2.1-.4 2.9-1.2.8-.8 1.2-1.8 1.2-2.9v-27.7l7.2 7.2c.4.4.8.7 1.3.9.5.2 1 .3 1.6.3.5 0 1.1-.1 1.6-.3.5-.2 1-.5 1.3-.9.4-.4.7-.8.9-1.3.2-.5.3-1 .3-1.6 0-.5-.1-1.1-.3-1.6-.2-.5-.5-.9-.9-1.3z"
      />
      <path
        className="st3"
        d="M250.7 209.1c-23.9 0-43.3 19.4-43.3 43.3s19.4 43.3 43.3 43.3 43.3-19.4 43.3-43.3-19.4-43.3-43.3-43.3z"
      />
      <path
        className="st2"
        d="m234.1 259.1 14.2 14.2c.8.8 1.8 1.2 2.9 1.2 1.1 0 2.1-.4 2.9-1.2l14.2-14.2c.4-.4.7-.8.9-1.3.2-.5.3-1 .3-1.6 0-.5-.1-1.1-.3-1.6-.2-.5-.5-1-.9-1.3-.4-.4-.8-.7-1.3-.9-.5-.2-1-.3-1.6-.3-.5 0-1.1.1-1.6.3-.5.2-1 .5-1.3.9l-7.2 7.2v-27.7c0-1.1-.4-2.1-1.2-2.9-.8-.8-1.8-1.2-2.9-1.2-1.1 0-2.1.4-2.9 1.2-.8.8-1.2 1.8-1.2 2.9v27.7l-7.2-7.2c-.4-.4-.8-.7-1.3-.9-.5-.2-1-.3-1.6-.3-.5 0-1.1.1-1.6.3-.5.2-1 .5-1.3.9-.4.4-.7.8-.9 1.3-.2.5-.3 1-.3 1.6 0 .5.1 1.1.3 1.6.2.5.5.9.9 1.3z"
      />
      <path
        d="M274.1 29.5c10.1-8.1 22.6-12.4 35.6-12.4 15.1 0 29.6 6 40.3 16.7 8.1 8.1 13.6 18.5 15.7 29.6 1.1-.1 2.2-.2 3.4-.2 9 0 17.6 3.6 24 9.9 6.4 6.4 9.9 15 9.9 24s-3.6 17.6-9.9 24c-6.4 6.4-15 9.9-24 9.9H247.3c-4.9 0-9.8-1-14.2-3-4.5-2-8.5-4.9-11.8-8.5-3.3-3.6-5.8-7.9-7.3-12.6-1.5-4.7-2.1-9.6-1.6-14.5s2-9.6 4.4-13.9c2.4-4.3 5.7-8 9.6-10.9 3.9-2.9 8.4-5 13.2-6.1 4.8-1.1 9.7-1.2 14.6-.2 2.7-12.5 9.8-23.7 19.9-31.8z"
        style={{
          fill: '#d4d9e3',
        }}
      />
    </SvgIcon>
  );
};

export default AssumptionsUploadIcon;
