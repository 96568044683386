import React, { FunctionComponent } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { Cloud, InfoOutlined } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';

import { filterSearch } from '../../utils/misc';
import Flex from '../shared/Flex';

const tooltipContent = 'Edit Assumptions';

interface IEditAssumptionsProps {
  disabled?: boolean;
}

const EditAssumptions: FunctionComponent<IEditAssumptionsProps> = ({
  disabled,
}) => {
  const location = useLocation();
  const { search } = location;
  return (
    <Grid container justifyContent="space-between">
      <Grid md={6} component={Flex}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <Tooltip arrow title={tooltipContent}>
            <InfoOutlined fontSize="small" sx={{ color: '#919191' }} />
          </Tooltip>
          <Typography variant="subtitle1">Edit Assumptions</Typography>
          <Cloud color="primary" />
        </Stack>
      </Grid>
      <Grid sm={6} container justifyContent="flex-end">
        <Button
          disabled={disabled}
          component={NavLink}
          variant="contained"
          size="small"
          to={{
            pathname: '/scenario-manager/assumptions',
            search: filterSearch(search, [
              'client',
              'model',
              'instance',
              'scenario',
            ]),
          }}
        >
          Edit Online
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditAssumptions;
