import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ThDerivedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        id="Shape"
        d="M5.3,14.3C5.1,14.5,5,14.7,5,15c0,0.6,0.4,1,1,1c0.3,0,0.5-0.1,0.7-0.3l3-3
			C9.9,12.5,10,12.3,10,12s-0.1-0.5-0.3-0.7l-3-3C6.5,8.1,6.3,8,6,8C5.4,8,5,8.4,5,9c0,0.3,0.1,0.5,0.3,0.7L6.6,11H1
			c-0.6,0-1,0.4-1,1s0.4,1,1,1h5.6L5.3,14.3L5.3,14.3z M19,2H3C2.5,2,2,2.5,2,3v6h1c0-1.7,1.3-3,3-3c0.8,0,1.6,0.3,2.1,0.9L8.2,7H9
			v0.8l1,1V7h8v3h-6.8c0.3,0.3,0.5,0.6,0.6,1H18v3h-6.8l-0.1,0.1L10.2,15H18v3h-8v-2.8l-1,1V18H4v-0.8c-0.6-0.5-1-1.3-1-2.2H2v4
			c0,0.5,0.5,1,1,1h16c0.6,0,1-0.5,1-1V3C20,2.5,19.5,2,19,2L19,2z"
      />
    </SvgIcon>
  );
};

export default ThDerivedIcon;
