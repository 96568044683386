import React, { FunctionComponent, PropsWithChildren } from 'react';

interface IConditionalWrapProps {
  condition: boolean;
  wrap: (children: any) => JSX.Element;
}

const ConditionalWrap: FunctionComponent<
  PropsWithChildren<IConditionalWrapProps>
> = ({ condition, wrap, children }) =>
  condition ? React.cloneElement(wrap(children)) : <>{children}</>;

export default ConditionalWrap;
