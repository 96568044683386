import { HTTPError } from '../support/errors';

import { apiRequest, msalInstance } from './authConfig';

const API_BASE = process.env.REACT_APP_API_HOST;

const getTokenFromMSAL = async (): Promise<string | undefined> => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    const accessTokenResponse = await msalInstance.acquireTokenSilent({
      ...apiRequest,
    });
    return accessTokenResponse.accessToken;
  }
};

async function request(path: string, options?: RequestInit) {
  let token;
  if (localStorage.getItem('msal.idtoken')) {
    token = localStorage.getItem('msal.idtoken');
  } else {
    token = await getTokenFromMSAL();
  }

  options = { ...options };
  options.headers = { Authorization: `Bearer ${token}`, ...options.headers };

  const url = `${API_BASE}${path}`;
  const response = await fetch(url, options);

  if (!response.ok) {
    let body;
    try {
      body = await response.json();
    } catch {
      // Ignore a failure to parse the response
    }

    throw new HTTPError(url, response.status, response.statusText, body);
  }

  return response;
}

export async function load(path: string, signal?: RequestInit) {
  if (path) {
    const response = await request(path, signal);
    return await response.json();
  }
  return null;
}

export async function put<T>(path: string, payload: object) {
  const response = await request(path, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });

  return (await response.json()) as T;
}

export async function create<T>(path: string, payload: object) {
  const response = await request(path, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });

  return (await response.json()) as T;
}

export const update = create;

export async function remove(path: string) {
  await request(path, {
    method: 'DELETE',
  });
}
