import React, { PureComponent } from 'react';
import {
  Box,
  Alert,
  AlertTitle,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { map } from 'lodash';

import * as API from '../../services/API';
import {
  ModelInstance,
  ModelInstanceVisibility,
  ModelInstanceArchiveFlag,
} from '../../types/models';

import APICacheContext from '../shared/APICacheContext';
import APICache from '../../services/APICache';
import CenteredSpinner from '../shared/CenteredSpinner';

interface Props {
  clientId: number;
  modelId: number;
  instance: ModelInstance;
  filter: ModelInstanceArchiveFlag;
}

interface State {
  busy: boolean;
  error?: Error;
}

const visibilityOptions = [
  { value: ModelInstanceVisibility.No_One, label: 'No one (default)' },
  { value: ModelInstanceVisibility.Trinity_Users, label: 'All Trinity Users' },
  {
    value: ModelInstanceVisibility.Trinity_Users_And_Model_Owners,
    label: 'Trinity Users and Model Owners',
  },
  { value: ModelInstanceVisibility.All_User, label: 'Everyone' },
];

const INITIAL_STATE: State = {
  busy: false,
  error: undefined,
};

class ModelInstancePermissionsItem extends PureComponent<Props, State> {
  public static contextType = APICacheContext;
  public state: State = INITIAL_STATE;

  public render() {
    const { instance, filter } = this.props;
    const { busy, error } = this.state;

    return (
      <>
        <Grid container alignItems="center" spacing={2}>
          <Grid sm={6}>
            <Typography
              color={(t) => t.palette.common.muted}
              component="span"
              sx={{ wordWrap: 'break-word' }}
            >
              {instance.Name}
            </Typography>
          </Grid>
          <Grid sm={6} mt={1}>
            <Select
              margin="none"
              value={instance.Visibility.toString()}
              disabled={busy || filter === 1}
              onChange={(e: SelectChangeEvent) =>
                this.handleVisibilityChange(instance, e)
              }
              displayEmpty
              size="small"
              fullWidth
            >
              {map(visibilityOptions, (i) => (
                <MenuItem key={i.value} value={i.value}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>
            {busy && (
              <Box ml={2}>
                <CenteredSpinner />
              </Box>
            )}
          </Grid>
        </Grid>
        {error && (
          <Box mt={2} mb={3}>
            <Alert icon={false} severity="error" title="Something went wrong">
              <AlertTitle>Something went wrong</AlertTitle>
              Failed to update model instance visibility, check your connection
              and try again
            </Alert>
          </Box>
        )}
      </>
    );
  }

  private handleVisibilityChange = async (
    instance: ModelInstance,
    event: SelectChangeEvent
  ) => {
    const { clientId, modelId } = this.props;

    try {
      this.setState({ error: undefined, busy: true });
      await API.update(`/instances/${instance.id}/permissions`, {
        Visibility: parseInt(event.target.value, 10),
      });
      await (this.context as APICache).load(
        [`/clients/${clientId}/models/${modelId}/model_instances_load`],
        true
      );
    } catch (error: any) {
      this.setState({ error, busy: false });
      return;
    }

    this.setState({ busy: false });
  };
}

export default ModelInstancePermissionsItem;
