import { Box } from '@mui/material';
import React, { PureComponent } from 'react';
import { filter as filterList, isArray, map } from 'lodash';

import { ModelMembership } from '../../types/models';
import { filterUser } from '../../utils/user';

import Loader, { LoaderInfo } from '../shared/Loader';

import TeamMemberCard from './TeamMemberCard';
import TeamMemberInvite from './TeamMemberInvite';

interface Props {
  clientId: number;
  modelId: number;
  filter: string;
}

class TeamMemberList extends PureComponent<Props> {
  public render() {
    const { modelId } = this.props;

    const needs = {
      memberships: `/models/${modelId}/model_memberships`,
    };

    return (
      <>
        <Loader
          needs={needs}
          handleErrors={true}
          render={this.renderContents}
        />
      </>
    );
  }

  private renderContents = ({
    data,
  }: LoaderInfo<{ memberships: ModelMembership }>) => {
    const { clientId, modelId, filter } = this.props;
    const { memberships } = data;

    if (isArray(memberships)) {
      return (
        <>
          <TeamMemberInvite
            clientId={clientId}
            modelId={modelId}
            filter={filter}
            memberships={memberships}
          />
          {this.renderList(memberships)}
        </>
      );
    } else {
      return null;
    }
  };

  private renderList = (memberships: ModelMembership[]) => {
    const { filter } = this.props;

    return map(
      filterList(memberships, (membership) =>
        filterUser(membership.User, filter)
      ).sort(({ User: a }, { User: b }) =>
        a.Display_Name > b.Display_Name
          ? 1
          : b.Display_Name > a.Display_Name
            ? -1
            : 0
      ),
      (membership) => (
        <Box key={membership.id} mt={2}>
          <TeamMemberCard membership={membership} />
        </Box>
      )
    );
  };
}

export default TeamMemberList;
