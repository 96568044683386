import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useEffect,
} from 'react';
import { find, isEmpty, map, sortBy, values } from 'lodash';
import { CloudDownload, InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Tooltip,
  Typography,
  Stack,
  TextField,
  MenuItem,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';

import ExportSVG from '../../assets/images/export.svg';

import { HTTPError } from '../../support/errors';
import {
  DefaultModelFiles,
  GeneratedFile,
  Submission,
  SubmissionStatus,
  UploadedFile,
} from '../../types/models';
import * as API from '../../services/API';

import { getFileExtension } from '../../utils/misc';

import GeneratedFileDownload from '../shared/GeneratedFileDownload';

import UploadFile from '../shared/UploadFile';

import useSetState from '../../hooks/useSetState';
import { useData } from '../../hooks/useData';

import ScenarioUpdateErrorCallout from './ScenarioUpdateErrorCallout';

interface Props {
  instanceId: number;
  scenarioId: number;
  lockSubmissionId: number;
  refresh: () => Promise<any[]>;
  doesInstanceHaveGroups: boolean;
  defaultModelFiles?: DefaultModelFiles[];
}

interface State {
  error: HTTPError | null;
  isBusy: boolean;
  file: File | null;
  generatedFile?: GeneratedFile;
  fileTypeError: boolean;
  isUploadFromCloudCast: boolean;
  selectedGroup?: DefaultModelFiles['id'];
  isCloudCastDownloadLoading: boolean;
}

const INITIAL_STATE: State = {
  error: null,
  isBusy: false,
  file: null,
  generatedFile: undefined,
  fileTypeError: false,
  isUploadFromCloudCast: false,
  selectedGroup: undefined,
  isCloudCastDownloadLoading: false,
};

const tooltipContent =
  'Download this scenario data into an existing Excel file';

const DownloadForecast: FunctionComponent<Props> = (props) => {
  const [state, setState] = useSetState<State>(INITIAL_STATE);

  const { data } = useData<{
    submissions?: Submission[];
  }>(
    () => ({
      submissions:
        props.instanceId !== undefined && props.scenarioId !== undefined
          ? `/instances/${props.instanceId}/scenarios/${props.scenarioId}/submissions`
          : undefined,
    }),
    [props.instanceId, props.scenarioId],
    true
  );

  const submissions = data.submissions || [];

  const activeSubmissionIds = values(
    submissions.reduce<{ [id: number]: number }>(
      (activeIds, submission) => ({
        ...activeIds,
        ...(submission.Status === SubmissionStatus.Finished &&
        submission.GroupRowIdentifierID !== undefined &&
        activeIds[submission.GroupRowIdentifierID] === undefined
          ? { [submission.GroupRowIdentifierID]: submission.id }
          : {}),
      }),
      {}
    )
  );

  useEffect(() => {
    if (isEmpty(props.defaultModelFiles)) {
      setState({ ...state, isUploadFromCloudCast: false });
    }
  }, [props.defaultModelFiles]);

  const handleCancelClick = (): void => setState(INITIAL_STATE);

  const handleFileChange = (event: FormEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;

    if (getFileExtension(files) === 'xlsm') {
      setState({
        file: files && files[0],
        fileTypeError: false,
      });
    } else {
      setState({ fileTypeError: true, file: null });
    }
  };

  const handleExport = async (uploadData: UploadedFile) => {
    const { scenarioId, lockSubmissionId, refresh } = props;

    if (uploadData.error) {
      setState({ isBusy: false, error: uploadData.error });
      return;
    }
    if (uploadData.file === null) {
      return;
    }

    setState({ isBusy: true, error: null });

    if (uploadData.fileUploadData) {
      try {
        const generatedFile = await API.create<GeneratedFile>(
          `/generated_files/export`,
          {
            UploadID: uploadData.fileUploadData.id,
            ScenarioID: scenarioId,
            FileName: uploadData.fileUploadData.FileName, // TODO: Is there better logic for this?
            LockSubmissionID: lockSubmissionId,
            ModelInstanceID: props.instanceId,
            ExportType: 'export',
          }
        );

        setState({ isBusy: false, generatedFile, file: null });
      } catch (error: any) {
        setState({ isBusy: false, error });
        return;
      }
      refresh();
    }
  };

  const handleItemSelect = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      selectedGroup: Number(event.target.value),
    });
  };

  const downloadFileWithCloudCast = async (
    BlobName?: string,
    FileName?: string
  ) => {
    const { scenarioId, lockSubmissionId, refresh } = props;
    setState({ isCloudCastDownloadLoading: true });
    try {
      const generatedFile = await API.create<GeneratedFile>(
        `/generated_files/export`,
        {
          ScenarioID: scenarioId,
          LockSubmissionID: lockSubmissionId,
          ModelInstanceID: props.instanceId,
          ExportType: 'default_model_file',
          DefaultModelFileBlobName: BlobName,
          FileName: FileName,
        }
      );

      if (generatedFile.BlobURL) {
        window.open(generatedFile.BlobURL, '_blank');
      }

      setState({
        isBusy: false,
        generatedFile,
        file: null,
        isCloudCastDownloadLoading: false,
      });
    } catch (error: any) {
      setState({
        isBusy: false,
        error,
        isCloudCastDownloadLoading: false,
      });
      return;
    }
    refresh();
  };

  const { refresh, scenarioId, doesInstanceHaveGroups, defaultModelFiles } =
    props;
  const { isBusy, error, file, generatedFile, fileTypeError } = state;
  return (
    <Grid container>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Grid sm={6}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Tooltip arrow title={tooltipContent}>
              <InfoOutlined fontSize="small" sx={{ color: '#919191' }} />
            </Tooltip>
            <Typography variant="subtitle1" color="disabled">
              Download Forecast
            </Typography>
            <img alt="" src={ExportSVG} />
          </Stack>
        </Grid>
        {!file && !generatedFile && (
          <>
            {(() => {
              if (doesInstanceHaveGroups) {
                if (!state.isUploadFromCloudCast) {
                  return (
                    <Grid sm={6} container justifyContent="flex-end">
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{ alignItems: 'center' }}
                      >
                        {!isEmpty(props.defaultModelFiles) && (
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() =>
                              setState({
                                isUploadFromCloudCast: true,
                              })
                            }
                            disabled={isEmpty(activeSubmissionIds)}
                          >
                            From CloudCast
                          </Button>
                        )}
                        <Box className="h-100">
                          <input
                            type="file"
                            id="uploadFileExport"
                            accept=".xlsm"
                            onChange={handleFileChange}
                            onClick={() =>
                              setState({ isUploadFromCloudCast: false })
                            }
                          />
                          <Button
                            size="small"
                            variant="contained"
                            component="label"
                            htmlFor="uploadFileExport"
                            disabled={isEmpty(activeSubmissionIds)}
                          >
                            Select file
                          </Button>
                        </Box>
                      </Stack>
                    </Grid>
                  );
                } else {
                  let disabled = false;

                  if (isEmpty(defaultModelFiles)) {
                    disabled = true;
                  }
                  return (
                    <Grid
                      container
                      sx={{ mt: 2, width: '100%' }}
                      alignItems="center"
                    >
                      <Grid md={6}>
                        <TextField
                          fullWidth
                          select
                          size="small"
                          label="Select a group"
                          disabled={disabled}
                          value={state.selectedGroup}
                          onChange={handleItemSelect}
                        >
                          {map(
                            sortBy(
                              defaultModelFiles,
                              'GroupRowIdentifierName'
                            ) || [],
                            (group) => (
                              <MenuItem key={group.id} value={group.id}>
                                {group.GroupRowIdentifierName}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                      </Grid>
                      <Grid md={6} container justifyContent="flex-end">
                        <Stack direction="row" spacing={2}>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              setState({
                                isUploadFromCloudCast: false,
                              })
                            }
                          >
                            Cancel
                          </Button>
                          <LoadingButton
                            size="small"
                            endIcon={<CloudDownload />}
                            variant="contained"
                            disabled={state.selectedGroup === undefined}
                            onClick={() =>
                              downloadFileWithCloudCast(
                                (
                                  find(defaultModelFiles, {
                                    id: state.selectedGroup,
                                  }) as DefaultModelFiles
                                ).BlobName,
                                (
                                  find(defaultModelFiles, {
                                    id: state.selectedGroup,
                                  }) as DefaultModelFiles
                                ).FileName
                              )
                            }
                            loading={state.isCloudCastDownloadLoading}
                          >
                            Download
                          </LoadingButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  );
                }
              } else {
                return (
                  <Grid justifyContent="flex-end">
                    <Stack direction="row" spacing={2}>
                      {!isEmpty(props.defaultModelFiles) && (
                        <LoadingButton
                          size="small"
                          variant="contained"
                          onClick={() =>
                            downloadFileWithCloudCast(
                              props.defaultModelFiles &&
                                props.defaultModelFiles[0].BlobName,
                              props.defaultModelFiles &&
                                props.defaultModelFiles[0].FileName
                            )
                          }
                          loading={state.isCloudCastDownloadLoading}
                          disabled={isEmpty(activeSubmissionIds)}
                        >
                          From CloudCast
                        </LoadingButton>
                      )}
                      <Box>
                        <input
                          type="file"
                          id="uploadFileExport"
                          accept=".xlsm"
                          onChange={handleFileChange}
                          onClick={() =>
                            setState({ isUploadFromCloudCast: false })
                          }
                        />
                        <Button
                          size="small"
                          variant="contained"
                          component="label"
                          htmlFor="uploadFileExport"
                          disabled={isEmpty(activeSubmissionIds)}
                        >
                          Select file
                        </Button>
                      </Box>
                    </Stack>
                  </Grid>
                );
              }
            })()}
          </>
        )}
      </Grid>
      <Grid sm={12}>
        {error && (
          <Box mt={1}>
            <ScenarioUpdateErrorCallout
              error={error}
              text="Downloading scenario failed, check your connection and try again."
              refresh={() => {
                refresh().then(() => setState({ error: null }));
              }}
            />
          </Box>
        )}
        {fileTypeError && (
          <Box mt={1}>
            <ScenarioUpdateErrorCallout
              error={fileTypeError}
              text="Please upload a file with a .xlsm extension"
            />
          </Box>
        )}
        {file && (
          <Box mt={1}>
            <UploadFile
              isBusy={isBusy}
              fileExtension={'.xlsm'}
              file={file}
              handleCancelClick={handleCancelClick}
              uploadButtonText={'Download'}
              uploadIcon={<CloudDownload />}
              fileUploadData={handleExport}
              handleFileChange={handleFileChange}
              uploadComment={`Upload for download from ${scenarioId}`}
              downloadButtonIntent="primary"
              uploadText="Preparing download"
            />
          </Box>
        )}
        {generatedFile && !isBusy && (
          <Box mt={1}>
            <GeneratedFileDownload
              onClose={handleCancelClick}
              generatedFileId={generatedFile.id}
              progressMsg="Preparing download"
              calloutMessage={`Note: a new active version was found and loaded into your file. Your submission list has been refreshed.`}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default DownloadForecast;
