import React, { FunctionComponent, ChangeEvent, useState } from 'react';
import _, { map, toString } from 'lodash';
import { Search } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';

import {
  RowIdentifier,
  Scenario,
  Submission,
  SubmissionStatus,
} from '../../../types/models';
import { remove } from '../../../services/API';
import { useData } from '../../../hooks/useData';

import SubmissionList from '../SubmissionList';
import UtilClasses from '../../../assets/stylesheets/UtilClasses.module.scss';
import Loading from '../../shared/Loading';
import LoadingError from '../../shared/LoadingError';

import RestoreSubmissionDialog from '../RestoreSubmissionDialog';
import TransferSubmissionDialog from '../TransferSubmissionDialog';

import SubmissionMonitor from './SubmissionMonitor';

interface Props {
  groups?: RowIdentifier[];
  modelId: number;
  instanceId: number;
  scenario: Scenario;
  canSubmit: boolean;
}

const defaultFilter = {
  label: 'All Groups',
  value: -1,
};

// The group with GroupID 0 is a sentinel, and signals that no submissions have
// a group. If it's present, it is the only group present.
const getOptions = (arr: Array<RowIdentifier>) =>
  _(arr)
    .filter((row) => row.GroupID !== 0)
    .sortBy('Name')
    .map((item) => ({ label: item.Name, value: item.id }))
    .value();

interface RestoreModal {
  open: boolean;
  submission?: Submission;
}

const defaultRestoreModal: RestoreModal = {
  open: false,
  submission: undefined,
};

interface TransferModal {
  open: boolean;
  submission?: Submission;
}

const defaultTransferModal: TransferModal = {
  open: false,
  submission: undefined,
};

const ScenarioVersions: FunctionComponent<Props> = ({
  groups,
  modelId,
  instanceId,
  scenario,
  canSubmit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFilter, setFilter] = useState(defaultFilter.value);
  const [restoreModal, setRestoreModal] = useState(defaultRestoreModal);
  const [transferModal, setTransferModal] = useState(defaultTransferModal);
  const [searchVersion, setSearchVersion] = useState<string | null>(null);
  const [searchCollapsed, setSearchCollapsed] = useState<boolean>(true);

  const { data, loading, errors, refresh } = useData<{
    submissions?: Submission[];
  }>(
    () => ({
      submissions:
        instanceId !== undefined && scenario.id !== undefined
          ? `/instances/${instanceId}/scenarios/${scenario.id}/submissions`
          : undefined,
    }),
    [instanceId, scenario.id],
    true
  );

  const options = groups && getOptions(groups);
  const hasGroups = !!options && options.length > 0;

  if (loading && !_.isArray(data.submissions)) {
    return (
      <Box p={3}>
        <Loading />
      </Box>
    );
  }

  if (_.isArray(errors) && errors.length > 0) {
    return (
      <Box p={3}>
        <LoadingError retry={refresh} />
      </Box>
    );
  }

  const submissions = (data.submissions || [])
    .slice()
    .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
  const activeSubmissionIds = Object.values(
    submissions.reduce<{ [id: number]: number }>(
      (activeIds, submission) => ({
        ...activeIds,
        ...(submission.Status === SubmissionStatus.Finished &&
        submission.GroupRowIdentifierID !== undefined &&
        activeIds[submission.GroupRowIdentifierID] === undefined
          ? { [submission.GroupRowIdentifierID]: submission.id }
          : {}),
      }),
      {}
    )
  );
  let filteredSubmissions = submissions;
  if (selectedFilter !== defaultFilter.value) {
    filteredSubmissions = _.filter(
      filteredSubmissions,
      (s) => selectedFilter === s.GroupRowIdentifierID
    );
  }
  if (searchVersion !== null) {
    filteredSubmissions = _.filter(filteredSubmissions, (s) =>
      s.version.startsWith(searchVersion)
    );
  }

  return (
    <>
      <RestoreSubmissionDialog
        instanceId={instanceId}
        scenario={scenario}
        submission={restoreModal.submission}
        isOpen={restoreModal.open}
        onCancel={() => {
          setRestoreModal(defaultRestoreModal);
        }}
        onComplete={() => {
          setRestoreModal(defaultRestoreModal);
        }}
      />
      <TransferSubmissionDialog
        modelId={modelId}
        instanceId={instanceId}
        scenario={scenario}
        submission={transferModal.submission}
        isOpen={transferModal.open}
        onCancel={() => {
          setTransferModal(defaultTransferModal);
        }}
        onComplete={() => {
          setTransferModal(defaultTransferModal);
        }}
      />
      <Grid container my={2} justifyContent="space-between" alignItems="center">
        <Grid md={3}>
          <Typography variant="h5" className={UtilClasses.NO_MARGINS}>
            Versions
          </Typography>
        </Grid>
        <Grid md={9} container justifyContent="flex-end" alignItems="center">
          {hasGroups && (
            <>
              <Grid container md={4}>
                <Grid md={12}>
                  <TextField
                    label="Dimension Group"
                    size="small"
                    select
                    fullWidth
                    value={toString(selectedFilter)}
                    onChange={(e) => setFilter(parseInt(e.target.value, 10))}
                  >
                    {map([defaultFilter].concat(options || []), (i) => (
                      <MenuItem key={i.value} value={toString(i.value)}>
                        {i.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </>
          )}
          <Grid>
            <IconButton
              size="small"
              onClick={() => setSearchCollapsed(!searchCollapsed)}
            >
              <Search fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={!searchCollapsed}>
        <TextField
          label="Find version"
          value={searchVersion || ''}
          placeholder="XjxkL5YG"
          type="search"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            e.target.value.trim().length === 0
              ? setSearchVersion(null)
              : setSearchVersion(e.target.value)
          }
          size="small"
          InputProps={{
            startAdornment: <Search />,
          }}
        />
        <Box py={3}>
          <Divider />
        </Box>
      </Collapse>
      <SubmissionMonitor
        instanceId={instanceId}
        scenarioId={scenario.id}
        submissions={submissions}
        refresh={refresh}
      />
      <SubmissionList
        instanceId={instanceId}
        activeSubmissionIds={activeSubmissionIds}
        submissions={filteredSubmissions}
        canSubmit={canSubmit}
        groups={
          groups &&
          groups.reduce(
            (mapping, group) => ({ ...mapping, [group.id]: group.Name }),
            {}
          )
        }
        deleteSubmission={(submission: Submission) => {
          remove(
            `/instances/${instanceId}/scenarios/${scenario.id}/submissions/${submission.id}`
          )
            .then(() => refresh())
            .catch((e) => {
              enqueueSnackbar(e.message, { variant: 'error' });
            });
        }}
        onSubmissionRestore={(submission) => {
          setRestoreModal({ open: true, submission });
        }}
        onSubmissionTransfer={(submission) => {
          setTransferModal({ open: true, submission });
        }}
      />
    </>
  );
};

export default ScenarioVersions;
