import React, {
  createContext,
  useEffect,
  useState,
  FunctionComponent,
  ComponentType,
} from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import { Profile } from '../../types/models';
import * as API from '../../services/API';

import Splash from './Splash';
import Unauthorized from './Unauthorized';

export interface ProfileProps {
  profile?: Profile;
}

export const ProfileContext = createContext<Profile | undefined>(undefined);

export function withProfile<P>(Component: ComponentType<P>) {
  return (props: P) => (
    <ProfileContext.Consumer>
      {(profile?: Profile) => <Component profile={profile} {...props} />}
    </ProfileContext.Consumer>
  );
}

interface Props {
  children: React.ReactNode;
}

const AuthController: FunctionComponent<Props> = ({ children }) => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [profile, setProfile] = useState<Profile | undefined>(undefined);
  const [profileError, setProfileError] = useState(false);

  const loadProfile = async () => {
    if (isAuthenticated) {
      try {
        const profileData: Profile = await API.load(`/profile`);
        setProfile(profileData);
      } catch (error: any) {
        setProfileError(true);
        setProfile(undefined);
        // Handle profile load failure
      }
    }
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None && isAuthenticated) {
      loadProfile();
    }
  }, [isAuthenticated, inProgress]);

  if (
    inProgress === InteractionStatus.Startup ||
    inProgress === InteractionStatus.HandleRedirect
  ) {
    return <Splash />;
  }

  if (!profile && !profileError) {
    // Optionally, return a different component or null if you don't want to show anything
    // This is where you could return a loading indicator or a message prompting the user to log in.
    return <Splash />; // Placeholder for loading or additional instructions
  }

  if (!profile && profileError) {
    return <Unauthorized />;
  }

  return (
    <ProfileContext.Provider value={profile}>
      {children}
    </ProfileContext.Provider>
  );
};

export default AuthController;
