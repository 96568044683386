import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
} from 'react';

import { isArray, trimStart, toString, values, some, trim, keys } from 'lodash';

import { Alert, AlertTitle, Box, TextField, Typography } from '@mui/material';

import * as API from '../../services/API';
import { Scenario } from '../../types/models';
import {
  isPrintableASCII,
  SCENARIO_NAME_LENGTH,
  YEAR_TEXT_LENGTH,
} from '../../utils/validation';

import APICacheContext from '../shared/APICacheContext';
import ErrorList from '../shared/ErrorList';
import useSetState from '../../hooks/useSetState';
import APICache from '../../services/APICache';
import PopupDialog from '../shared/PopupDialog';
import Flex from '../shared/Flex';

interface Props {
  scenario: Scenario;
  isOpen: boolean;
  onCancel(): void;
  onComplete(): void;
}

interface State {
  busy: boolean;
  name?: string;
  year?: string;
  formErrors: {
    name?: string[];
    year?: string[];
  };
  error?: Error;
  focusedInput?: string;
}

const INITIAL_STATE = {
  busy: false,
  name: undefined,
  year: undefined,
  formErrors: {},
  error: undefined,
  focusedInput: undefined,
};

const EditScenarioDialog: FunctionComponent<Props> = ({
  scenario,
  onComplete,
  isOpen,
  onCancel,
}) => {
  const editScenarioDialogContext = useContext(APICacheContext);
  const [state, setState] = useSetState<State>(INITIAL_STATE);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => ref.current && ref.current.focus(), 100);
    }
  }, [isOpen]);

  const validate = () => {
    const { name, year } = state;

    const fields = {
      name: name === undefined ? scenario.Name : trim(name),
      year: year === undefined ? scenario.Year : parseInt(year, 10),
    };

    const formErrors: { [P in keyof typeof fields]?: string[] } = {};

    if (fields.name === '') {
      formErrors.name = ['Name may not be blank'];
    } else if (fields.name.length > 255) {
      formErrors.name = ['Name may not be longer than 255 characters'];
    }

    if (!isPrintableASCII(fields.name)) {
      formErrors.name = formErrors.name || [];
      formErrors.name.push('Name contains unsupprted characters');
    }

    if (
      Number.isNaN(fields.year) ||
      fields.year < 1900 ||
      fields.year > 2100 ||
      (year !== undefined && !/^\s*\d{4}\s*$/.test(year))
    ) {
      formErrors.year = ['Invalid year'];
    }

    return { fields, formErrors };
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      name: trimStart(event.target.value.substring(0, SCENARIO_NAME_LENGTH)),
    });
  };

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      year: trimStart(event.target.value.substring(0, YEAR_TEXT_LENGTH)),
    });
  };

  const handleCancel = () => {
    onCancel();
    setState(INITIAL_STATE);
  };

  const handleSubmit = async () => {
    setState({ error: undefined, formErrors: {} });

    const { fields, formErrors } = validate();

    if (keys(formErrors).length !== 0) {
      setState({ formErrors });
      return;
    }

    setState({ busy: true, formErrors: {}, error: undefined });

    try {
      await API.update(
        `/instances/${scenario.ModelInstanceID}/scenarios/${scenario.id}`,
        {
          Name: fields.name,
          Year: fields.year,
        }
      );
    } catch (error: any) {
      if (
        typeof error.body === 'object' &&
        typeof error.body.errors === 'object'
      ) {
        setState({ busy: false, formErrors: error.body.errors });
      } else {
        setState({ busy: false, error });
      }
      return;
    }

    await (editScenarioDialogContext as APICache).load(
      [
        `/clients`,
        `/instances/${scenario.ModelInstanceID}/scenarios`,
        `/instances/${scenario.ModelInstanceID}/scenarios/${scenario.id}`,
      ],
      true
    );

    onComplete();
    setState(INITIAL_STATE);
  };

  const { busy, name, year, formErrors, error, focusedInput } = state;

  return (
    <PopupDialog
      open={isOpen}
      close={handleCancel}
      title="Edit Scenario"
      primaryButtons={[
        {
          id: 'edit-scenario-dialog-confirm',
          label: 'Save',
          onClick: handleSubmit,
          disabled: busy,
        },
      ]}
      isLoading={busy}
    >
      <Typography>Select a new name or year for this scenario.</Typography>
      <Flex mt={2}>
        <Box mr={2} width={1 / 3}>
          <TextField
            label="Year"
            size="small"
            helperText={
              focusedInput === 'year' &&
              `${
                YEAR_TEXT_LENGTH -
                (year === undefined ? scenario.Year.toString() : year).length
              } characters left`
            }
            onChange={handleYearChange}
            type="number"
            onFocus={() => {
              setState({
                focusedInput: `year`,
              });
            }}
            inputRef={ref}
            onBlur={() => {
              setState({
                focusedInput: undefined,
              });
            }}
            disabled={busy}
            value={year === undefined ? toString(scenario.Year) : year}
            error={isArray(formErrors.year) && formErrors.year.length > 0}
          />
        </Box>
        <Box flex="auto">
          <TextField
            label="Name"
            size="small"
            helperText={
              focusedInput === 'scenarioName' &&
              `${
                SCENARIO_NAME_LENGTH -
                (name === undefined ? scenario.Name : name).length
              } characters left`
            }
            onFocus={() => {
              setState({
                focusedInput: `scenarioName`,
              });
            }}
            fullWidth
            onBlur={() => {
              setState({
                focusedInput: undefined,
              });
            }}
            disabled={busy}
            error={isArray(formErrors.name) && formErrors.name.length > 0}
            value={name === undefined ? scenario.Name : name}
            onChange={handleNameChange}
          />
        </Box>
      </Flex>

      {some(
        values(formErrors),
        (errors) => Array.isArray(errors) && errors.length > 0
      ) && (
        <Box mt={2}>
          <ErrorList errors={formErrors} />
        </Box>
      )}

      {error && (
        <Box mt={2}>
          <Alert severity="error">
            <AlertTitle>Something went wrong</AlertTitle>
            Unable to save, check your connection and try again.
          </Alert>
        </Box>
      )}
    </PopupDialog>
  );
};

export default EditScenarioDialog;
