import React, { FunctionComponent, useEffect } from 'react';
import { map, range, some } from 'lodash';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Select,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  ArrowBackIos,
  ArrowForwardIos,
  ArrowRightAlt,
  CalendarMonth,
} from '@mui/icons-material';

import useSetState from '../../../../../hooks/useSetState';
import StyledPopper from '../../../../shared/StyledPopper';

import { IChartDataDate } from './ChartRangePicker';

interface IQuarterSelectorProps
  extends Pick<
    IQuarterRangePickerPickerProps,
    'startYear' | 'endYear' | 'dates'
  > {
  quarter: number;
  year: number;
  onSelectYear: (val: any) => void;
  title: string;
  minDate?: { quarter: number; year: number };
}

interface IQuarterSelectorState {
  yearState: number;
  quarterState: number;
}

const QuarterSelector: FunctionComponent<IQuarterSelectorProps> = ({
  quarter,
  year,
  onSelectYear,
  startYear,
  endYear,
  dates,
  title,
  minDate,
}: IQuarterSelectorProps) => {
  const [state, setState] = useSetState<IQuarterSelectorState>({
    yearState: year,
    quarterState: quarter,
  });

  useEffect(() => {
    onSelectYear({
      quarter: state.quarterState,
      year: state.yearState,
    });
  }, [state]);

  const styles: Record<string, React.CSSProperties> = {
    yearNav: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 1px 5px',
      justifyContent: 'center',
    },
    yearButton: {
      flex: '1',
      textAlign: 'center',
      margin: '0 2px',
    },
    yearButtonText: {
      verticalAlign: 'middle',
      fontWeight: 'bold',
    },
    quarterContainer: {
      display: 'inline-block',
      padding: '1px',
      boxSizing: 'border-box',
    },
    quarterButton: {
      lineHeight: '3em',
      textAlign: 'center',
      width: '100%',
      minWidth: '4em',
    },
  };

  const yearsRange: number[] = range(startYear.year, endYear.year + 1);

  const onPrevYear = (): void => {
    setState({ yearState: state.yearState - 1 });
  };

  const onNextYear = (): void => {
    setState({ yearState: state.yearState + 1 });
  };

  const onSelect = (quarter: number): void => {
    setState({ quarterState: quarter });
  };

  const renderQuarter = (quarterItem: number): JSX.Element => {
    const selected: boolean =
      quarter === quarterItem && year === state.yearState;

    return (
      <Box
        className="quarter-container"
        style={styles.quarterContainer}
        key={quarterItem}
      >
        <Button
          size="small"
          variant={selected ? 'contained' : 'outlined'}
          sx={{ m: 0.25 }}
          onClick={() => onSelect(quarterItem)}
          disabled={
            !some(dates, { Date: `${state.yearState} Q${quarterItem}` }) ||
            (minDate &&
              (state.yearState < minDate.year ||
                (state.yearState === minDate.year &&
                  quarterItem < minDate.quarter)))
          }
        >
          {`Q${quarterItem}`}
        </Button>
      </Box>
    );
  };

  const quarters: JSX.Element[] = [];
  for (let i = 1; i <= 4; i++) {
    quarters.push(renderQuarter(i));
  }

  return (
    <Box>
      <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {title}
      </Typography>{' '}
      <Box className="month-year-nav" style={styles.yearNav}>
        <IconButton
          size="small"
          onClick={onPrevYear}
          disabled={state.yearState <= startYear.year}
        >
          <ArrowBackIos fontSize="small" />
        </IconButton>
        <Select
          native
          size="small"
          value={state.yearState}
          onChange={(e) => {
            (e as any).stopPropagation();
            setState({ yearState: Number(e.target.value) });
          }}
        >
          {map(yearsRange, (yearItem) => (
            <option
              key={yearItem}
              value={yearItem}
              {...(minDate?.year && { disabled: yearItem < minDate?.year })}
            >
              {yearItem}
            </option>
          ))}
        </Select>
        <IconButton
          size="small"
          onClick={onNextYear}
          disabled={state.yearState >= endYear.year}
        >
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      </Box>
      <Box style={styles.quarters}>{quarters}</Box>
    </Box>
  );
};

interface IQuarterRangePickerPickerProps {
  startYear: { quarter: number; year: number };
  endYear: { quarter: number; year: number };
  onChange: (
    startYear: { quarter: number; year: number },
    endYear: { quarter: number; year: number }
  ) => void;
  dates: IChartDataDate[];
  value: { quarter: number; year: number }[];
}

interface IQuarterRangePickerPickerState {
  startState: { quarter: number; year: number };
  endState: { quarter: number; year: number };
}

const QuarterRangePicker: FunctionComponent<IQuarterRangePickerPickerProps> = ({
  startYear,
  endYear,
  onChange,
  dates,
  value,
}: IQuarterRangePickerPickerProps) => {
  const [state, setState] = useSetState<IQuarterRangePickerPickerState>({
    startState: startYear,
    endState: endYear,
  });

  useEffect(() => {
    setState({
      startState: value[0],
      endState: value[1],
    });
  }, [value]);

  const onSetStart = (startState: { quarter: number; year: number }): void => {
    setState({ startState });
  };

  const onSetEnd = (endState: { quarter: number; year: number }): void => {
    setState({ endState });
  };

  const handleClose = (): void => {
    onChange(state.startState, state.endState);
  };

  return (
    <StyledPopper
      onClose={handleClose}
      content={() => (
        <Grid container spacing={2}>
          <Grid md={6}>
            <QuarterSelector
              quarter={state.startState.quarter}
              year={state.startState.year}
              onSelectYear={onSetStart}
              startYear={startYear}
              endYear={endYear}
              dates={dates}
              title="Start Date"
            />
          </Grid>
          <Grid md={6}>
            <QuarterSelector
              quarter={state.endState.quarter}
              year={state.endState.year}
              onSelectYear={onSetEnd}
              startYear={startYear}
              endYear={endYear}
              dates={dates}
              title="End Date"
              minDate={state.startState}
            />
          </Grid>
        </Grid>
      )}
      id="quarter-range-picker-popper"
    >
      <Button startIcon={<CalendarMonth />} variant="outlined" size="small">
        <Chip
          size="small"
          className="mr-1"
          label={`Q${state.startState.quarter}-${state.startState.year}`}
        />
        <ArrowRightAlt sx={{ color: '#c3ced5' }} fontSize="small" />
        <Chip
          size="small"
          className="ml-1"
          label={`Q${state.endState.quarter}-${state.endState.year}`}
        />
      </Button>
    </StyledPopper>
  );
};

export default QuarterRangePicker;
