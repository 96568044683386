import React, { FunctionComponent } from 'react';

import { useSnackbar } from 'notistack';

import * as API from '../../../../services/API';

import { IPortfolioReportStateData } from '../../index';

import useSetState from '../../../../hooks/useSetState';

import PopupDialog from '../../../shared/PopupDialog';

import { IDataCutFieldsList } from './EditPortfolioSourceType';

interface IDeleteDataCutDialogProps {
  isOpen: boolean;
  onClose: () => void;
  dataCut: IDataCutFieldsList;
  refreshData: () => void;
  selectedReport?: IPortfolioReportStateData;
}

interface IDeleteDataCutDialogState {
  loading: boolean;
}

const initialDeleteDataCutDialogState: IDeleteDataCutDialogState = {
  loading: false,
};

const DeleteDataCutDialog: FunctionComponent<IDeleteDataCutDialogProps> = (
  props: IDeleteDataCutDialogProps
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useSetState<IDeleteDataCutDialogState>(
    initialDeleteDataCutDialogState
  );

  const deleteDataCut = async (): Promise<void> => {
    try {
      setState({ loading: true });
      await API.remove(
        `/portfolio_datasources/${props.selectedReport?.DataSourceID}/portfolio_datacuts/${props.dataCut.id}`
      );
      props.onClose();
      props.refreshData();
      setState({ loading: false });
    } catch (e) {
      enqueueSnackbar(e as string, { variant: 'error' });
    }
  };
  return (
    <PopupDialog
      open={props.isOpen}
      title="Delete Data Cut"
      close={() => {
        if (!state.loading) {
          props.onClose();
        }
      }}
      primaryButtons={[
        {
          id: 'delete-data-cut',
          label: 'Delete',
          onClick: deleteDataCut,
        },
      ]}
    >
      Are you sure you want to delete the datacut - {props.dataCut?.Name}?
    </PopupDialog>
  );
};

export default DeleteDataCutDialog;
