import { Box } from '@mui/material';
import React, { PureComponent } from 'react';

import CenteredSpinner from './CenteredSpinner';

class Loading extends PureComponent {
  public render() {
    return (
      <Box m={5}>
        <CenteredSpinner />
      </Box>
    );
  }
}

export default Loading;
