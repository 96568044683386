import React, { FormEvent, PureComponent } from 'react';

import {
  Card,
  CardContent,
  Box,
  Switch,
  FormGroup,
  FormControlLabel,
  IconButton,
  Snackbar,
  Alert,
  AlertTitle,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import red from '@mui/material/colors/red';
import { Delete } from '@mui/icons-material';

import { loadProfile } from '../../services/Auth';
import * as API from '../../services/API';
import {
  ModelMembership,
  ModelMembershipRole,
  UserRole,
} from '../../types/models';

import { ProfileProps, withProfile } from '../shared/AuthController';
import APICacheContext from '../shared/APICacheContext';
import UtilClasses from '../../assets/stylesheets/UtilClasses.module.scss';
import APICache from '../../services/APICache';
import CenteredSpinner from '../shared/CenteredSpinner';

interface Props extends ProfileProps {
  membership: ModelMembership;
}

interface State {
  busy: boolean;
  error?: Error;
}

const INITIAL_STATE: State = {
  busy: false,
  error: undefined,
};

class TeamMemberCard extends PureComponent<Props, State> {
  public static contextType = APICacheContext;
  public state = INITIAL_STATE;

  public render() {
    const { membership, profile } = this.props;
    const { busy, error } = this.state;

    // Non-admin users may not delete their own membership to a model
    const locked =
      profile !== undefined &&
      membership.User.id === profile.User.id &&
      profile.User.Role !== UserRole.Trinity_Admin_User;

    return (
      <Card>
        <CardContent sx={{ p: '0.75rem !important' }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid xs={6}>
              <Typography flex="auto" mr={2}>
                <Typography>{membership.User.Display_Name}</Typography>
                <Typography sx={{ color: '#8c8c8c', wordBreak: 'break-all' }}>
                  {membership.User.User_Name}
                </Typography>
              </Typography>
            </Grid>
            <Grid xs={1}>
              {busy && (
                <Box mr={2}>
                  <CenteredSpinner />
                </Box>
              )}
            </Grid>
            <Grid xs={4} container justifyContent="flex-end">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={busy || locked}
                      checked={membership.Role === ModelMembershipRole.Owner}
                      className={UtilClasses.NO_MARGINS}
                      onChange={this.handleChangeRole}
                    />
                  }
                  label="Model Owner"
                />
              </FormGroup>
            </Grid>
            <Grid xs={1}>
              <IconButton disabled={busy || locked} onClick={this.handleDelete}>
                <Delete sx={{ color: red[700] }} />
              </IconButton>
            </Grid>
          </Grid>
          {error && (
            <Box mt={2}>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={Boolean(error)}
                title="Something went wrong"
              >
                <Alert
                  onClose={() => this.setState({ error: undefined })}
                  severity="error"
                >
                  <AlertTitle>Something went wrong</AlertTitle>
                  Check your connection and try again
                </Alert>
              </Snackbar>
            </Box>
          )}
        </CardContent>
      </Card>
    );
  }

  private handleChangeRole = async (event: FormEvent<HTMLInputElement>) => {
    const { membership, profile } = this.props;

    const Role = event.currentTarget.checked
      ? ModelMembershipRole.Owner
      : ModelMembershipRole.Contributor;

    this.setState({ busy: true, error: undefined });

    try {
      await API.update(`/model_memberships/${membership.id}`, {
        Role,
      });

      await (this.context as APICache).load(
        [`/models/${membership.ModelID}/model_memberships`],
        true
      );

      if (profile && profile.User.id === membership.UserID) {
        await loadProfile();
      }
    } catch (error: any) {
      this.setState({ error, busy: false });
      return;
    }

    this.setState(INITIAL_STATE);
  };

  private handleDelete = async () => {
    const { membership, profile } = this.props;

    this.setState({ busy: true, error: undefined });

    try {
      await API.remove(`/model_memberships/${membership.id}`);

      await (this.context as APICache).load(
        [`/models/${membership.ModelID}/model_memberships`],
        true
      );

      if (profile !== undefined && membership.UserID === profile.User.id) {
        await loadProfile();
      }
    } catch (error: any) {
      this.setState({ error, busy: false });
      return;
    }

    // Skip this.setState since the component should be unmounted
  };
}

export default withProfile(TeamMemberCard);
