import React from 'react';
import { FileWithPath } from 'react-dropzone';

import { ModelInstance, Upload } from '../../types/models';

export interface IUploadData {
  fileData: Upload[];
  files: FileWithPath[];
  modelInstanceId?: ModelInstance['id'];
  fileProcessingURL: string;
  isFilesProcessingDone: boolean;
}

export interface IUploadContext {
  fileUploadData: IUploadData;
  handleDataChange: (
    fileData: Upload[],
    file: FileWithPath[],
    modelInstanceId: ModelInstance['id'] | undefined,
    fileProcessingURL: string,
    isFilesProcessingDone: boolean
  ) => void;
  isFilesProcessingDone: (val: boolean) => void;
}

export const UploadContext = React.createContext<IUploadContext | null>(null);
