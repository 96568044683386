import { SourceDropDown } from '../types/models';

import { useProfile } from '../hooks/useProfile';

import { parseId } from './parsing';

export function GetParams(search: string) {
  const profile = useProfile();
  const params = new URLSearchParams(search);

  const clientId =
    profile && profile.User.ClientID !== null
      ? profile.User.ClientID
      : parseId(params.get('client'));
  const modelId = parseId(params.get('model'));
  const modelInstanceId = parseId(params.get('instance'));
  const scenarioId = parseId(params.get('scenario'));
  let source: Partial<SourceDropDown> | undefined;
  switch (params.get('source')) {
    case 'inputs':
      source = 'inputs';
      break;
    case 'outputs':
      source = 'outputs';
      break;
    case 'standardReport':
      source = 'standardReport';
      break;
    default:
      source = undefined;
  }

  return {
    clientId,
    modelId,
    modelInstanceId,
    scenarioId,
    source,
  };
}
