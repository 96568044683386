import React, { FunctionComponent } from 'react';

import { useSnackbar } from 'notistack';

import * as API from '../../../../services/API';

import { IPortfolioReportStateData } from '../../index';

import useSetState from '../../../../hooks/useSetState';

import PopupDialog from '../../../shared/PopupDialog';

import { PortfolioDataSourceData } from '../../types';

import { IDimensionsList } from './EditPortfolioSourceType';

interface IDeleteDimensionDialogProps {
  isOpen: boolean;
  onClose: (e: boolean) => void;
  dimension: IDimensionsList;
  selectedReport?: IPortfolioReportStateData;
  dataSourceID?: PortfolioDataSourceData['id'];
}

interface IDeleteDimensionDialogState {
  loading: boolean;
}

const initialDeleteDimensionDialogState: IDeleteDimensionDialogState = {
  loading: false,
};

const DeleteDimensionDialog: FunctionComponent<IDeleteDimensionDialogProps> = (
  props: IDeleteDimensionDialogProps
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useSetState<IDeleteDimensionDialogState>(
    initialDeleteDimensionDialogState
  );

  const deleteDimension = async (): Promise<void> => {
    try {
      setState({ loading: true });
      await API.remove(
        `/portfolio_datasources/${props.dataSourceID}/portfolio_dimensions/${props.dimension.id}`
      );
      props.onClose(true);
      setState({ loading: false });
    } catch (e) {
      enqueueSnackbar(e as string, { variant: 'error' });
    }
  };

  return (
    <PopupDialog
      open={props.isOpen}
      title="Delete Dimension"
      close={() => {
        if (!state.loading) {
          props.onClose(false);
        }
      }}
      primaryButtons={[
        {
          id: 'delete-dimension-button',
          label: 'Delete',
          onClick: deleteDimension,
        },
      ]}
    >
      Are you sure you want to delete the dimension -{' '}
      {props.dimension?.fieldValue}?
    </PopupDialog>
  );
};

export default DeleteDimensionDialog;
