import React, {
  FormEvent,
  FunctionComponent,
  useContext,
  ChangeEvent,
} from 'react';
import { isArray, keys, some, trimStart, values } from 'lodash';
import { Box, Alert, AlertTitle, TextField, Typography } from '@mui/material';

import * as API from '../../services/API';
import { Scenario } from '../../types/models';
import {
  isPrintableASCII,
  SCENARIO_NAME_LENGTH,
  YEAR_TEXT_LENGTH,
} from '../../utils/validation';

import APICacheContext from '../shared/APICacheContext';
import ErrorList from '../shared/ErrorList';
import SelectModelInstance from '../shared/SelectModelInstance';
import useSetState from '../../hooks/useSetState';
import APICache from '../../services/APICache';
import PopupDialog from '../shared/PopupDialog';
import Flex from '../shared/Flex';

interface Props {
  clientId: number;
  modelId: number;
  scenario: Scenario;
  isOpen: boolean;
  onCancel(): void;
  onComplete(): void;
}

interface State {
  busy: boolean;
  name?: string;
  year?: string;
  instanceId?: number;
  formErrors: {
    name?: string[];
    year?: string[];
    instanceId?: string[];
  };
  error?: Error;
  focusedInput?: string;
}

const INITIAL_STATE = {
  busy: false,
  name: undefined,
  year: undefined,
  instanceId: undefined,
  formErrors: {},
  error: undefined,
  focusedInput: undefined,
};

const CopyScenarioDialog: FunctionComponent<Props> = ({
  scenario,
  modelId,
  isOpen,
  clientId,
  onComplete,
  onCancel,
}) => {
  const copyScenarioDialogContext = useContext(APICacheContext);
  const [state, setState] = useSetState<State>(INITIAL_STATE);

  const validate = () => {
    const { name, year, instanceId } = state;

    const fields = {
      name: name === undefined ? `${scenario.Name} Copy` : name.trim(),
      year: year === undefined ? scenario.Year : parseInt(year, 10),
      instanceId:
        instanceId === undefined ? scenario.ModelInstanceID : instanceId,
    };

    const formErrors: { [P in keyof typeof fields]?: string[] } = {};

    if (fields.name === '') {
      formErrors.name = ['Name may not be blank'];
    } else if (fields.name.length > 255) {
      formErrors.name = ['Name may not be longer than 255 characters'];
    }

    if (!isPrintableASCII(fields.name)) {
      formErrors.name = formErrors.name || [];
      formErrors.name.push('Name contains unsupported characters');
    }

    if (Number.isNaN(fields.year) || fields.year < 1900 || fields.year > 2100) {
      formErrors.year = ['Invalid year'];
    }

    return { fields, formErrors };
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setState({ error: undefined, formErrors: {} });

    const { fields, formErrors } = validate();

    if (keys(formErrors).length !== 0) {
      setState({ formErrors });
      return;
    }

    setState({ busy: true, formErrors: {}, error: undefined });

    let newScenario: Scenario;

    try {
      newScenario = await API.create(
        `/instances/${fields.instanceId}/scenarios`,
        {
          Source_ScenarioID: scenario.id,
          Name: fields.name,
          Year: fields.year,
        }
      );
    } catch (error: any) {
      if (
        typeof error.body === 'object' &&
        typeof error.body.errors === 'object'
      ) {
        setState({ busy: false, formErrors: error.body.errors });
      } else {
        setState({ busy: false, error });
      }
      return;
    }

    await (copyScenarioDialogContext as APICache).load(
      [`/instances/${fields.instanceId}/scenarios`],
      true
    );

    onComplete();
    setState(INITIAL_STATE);

    return newScenario;
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      name: trimStart(event.target.value.substring(0, SCENARIO_NAME_LENGTH)),
    });
  };

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      year: trimStart(event.target.value.substring(0, YEAR_TEXT_LENGTH)),
    });
  };

  const handleInstanceChange = (instanceId?: number) => {
    setState({ instanceId });
  };

  const handleCancel = () => {
    onCancel();
    setState(INITIAL_STATE);
  };

  const { busy, name, year, instanceId, formErrors, error, focusedInput } =
    state;

  return (
    <PopupDialog
      open={isOpen}
      close={handleCancel}
      title="Copy Scenario"
      isLoading={busy}
      primaryButtons={[
        {
          id: 'copy-scenario-dialog-submit',
          label: 'Confirm',
          disabled: busy,
          onClick: handleSubmit,
        },
      ]}
    >
      <Typography>
        This will duplicate the existing scenario with all the same inputs and
        data.
      </Typography>

      <Box my={2}>
        <SelectModelInstance
          size="small"
          clientId={clientId}
          modelId={modelId}
          modelInstanceId={
            instanceId === undefined ? scenario.ModelInstanceID : instanceId
          }
          onChange={handleInstanceChange}
        />
      </Box>

      <Flex>
        <Box mr={2} width={1 / 3}>
          <TextField
            label="Year"
            size="small"
            helperText={
              focusedInput === 'year' &&
              `${
                YEAR_TEXT_LENGTH -
                (year === undefined ? scenario.Year.toString() : year).length
              } characters left`
            }
            onChange={handleYearChange}
            onFocus={() => {
              setState({
                focusedInput: `year`,
              });
            }}
            onBlur={() => {
              setState({
                focusedInput: undefined,
              });
            }}
            type="number"
            disabled={busy}
            value={year === undefined ? scenario.Year.toString() : year}
            error={Array.isArray(formErrors.year) && formErrors.year.length > 0}
          />
        </Box>
        <Box flex="auto">
          <TextField
            fullWidth
            size="small"
            label="Name"
            helperText={
              focusedInput === 'scenarioName' &&
              `${
                SCENARIO_NAME_LENGTH -
                (name === undefined ? `${scenario.Name} Copy` : name).length
              } characters left`
            }
            onChange={handleNameChange}
            onFocus={() => {
              setState({
                ...state,
                focusedInput: `scenarioName`,
              });
            }}
            onBlur={() => {
              setState({
                ...state,
                focusedInput: undefined,
              });
            }}
            disabled={busy}
            value={name === undefined ? `${scenario.Name} Copy` : name}
            error={Array.isArray(formErrors.name) && formErrors.name.length > 0}
          />
        </Box>
      </Flex>

      {some(
        values(formErrors),
        (errors) => isArray(errors) && errors.length > 0
      ) && (
        <Box mt={2}>
          <ErrorList errors={formErrors} />
        </Box>
      )}

      {error && (
        <Box mt={2}>
          <Alert severity="error">
            <AlertTitle>Something went wrong</AlertTitle>

            {(error as any).body.message ||
              'Unable to copy scenario, check your connection and verify that the model instance you are copying to has been published.'}
          </Alert>
        </Box>
      )}
    </PopupDialog>
  );
};

export default CopyScenarioDialog;
