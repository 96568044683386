import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  cyan,
  teal,
  green,
  orange,
  lightGreen,
  blueGrey,
  lime,
  deepOrange,
  brown,
} from '@mui/material/colors';

const minColorsGenerator = (color: Record<string, string>) => [
  color[300],
  color[500],
  color[700],
  color[900],
  color['A200'],
  color['A700'],
];

const maxColorsGenerator = (color: Record<string, string>) => [
  color[300],
  color[400],
  color[500],
  color[600],
  color[700],
  color[800],
  color[900],
  color['A100'],
  color['A200'],
  color['A400'],
  color['A700'],
];

export const getColors = (length: number): Map<number, string[]> => {
  const colorsPalette = new Map();

  if (length <= 6) {
    colorsPalette.set(0, minColorsGenerator(red));
    colorsPalette.set(1, minColorsGenerator(green));
    colorsPalette.set(2, minColorsGenerator(blue));
    colorsPalette.set(3, minColorsGenerator(deepPurple));
    colorsPalette.set(4, minColorsGenerator(indigo));
    colorsPalette.set(5, minColorsGenerator(pink));
    colorsPalette.set(6, minColorsGenerator(cyan));
    colorsPalette.set(7, minColorsGenerator(teal));
    colorsPalette.set(8, minColorsGenerator(purple));
    colorsPalette.set(9, minColorsGenerator(lightGreen));
    colorsPalette.set(10, minColorsGenerator(lime));
    colorsPalette.set(11, minColorsGenerator(orange));
    colorsPalette.set(12, minColorsGenerator(deepOrange));
    colorsPalette.set(13, minColorsGenerator(brown));
    colorsPalette.set(14, minColorsGenerator(blueGrey));
  } else {
    colorsPalette.set(0, maxColorsGenerator(red));
    colorsPalette.set(1, minColorsGenerator(green));
    colorsPalette.set(2, minColorsGenerator(blue));
    colorsPalette.set(3, maxColorsGenerator(deepPurple));
    colorsPalette.set(4, maxColorsGenerator(indigo));
    colorsPalette.set(5, maxColorsGenerator(pink));
    colorsPalette.set(6, maxColorsGenerator(cyan));
    colorsPalette.set(7, maxColorsGenerator(teal));
    colorsPalette.set(8, maxColorsGenerator(purple));
    colorsPalette.set(9, maxColorsGenerator(lightGreen));
    colorsPalette.set(10, maxColorsGenerator(lime));
    colorsPalette.set(11, maxColorsGenerator(orange));
    colorsPalette.set(12, maxColorsGenerator(deepOrange));
    colorsPalette.set(13, maxColorsGenerator(brown));
    colorsPalette.set(14, maxColorsGenerator(blueGrey));
  }
  return colorsPalette;
};
