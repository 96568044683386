import React, { FunctionComponent, useEffect, useState } from 'react';

import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';

import { Search } from '@mui/icons-material';

import {
  filter as filterList,
  filter,
  includes,
  isEqual,
  map,
  toLower,
} from 'lodash';

import { byName } from '../../utils/misc';
import { RowIdentifier } from '../../types/models';

import CheckboxCard from './CheckboxCard';
import { FixedHeader } from './FixedHeader';

import { Dispatch } from './Store';

interface Props {
  outputs: RowIdentifier[];
  selection: number[];

  dispatch: Dispatch;
}

const OutputsColumn: FunctionComponent<Props> = ({
  outputs,
  selection,
  dispatch,
}) => {
  const [outputSelect, setOutputSelect] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');

  const handleOutputSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOutputSelect(event.target.checked);
    dispatch({
      type: 'TOGGLE_OUTPUTS',
      ids: map(
        filterList(outputs, (i) => includes(toLower(i.Name), toLower(search))),
        'id'
      ),
      value: event.target.checked,
    });
  };

  useEffect(() => {
    setOutputSelect(isEqual(selection.length, outputs.length));
  }, [selection]);

  return (
    <Box>
      <FixedHeader>
        <Typography variant="subtitle1" sx={{ mt: 1 }}>
          Outputs
        </Typography>
      </FixedHeader>
      <Box pb={3.2} />
      <FormControlLabel
        control={
          <Checkbox
            checked={outputSelect}
            onChange={handleOutputSelectChange}
          />
        }
        label="Select all"
      />
      <Box pb={2.5} />
      <TextField
        label="Search Outputs"
        InputProps={{
          startAdornment: <Search />,
        }}
        fullWidth
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Box style={{ paddingBottom: '0.3rem' }} />
      <Box pb={2} />
      {map(
        filter(outputs, (i) => includes(toLower(i.Name), toLower(search))).sort(
          byName
        ),
        (output, index, array) => (
          <Box key={output.id}>
            <CheckboxCard
              checked={selection.indexOf(output.id) !== -1}
              onCardClick={(checked) =>
                dispatch({
                  type: 'TOGGLE_OUTPUT',
                  id: output.id,
                  value: checked,
                })
              }
              label={output.Name}
            />
            {index < array.length - 1 && <Box pb={2} />}
          </Box>
        )
      )}
    </Box>
  );
};

export default OutputsColumn;
