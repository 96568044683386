import {
  every,
  filter,
  forEach,
  indexOf,
  isEmpty,
  keys,
  reduce,
  toLower,
} from 'lodash';
import { IRowNode } from 'ag-grid-community';

export const byName = (a: { Name: string }, b: { Name: string }) =>
  a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0;

export const unique = <T>(value: T, index: number, self: Array<T>) =>
  indexOf(self, value) === index;

/**
 * Method to return the file extension
 * @param file
 */
export const getFileExtension = (file: any) => {
  const fileName = file && file[0].name;
  return fileName.slice(fileName.lastIndexOf('.') + 1);
};

/**
 * Remove duplicate values from a list by id
 */
export const removeDuplicateValuesById = <T extends { id: number }>(
  values: T[]
): T[] => {
  return filter(
    values,
    (el: { id: number }) =>
      filter(values, (e: { id: number }) => e.id === el.id).length === 1
  );
};

export const filterSearch = (search: string | undefined, only: string[]) => {
  const params = new URLSearchParams(search);
  const onlySet = new Set(only);
  forEach(
    filter(Array.from(keys(params)), (key) => !onlySet.has(key)),
    (key) => params.delete(key)
  );

  return params.toString();
};

export const getFormattedFileSize = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const mergeByName = <T extends { name: string }>(
  arr: T[]
): { with(arr2: T[]): T[] } => ({
  with: (arr2) => {
    return Array.from(
      reduce(
        [...arr, ...arr2],
        (r, o) => r.set(toLower(o.name), o),
        new Map()
      ).values()
    );
  },
});

export const containsOnly = <T>(array1: T[], array2: T[]): boolean => {
  if (!isEmpty(array2)) {
    const set = new Set(array1);
    return every(array2, (elem) => set.has(elem));
  }
  return false;
};

export const hasDuplicates = <T>(array: T[]): boolean => {
  return new Set(array).size !== array.length;
};

export const dateSortInGrid = <T>(
  valueA: string,
  valueB: string,
  nodeA: IRowNode<T>,
  nodeB: IRowNode<T>,
  isDescending: boolean
): number => {
  const dateA = new Date(valueA);
  const dateB = new Date(valueB);

  const comparison = dateA.getTime() - dateB.getTime();

  return isDescending ? -comparison : comparison;
};
