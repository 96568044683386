import { values } from 'lodash';
import React, { PureComponent, ReactNode } from 'react';

import APICache, { Subscription, Summary } from '../../services/APICache';

import APICacheContext from './APICacheContext';
import Loading from './Loading';
import LoadingError from './LoadingError';

export type LoaderInfo<T> = Summary<T>;

interface Props<T> {
  needs: { [P in keyof T]: string };
  force?: boolean;

  handleErrors?: boolean;
  handleLoading?: boolean;

  render(summary: Summary<T>, refresh: () => void): ReactNode;
}

interface State<T> {
  summary?: Summary<T>;
}

class Loader<T> extends PureComponent<Props<T>, State<T>> {
  public state: State<T> = {};
  private subscription?: Subscription;

  public componentDidMount() {
    if (this.context === undefined) {
      throw Error('APICacheContext.Provider missing');
    }

    const { needs } = this.props;
    const paths = values(needs);

    this.subscription = (this.context as APICache).subscribe(
      paths as string[],
      this.update
    );
    (this.context as APICache).load(paths as string[], this.props.force);
    this.update();
  }

  public componentDidUpdate(prevProps: Props<T>) {
    if (this.context === undefined) {
      throw Error('APICacheContext.Provider missing');
    }

    const paths = Object.values(this.props.needs);
    const prevPaths = Object.values(prevProps.needs);
    if (JSON.stringify(paths) !== JSON.stringify(prevPaths)) {
      if (this.subscription !== undefined) {
        this.subscription.cancel();
      }

      this.subscription = (this.context as APICache).subscribe(
        paths as string[],
        this.update
      );
      (this.context as APICache).load(paths as string[], this.props.force);
      this.update();
    }
  }

  public componentWillUnmount() {
    if (this.subscription !== undefined) {
      this.subscription.cancel();
    }
  }

  public render() {
    const { summary } = this.state;

    if (summary === undefined) {
      return null;
    }

    const { handleErrors, handleLoading } = this.props;
    const { errors, loading } = summary;

    if (handleErrors && errors && errors.length > 0) {
      return <LoadingError retry={this.refresh} />;
    }

    if (handleLoading && loading) {
      return <Loading />;
    }

    return this.props.render(summary, this.refresh);
  }

  private update = () => {
    if (this.context === undefined) {
      throw Error('APICacheContext.Provider missing');
    }

    const { needs } = this.props;

    this.setState({
      summary: (this.context as APICache).summarize(needs),
    });
  };

  private refresh = () => {
    const paths = values(this.props.needs);
    (this.context as APICache).load(paths as string[], true);
  };
}

Loader.contextType = APICacheContext;

export default Loader;
