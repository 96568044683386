import React, {
  ChangeEvent,
  createRef,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { includes, map, snakeCase, trimEnd, trimStart } from 'lodash';
import { useSnackbar } from 'notistack';

import { Stack, TextField, MenuItem } from '@mui/material';

import { useSearchParams } from 'react-router-dom';

import * as API from '../../services/API';

import {
  Client,
  Model,
  ModelInstance,
  ModelInstanceVisibility,
  User,
} from '../../types/models';

import { ProfileContext } from '../shared/AuthController';
import { isAdminUser } from '../../utils/user';

import PopupDialog from '../shared/PopupDialog';

import ClearableTextField from '../shared/ClearableTextField';

import useSetState from '../../hooks/useSetState';

import { useData } from '../../hooks/useData';

import { parseId } from '../../utils/parsing';

import { isModelMember } from '../../utils/profile';

import { portfolioReportTypes } from './portfolioDashboardConstants';
import { PortfolioReportData } from './types';

export const REPORT_DESCRIPTION_LENGTH: number = 256;
export const REPORT_NAME_LENGTH: number = 75;

interface ICreateNewReportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  refreshReports: () => void;
  reportNames: PortfolioReportData['Name'][];
  clientId?: Client['id'];
  reportName?: string;
}

interface ICreateNewReportDialogState {
  reportName: string;
  reportDescription: string;
  selectedPortfolioReportType?: string;
  duplicateReportName: boolean;
  onFocusedInput: string;
  loading: boolean;
  selectedModel?: Model['id'];
  selectedModelInstance?: ModelInstance['id'];
}

const initialState: ICreateNewReportDialogState = {
  reportName: '',
  reportDescription: '',
  selectedPortfolioReportType: 'Waterfall',
  duplicateReportName: false,
  onFocusedInput: '',
  loading: false,
  selectedModel: undefined,
  selectedModelInstance: undefined,
};

const CreateNewReportDialog: FunctionComponent<ICreateNewReportDialogProps> = (
  props: ICreateNewReportDialogProps
) => {
  const { enqueueSnackbar } = useSnackbar();

  const profile = useContext(ProfileContext);
  const isUserAnAdmin = isAdminUser(profile?.User as User);

  const [state, setState] =
    useSetState<ICreateNewReportDialogState>(initialState);

  const [searchParams] = useSearchParams();

  const clientId =
    profile && profile.User.ClientID !== null
      ? profile.User.ClientID
      : parseId(searchParams.get('client'));

  const { data } = useData<{
    models?: Model[];
    modelInstances?: ModelInstance[];
  }>(() => {
    return {
      models:
        state.selectedPortfolioReportType === 'Waterfall'
          ? `/clients/${clientId}/models`
          : undefined,
      modelInstances: state.selectedModel
        ? `/clients/${clientId}/models/${state.selectedModel}/model_instances_load`
        : undefined,
    };
  }, [state.selectedPortfolioReportType, state.selectedModel]);

  useEffect(() => {
    if (!isUserAnAdmin && props.isOpen) {
      setState({
        selectedPortfolioReportType: 'Waterfall',
      });
    }
  }, [isUserAnAdmin, props.isOpen]);

  useEffect(() => {
    if (props.reportName && props.isOpen) {
      setState({ reportName: props.reportName });
    }
  }, [props.reportName, props.isOpen]);

  const reportNameInputRef = createRef<HTMLInputElement>();

  const handleReportNameChange = useCallback((value: string) => {
    setState({ reportName: trimStart(value.substring(0, REPORT_NAME_LENGTH)) });
  }, []);

  const handleReportDescriptionChange = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    setState({
      reportDescription: trimStart(
        e.target.value.substring(0, REPORT_DESCRIPTION_LENGTH)
      ),
    });
  };

  const handlePortfolioReportTypeSelect = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    setState({
      selectedPortfolioReportType: e.target.value,
    });
  };

  const handleChangeModel = (e: ChangeEvent<HTMLInputElement>): void => {
    setState({
      selectedModel: Number(e.target.value) as Model['id'],
    });
  };

  const handleChangeModelInstance = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    setState({
      selectedModelInstance: Number(e.target.value) as ModelInstance['id'],
    });
  };

  const existingReportNames: string[] = map(props.reportNames, (i) =>
    snakeCase(i)
  );

  const handleCreateNewReport = async (): Promise<void> => {
    if (includes(existingReportNames, snakeCase(state.reportName))) {
      setState({
        duplicateReportName: true,
      });
    } else {
      setState({ loading: true });
      try {
        const reportResult = await API.create<PortfolioReportData>(
          `/clients/${props.clientId}/portfolio_reports`,
          {
            Name: trimEnd(state.reportName),
            Type: state.selectedPortfolioReportType,
            Description: trimEnd(state.reportDescription),
            ...(state.selectedPortfolioReportType === 'Waterfall' && {
              Model: state.selectedModel,
              ModelInstance: state.selectedModelInstance,
            }),
          }
        );
        if (reportResult.id) {
          props.onClose();
          props.refreshReports();
          setState(initialState);
        }
      } catch (e: any) {
        enqueueSnackbar(e.body.message, { variant: 'error' });
      }
    }
  };

  const disabledSubmitButton = () => {
    const {
      selectedPortfolioReportType,
      reportName,
      selectedModel,
      selectedModelInstance,
    } = state;

    if (!reportName) {
      return true;
    }

    if (selectedPortfolioReportType === 'Waterfall') {
      if (!selectedModel || !selectedModelInstance) {
        return true;
      }
    }

    return false;
  };

  const models: Model[] = (data.models || [])
    .filter((model) => profile && isModelMember(profile, model.id))
    .sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));

  const instances: ModelInstance[] = (data.modelInstances || [])
    // Filter out hidden instances, since admins and trinity-owners are
    // allowed to see even hidden model instances in the admin tools
    .filter(
      (instance) => instance.Visibility !== ModelInstanceVisibility.No_One
    )
    .sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));

  return (
    <>
      <PopupDialog
        close={() => {
          props.onClose();
          setState(initialState);
        }}
        title="Create New Report"
        open={props.isOpen}
        isLoading={state.loading}
        primaryButtons={[
          {
            id: 'create-new-report-button',
            label: 'Create',
            onClick: handleCreateNewReport,
            disabled: disabledSubmitButton(),
            title:
              'Please enter a report name and a report description to continue.',
          },
        ]}
      >
        <Stack component="form" spacing={3} noValidate autoComplete="off">
          <ClearableTextField
            size="small"
            id="reportNameInput"
            name="reportName"
            onHandleChange={handleReportNameChange}
            value={state.reportName}
            maxLength={REPORT_NAME_LENGTH}
            label="Report Name"
            required
            inputRef={reportNameInputRef}
            hasError={state.duplicateReportName}
            errorMessage="A similar report name already exists. Please rename your report to proceed."
          />
          <TextField
            size="small"
            label="Report Description"
            maxRows={4}
            multiline
            fullWidth
            id="reportDescriptionInput"
            name="reportDescription"
            onFocus={() =>
              setState({ onFocusedInput: 'reportDescriptionInput' })
            }
            onBlur={() => setState({ onFocusedInput: '' })}
            onChange={handleReportDescriptionChange}
            value={state.reportDescription}
            helperText={
              state.onFocusedInput === 'reportDescriptionInput' && (
                <>{`${
                  REPORT_DESCRIPTION_LENGTH - state.reportDescription.length
                } characters left`}</>
              )
            }
          />
          <TextField
            size="small"
            select
            label="Report Type"
            value={state.selectedPortfolioReportType}
            onChange={handlePortfolioReportTypeSelect}
            variant="filled"
          >
            {map(portfolioReportTypes(), (item) => (
              <MenuItem key={item.type} value={item.type}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          {state.selectedPortfolioReportType === 'Waterfall' && (
            <>
              <TextField
                size="small"
                select
                label="Models"
                value={state.selectedModel}
                onChange={handleChangeModel}
                variant="filled"
              >
                {map(models, (item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.Name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size="small"
                select
                label="Model Instance"
                value={state.selectedModelInstance}
                onChange={handleChangeModelInstance}
                variant="filled"
              >
                {map(instances, (item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.Name}
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}
        </Stack>
      </PopupDialog>
    </>
  );
};

export default CreateNewReportDialog;
