import { Box, Container, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

import useAppBarHeight from '../../hooks/useAppBarHeight';

import userBlockedImg from '../../assets/images/UserBlocked.png';

import Footer from './Footer';
import Splash from './Splash';

const Unauthorized = () => {
  const appBarHeight = useAppBarHeight();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (!showContent) {
    return <Splash />;
  }

  return (
    <Box>
      <Container
        sx={{
          height: `calc(100vh - ${2 * appBarHeight}px)`,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        maxWidth="md"
      >
        <img
          src={userBlockedImg}
          alt="userBlockedImg"
          height={300}
          width={300}
        />
        <Typography variant="body1">
          You do not have access to the application.
        </Typography>
        <Typography variant="body1">
          Please reach out to your Trinity team for access.
        </Typography>
      </Container>
      <Footer />
    </Box>
  );
};

export default Unauthorized;
