import React, { FunctionComponent } from 'react';
import { filter, isEmpty, map, toString } from 'lodash';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

import { ModelInstance, ModelInstanceVisibility } from '../../types/models';

import Loader, { LoaderInfo } from './Loader';

interface Props {
  size?: 'small' | 'medium';
  clientId: number;
  modelId?: number;
  modelInstanceId?: number;
  onChange(modelInstanceId: number): void;
  hasError?: boolean;
  showOnlyPublished?: boolean;
  btnStyle?: React.CSSProperties;
}

const SelectModelInstance: FunctionComponent<Props> = ({
  size,
  clientId,
  modelId,
  modelInstanceId,
  onChange,
  hasError,
  showOnlyPublished,
  btnStyle,
}) => {
  const renderContent = ({
    loading,
    errors,
    data,
  }: LoaderInfo<{ instances?: ModelInstance[] }>) => {
    let instances: ModelInstance[] = (data.instances || [])
      // Filter out hidden instances, since admins and trinity-owners are
      // allowed to see even hidden model instances in the admin tools
      .filter(
        (instance) => instance.Visibility !== ModelInstanceVisibility.No_One
      )
      .sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));

    if (showOnlyPublished) {
      instances = filter(instances, 'Published');
    }

    let disabled = false;

    if (clientId === undefined || modelId === undefined) {
      disabled = true;
    } else if (errors.length > 0) {
      disabled = true;
    } else if (instances.length < 1) {
      disabled = true;
    }

    const handleChange = (event: SelectChangeEvent) => {
      onChange(parseInt(event.target.value, 10));
    };

    return (
      <FormControl error={hasError} size={size} disabled={disabled} fullWidth>
        <InputLabel id="select-model-instance-label">
          {isEmpty(instances)
            ? 'No Model Instances found'
            : 'Select a Model Instance'}
        </InputLabel>
        <Select
          labelId="select-model-instance-label"
          id="select-model-instance"
          value={toString(modelInstanceId)}
          label="Select a Model Instance"
          onChange={handleChange}
          fullWidth
        >
          {map(instances, (instance) => (
            <MenuItem key={instance.id} value={instance.id}>
              {instance.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const needs = {
    instances:
      clientId !== undefined && modelId !== undefined
        ? `/clients/${clientId}/models/${modelId}/model_instances_load`
        : undefined,
  };

  return <Loader needs={needs} render={renderContent} />;
};

export default SelectModelInstance;
