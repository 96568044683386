import { useContext } from 'react';

import { ProfileContext } from '../components/shared/AuthController';

export function useProfile() {
  const profile = useContext(ProfileContext);
  if (profile === undefined) {
    throw Error('You can not `useProfile()` outside a `<AuthController>`');
  }
  return profile;
}
