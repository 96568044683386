import React, { FunctionComponent } from 'react';

import PopupDialog from '../../shared/PopupDialog';

interface IPublishChangesDialogProps {
  open: boolean;
  close: () => void;
  onConfirm: () => void;
}

const PublishChangesDialog: FunctionComponent<IPublishChangesDialogProps> = ({
  open,
  close,
  onConfirm,
}) => {
  return (
    <PopupDialog
      open={open}
      close={close}
      title="Publish Changes"
      primaryButtons={[
        {
          id: 'confirmButton',
          label: 'Confirm',
          onClick: onConfirm,
        },
      ]}
    >
      Please confirm you would like to save your edits as the new active version
      of the scenario.
    </PopupDialog>
  );
};

export default PublishChangesDialog;
