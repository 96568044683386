import { useRef, useCallback } from 'react';

type AnyFunction = (...args: any) => any;

function useDynamicCallback(
  this: ThisParameterType<AnyFunction>,
  callback: AnyFunction
) {
  const ref = useRef<AnyFunction>();
  ref.current = callback;
  return useCallback(
    (...args: Parameters<AnyFunction>) => ref.current?.(...args),
    []
  );
}
export default useDynamicCallback;
