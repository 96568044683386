import React, { ChangeEvent, PureComponent } from 'react';

import { map, toString, filter } from 'lodash';

import { MenuItem, TextField } from '@mui/material';

import {
  Scenario,
  ScenarioEditState,
  ScenarioArchiveFlag,
} from '../../types/models';

import { isModelMember, isModelOwner } from '../../utils/profile';
import { isTrinityUser } from '../../utils/user';

import { ProfileProps, withProfile } from '../shared/AuthController';

import Loader, { LoaderInfo } from '../shared/Loader';

interface Props extends ProfileProps {
  large?: boolean;
  modelId: number;
  modelInstanceId: number;
  fromScenarioId?: number;
  toScenarioId?: number;
  onChange(toScenarioId?: number): void;
}

interface State {
  activeItemId?: number | undefined;
}

class SelectScenario extends PureComponent<Props, State> {
  public static defaultProps = { large: true };
  public state: State = {};

  public render() {
    const { modelInstanceId } = this.props;

    const needs = {
      scenarios:
        modelInstanceId !== undefined
          ? `/instances/${modelInstanceId}/scenarios`
          : undefined,
    };

    return <Loader needs={needs} render={this.renderContent} />;
  }

  private renderContent = ({
    loading,
    errors,
    data,
  }: LoaderInfo<{ scenarios?: Scenario[] }>) => {
    const { modelId, modelInstanceId, fromScenarioId, toScenarioId, profile } =
      this.props;
    const scenarios: Scenario[] =
      // Filter out: the "from scenario", archived scenarios, locked scenarios, scenarios set to Owner edit (for non-Trinity model contributors)

      filter(
        filter(
          filter(
            filter(
              data.scenarios || [],
              (s) => s.Archive_Flag === ScenarioArchiveFlag.Current
            ),
            (s) => s.id !== fromScenarioId
          ),
          (s) => s.Edit_State !== ScenarioEditState.Locked
        ),
        (s) =>
          s.Edit_State !== ScenarioEditState.OwnerEdit ||
          (profile !== undefined &&
            (isTrinityUser(profile.User)
              ? isModelMember(profile, modelId)
              : isModelOwner(profile, modelId)))
      ).sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));

    let disabled = false;

    if (modelInstanceId === undefined) {
      disabled = true;
    } else if (errors.length > 0) {
      disabled = true;
    } else if (scenarios.length < 1) {
      disabled = true;
    }

    return (
      <TextField
        select
        disabled={disabled || loading}
        value={toString(toScenarioId)}
        label="Select a Scenario"
        fullWidth
        onChange={this.handleItemSelect}
      >
        {map(scenarios, (s) => (
          <MenuItem key={s.id} value={s.id}>
            {s.Name}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  private handleItemSelect = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(Number(e.target.value));
  };
}

export default withProfile(SelectScenario);
