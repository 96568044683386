import { ModelMembershipRole, Profile } from '../types/models';

export function isModelMember(profile: Profile, modelId: number) {
  return profile.ModelMemberships.some(
    (membership) => membership.ModelID === modelId
  );
}

export function isModelOwner(profile: Profile, modelId: number) {
  return profile.ModelMemberships.some(
    (membership) =>
      membership.ModelID === modelId &&
      membership.Role === ModelMembershipRole.Owner
  );
}

export function isModelMemberInClient(profile: Profile, clientId: number) {
  return profile.ModelMemberships.some(
    (membership) => membership.ClientID === clientId
  );
}

export function isModelOwnerInClient(profile: Profile, clientId: number) {
  return profile.ModelMemberships.some(
    (membership) =>
      membership.ClientID === clientId &&
      membership.Role === ModelMembershipRole.Owner
  );
}
