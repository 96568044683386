import React, { FunctionComponent } from 'react';
import { map } from 'lodash';

import * as API from '../../../../services/API';

import useSetState from '../../../../hooks/useSetState';

import PopupDialog from '../../../shared/PopupDialog';

import { PortfolioDataSourceData } from '../../types';

import { IDimensionsList } from './EditPortfolioSourceType';

interface IFinalizeDimensionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  dataSourceID?: PortfolioDataSourceData['id'];
  dimensionsList: IDimensionsList[];
  refreshData: () => void;
}

interface IFinalizeDimensionDialogState {
  loading: boolean;
}

const initialFinalizeDimensionDialogState: IFinalizeDimensionDialogState = {
  loading: false,
};

const FinalizeDimensionDialog: FunctionComponent<
  IFinalizeDimensionDialogProps
> = (props: IFinalizeDimensionDialogProps) => {
  const [state, setState] = useSetState<IFinalizeDimensionDialogState>(
    initialFinalizeDimensionDialogState
  );

  const onFinalizeDimensions = async (): Promise<void> => {
    try {
      setState({ loading: true });
      await API.create(
        `/portfolio_datasources/${props.dataSourceID}/portfolio_dimensions`,
        {
          dimensions: map(props.dimensionsList, (item, index) => {
            return {
              DimNumber: index,
              Name: item.fieldValue,
            };
          }),
          Finalized: 1,
        }
      );
      props.refreshData();
      props.onClose();
      setState({
        loading: false,
      });
    } catch (e) {
      setState({ loading: false });
    }
  };

  return (
    <PopupDialog
      open={props.isOpen}
      title="Finalize dimensions"
      close={props.onClose}
      isLoading={state.loading}
      primaryButtons={[
        {
          id: 'finalize-dimensions',
          label: 'Yes',
          onClick: onFinalizeDimensions,
          disabled: state.loading,
        },
      ]}
    >
      Once you finalize, you will not be able to delete/add dimensions. Only
      renaming dimensions are allowed. Do you want to proceed?
    </PopupDialog>
  );
};

export default FinalizeDimensionDialog;
