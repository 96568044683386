import React, { FunctionComponent } from 'react';
import { filter, map } from 'lodash';
import { AppBar, Toolbar, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';

import { Scenario, RowIdentifier, SourceDropDown } from '../../types/models';

import Flex from '../shared/Flex';

import { useWindowDimensions } from '../../hooks/useWindowDimensions';

import { FixedHeader } from './FixedHeader';

import { State, Dispatch } from './Store';
import InputsColumn from './InputsColumn';
import OutputsColumn from './OutputsColumn';
import ScenariosColumn from './ScenariosColumn';
import TimescalesColumn from './TimescalesColumn';
import DimensionColumn from './DimensionColumn';

interface Props {
  source: SourceDropDown;
  scenarios: Scenario[];
  rowIdentifiers: RowIdentifier[];
  timescales: string[];
  selection: State;
  dispatch: Dispatch;
}

const Body: FunctionComponent<Props> = ({
  source,
  scenarios,
  rowIdentifiers,
  timescales,
  selection,
  dispatch,
}) => {
  const filtered = filter(
    rowIdentifiers,
    (r) => r.Type === 'Input' || r.Type === 'Output' || r.Type === 'Dimension'
  );
  const sorted = filtered.sort((a, b) => {
    if (a.Type !== b.Type) {
      return a.Type < b.Type ? -1 : 1;
    }
    return (a.DimNumber as number) - (b.DimNumber as number);
  });
  const inputs = filter(sorted, { Type: 'Input' });
  const outputs = filter(sorted, { Type: 'Output' });
  const dimensions = filter(sorted, { Type: 'Dimension' });

  const { width } = useWindowDimensions();

  return (
    <Grid container pb={2}>
      <Grid
        item
        sm={6}
        py={1}
        pr={3}
        sx={{
          borderRight: `solid 1px ${grey[300]}`,
          background: '#FFFFFF',
        }}
      >
        <FixedHeader style={{ zIndex: 20 }}>
          <AppBar>
            <Toolbar
              sx={{
                position: 'absolute',
                zIndex: 10,
                top: -10,
                right: -24,
                width: 'calc(50vw + 480px)',
                background: 'rgb(245, 248, 250)',
              }}
            />
          </AppBar>
        </FixedHeader>
        <Grid container spacing={2}>
          <Grid item sm={source === 'inputs' ? 6 : 4}>
            <ScenariosColumn
              scenarios={scenarios}
              selection={selection.scenarios}
              dispatch={dispatch}
              source={source}
            />
          </Grid>
          {source === 'outputs' && (
            <>
              <Grid item sm={4}>
                <OutputsColumn
                  outputs={outputs}
                  selection={selection.outputs}
                  dispatch={dispatch}
                />
              </Grid>
              <Grid item sm={4}>
                <TimescalesColumn
                  timescales={timescales}
                  selection={selection.timescales}
                  dispatch={dispatch}
                />
              </Grid>
            </>
          )}
          {source === 'inputs' && (
            <Grid item sm={6}>
              <InputsColumn
                inputs={inputs}
                selection={selection.inputs}
                dispatch={dispatch}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item sm={6} pr={3}>
        <Flex>
          <FixedHeader style={{ zIndex: 10 }}>
            <AppBar>
              <Toolbar
                sx={{
                  position: 'absolute',
                  zIndex: 10,
                  left: 0,
                  width: `calc(${dimensions.length * 250}px + ${
                    dimensions.length <= 3 ? '25vw' : '100px'
                  })`,
                  maxWidth: `calc(75vw * ${width})`,
                  background: 'rgb(245, 248, 250)',
                  top: '-2px',
                }}
              />
            </AppBar>
          </FixedHeader>
          <Grid container spacing={2} sx={{ display: 'contents' }}>
            {map(dimensions, (dim, dimIdx) => (
              <Grid
                item
                key={dim.id}
                sx={{ ml: dimIdx === 0 ? 2 : 0, minWidth: 250 }}
              >
                <DimensionColumn
                  dimension={dim}
                  instances={rowIdentifiers.filter(
                    (r) =>
                      r.Type === 'DimensionInstance' &&
                      r.DimNumber === dim.DimNumber
                  )}
                  selection={selection.dimensionInstances}
                  includeBlanks={
                    selection.dimensionBlanks.indexOf(dim.id) !== -1
                  }
                  allowBlanks={source === 'inputs' || dim.DimNumber !== 0}
                  dispatch={dispatch}
                />
              </Grid>
            ))}
          </Grid>
        </Flex>
      </Grid>
    </Grid>
  );
};

export default Body;
