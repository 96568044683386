import { Warning } from '@mui/icons-material';
import React, { FunctionComponent } from 'react';

import { Button } from '@mui/material';

import NonIdealState from './NonIdealState';

interface Props {
  retry(): void;
}

const LoadingError: FunctionComponent<Props> = ({ retry }) => {
  return (
    <NonIdealState
      icon={<Warning />}
      title="Failed to load"
      action={<Button onClick={retry}>Retry</Button>}
    />
  );
};

export default LoadingError;
