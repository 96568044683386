import { has } from 'lodash';

import { Source } from '../../types/models';

import OutputsPanel from '../ScenarioManager/EditAssumptionsDetail/OutputsPanel';

import PortfolioDashboard from '../Reports';

export type IChartTab = { eventKey: string; title: string };
export type IStringType = Record<string, string>;
export type INumberType = Record<string, number>;
export type IBooleanType = Record<string, boolean>;

export const filterObject = <T extends Record<string, any>>(
  obj: T,
  key: string
): T => {
  for (const i in obj) {
    if (!has(obj, i)) continue;
    if (i === key) {
      delete obj[key];
    } else if (typeof obj[i] === 'object') {
      filterObject(obj[i], key);
    }
  }
  return obj;
};

export interface IVisualizationTabs {
  title: string;
  label: string;
  code: string;
  component: JSX.Element;
}

export const visualizationTabs = [
  {
    title: 'Custom Chart',
    label: 'Custom Chart Control',
    code: 'customChart',
    component: OutputsPanel,
  },
  {
    title: 'Portfolio Dashboard',
    label: 'Dashboards',
    code: 'dashboards',
    component: PortfolioDashboard,
  },
  // { title: 'Extracts & PPT', label: 'Extracts and Reports', code: 'ppt' },
];

export const maxSelectedScenarios: number = 10;

export const monthNames: string[] = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const truncationValues: Record<string, 1 | 1000 | 1000000> = {
  0: 1,
  1: 1000,
  2: 1000000,
};

export const truncationTypes: string[] = [
  'None',
  '000 (Thousands)',
  '000, 000 (Millions)',
];

export const dataSourceTypes: string[] = ['Data Cut', 'Portfolio'];

export const dataSourceCutTypes: Source[] = ['inputs', 'outputs'];

export const portfolioNumScenarios: number = 15;
