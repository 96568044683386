import React, { FunctionComponent, useContext, useState } from 'react';
import { Alert, AlertTitle, Box, Typography } from '@mui/material';

import * as API from '../../services/API';
import { Scenario, ScenarioArchiveFlag } from '../../types/models';

import APICacheContext from '../shared/APICacheContext';
import APICache from '../../services/APICache';
import PopupDialog from '../shared/PopupDialog';

interface Props {
  scenario: Scenario;
  isOpen: boolean;
  onCancel(): void;
  onComplete(): void;
}

interface State {
  busy: boolean;
  error?: Error;
}

const INITIAL_STATE = {
  busy: false,
  name: '',
  error: undefined,
};

const UnarchiveScenarioDialog: FunctionComponent<Props> = ({
  scenario,
  isOpen,
  onComplete,
  onCancel,
}: Props) => {
  const unarchiveScenarioDialogContext = useContext(APICacheContext);
  const [state, setState] = useState<State>(INITIAL_STATE);

  const handleCancel = () => {
    onCancel();
    setState(INITIAL_STATE);
  };

  const handleConfirm = async () => {
    setState({ busy: true, error: undefined });

    try {
      await API.update(
        `/instances/${scenario.ModelInstanceID}/scenarios/${scenario.id}`,
        { Archive_Flag: ScenarioArchiveFlag.Current }
      );
    } catch (error: any) {
      setState({ busy: false, error });
      return;
    }

    await (unarchiveScenarioDialogContext as APICache).load(
      [
        `/clients`,
        `/instances/${scenario.ModelInstanceID}/scenarios`,
        `/instances/${scenario.ModelInstanceID}/scenarios/${scenario.id}`,
      ],
      true
    );

    onComplete();
    setState(INITIAL_STATE);
  };

  const { busy, error } = state;

  return (
    <PopupDialog
      open={isOpen}
      close={handleCancel}
      title="Unarchive Scenario"
      isLoading={busy}
      primaryButtons={[
        {
          id: 'unarchive-scenario-dialog-confirm',
          label: 'Yes, unarchive it',
          onClick: handleConfirm,
          disabled: busy,
        },
      ]}
    >
      <Typography>
        Are you sure you want to unarchive "{scenario.Name}"? You can find it
        later in the "Current" section of your scenarios.
      </Typography>

      {error && (
        <Box mt={2}>
          <Alert severity="error">
            <AlertTitle>Something went wrong</AlertTitle>
            Unable to unarchive scenario, check your connection and try again.
          </Alert>
        </Box>
      )}
    </PopupDialog>
  );
};

export default UnarchiveScenarioDialog;
