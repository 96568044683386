import { PortfolioReportTypes } from './types';

export interface IPortfolioReport {
  type: string;
  name: string;
}

export const portfolioReportTypes = (): IPortfolioReport[] => {
  const result = [] as IPortfolioReport[];
  for (const key in PortfolioReportTypes) {
    if (PortfolioReportTypes.hasOwnProperty(key)) {
      result.push({
        name: key.replace(/([A-Z])/g, ' $1').trim(),
        type: (PortfolioReportTypes as Record<string, string>)[key],
      });
    }
  }
  return result;
};
export const MAX_DIMENSIONS: number = 8;
