import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
} from '@mui/material';
import { map } from 'lodash';

const StyledTableCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const SkeletonTable = () => {
  // create an array of 10 items
  const skeletonArray = Array(10).fill('');

  return (
    <TableContainer sx={{ display: 'flex' }}>
      <Table sx={{ width: '100%' }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {map(skeletonArray, (item, index) => (
              <StyledTableCell key={`${item}-${index}`}>
                <Skeleton />
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(skeletonArray, (item, index, arr) => (
            <StyledTableRow key={`${item}-${index}`}>
              <StyledTableCell component="th" scope="row">
                <Skeleton />
              </StyledTableCell>
              {map(arr.slice(0, -1), (a, idx) => (
                <StyledTableCell key={`${a}-${idx}`}>
                  <Skeleton />
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SkeletonTable;
