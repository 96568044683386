import { Box, CircularProgress } from '@mui/material';
import React, { FunctionComponent } from 'react';

interface IFullscreenProgressProps {
  customHeight?: string;
}

const FullscreenProgress: FunctionComponent<IFullscreenProgressProps> = (
  props: IFullscreenProgressProps
) => {
  return (
    <Box
      top="top"
      width="100%"
      height={props.customHeight ? props.customHeight : '100vh'}
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <CircularProgress />
    </Box>
  );
};

export default FullscreenProgress;
