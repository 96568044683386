import { GlobalStyles, Theme } from '@mui/material';

const SetGlobalStyles = (theme: Theme) => (
  <GlobalStyles
    styles={{
      ':root': {
        '--color-primary': theme.palette.primary.main,
        '--color-secondary': theme.palette.secondary.main,
      },
    }}
  />
);

export default SetGlobalStyles;
