import React, { FunctionComponent } from 'react';

import { map, filter, isEqual, max, size as _size, includes } from 'lodash';

import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from '@mui/icons-material';
import {
  Autocomplete,
  Checkbox,
  Popper,
  PopperProps,
  TextField,
  Typography,
} from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { ModelInstance, Scenario } from '../../types/models';
import OverflowTooltip from '../shared/OverflowTooltip';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IScenariosMultiSelectProps {
  /**
   * List of scenarios to be populated
   */
  scenariosList: Scenario[];
  /**
   * List of scenarios selected
   */
  preSelectedScenarios?: Scenario[];
  /**
   * The onChange handler
   * Need to use the callback with handleChangeMultiSelect in misc.ts with the first parameter being the callback value
   * and the second parameter being the state value to get updated
   * @param scenario
   */
  handleScenarioChange: (scenario: Scenario[]) => void;
  /**
   * The model instance
   * This component will be disabled until a modelInstance is passed in
   */
  modelInstanceId?: ModelInstance['id'];
  /**
   * Show archived scenarios or not
   */
  showArchivedScenarios?: boolean;
  size?: 'small' | 'medium';
  maxSelection?: string;
  hasError?: boolean;
}

let width = 0;

const FitContentPopper: FunctionComponent<PopperProps> = (
  props: PopperProps
) => {
  return (
    <Popper
      {...props}
      style={{ width: `${8 * width + 100}px` }}
      placement="bottom-start"
    />
  );
};

const ScenariosMultiSelect: FunctionComponent<IScenariosMultiSelectProps> = ({
  scenariosList,
  preSelectedScenarios,
  handleScenarioChange,
  modelInstanceId,
  showArchivedScenarios = true,
  size = 'small',
  maxSelection,
}) => {
  const scenariosData: (Scenario & { isSelected: boolean })[] = map(
    showArchivedScenarios
      ? scenariosList
      : filter(scenariosList, ['Archive_Flag', 0]),
    (i) => {
      return {
        ...i,
        isSelected: includes(preSelectedScenarios, i),
      };
    }
  );

  const isMaxScenariosSelected =
    map(scenariosData, 'isSelected').filter((i) => i).length ===
    Number(maxSelection);

  const optionLabels = map(scenariosData, 'Name');

  return (
    <Autocomplete<Scenario & { isSelected: boolean }, true>
      onOpen={() => {
        width = max(map(optionLabels, (el) => _size(el))) as number;
      }}
      multiple
      fullWidth
      PopperComponent={FitContentPopper}
      value={map(preSelectedScenarios, (i) => {
        return {
          ...i,
          isSelected: true,
        };
      })}
      ChipProps={{
        size: 'small',
      }}
      onChange={(event, value) => handleScenarioChange(value)}
      disableCloseOnSelect
      options={scenariosData}
      getOptionLabel={(option) => option.Name}
      getOptionDisabled={(option) =>
        !option.isSelected && isMaxScenariosSelected
      }
      isOptionEqualToValue={(option, value) => isEqual(option.id, value.id)}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option.Name, inputValue, {
          insideWords: true,
          findAllOccurrences: true,
        });
        const parts = parse(option.Name, matches);
        return (
          <Typography component="li" {...props} noWrap>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {map(parts, (part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                }}
              >
                <OverflowTooltip value={part.text} />
              </span>
            ))}
          </Typography>
        );
      }}
      disabled={modelInstanceId === undefined}
      renderInput={(params) => (
        <TextField {...params} label="Select scenarios" fullWidth size={size} />
      )}
    />
  );
};

export default ScenariosMultiSelect;
