import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  difference,
  differenceBy,
  filter,
  has,
  includes,
  intersectionBy,
  isEmpty,
  map,
  reject,
  size,
  toLower,
  trimEnd,
  trimStart,
  uniqueId,
} from 'lodash';
import { FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import { format } from 'date-fns';
import {
  Button,
  IconButton,
  Radio,
  RadioGroup,
  Box,
  FormControlLabel,
  Typography,
  Tooltip,
  Chip,
  MenuItem,
  TextField,
  Alert,
  Collapse,
  List,
  ListItem,
  Divider,
  AlertTitle,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Close,
  Delete,
  InsertDriveFile,
  MoreHoriz,
  SwapHoriz,
} from '@mui/icons-material';
import { blue, grey } from '@mui/material/colors';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';

import { ColDef, ICellRendererParams, IRowNode } from 'ag-grid-community';

import { getFormattedFileSize, mergeByName } from '../../utils/misc';

import * as API from '../../services/API';
import {
  DefaultModelFiles,
  ModelInstance,
  RowIdentifier,
  Upload,
} from '../../types/models';

import { UploadContext } from '../shared/UploadFilesContext';
import APICacheContext from '../shared/APICacheContext';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import PopupDialog from '../shared/PopupDialog';
import useSetState from '../../hooks/useSetState';
import StyledMenu from '../shared/StyledMenu';

import NonIdealState from '../shared/NonIdealState';
import Table from '../shared/Table';
import CenteredSpinner from '../shared/CenteredSpinner';
import OverflowTooltip from '../shared/OverflowTooltip';

const FILE_NAME_LENGTH: number = 256;

const fileSizeFormatter = (
  params: ICellRendererParams<FileWithPath>
): JSX.Element => {
  return (
    <OverflowTooltip {...params} value={getFormattedFileSize(params.value)} />
  );
};

const fileNameFormatter = (
  params: ICellRendererParams<FileWithPath>
): JSX.Element => {
  if (params.value) {
    return <OverflowTooltip {...params} value={params.value} />;
  }
  return <>-</>;
};

const dateFormatter = (
  params: ICellRendererParams<DefaultModelFiles>
): JSX.Element => {
  if (params.data?.FileName) {
    return (
      <OverflowTooltip
        {...params}
        value={format(new Date(params.value), 'MM/dd/yyyy hh:mm:ss a')}
      />
    );
  }
  return <Typography>-</Typography>;
};

const addedByFormatter = (
  params: ICellRendererParams<DefaultModelFiles>
): JSX.Element => {
  if (has(params.data, 'User.User_Name')) {
    return (
      <Tooltip arrow title={params.data?.User.User_Name as string}>
        <span>
          <u style={{ color: blue[500] }}>{params.value}</u>
        </span>
      </Tooltip>
    );
  } else return <Typography>-</Typography>;
};

const dateSortFunc = (
  a: string,
  b: string,
  nodeA: IRowNode<any>,
  nodeB: IRowNode<any>,
  isDescending: boolean
): number => {
  if (!isDescending) {
    return Date.parse(a) - Date.parse(b);
  }
  return Date.parse(b) - Date.parse(a);
};

const fileExtensionTag: JSX.Element = <Chip label=".xlsm" variant="outlined" />;

interface IManageDefaultModelFilesProps {
  isManageDefaultModelFilesDialogOpen: boolean;
  handleCloseManageDefaultModelFilesDialog: () => void;
  modelInstanceId?: ModelInstance['id'];
  doesInstanceHaveGroups: boolean;
  defaultModelFiles: DefaultModelFiles[];
  refresh: () => Promise<void>;
}

interface IManageDefaultModelFilesState {
  selectedModelFilter: 'all' | 'withFile' | 'withoutFile';
  isAddFilesModalOpen: boolean;
  showRejectedFilesList: boolean;
  showDuplicateFileList: boolean;
  displayRejectedFilesCallout: boolean;
  areFilesBeingUploaded: boolean;
  manageDefaultFilesData: DefaultModelFiles[];
  showRenameFileModal: boolean;
  renameFileData: DefaultModelFiles | {};
  newRenamedFile: string;
  onFocusedInput?: string;
  showDeleteFileModal: boolean;
  deleteFileData: DefaultModelFiles | {};
  groups: RowIdentifier[];
  filteredManageDefaultFilesData: DefaultModelFiles[];
  isDataLoading: boolean;
  selectedRow: DefaultModelFiles | {};
}

const initialState: IManageDefaultModelFilesState = {
  selectedModelFilter: 'all',
  isAddFilesModalOpen: false,
  showRejectedFilesList: false,
  showDuplicateFileList: false,
  displayRejectedFilesCallout: false,
  areFilesBeingUploaded: false,
  manageDefaultFilesData: [],
  showRenameFileModal: false,
  renameFileData: {},
  newRenamedFile: '',
  onFocusedInput: undefined,
  showDeleteFileModal: false,
  deleteFileData: {},
  groups: [],
  filteredManageDefaultFilesData: [],
  isDataLoading: false,
  selectedRow: {},
};

const ManageDefaultModelFiles: FunctionComponent<
  IManageDefaultModelFilesProps
> = (props: IManageDefaultModelFilesProps) => {
  const [state, setState] =
    useSetState<IManageDefaultModelFilesState>(initialState);

  const { enqueueSnackbar } = useSnackbar();

  const { width } = useWindowDimensions();

  const [fileList, setFileList] = useState<FileWithPath[]>([]);
  const [fileRejectionsList, setFileRejectionsList] = useState<FileRejection[]>(
    []
  );
  const [duplicateFilesList, setDuplicateFilesList] = useState<FileWithPath[]>(
    []
  );

  const contextValue = useContext(APICacheContext);

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
      const duplicateFiles = intersectionBy(acceptedFiles, fileList, (i) =>
        toLower(i.name)
      );
      const difference = differenceBy(acceptedFiles, duplicateFiles, (i) =>
        toLower(i.name)
      );
      const acceptedFilesList = !isEmpty(difference) ? difference : [];
      if (!isEmpty(duplicateFiles)) {
        setDuplicateFilesList([...duplicateFilesList, ...duplicateFiles]);
      }
      const files = [...fileList, ...acceptedFilesList];
      setFileList(files);
      setFileRejectionsList((oldState) => [...oldState, ...fileRejections]);
    },
    [fileList, duplicateFilesList]
  );

  useEffect(() => {
    if (isEmpty(duplicateFilesList)) {
      setState({ showDuplicateFileList: false });
    }
  }, [duplicateFilesList]);

  useEffect(() => {
    if (!isEmpty(fileRejectionsList)) {
      setState({ displayRejectedFilesCallout: true });
    }
  }, [fileRejectionsList]);

  useEffect(() => {
    switch (state.selectedModelFilter) {
      case 'all': {
        setState({
          filteredManageDefaultFilesData: state.manageDefaultFilesData,
        });
        break;
      }
      case 'withFile': {
        setState({
          filteredManageDefaultFilesData: filter(
            state.manageDefaultFilesData,
            'FileName'
          ),
        });
        break;
      }
      case 'withoutFile': {
        setState({
          filteredManageDefaultFilesData: reject(
            state.manageDefaultFilesData,
            'FileName'
          ),
        });
      }
    }
  }, [state.selectedModelFilter]);

  useEffect(() => {
    if (props.modelInstanceId) {
      (async () => {
        setState({ isDataLoading: true });

        const gridData = await contextValue?.load(
          [`/instances/${props.modelInstanceId}/row_identifiers?type=Group`],
          true
        );
        const groups: RowIdentifier[] = gridData && gridData[0];
        const filesData = map(props.defaultModelFiles, (i) => {
          return {
            ...i,
            Name: i.GroupRowIdentifierName,
          };
        });
        const GroupRowIdentifierIDs = map(filesData, 'GroupRowIdentifierID');
        const ids = map(groups, 'id');
        const diff = difference(ids, GroupRowIdentifierIDs);
        const filteredGroup = filter(groups, (i) => includes(diff, i.id));
        let data: any[] = [...filesData, ...filteredGroup];
        if (isEmpty(filesData)) {
          data = map(filteredGroup, (item, index) => {
            return {
              Name: item.Name,
              id: index,
            };
          });
        }

        switch (state.selectedModelFilter) {
          case 'all': {
            setState({
              filteredManageDefaultFilesData: data,
            });
            break;
          }
          case 'withFile': {
            setState({
              filteredManageDefaultFilesData: filter(data, 'FileName'),
            });
            break;
          }
          case 'withoutFile': {
            setState({
              filteredManageDefaultFilesData: reject(data, 'FileName'),
            });
            break;
          }
        }
        setState({
          manageDefaultFilesData: data,
          isDataLoading: false,
        });
      })();
    }
  }, [props.defaultModelFiles, props.modelInstanceId]);

  const uploadContext = useContext(UploadContext);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
    },
    onDrop,
  });

  useEffect(() => {
    if (props.isManageDefaultModelFilesDialogOpen) {
      refreshData();
    }
  }, [props.isManageDefaultModelFilesDialogOpen]);

  const handleAddFilesModalOpen = (): void => {
    setState({
      isAddFilesModalOpen: true,
    });
    setFileList([]);
    setDuplicateFilesList([]);
    setFileRejectionsList([]);
  };

  const handleAddFilesModalClose = (): void => {
    setState({
      isAddFilesModalOpen: false,
    });
    setFileList([]);
    setDuplicateFilesList([]);
    setFileRejectionsList([]);
  };

  const removeFile = (file: FileWithPath): void => {
    setFileList((oldStateVal) => reject(oldStateVal, { name: file.name }));
  };

  const fileDeleteFormatter = (
    params: ICellRendererParams<FileWithPath>
  ): JSX.Element => {
    return (
      <IconButton
        onClick={() => removeFile(params.data as FileWithPath)}
        color="error"
      >
        <Delete />
      </IconButton>
    );
  };

  const actionFormatter = (
    params: ICellRendererParams<DefaultModelFiles>
  ): JSX.Element => {
    return (
      <PopupState variant="popover" popupId="manage-default-files-menu">
        {(popupState) => (
          <>
            <IconButton
              {...bindTrigger(popupState)}
              disabled={!params.data?.FileName}
            >
              <MoreHoriz />
            </IconButton>
            <StyledMenu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  setState({
                    showRenameFileModal: true,
                    renameFileData: params.data,
                    newRenamedFile: params.data?.FileName.slice(
                      0,
                      params.data?.FileName.length - 5
                    ),
                  });
                }}
              >
                Rename
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setState({
                    showDeleteFileModal: true,
                    deleteFileData: params.data,
                  });
                }}
              >
                Delete
              </MenuItem>
            </StyledMenu>
          </>
        )}
      </PopupState>
    );
  };

  const refreshData = (): void => {
    setState({ isDataLoading: true });
    props.refresh();
  };

  const uploadedFilesColumns: any[] = [
    {
      field: 'name',
      headerName: 'File Name',
      cellRenderer: fileNameFormatter,
      sortable: true,
      flex: 3,
    },
    {
      field: 'size',
      headerName: 'File Size',
      cellRenderer: fileSizeFormatter,
      sortable: true,
      flex: 1,
    },
    {
      field: 'delete',
      headerName: 'Delete',
      cellRenderer: fileDeleteFormatter,
      sortable: false,
      flex: 0.5,
    },
  ];

  const manageDefaultFilesColumns: ColDef[] = [
    {
      field: 'FileName',
      headerName: 'File Name',
      cellRenderer: fileNameFormatter,
      sortable: true,
      sort: 'asc',
      flex: 3,
    },
    {
      field: 'created_at',
      headerName: 'Added',
      cellRenderer: dateFormatter,
      sortable: true,
      comparator: dateSortFunc,
      flex: 2,
    },
    {
      field: 'User.Display_Name',
      headerName: 'Added By',
      cellRenderer: addedByFormatter,
      sortable: true,
      flex: 2,
    },
  ];

  const handleUpload = async (): Promise<void> => {
    if (isEmpty(fileList)) {
      enqueueSnackbar(`Please upload some '.xlsm' files to continue.`, {
        variant: 'error',
      });
    } else {
      uploadContext?.isFilesProcessingDone(false);
      setState({ areFilesBeingUploaded: true });
      const uploads = await API.create('/uploadMultiple', {
        FilesList: map(fileList, (item) => {
          return {
            FileName: item.name,
            Comment: 'Upload for default model file',
          };
        }),
      });
      if (uploads) {
        uploadContext?.handleDataChange(
          uploads as Upload[],
          fileList,
          props.modelInstanceId as ModelInstance['id'],
          '/uploads/default_model_files',
          false
        );
        setState({
          isAddFilesModalOpen: false,
          areFilesBeingUploaded: false,
        });
        props.handleCloseManageDefaultModelFilesDialog();
        setFileList([]);
        setFileRejectionsList([]);
        setDuplicateFilesList([]);
      }
    }
  };

  const handleCloseRenameFileModal = (): void => {
    setState({
      showRenameFileModal: false,
      renameFileData: {},
      newRenamedFile: '',
    });
  };

  const handleCloseDeleteFileModal = (): void => {
    setState({
      showDeleteFileModal: false,
      deleteFileData: {},
    });
  };

  const handleRenameFile = async (): Promise<void> => {
    if (state.newRenamedFile) {
      setState({ isDataLoading: true });
      const renamedFile = await API.create('/default_model_files/rename', {
        DefaultModelFileID: (state.renameFileData as DefaultModelFiles).id,
        DefaultModelFileNewFileName: `${trimEnd(state.newRenamedFile)}.xlsm`,
      });
      if (renamedFile) {
        props.refresh();
        setState({
          showRenameFileModal: false,
          renameFileData: {},
          isDataLoading: false,
        });
      }
    }
  };

  const handleDeleteFile = async (): Promise<void> => {
    try {
      await API.remove(
        `/default_model_files/delete/${
          (state.deleteFileData as DefaultModelFiles).id
        }`
      );
      props.refresh();
      setState({
        showDeleteFileModal: false,
        deleteFileData: {},
      });
    } catch (error) {
      return;
    }
  };

  if (props.doesInstanceHaveGroups) {
    manageDefaultFilesColumns.splice(0, 0, {
      field: 'Name',
      headerName: 'Group',
      cellRenderer: OverflowTooltip,
      sortable: true,
      flex: 1,
    });
  }

  if (state.selectedModelFilter !== 'withoutFile') {
    manageDefaultFilesColumns.push({
      field: 'id',
      headerName: 'Actions',
      cellRenderer: actionFormatter,
      flex: 1,
    });
  }

  // const selectedRowStyle = (row: DefaultModelFiles): React.CSSProperties => {
  //   const style: React.CSSProperties = {};
  //   if (
  //     row.id === (state.selectedRow as DefaultModelFiles).id ||
  //     row.id === (state.selectedRow as DefaultModelFiles).id
  //   ) {
  //     style.border = `3px solid ${blue[500]}`;
  //   }
  //
  //   return style;
  // };

  return (
    <>
      <PopupDialog
        open={state.showRenameFileModal}
        title="Rename file"
        close={handleCloseRenameFileModal}
        primaryButtons={[
          {
            id: 'rename-file-button',
            label: 'Rename',
            onClick: handleRenameFile,
            disabled: !state.newRenamedFile,
          },
        ]}
      >
        <TextField
          fullWidth
          label="Edit File Name"
          helperText={
            state.onFocusedInput === 'renameFileName' &&
            `${FILE_NAME_LENGTH - state.newRenamedFile.length} characters left`
          }
          onFocus={() => {
            setState({
              onFocusedInput: 'renameFileName',
            });
          }}
          onBlur={() => {
            setState({
              onFocusedInput: undefined,
            });
          }}
          id="renameFile"
          value={state.newRenamedFile}
          InputProps={{
            endAdornment: fileExtensionTag,
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setState({
              newRenamedFile: trimStart(
                e.target.value.substring(0, FILE_NAME_LENGTH)
              ),
            })
          }
        />
      </PopupDialog>
      <PopupDialog
        open={state.showDeleteFileModal}
        title="Delete file"
        close={handleCloseDeleteFileModal}
        primaryButtons={[
          {
            id: 'delete-file-button',
            label: 'Delete',
            onClick: handleDeleteFile,
          },
        ]}
      >
        Are you sure you want to delete this file?
      </PopupDialog>
      <PopupDialog
        title="Add/Replace file(s)"
        open={state.isAddFilesModalOpen}
        close={handleAddFilesModalClose}
        maxWidth="lg"
        primaryButtons={[
          {
            id: 'uploadButton',
            label: 'Upload',
            onClick: handleUpload,
            disabled: state.areFilesBeingUploaded || isEmpty(fileList),
          },
        ]}
      >
        {state.areFilesBeingUploaded ? (
          <Grid container alignItems="center" sx={{ height: '80vh' }}>
            <Grid md={12}>
              <CenteredSpinner />
            </Grid>
          </Grid>
        ) : (
          <>
            <Box p={3} my={1} style={{ border: `2px dashed ${grey[500]}` }}>
              <Box
                {...getRootProps({ className: 'dropzone' })}
                sx={{ cursor: 'pointer', textAlign: 'center' }}
              >
                <input {...getInputProps()} accept=".xlsm" />
                <NonIdealState
                  icon={<InsertDriveFile />}
                  title="Drop files here or click to upload"
                  description={
                    <Typography variant="body2">
                      Only *.xlsm files will be accepted
                    </Typography>
                  }
                />
              </Box>
            </Box>
            {!isEmpty(fileRejectionsList) &&
              state.displayRejectedFilesCallout && (
                <Alert
                  severity="warning"
                  action={
                    <IconButton
                      onClick={() => {
                        setState({ displayRejectedFilesCallout: false });
                        setFileRejectionsList([]);
                      }}
                    >
                      <Close />
                    </IconButton>
                  }
                >
                  <AlertTitle>Rejected file(s)</AlertTitle>
                  <Box>
                    The following file(s) could not be uploaded because they do
                    not have a ‘.xlsm’ extension.
                  </Box>
                  <Button
                    onClick={() =>
                      setState({
                        showRejectedFilesList: !state.showRejectedFilesList,
                      })
                    }
                  >
                    {state.showRejectedFilesList
                      ? 'Hide File(s)'
                      : 'Show File(s)'}
                  </Button>
                  <Collapse in={state.showRejectedFilesList}>
                    <Box
                      sx={{
                        width: '100%',
                        maxHeight: 300,
                        bgcolor: 'background.paper',
                      }}
                    >
                      <List>
                        {map(fileRejectionsList, (item) => {
                          return (
                            <ListItem key={uniqueId()}>
                              {item.file.name}
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  </Collapse>
                </Alert>
              )}
            {!isEmpty(duplicateFilesList) && (
              <Alert severity="warning" title="Duplicate file(s)">
                <Box>
                  The following {duplicateFilesList.length} file(s) have a
                  duplicate file name from the list. Please rename your file and
                  re-upload or you can also replace the existing file with this
                  file.
                </Box>
                <Button
                  onClick={() =>
                    setState({
                      showDuplicateFileList: !state.showDuplicateFileList,
                    })
                  }
                >
                  {state.showDuplicateFileList
                    ? 'Hide File(s)'
                    : 'Show File(s)'}
                </Button>
                <Collapse in={state.showDuplicateFileList}>
                  <Box
                    sx={{
                      width: '100%',
                      maxHeight: 300,
                      bgcolor: 'background.paper',
                    }}
                  >
                    <List>
                      {map(duplicateFilesList, (item) => {
                        return (
                          <ListItem key={uniqueId()}>
                            <Grid container alignItems="center">
                              <Grid md={9}>
                                <Typography noWrap>
                                  {item.name} -{' '}
                                  {getFormattedFileSize(item.size)}
                                </Typography>
                              </Grid>
                              <Grid md={1}>
                                <IconButton
                                  title="Replace"
                                  onClick={() => {
                                    setFileList((oldState) =>
                                      mergeByName(oldState).with([item])
                                    );
                                    setDuplicateFilesList((oldState) =>
                                      reject(oldState, { name: item.name })
                                    );
                                  }}
                                >
                                  <SwapHoriz />
                                </IconButton>
                              </Grid>
                              <Grid md={1}>
                                <IconButton
                                  title="Delete"
                                  onClick={() => {
                                    setDuplicateFilesList((oldStateVal) =>
                                      reject(oldStateVal, {
                                        name: item.name,
                                      })
                                    );
                                  }}
                                >
                                  <Delete sx={{ color: 'red' }} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                </Collapse>
              </Alert>
            )}
            {!isEmpty(fileList) && (
              <Box>
                <Table
                  id="upload-files-table"
                  isLoading={false}
                  isSuccess
                  pagination={true}
                  suppressPaginationPanel
                  rowCount={size(fileList)}
                  rowData={map(fileList, (item, i) => {
                    return {
                      id: i + 1,
                      name: item.name,
                      lastModified: item.lastModified,
                      size: item.size,
                      type: item.type,
                    };
                  })}
                  columnDefs={uploadedFilesColumns}
                  defaultColDef={{
                    suppressAutoSize: true,
                    suppressMenu: true,
                    suppressSizeToFit: true,
                  }}
                />
              </Box>
            )}
          </>
        )}
      </PopupDialog>
      <PopupDialog
        title="Manage default model files"
        open={props.isManageDefaultModelFilesDialogOpen}
        close={() => {
          props.handleCloseManageDefaultModelFilesDialog();
          setState(initialState);
        }}
        maxWidth={width > 1600 ? 'lg' : 'xl'}
        hideDialogActions
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Grid md={9}>
            <Grid container alignItems="center" spacing={2}>
              <Grid lg={3}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleAddFilesModalOpen}
                >
                  Add/Replace File(s)
                </Button>
              </Grid>
              <Grid lg={9}>
                {!isEmpty(state.manageDefaultFilesData) &&
                  props.doesInstanceHaveGroups && (
                    <RadioGroup
                      row
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setState({
                          selectedModelFilter: (e.target as HTMLInputElement)
                            .value as 'all' | 'withFile' | 'withoutFile',
                        })
                      }
                      value={state.selectedModelFilter}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="All"
                      />
                      <FormControlLabel
                        value="withFile"
                        control={<Radio />}
                        label="Groups with file"
                      />
                      <FormControlLabel
                        value="withoutFile"
                        control={<Radio />}
                        label="Groups w/o file"
                      />
                    </RadioGroup>
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Grid md={3} justifyContent="flex-end" container>
            <Button onClick={refreshData} variant="outlined" size="small">
              Refresh data
            </Button>
          </Grid>
        </Grid>
        {(() => {
          if (state.isDataLoading) {
            return <CenteredSpinner />;
          } else if (state.manageDefaultFilesData.length >= 1) {
            return (
              <>
                <Divider />
                <Table
                  id="manageDefaultModelFiles"
                  isLoading={false}
                  isSuccess
                  pagination
                  rowCount={size(state.filteredManageDefaultFilesData)}
                  rowData={state.filteredManageDefaultFilesData}
                  columnDefs={manageDefaultFilesColumns}
                  defaultColDef={{
                    suppressAutoSize: true,
                    suppressMenu: true,
                  }}
                />
              </>
            );
          }
          return null;
        })()}
      </PopupDialog>
    </>
  );
};

export default ManageDefaultModelFiles;
