import React, { ChangeEvent, FunctionComponent, useContext } from 'react';

import { isArray, trimStart, toString, values, some, trim, keys } from 'lodash';

import { Alert, AlertTitle, Box, TextField, Typography } from '@mui/material';

import * as API from '../../services/API';
import { Scenario, Submission } from '../../types/models';
import {
  isPrintableASCII,
  SCENARIO_NAME_LENGTH,
  YEAR_TEXT_LENGTH,
} from '../../utils/validation';

import APICacheContext from '../shared/APICacheContext';
import ErrorList from '../shared/ErrorList';
import useSetState from '../../hooks/useSetState';
import APICache from '../../services/APICache';
import PopupDialog from '../shared/PopupDialog';
import Flex from '../shared/Flex';

interface Props {
  instanceId: number;
  scenario: Scenario;
  submission?: Submission;
  isOpen: boolean;
  onCancel(): void;
  onComplete(): void;
}

interface State {
  busy: boolean;
  name?: string;
  year?: string;
  formErrors: {
    name?: string[];
    year?: string[];
  };
  error?: Error;
  focusedInput?: string;
}

const INITIAL_STATE = {
  busy: false,
  name: undefined,
  year: undefined,
  formErrors: {},
  error: undefined,
  focusedInput: undefined,
};

const RestoreSubmissionDialog: FunctionComponent<Props> = ({
  instanceId,
  submission,
  isOpen,
  scenario,
  onComplete,
  onCancel,
}) => {
  const restoreSubmissionDialogContext = useContext(APICacheContext);
  const [state, setState] = useSetState<State>(INITIAL_STATE);

  const validate = () => {
    const { name, year } = state;

    const fields = {
      name: name === undefined ? `${scenario.Name} Copy` : trim(name),
      year: year === undefined ? scenario.Year : parseInt(year, 10),
    };

    const formErrors: { [P in keyof typeof fields]?: string[] } = {};

    if (fields.name === '') {
      formErrors.name = ['Name may not be blank'];
    } else if (fields.name.length > 255) {
      formErrors.name = ['Name may not be longer than 255 characters'];
    }

    if (!isPrintableASCII(fields.name)) {
      formErrors.name = formErrors.name || [];
      formErrors.name.push('Name contains unsupported characters');
    }

    if (Number.isNaN(fields.year) || fields.year < 1900 || fields.year > 2100) {
      formErrors.year = ['Invalid year'];
    }

    return { fields, formErrors };
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      name: trimStart(
        event.currentTarget.value.substring(0, SCENARIO_NAME_LENGTH)
      ),
    });
  };

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      year: trimStart(event.currentTarget.value.substring(0, YEAR_TEXT_LENGTH)),
    });
  };

  const handleCancel = () => {
    onCancel();
    setState(INITIAL_STATE);
  };

  const handleSubmit = async () => {
    setState({ error: undefined, formErrors: {} });

    const { fields, formErrors } = validate();

    if (keys(formErrors).length !== 0) {
      setState({ formErrors });
      return;
    }

    if (submission === undefined) {
      setState({
        busy: true,
        formErrors: {},
        error: new Error('Could not find submission'),
      });
      return;
    }

    setState({ busy: true, formErrors: {}, error: undefined });

    let newScenario: Scenario;

    try {
      newScenario = await API.create(`/instances/${instanceId}/scenarios`, {
        Source_ScenarioID: scenario.id,
        Source_SubmissionID: submission.id,
        Name: fields.name,
        Year: fields.year,
      });
    } catch (error: any) {
      if (
        typeof error.body === 'object' &&
        typeof error.body.errors === 'object'
      ) {
        setState({ busy: false, formErrors: error.body.errors });
      } else {
        setState({ busy: false, error });
      }
      return;
    }

    await (restoreSubmissionDialogContext as APICache).load(
      [`/clients`, `/instances/${instanceId}/scenarios`],
      true
    );

    onComplete();
    setState(INITIAL_STATE);

    return newScenario;
  };

  const { busy, name, year, formErrors, error, focusedInput } = state;

  return (
    <PopupDialog
      isLoading={busy}
      open={isOpen}
      close={handleCancel}
      title="Copy Scenario"
      primaryButtons={[
        {
          id: 'restore-submission-dialog-confirm',
          label: 'Confirm',
          onClick: handleSubmit,
          disabled: busy,
        },
      ]}
    >
      <Typography>
        This will duplicate the existing scenario with the inputs and data
        corresponding to the selected version.
      </Typography>

      <Flex mt={2}>
        <Box mr={2} width={1 / 3}>
          <TextField
            label="Year"
            helperText={
              focusedInput === 'year' &&
              `${
                YEAR_TEXT_LENGTH -
                (year === undefined ? scenario.Year.toString() : year).length
              } characters left`
            }
            onChange={handleYearChange}
            type="number"
            onFocus={() => {
              setState({
                focusedInput: `year`,
              });
            }}
            onBlur={() => {
              setState({
                focusedInput: undefined,
              });
            }}
            disabled={busy}
            value={year === undefined ? toString(scenario.Year) : year}
            error={isArray(formErrors.year) && formErrors.year.length > 0}
          />
        </Box>
        <Box flex="auto">
          <TextField
            fullWidth
            label="Name"
            helperText={
              focusedInput === 'scenarioName' &&
              `${
                SCENARIO_NAME_LENGTH -
                (name === undefined ? scenario.Name : name).length
              } characters left`
            }
            onFocus={() => {
              setState({
                focusedInput: `scenarioName`,
              });
            }}
            onBlur={() => {
              setState({
                focusedInput: undefined,
              });
            }}
            disabled={busy}
            onChange={handleNameChange}
            value={name === undefined ? `${scenario.Name} Copy` : name}
            error={isArray(formErrors.name) && formErrors.name.length > 0}
          />
        </Box>
      </Flex>

      {some(
        values(formErrors),
        (errors) => isArray(errors) && errors.length > 0
      ) && (
        <Box mt={2}>
          <ErrorList errors={formErrors} />
        </Box>
      )}

      {error && (
        <Box mt={2}>
          <Alert severity="error">
            <AlertTitle>Something went wrong</AlertTitle>
            Unable to copy scenario, check your connection and verify that the
            model instance you are copying to has been published.
          </Alert>
        </Box>
      )}
    </PopupDialog>
  );
};

export default RestoreSubmissionDialog;
