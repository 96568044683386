import { ICellRendererParams } from 'ag-grid-enterprise';
import { get, find } from 'lodash';
import { format } from 'date-fns';
import React from 'react';

import {
  formatParameters,
  IFormatParameters,
} from '../../../Visualization/formatParameters';
import OverflowTooltip from '../../../shared/OverflowTooltip';

const magnitudeTruncation = new Map();
magnitudeTruncation.set(0, 0);
magnitudeTruncation.set(1, 1000);
magnitudeTruncation.set(2, 1000000);

export const getFormattedValue = (value: any, formatKey: number) => {
  const formatKeyParams = find(formatParameters, {
    formatKey: formatKey,
  }) as IFormatParameters;
  const decimalPlacesValue = get(formatKeyParams, 'decimalPlaces', 0) as number;

  const isNumber = (value: string) => {
    return value !== null && !Number.isNaN(Number(value));
  };

  switch (get(formatKeyParams, 'numberType')) {
    case '$': {
      const magnitudeTruncationValue = get(
        formatKeyParams,
        'magnitudeTruncation',
        1
      );
      const magnitudeTruncationFactor =
        magnitudeTruncation.get(magnitudeTruncationValue) || 1;
      const formattedValue = `$${Number(value / magnitudeTruncationFactor)
        .toFixed(decimalPlacesValue)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      return value ? formattedValue : null;
    }
    case 'Percent': {
      return isNumber(value)
        ? `${(Number(value) * 100).toFixed(decimalPlacesValue)}%`
        : value;
    }
    case 'Date': {
      if (formatKeyParams.numberType === 'Date') {
        const dateFormat = get(
          formatKeyParams,
          'dateFormat',
          'M/d/yyyy'
        ) as string;
        if (!value) {
          return '';
        }
        return format(new Date(value), dateFormat);
      }
      return '';
    }
    default: {
      if (value && isNaN(Number(value))) {
        return value;
      }
      return value
        ? Number(
            value /
              (magnitudeTruncation.get(
                get(formatKeyParams, 'magnitudeTruncation', 1)
              ) === 0
                ? 1
                : magnitudeTruncation.get(
                    get(formatKeyParams, 'magnitudeTruncation', 1)
                  ))
          )
            .toFixed(decimalPlacesValue)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : null;
    }
  }
};

const ValuesCellRenderer = (
  params: ICellRendererParams & {
    formatKey: number;
  }
) => {
  return (
    <OverflowTooltip
      value={getFormattedValue(params.value, params.formatKey)}
    />
  );
};

export default ValuesCellRenderer;
