import React, { PureComponent } from 'react';

import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';

import { BugReport } from '@mui/icons-material';

import Flex from '../shared/Flex';
import Loader, { LoaderInfo } from '../shared/Loader';
import { Scenario } from '../../types/models';

import NonIdealState from '../shared/NonIdealState';

import CenteredSpinner from '../shared/CenteredSpinner';

import ScenarioCard from './ScenarioCard';

interface Props {
  clientId: number;
  modelId?: number;
  instanceId?: number;
  scenarioId?: number;
  filter?: string;

  onScenarioChange(id?: number): void;
  onFilterChange(filter?: string): void;
}

class ScenarioList extends PureComponent<Props> {
  public render() {
    const { instanceId, filter } = this.props;

    if (instanceId === undefined) {
      return ScenarioList.renderPlaceholder();
    }

    const needs = {
      scenarios: `/instances/${instanceId}/scenarios`,
    };

    return (
      <Flex flexDirection="column">
        <Flex alignItems="center" sx={{ paddingTop: 2, width: '95%' }}>
          <Box flex="auto">
            <Typography variant="h5">Scenarios</Typography>
          </Box>

          <ToggleButtonGroup
            size="small"
            color="primary"
            value={filter || 'current'}
            exclusive
            onChange={this.handleFilterChange}
            aria-label="Platform"
          >
            <ToggleButton value="current">Current</ToggleButton>
            <ToggleButton value="archive">Archive</ToggleButton>
          </ToggleButtonGroup>
        </Flex>
        <Loader
          needs={needs}
          render={this.renderBody}
          handleErrors={true}
          force
          handleLoading
        />
      </Flex>
    );
  }

  private static renderPlaceholder() {
    return (
      <Flex flexDirection="column" flex="auto">
        <Flex alignItems="center" sx={{ paddingTop: 2 }}></Flex>
        <Typography variant="h5" color="disabled">
          Scenarios
        </Typography>
        <Flex
          flexDirection="column"
          py={2}
          flex="auto"
          justifyContent="center"
          alignItems="center"
        >
          <Typography color="disabled">
            Select a model and model instance to see the scenarios associated
            with it.
          </Typography>
        </Flex>
      </Flex>
    );
  }

  private renderBody = ({
    loading,
    data,
  }: LoaderInfo<{ scenarios: Scenario[] }>) => {
    const { filter } = this.props;
    const scenarios = (data.scenarios || [])
      .filter((s) => !!s.Archive_Flag === (filter === 'archive'))
      .sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));

    if (scenarios === undefined || scenarios.length === 0) {
      if (loading) {
        return (
          <Box p={3}>
            <CenteredSpinner />
          </Box>
        );
      } else {
        return (
          <Box p={3}>
            <NonIdealState icon={<BugReport />} title="No scenarios found" />
          </Box>
        );
      }
    }

    const years = scenarios
      .map((s) => s.Year)
      .filter((x, i, a) => a.indexOf(x) === i)
      .sort((a, b) => b - a);

    return (
      <>
        {years.map((year) => (
          <Box mt={2} key={year}>
            {this.renderSection(
              year,
              scenarios.filter((s) => s.Year === year)
            )}
          </Box>
        ))}
      </>
    );
  };

  private renderSection(year: number, scenarios: Scenario[]) {
    const { clientId, modelId, scenarioId } = this.props;

    if (!clientId || !modelId) {
      return null;
    }

    return (
      <>
        <Box mb={1}>
          <Typography variant="caption" color="muted">
            {year}
          </Typography>
        </Box>
        {scenarios.map((scenario, i) => (
          <Box mt={i === 0 ? 0 : 2} key={scenario.id}>
            <ScenarioCard
              clientId={clientId}
              modelId={modelId}
              selectedScenarioId={scenarioId}
              scenario={scenario}
              onClick={this.handleScenarioClick}
              onDeleted={this.handleScenarioDeleted}
              onUnarchived={this.handleScenarioUnarchived}
              onTransferred={this.handleScenarioTransferred}
              onCopied={this.handleScenarioCopied}
              onArchived={this.handleScenarioArchived}
            />
          </Box>
        ))}
      </>
    );
  }

  private handleFilterChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    const { onFilterChange } = this.props;
    onFilterChange(newValue);
  };

  private handleScenarioClick = (scenario: Scenario) => {
    const { onScenarioChange, scenarioId } = this.props;

    if (scenarioId === scenario.id) {
      onScenarioChange(undefined);
    } else {
      onScenarioChange(scenario.id);
    }
  };

  private handleScenarioDeleted = (scenario: Scenario) => {
    const { onScenarioChange, scenarioId } = this.props;

    if (scenarioId === scenario.id) {
      onScenarioChange(undefined);
    }
  };

  private handleScenarioArchived = (scenario: Scenario) => {
    const { onScenarioChange, scenarioId } = this.props;

    if (scenarioId === scenario.id) {
      onScenarioChange(undefined);
    }
  };

  private handleScenarioUnarchived = (scenario: Scenario) => {
    const { onScenarioChange, scenarioId } = this.props;

    if (scenarioId === scenario.id) {
      onScenarioChange(undefined);
    }
  };

  private handleScenarioCopied = () => {
    // No op
  };

  private handleScenarioTransferred = () => {
    // No op
  };
}

export default ScenarioList;
