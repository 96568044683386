import React, { FormEvent, FunctionComponent, useState } from 'react';
import { filter, isEmpty, map, uniq } from 'lodash';

import {
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

import { Search } from '@mui/icons-material';

import { byName } from '../../utils/misc';
import {
  Scenario,
  ScenarioArchiveFlag,
  SourceDropDown,
} from '../../types/models';

import Rule from '../shared/Rule';

import CheckboxCard from './CheckboxCard';
import { FixedHeader } from './FixedHeader';

import { Dispatch } from './Store';

interface Props {
  scenarios: Scenario[];
  selection: number[];
  dispatch: Dispatch;
  source?: SourceDropDown;
}

const ScenarioList: FunctionComponent<Props> = ({
  scenarios,
  selection,
  dispatch,
}) => {
  const years = uniq(map(scenarios, 'Year')).sort().reverse();

  return (
    <>
      {map(years, (year) => (
        <Box pb={2} key={year}>
          <section>
            <Typography mb={2} variant="caption">
              {year}
            </Typography>
            {map(
              filter(scenarios, (s) => s.Year === year).sort(byName),
              (scenario, index, array) => (
                <Box key={scenario.id}>
                  <CheckboxCard
                    checked={selection.indexOf(scenario.id) !== -1}
                    onCardClick={(checked) =>
                      dispatch({
                        type: 'TOGGLE_SCENARIO',
                        id: scenario.id,
                        value: checked,
                      })
                    }
                    label={scenario.Name}
                    styleProp={{
                      height: 'auto',
                    }}
                  />
                  {index < array.length - 1 && <Box pb={2} />}
                </Box>
              )
            )}
          </section>
        </Box>
      ))}
    </>
  );
};

export const ScenariosColumn: FunctionComponent<Props> = ({
  scenarios,
  selection,
  dispatch,
}) => {
  const [showArchived, setShowArchived] = useState(false);
  const [search, setSearch] = useState<string>('');

  const handleChangeShowArchived = (event: FormEvent<HTMLInputElement>) => {
    setShowArchived(event.currentTarget.checked);
  };

  const currentScenarios = filter(
    filter(scenarios, (s) => s.Archive_Flag === ScenarioArchiveFlag.Current),
    (s) => s.Name.toLowerCase().includes(search.toLowerCase())
  );

  const archivedScenarios = scenarios.filter(
    (s) => s.Archive_Flag === ScenarioArchiveFlag.Archive
  );

  return (
    <Box>
      <FixedHeader>
        <Typography variant="subtitle1" sx={{ mt: 1 }}>
          Scenarios
        </Typography>
      </FixedHeader>
      <Box sx={{ pb: '5.5rem' }} />
      <TextField
        label="Search Scenarios"
        InputProps={{ startAdornment: <Search /> }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
        size="small"
      />
      <ScenarioList
        scenarios={currentScenarios}
        selection={selection}
        dispatch={dispatch}
      />
      {!isEmpty(archivedScenarios) && (
        <>
          <Box pb={2} />
          <Rule />
          <Box pb={2} />
          <FormControlLabel
            control={
              <Switch
                checked={showArchived}
                onChange={handleChangeShowArchived}
              />
            }
            label="Show Archived"
          />
          <Box pb={2} />
          {showArchived && (
            <ScenarioList
              scenarios={archivedScenarios}
              selection={selection}
              dispatch={dispatch}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ScenariosColumn;
