import { ModelInstance, Timescale } from '../types/models';

export function getTimescalesForModelInstance(instance: ModelInstance) {
  const timescales = [instance.Timescale];

  if (instance.Monthly_Flag === 1) {
    timescales.push(Timescale.Monthly);
  }

  if (instance.Quarterly_Flag === 1) {
    timescales.push(Timescale.Quarterly);
  }

  if (instance.Yearly_Flag === 1) {
    timescales.push(Timescale.Yearly);
  }

  return timescales;
}
