import { Button, styled } from '@mui/material';
import grey from '@mui/material/colors/grey';

const RegionButton = styled(Button)({
  background: '#ffffff',
  padding: `24px`,
  height: `72px`,
  fontWeight: 500,
  color: `${grey[700]} !important`,
});

export default RegionButton;
