import React, {
  useEffect,
  Fragment,
  useContext,
  FunctionComponent,
  cloneElement,
} from 'react';
import {
  AppBar,
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Divider,
  Drawer,
  Grow,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
} from '@mui/material';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import { map, find, isEmpty, isUndefined, isArray, includes } from 'lodash';
import {
  AccountCircle,
  Close,
  Email,
  ExpandLess,
  ExpandMore,
  Logout,
  Menu,
  Settings,
} from '@mui/icons-material';
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';

import { useMsal } from '@azure/msal-react';

import useWindowScroll from '../../hooks/useWindowScroll';
import useSetState from '../../hooks/useSetState';
import useResponsive from '../../hooks/useResponsive';

import { ProfileContext } from './AuthController';
import StyledMenu from './StyledMenu';
import TrinityLogo from './TrinityLogo';
import RouteParams, { IRouteParams } from './RouteParams';

const drawerWidth = 240;

const StyledHeaderLogo = styled(TrinityLogo)`
  max-width: 10rem;
  min-width: 5rem;
  &:hover {
    cursor: pointer;
  }
`;

interface IHeaderProps {
  additionalMenuItems?: JSX.Element[];
}

interface IHeaderState {
  selectedItem: string;
  anchorRef: null | HTMLElement;
  isSearchEnabled: boolean;
  searchTerm: string;
  openMobileDrawer: boolean;
  scrolled: number;
}

const Header: FunctionComponent<IHeaderProps> = (props) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();
  const { search } = location;

  const profile = useContext(ProfileContext);
  if (profile === undefined) {
    throw Error('Profile is required to render NavigationBar');
  }

  const { y } = useWindowScroll();

  const { isLaptop } = useResponsive();

  const [state, setState] = useSetState<IHeaderState>({
    selectedItem: '',
    anchorRef: null,
    isSearchEnabled: false,
    searchTerm: '',
    openMobileDrawer: false,
    scrolled: 0,
  });

  useEffect(() => {
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    setState({ scrolled: (y / height) * 100 });
  }, [y]);

  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  const handleClose = (id: IRouteParams['id']): void => {
    setState({
      selectedItem: id === state.selectedItem ? '' : id,
      anchorRef: null,
    });
  };

  const StyledHeaderLogoComponent = (): JSX.Element => (
    <StyledHeaderLogo
      onClick={() => {
        searchParams.delete('client');
        searchParams.delete('model');
        searchParams.delete('instance');
        searchParams.delete('scenario');
        searchParams.delete('source');
        setSearchParams(searchParams);
        navigate('/');
      }}
    />
  );

  const UserInfoDropdown = (): JSX.Element => {
    const popupState = usePopupState({
      variant: 'popover',
      popupId: 'user-account-popup',
    });

    return (
      <>
        <IconButton
          aria-controls="user-account-popup-menu"
          aria-expanded={popupState.isOpen ? 'true' : undefined}
          aria-label="User Settings"
          {...bindTrigger(popupState)}
        >
          <Settings />
        </IconButton>
        <StyledMenu {...bindMenu(popupState)}>
          <ListItemButton disabled>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary={profile && profile.User.Display_Name} />
          </ListItemButton>
          <ListItemButton disabled>
            <ListItemIcon>
              <Email />
            </ListItemIcon>
            <ListItemText primary={profile && profile.User.User_Name} />
          </ListItemButton>
          {map(props.additionalMenuItems, (item, index) =>
            cloneElement(item, { key: index })
          )}
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                popupState.close();
                handleLogout();
              }}
            >
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </StyledMenu>
      </>
    );
  };

  return (
    <nav>
      <AppBar
        sx={{
          backgroundColor: '#304659',
          zIndex: (theme) => theme.zIndex.drawer - 1,
        }}
      >
        <Toolbar variant="dense">
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={{ xs: 1, sm: 2, md: 4 }}
            sx={{ width: '100%' }}
          >
            {isLaptop ? (
              <>
                <Grid xs={12} sm={8} alignItems="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      '& hr': {
                        mx: 2,
                      },
                    }}
                  >
                    <StyledHeaderLogoComponent />
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{ borderColor: 'white' }}
                    />
                    {map(RouteParams(search).routes, (headerItem) => (
                      <Box
                        key={headerItem.id}
                        sx={{
                          px: 1,
                          background: includes(
                            location.pathname,
                            headerItem.pathName.slice(0, 4)
                          )
                            ? '#527799'
                            : '#304659',
                          minHeight: '48px',
                        }}
                      >
                        <Button
                          disableRipple
                          component={RouterLink}
                          key={headerItem.id}
                          id={headerItem.id}
                          to={{
                            pathname: headerItem.pathName,
                            search: headerItem.search,
                          }}
                          sx={{
                            borderRadius: 0,
                            color: 'white',
                            textTransform: 'none',
                            px: 1,
                            background: includes(
                              location.pathname,
                              headerItem.pathName.slice(0, 4)
                            )
                              ? '#527799'
                              : '#304659',
                            minHeight: '48px',
                          }}
                          startIcon={headerItem.icon}
                        >
                          {headerItem.label}
                        </Button>
                      </Box>
                    ))}
                    <Popper
                      open={Boolean(state.selectedItem)}
                      anchorEl={state.anchorRef}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => {
                        const headerItem = find(RouteParams(search).routes, {
                          id: state.selectedItem,
                        }) as IRouteParams;
                        if (!isEmpty(headerItem)) {
                          return (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={() => handleClose(headerItem.id)}
                                >
                                  <MenuList id={`${state.selectedItem}-menu`}>
                                    {(() => {
                                      if (
                                        headerItem.id === state.selectedItem &&
                                        isArray(headerItem.pathName)
                                      ) {
                                        return map(
                                          headerItem.pathName,
                                          (option) => (
                                            <MenuItem
                                              key={option.id}
                                              onClick={() => {
                                                navigate(option.path);
                                                setState({
                                                  selectedItem: '',
                                                  anchorRef: null,
                                                });
                                              }}
                                            >
                                              {option.label}
                                            </MenuItem>
                                          )
                                        );
                                      }
                                      return null;
                                    })()}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          );
                        }
                        return null;
                      }}
                    </Popper>
                  </Box>
                </Grid>
                <Grid xs={12} sm={4} alignItems="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{
                      '*': {
                        color: 'white',
                      },
                    }}
                  >
                    {/* <ClickAwayListener onClickAway={onCloseSearch}> */}
                    {/*  {state.isSearchEnabled ? ( */}
                    {/*    <OutlinedInput */}
                    {/*      inputRef={searchInputRef} */}
                    {/*      autoFocus */}
                    {/*      startAdornment={ */}
                    {/*        <InputAdornment position="start"> */}
                    {/*          <Search /> */}
                    {/*        </InputAdornment> */}
                    {/*      } */}
                    {/*      size="small" */}
                    {/*      sx={{ */}
                    {/*        color: 'white', */}
                    {/*        background: '#527799', */}
                    {/*        '& .MuiOutlinedInput-notchedOutline': { */}
                    {/*          borderColor: 'white', */}
                    {/*        }, */}
                    {/*      }} */}
                    {/*      id="navbar-search" */}
                    {/*      value={state.searchTerm} */}
                    {/*      onChange={(e) => */}
                    {/*        setState({ searchTerm: e.target.value }) */}
                    {/*      } */}
                    {/*      endAdornment={ */}
                    {/*        <InputAdornment position="end"> */}
                    {/*          <IconButton */}
                    {/*            aria-label="Close Search" */}
                    {/*            onClick={onCloseSearch} */}
                    {/*          > */}
                    {/*            <Close /> */}
                    {/*          </IconButton> */}
                    {/*        </InputAdornment> */}
                    {/*      } */}
                    {/*    /> */}
                    {/*  ) : ( */}
                    {/*    <IconButton aria-label="Search" onClick={onClickSearch}> */}
                    {/*      <Search /> */}
                    {/*    </IconButton> */}
                    {/*  )} */}
                    {/* </ClickAwayListener> */}
                    {/* <IconButton aria-label="Help"> */}
                    {/*  <Help /> */}
                    {/* </IconButton> */}
                    {/* <IconButton aria-label="Notification"> */}
                    {/*  <NotificationsNone /> */}
                    {/* </IconButton> */}
                    {UserInfoDropdown()}
                    {/* <IconButton aria-label="App Switcher"> */}
                    {/*  <Apps /> */}
                    {/* </IconButton> */}
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  xs={8}
                  alignItems="center"
                  sx={{
                    '*': {
                      color: 'white',
                    },
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      '& hr': {
                        mx: 2,
                      },
                    }}
                  >
                    <IconButton
                      onClick={() =>
                        setState({
                          openMobileDrawer: !state.openMobileDrawer,
                        })
                      }
                    >
                      {state.openMobileDrawer ? <Close /> : <Menu />}
                    </IconButton>
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{ borderColor: 'white' }}
                    />
                    <StyledHeaderLogoComponent />
                  </Box>
                </Grid>
                <Grid xs={4}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{
                      '& hr': {
                        mx: 2,
                      },
                      '*': {
                        color: 'white',
                      },
                    }}
                  >
                    {UserInfoDropdown()}
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
        <LinearProgress variant="determinate" value={state.scrolled} />
      </AppBar>
      {state.openMobileDrawer && (
        <ClickAwayListener
          onClickAway={() => setState({ openMobileDrawer: false })}
        >
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
          >
            <Toolbar sx={{ justifyContent: 'flex-end' }}>
              <IconButton onClick={() => setState({ openMobileDrawer: false })}>
                <Close />
              </IconButton>
            </Toolbar>

            <Box sx={{ overflow: 'auto' }}>
              <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
              >
                {map(RouteParams(search).routes, (headerItem) => {
                  return (
                    <Fragment key={headerItem.id}>
                      <ListItemButton
                        component={RouterLink}
                        to={{
                          pathname: headerItem.pathName,
                          search: headerItem.search,
                        }}
                      >
                        <ListItemText primary={headerItem.label} />
                        {(() => {
                          if (isArray(headerItem.pathName)) {
                            if (isEmpty(headerItem.pathName)) {
                              return null;
                            }
                            if (
                              state.openMobileDrawer &&
                              headerItem.id !== state.selectedItem
                            ) {
                              return <ExpandMore />;
                            }
                            if (
                              state.openMobileDrawer &&
                              headerItem.id === state.selectedItem
                            ) {
                              return <ExpandLess />;
                            }
                          }
                          return null;
                        })()}
                      </ListItemButton>
                      {(() => {
                        if (
                          !isUndefined(headerItem) &&
                          isArray(headerItem.pathName)
                        ) {
                          return (
                            <Collapse
                              key={`${headerItem.id}-submenu`}
                              in={headerItem.id === state.selectedItem}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {map(
                                  headerItem.pathName as IRouteParams[],
                                  (item) => (
                                    <ListItemButton
                                      key={item.id}
                                      sx={{ pl: 4 }}
                                      onClick={() => {
                                        navigate(item.pathName);
                                        setState({
                                          selectedItem: '',
                                          openMobileDrawer: false,
                                        });
                                      }}
                                    >
                                      <ListItemText primary={item.label} />
                                    </ListItemButton>
                                  )
                                )}
                              </List>
                            </Collapse>
                          );
                        }
                        return null;
                      })()}
                    </Fragment>
                  );
                })}
              </List>
            </Box>
          </Drawer>
        </ClickAwayListener>
      )}
      <Toolbar />
    </nav>
  );
};

export default Header;
