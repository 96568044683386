import { get, includes, keys, forEach } from 'lodash';

import * as API from '../services/API';
import { SubmissionStatus } from '../types/models';

/**
 * Keeps getting data every 2.5 seconds
 * If path is Status, keep calling the API every 2.5 seconds until Status is Finished or Failed, and then resolve
 * @param url
 * @param path
 */

/**
 * The MAP where the list of timers are stored
 */
let dataFetchingTimerMap: Record<string, number> = {};

const clearDataFetchingTimer = (key: string): void => {
  /**
   * Clear the timeout
   */
  clearTimeout(dataFetchingTimerMap[key]);
  /**
   * Delete the key
   */
  delete dataFetchingTimerMap[key];
};

const setDataFetchingTimer = (
  key: string,
  cb: Function,
  delay: number
): void => {
  /**
   * Save the timeout with a key
   */
  dataFetchingTimerMap[key] = window.setTimeout(() => {
    /**
     * Delete key when it executes
     */
    clearDataFetchingTimer(key);
    /**
     * Execute the callback (loop function)
     */
    cb();
  }, delay);
};

export const getDataAtIntervals = (url: string, path: string): Promise<any> => {
  const timerKey = `${url}_${path}`;

  clearTimeout(+timerKey);
  return new Promise<any>((resolve: Function, reject: Function): void => {
    (async function loop() {
      try {
        const result = await API.load(url);
        if (
          get(result, path) &&
          includes(
            [
              SubmissionStatus.Finished,
              SubmissionStatus.Failed,
              SubmissionStatus.FailedWithReturnFile,
              SubmissionStatus.NoDataReturned,
            ],
            get(result, path)
          )
        ) {
          /**
           * Resolve with the data
           */
          return resolve(result);
        }
        setDataFetchingTimer(timerKey, loop, 2500);
      } catch (e: any) {
        reject(e.body.message);
      }
    })();
  });
};

/**
 * Clear every timeout
 */
export const clearGetDataAtIntervals = (): any =>
  forEach(keys(dataFetchingTimerMap), clearDataFetchingTimer);
