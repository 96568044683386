import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { format } from 'date-fns';
import { isEmpty, omit } from 'lodash';

import { Search } from '@mui/icons-material';

import { useSearchParams, useLocation } from 'react-router-dom';

import { Box, Container } from '@mui/material';

import APICacheContext from '../shared/APICacheContext';

import {
  GeneratedFile,
  ModelInstance,
  ReportType,
  RowIdentifier,
  Scenario,
  RequestStateType,
  SourceDropDown,
} from '../../types/models';
import * as API from '../../services/API';
import { GetParams } from '../../utils/history';
import { getTimescalesForModelInstance } from '../../utils/timescales';

import ClientIndex from '../shared/ClientIndex';
import GenerateReportDialog from '../shared/GenerateReportDialog';
import Loading from '../shared/Loading';
import LoadingError from '../shared/LoadingError';

import NonIdealState from '../shared/NonIdealState';

import { useProfile } from '../../hooks/useProfile';

import { useData } from '../../hooks/useData';

import { INITIAL_STATE, reducer, State } from './Store';
import Navigation from './Navigation';
import Body from './Body';
import ReportOptionsDialog from './ReportOptionsDialog';
import InvalidSelectionDialog from './InvalidSelectionDialog';
import { GENERATED_FILES_URL } from './IOConsolidationConstants';

import DefineStandardReportDialog from './DefineStandardReportDialog';
import RefreshReportDialog from './RefreshReportDialog';

function isSelectionValid(
  selection: State,
  source: SourceDropDown | undefined
) {
  return (
    selection.scenarios.length > 0 &&
    (source === 'outputs'
      ? selection.outputs.length > 0 && selection.timescales.length > 0
      : source !== 'inputs' || selection.inputs.length > 0)
  );
}

const isSelectionValidStandardReport = (
  selection: State,
  source: SourceDropDown | undefined
) => {
  return source === 'outputs'
    ? selection.outputs.length > 0 && selection.timescales.length > 0
    : source !== 'inputs' || selection.inputs.length > 0;
};

const IOConsolidation: FunctionComponent = () => {
  const location = useLocation();
  const profile = useProfile();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsData = Object.fromEntries(searchParams);

  let { clientId, modelId, modelInstanceId, source } = GetParams(
    location.search
  );

  const [refreshScenario, setRefreshScenario] = useState(false);

  const contextValue = useContext(APICacheContext);

  clientId = clientId || profile.User.ClientID || undefined;

  useEffect(() => {
    if (modelInstanceId) {
      // Using an IIFE
      (async () => {
        try {
          const generatedScenarios = await contextValue!.load(
            [`/instances/${modelInstanceId}/scenarios_load/finished`],
            true
          );
          if (!isEmpty(generatedScenarios)) {
            setRefreshScenario(false);
          }
        } catch (error) {
          // no op
        }
      })();
    }
  }, [modelInstanceId, contextValue]);

  const { data, loading, errors, refresh } = useData<{
    modelInstance?: ModelInstance;
    scenarios?: Scenario[];
    rowIdentifiers?: RowIdentifier[];
  }>(
    () => ({
      modelInstance:
        modelInstanceId !== undefined && modelId !== undefined
          ? `/instances/${modelInstanceId}`
          : undefined,
      scenarios:
        modelInstanceId !== undefined
          ? `/instances/${modelInstanceId}/scenarios_load/finished`
          : undefined,
      rowIdentifiers:
        modelInstanceId !== undefined
          ? `/instances/${modelInstanceId}/row_identifiers`
          : undefined,
    }),
    [modelInstanceId]
  );

  const [selection, dispatch] = useReducer(reducer, INITIAL_STATE);

  const handleClientChange = (id?: number) => {
    dispatch({ type: 'RESET' });

    if (id === clientId) {
      return;
    }

    if (id === undefined) {
      searchParams.delete('client');
    } else {
      searchParams.set('client', id.toString());
    }
    searchParams.delete('model');
    searchParams.delete('instance');
    searchParams.delete('scenario');
    searchParams.delete('source');
    setSearchParams(searchParams);
  };

  const handleModelChange = (id: number) => {
    if (id === modelId) {
      return;
    }

    dispatch({ type: 'RESET' });
    setSearchParams(
      omit({ ...searchParamsData, model: id.toString() }, [
        'instance',
        'source',
      ])
    );
  };

  const handleModelInstanceChange = (id: number) => {
    if (id === modelInstanceId) {
      setRefreshScenario(false);
      return;
    }
    setRefreshScenario(true);

    dispatch({ type: 'RESET' });
    setSearchParams({
      ...searchParamsData,
      instance: id.toString(),
    });
  };

  const handleSourceChange = useCallback(
    (newSource: SourceDropDown) => {
      if (newSource === source) {
        return;
      }

      const firstDimension = (data.rowIdentifiers || []).find(
        (ident) => ident.Type === 'Dimension' && ident.DimNumber === 0
      );

      if (firstDimension !== undefined) {
        dispatch({
          type: 'TOGGLE_DIMENSION_BLANKS',
          id: firstDimension.id,
          value: false,
        });
      }

      if (newSource) {
        setSearchParams({
          client: clientId?.toString() || '',
          model: modelId?.toString() || '',
          instance: modelInstanceId?.toString() || '',
          source: newSource,
        });
      }
    },
    [searchParamsData, source, clientId, modelId, modelInstanceId, data]
  );

  const [requestState, setRequestState] = useState<{
    state: RequestStateType;
    reportType?: ReportType;
    generatedFile?: GeneratedFile;
    error?: Error;
  }>({
    state: 'idle',
  });

  const handleReportConfig = () => {
    const isValid = isSelectionValid(selection, source);

    if (!isValid || data.modelInstance === undefined) {
      setRequestState({ state: 'invalid' });
      return;
    }

    setRequestState({ state: 'config', reportType: ReportType.Standard });
  };

  const handleStandardReportConfig = (requestState: RequestStateType) => {
    const isValid = isSelectionValidStandardReport(selection, source);

    if (!isValid || data.modelInstance === undefined) {
      setRequestState({ state: 'defineStandardReportInvalid' });
      return;
    }

    setRequestState({ state: requestState });
  };

  const handleGenerateReport = async () => {
    const isValid = isSelectionValid(selection, source);
    const { reportType } = requestState;

    if (!isValid || data.modelInstance === undefined) {
      setRequestState({ state: 'invalid' });
      return;
    }

    try {
      setRequestState({ state: 'busy' });

      const generatedFile = await API.create<GeneratedFile>(
        GENERATED_FILES_URL[source!],
        {
          ModelInstanceID: modelInstanceId,
          ScenarioIDs: selection.scenarios,
          Timescales: source === 'inputs' ? [] : selection.timescales,
          RowIdentifierIDs: ([] as number[]).concat(
            selection[source === 'inputs' ? 'inputs' : 'outputs'],
            selection.dimensionInstances
          ),
          IncludeBlanksRowIdentifierIDs: selection.dimensionBlanks,
          FileName: `${data.modelInstance.Name}_${source}_${format(
            new Date(),
            'yyyy-MM-ddTHH:MM'
          )}.xlsx`,
          ReportTypeID: reportType,
        }
      );

      setRequestState({ state: 'busy', generatedFile });
    } catch (error: any) {
      setRequestState({ state: 'busy', error });
    }
  };

  const closeModal = () => {
    setSearchParams(omit(searchParamsData, ['source']));
    setRequestState({ state: 'idle' });
  };

  if (clientId === undefined) {
    return <ClientIndex onSelect={handleClientChange} />;
  }

  return (
    <>
      {source !== undefined && (
        <ReportOptionsDialog
          isOpen={requestState.state === 'config'}
          source={source}
          reportType={requestState.reportType}
          onChange={(reportType: ReportType) =>
            setRequestState({ state: 'config', reportType })
          }
          onCancel={() => setRequestState({ state: 'idle' })}
          onConfirm={handleGenerateReport}
        />
      )}
      {source !== undefined && (
        <DefineStandardReportDialog
          isOpen={requestState.state === 'defineStandardReport'}
          onClose={() => setRequestState({ state: 'idle' })}
          instanceId={modelInstanceId}
          rowIdentifierIds={([] as number[]).concat(
            selection[source === 'inputs' ? 'inputs' : 'outputs'],
            selection.dimensionInstances
          )}
          data={data}
          includeBlanksRowIdentifierIDs={selection.dimensionBlanks}
          timescales={selection.timescales}
          source={source}
        />
      )}
      <RefreshReportDialog
        isOpen={source === 'standardReport'}
        onClose={closeModal}
        instanceId={modelInstanceId}
      />
      <GenerateReportDialog
        isOpen={requestState.state === 'busy'}
        generatedFile={requestState.generatedFile}
        error={requestState.error}
        onClose={() => setRequestState({ state: 'idle' })}
      />
      <InvalidSelectionDialog
        isOpen={
          requestState.state === 'invalid' ||
          requestState.state === 'defineStandardReportInvalid'
        }
        source={source}
        onClose={() => setRequestState({ state: 'idle' })}
        requestState={requestState.state}
      />
      <Navigation
        clientId={clientId}
        modelId={modelId}
        modelInstanceId={modelInstanceId}
        source={source}
        onModelChange={handleModelChange}
        onModelInstanceChange={handleModelInstanceChange}
        onSourceChange={handleSourceChange}
        onGenerateReport={handleReportConfig}
        onGenerateStandardReport={handleStandardReportConfig}
      />
      <Box pt={2} />
      {(() => {
        if (source === 'inputs' || source === 'outputs') {
          if (
            clientId === undefined ||
            modelId === undefined ||
            modelInstanceId === undefined
          ) {
            return (
              <Container maxWidth={false}>
                <NonIdealState
                  icon={<Search />}
                  title="Select a model, model instance and a source to continue"
                />
              </Container>
            );
          } else if (Array.isArray(errors) && errors.length > 0) {
            return (
              <Container maxWidth={false}>
                <LoadingError retry={refresh} />
              </Container>
            );
          } else if (refreshScenario) {
            return (
              <Container maxWidth={false}>
                <Loading />
              </Container>
            );
          } else if (
            Array.isArray(data.scenarios) &&
            Array.isArray(data.rowIdentifiers) &&
            data.modelInstance !== undefined
          ) {
            return (
              <Body
                source={source}
                scenarios={data.scenarios || []}
                rowIdentifiers={data.rowIdentifiers || []}
                timescales={getTimescalesForModelInstance(data.modelInstance)}
                selection={selection}
                dispatch={dispatch}
              />
            );
          } else if (loading) {
            return (
              <Container maxWidth={false}>
                <Loading />
              </Container>
            );
          } else {
            return (
              <Container maxWidth={false}>
                <LoadingError retry={refresh} />
              </Container>
            );
          }
        } else {
          return (
            <Container maxWidth={false}>
              {source !== 'standardReport' && (
                <NonIdealState
                  icon={<Search />}
                  title="Select a model, model instance and a source to continue"
                />
              )}
            </Container>
          );
        }
      })()}
    </>
  );
};

export default IOConsolidation;
