import React, { FunctionComponent, useContext } from 'react';
import { format, eachDayOfInterval } from 'date-fns';
import { filter, find, includes, isEmpty, map } from 'lodash';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { useLocation } from 'react-router-dom';

import { TextField } from '@mui/material';

import { ArrowDropDown } from '@mui/icons-material';

import Loader, { LoaderInfo } from '../../shared/Loader';
import { ModelInstance, Timescale, XRefDate } from '../../../types/models';

import { ProfileContext } from '../../shared/AuthController';
import { GetParams } from '../../../utils/history';

import MonthPicker from './MonthPicker';
import QuarterPicker from './QuarterPicker';
import YearPicker from './YearPicker';

export interface ISelectTimescaleValueProps {
  modelInstance?: ModelInstance;
  timescale?: Timescale;
  size?: 'small' | 'medium';
  onChange: (timescaleValue: XRefDate['DateID']) => void;
  value?: XRefDate['DateID'];
}

const SelectTimescaleValue: FunctionComponent<ISelectTimescaleValueProps> = ({
  modelInstance,
  timescale,
  size = 'medium',
  onChange,
  value,
}) => {
  const location = useLocation();
  const { search } = location;

  const params = GetParams(search);
  const profile = useContext(ProfileContext);

  const clientId =
    profile && profile.User.ClientID !== null
      ? profile.User.ClientID
      : params.clientId;

  const needs = {
    dates: modelInstance !== undefined ? `/xref_date/${clientId}` : undefined,
  };

  const renderContent = ({
    loading,
    data,
  }: LoaderInfo<{
    dates?: XRefDate[];
  }>) => {
    const dates: XRefDate[] = filter(data.dates || [], [
      'Timescale',
      timescale,
    ]);

    if (modelInstance !== undefined && timescale && !isEmpty(dates)) {
      const startDate = (
        find(data.dates, {
          DateID: (modelInstance as ModelInstance).Start_DateID,
        }) as XRefDate
      ).Date;
      const endDate = (
        find(data.dates, {
          DateID: (modelInstance as ModelInstance).End_DateID,
        }) as XRefDate
      ).Date;
      if (timescale === Timescale.Weekly) {
        const dateRanges = map(
          eachDayOfInterval({
            start: new Date(startDate),
            end: new Date(endDate),
          }),
          (date) => format(date, 'M/d/yyyy')
        );

        const datesOnly = map(dates, 'Date');

        const disabledDates = filter(
          dateRanges,
          (i) => !includes(datesOnly, i)
        );

        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              minDate={new Date(startDate)}
              maxDate={new Date(endDate)}
              shouldDisableDate={(date) => {
                return includes(disabledDates, format(date, 'M/d/yyyy'));
              }}
              disabled={timescale === undefined}
              onChange={(selectedDate) => {
                selectedDate &&
                  onChange(
                    (
                      find(data.dates, {
                        Date: format(selectedDate, 'M/d/yyyy'),
                      }) as XRefDate
                    ).DateID
                  );
              }}
              value={
                value
                  ? new Date(
                      (
                        find(data.dates, {
                          DateID: value,
                        }) as XRefDate
                      ).Date
                    )
                  : null
              }
            />
          </LocalizationProvider>
        );
      }

      if (!isEmpty(data.dates)) {
        if (timescale === Timescale.Monthly) {
          return (
            <MonthPicker
              minYear={Number(format(new Date(startDate), 'yyyy'))}
              maxYear={Number(format(new Date(endDate), 'yyyy'))}
              onChange={(month, year) =>
                month &&
                year &&
                onChange(
                  (find(data.dates, { Date: `${month}/1/${year}` }) as XRefDate)
                    .DateID
                )
              }
              value={
                value
                  ? (find(data.dates, { DateID: value }) as XRefDate).Date
                  : undefined
              }
              dates={filter(data.dates, (date) => {
                const dateItem = new Date(date.Date);
                return (
                  dateItem >=
                    new Date(
                      `${format(new Date(startDate), 'M')}/1/${format(
                        new Date(startDate),
                        'yyyy'
                      )}`
                    ) &&
                  dateItem <=
                    new Date(
                      `${format(new Date(endDate), 'M')}/1/${format(
                        new Date(endDate),
                        'yyyy'
                      )}`
                    )
                );
              })}
            />
          );
        } else if (timescale === Timescale.Quarterly) {
          return (
            <QuarterPicker
              minYear={Number(startDate.substring(0, 4))}
              maxYear={Number(endDate.substring(0, 4))}
              onChange={(quarter, year) =>
                quarter &&
                year &&
                onChange(
                  (
                    find(data.dates, {
                      Date: `${year} Q${quarter}`,
                    }) as XRefDate
                  ).DateID
                )
              }
              value={
                value
                  ? (find(data.dates, { DateID: value }) as XRefDate).Date
                  : undefined
              }
              dates={filter(data.dates, (date) => {
                return date.Date >= startDate && date.Date <= endDate;
              })}
            />
          );
        } else if (timescale === Timescale.Yearly) {
          const selectedDate = (find(data.dates, { DateID: value }) as XRefDate)
            .Date;
          return (
            <YearPicker
              onChange={(year) =>
                year &&
                onChange(
                  (find(data.dates, { Date: `${year}` }) as XRefDate).DateID
                )
              }
              value={
                selectedDate.length === 4
                  ? selectedDate
                  : format(new Date(selectedDate), 'yyyy')
              }
              minYear={Number(format(new Date(startDate), 'yyyy'))}
              maxYear={Number(format(new Date(endDate), 'yyyy'))}
            />
          );
        }
      }
    }

    if (
      timescale === undefined ||
      modelInstance === undefined ||
      isEmpty(data.dates)
    ) {
      return (
        <TextField
          disabled
          fullWidth
          size={size}
          variant="outlined"
          InputProps={{
            endAdornment: <ArrowDropDown />,
          }}
          label="Select a timescale value"
          placeholder="Select a timescale value"
        />
      );
    }

    return null;
  };

  return <Loader needs={needs} render={renderContent} />;
};

export default SelectTimescaleValue;
