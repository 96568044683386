import React, { FunctionComponent } from 'react';
import { includes, values, flatten, reduce } from 'lodash';
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';

import { Article } from '@mui/icons-material';

import { GeneratedFile, SubmissionStatus } from '../../types/models';

import Loader, { LoaderInfo } from './Loader';
import Monitor from './Monitor';
import Flex from './Flex';
import LinearProgressBar from './LinearProgressBar';

interface Props {
  generatedFileId?: number | null;
  progressMsg: string;
  onClose: () => void;
  calloutMessage?: string;
}

const GeneratedFileDownload: FunctionComponent<Props> = (props) => {
  const { generatedFileId } = props;
  const needs: Record<string, string> = {};

  if (generatedFileId !== undefined && generatedFileId !== null) {
    needs.generatedFile = `/generated_files/${generatedFileId}`;
  }

  const renderContent = (
    { data }: LoaderInfo<{ generatedFile?: GeneratedFile }>,
    refresh: () => void
  ) => {
    const { generatedFileId, onClose, progressMsg, calloutMessage } = props;
    const { generatedFile } = data;

    if (generatedFile && generatedFile.Status === SubmissionStatus.Failed) {
      return (
        <Alert severity="error" icon={false}>
          <AlertTitle>File download failed</AlertTitle>
          <Box mb={1}>{generatedFile.Status_Message}</Box>
          <Button onClick={onClose} color="error" variant="contained">
            OK
          </Button>
        </Alert>
      );
    }

    if (
      generatedFile &&
      generatedFile.Status === SubmissionStatus.NoDataReturned
    ) {
      return (
        <Alert severity="warning" icon={false}>
          <AlertTitle>Unable to generate file</AlertTitle>
          <Box mb={1}>{generatedFile.Status_Message}</Box>
          <Button onClick={onClose} color="warning" variant="contained">
            OK
          </Button>
        </Alert>
      );
    }

    if (
      generatedFile &&
      generatedFile.Status === SubmissionStatus.FailedWithReturnFile
    ) {
      return (
        <Alert severity="error" icon={false}>
          <AlertTitle>File download failed</AlertTitle>
          <Box alignItems="center">
            <Flex alignItems="center">
              <Typography>{generatedFile.Status_Message}</Typography>
            </Flex>
            <Box>
              <Button variant="text" onClick={onClose}>
                Close
              </Button>
              <Button variant="text" href={generatedFile.BlobURL}>
                Download Error Report
              </Button>
            </Box>
          </Box>
        </Alert>
      );
    }

    if (generatedFile && generatedFile.Status === SubmissionStatus.Finished) {
      if (generatedFile.Status_Message === '409') {
        return (
          <Alert severity="warning">
            <AlertTitle>{calloutMessage}</AlertTitle>
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center">
                <Box mr={1}>
                  <Article />
                </Box>
                <Typography>File is ready to download</Typography>
              </Flex>
              <Box>
                <Button variant="text" onClick={onClose}>
                  Close
                </Button>
                <Button variant="text" href={generatedFile.BlobURL}>
                  Download
                </Button>
              </Box>
            </Flex>
          </Alert>
        );
      }
      if (includes(generatedFile.Status_Message, 'UnRefreshedTabs')) {
        const unRefreshedTabs = flatten(
          values(JSON.parse(generatedFile.Status_Message as string))
        );
        return (
          <Alert severity="warning">
            <AlertTitle>{`Warning: The following tab(s) "${reduce(
              unRefreshedTabs,
              (text, value, i, array) =>
                text + (i < array.length - 1 ? ', ' : ' and ') + value
            )}" were found in the file, but were not refreshed.`}</AlertTitle>
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center">
                <Box mr={1}>
                  <Article />
                </Box>
                <Typography>File is ready to download</Typography>
              </Flex>
              <Box>
                <Button variant="text" onClick={onClose}>
                  Close
                </Button>
                <Button variant="text" href={generatedFile.BlobURL}>
                  Download
                </Button>
              </Box>
            </Flex>
          </Alert>
        );
      }
      return (
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Box mr={1}>
              <Article />
            </Box>
            <Typography>File is ready to download</Typography>
          </Flex>
          <Box>
            <Button variant="text" onClick={onClose}>
              Close
            </Button>
            <Button variant="text" href={generatedFile.BlobURL}>
              Download
            </Button>
          </Box>
        </Flex>
      );
    }

    return (
      <>
        {generatedFileId !== undefined && (
          <Monitor
            path={`/generated_files/${generatedFileId}`}
            object={generatedFile}
            onChange={refresh}
          />
        )}
        <Typography variant="h6" sx={{ mb: 2 }}>
          {progressMsg}
        </Typography>
        <LinearProgressBar color="success" />
      </>
    );
  };

  return <Loader needs={needs} render={renderContent} handleErrors={true} />;
};

export default GeneratedFileDownload;
