import {
  AnonymousCredential,
  BlobUploadCommonResponse,
  BlockBlobClient,
  BlockBlobParallelUploadOptions,
  BlockBlobUploadHeaders,
  newPipeline,
  Pipeline,
} from '@azure/storage-blob';
import { forEach } from 'lodash';

import { Upload, UploadMultipleToAzure } from '../types/models';

export const uploadSingleFileToAzure = <T extends { BlobURL: string }>(
  upload: T,
  blob: Blob,
  options: BlockBlobParallelUploadOptions = {}
): Promise<BlobUploadCommonResponse> => {
  const CREDENTIALS: AnonymousCredential = new AnonymousCredential();
  const PIPELINE: Pipeline = newPipeline(CREDENTIALS);

  let blockBlobClient: BlockBlobClient;
  if ('BlobURL' in upload) {
    blockBlobClient = new BlockBlobClient(upload.BlobURL, PIPELINE);
  }

  return blockBlobClient!.uploadData(blob, options);
};

export const uploadMultipleFilesToAzure = (
  uploadData: Omit<UploadMultipleToAzure, 'modelInstanceId'>[],
  handleProgress: (
    loadedBytes: number,
    fileData: UploadMultipleToAzure['fileData'],
    uploadId: Upload['id']
  ) => void,
  individualCallback: Function
): Promise<BlockBlobUploadHeaders[]> => {
  const PIPELINE: Pipeline = newPipeline(new AnonymousCredential(), {
    retryOptions: { maxTries: 4 }, // Retry options
    keepAliveOptions: {
      // Keep alive is enabled by default, disable keep alive by setting false
      enable: false,
    },
  });

  const promises: Promise<BlobUploadCommonResponse>[] = [];
  forEach(uploadData, (uploadItem) => {
    let blockBlobClient: BlockBlobClient = new BlockBlobClient(
      uploadItem.BlobURL,
      PIPELINE
    );
    promises.push(
      (
        blockBlobClient.uploadData(uploadItem.fileData as Blob, {
          blockSize:
            (uploadItem.fileData as Blob).size > 1024 * 1024 * 32
              ? 1024 * 1024 * 4
              : 1024 * 512,
          maxSingleShotSize: 1024 * 512,
          concurrency: 20, // 20 concurrency,
          onProgress: (ev) =>
            handleProgress(
              ev.loadedBytes / (uploadItem.fileData as Blob).size,
              uploadItem.fileData,
              uploadItem.id
            ),
        }) as any
      ).then(() => {
        individualCallback(uploadItem);
      })
    );
  });
  return Promise.all(promises);
};
