import React, { ChangeEvent, PureComponent } from 'react';
import { map } from 'lodash';
import { FormControl, MenuItem, TextField } from '@mui/material';

import { Client } from '../../types/models';
import { isModelOwnerInClient } from '../../utils/profile';
import { isAdminUser } from '../../utils/user';

import { ProfileProps, withProfile } from '../shared/AuthController';

import Loader, { LoaderInfo } from '../shared/Loader';

interface Props extends ProfileProps {
  filter?: 'default' | 'admin';
  showAll?: boolean;
  clientId?: number;
  onChange(clientId: number): void;
  size?: 'small' | 'medium';
  hasError?: boolean;
}

class SelectClient extends PureComponent<Props> {
  static defaultProps = {
    size: 'small' as 'small',
  };

  public render() {
    const needs = {
      clients: `/clients`,
    };
    return <Loader needs={needs} render={this.renderContent} />;
  }

  private renderContent = ({
    loading,
    errors,
    data,
  }: LoaderInfo<{ clients: Client[] }>) => {
    const { clientId, profile, size, hasError } = this.props;
    const clients: Client[] = (data.clients || [])
      .filter(
        (client) =>
          profile &&
          (isAdminUser(profile.User) ||
            isModelOwnerInClient(profile, client.id))
      )
      .sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));

    let disabled = false;

    if (clientId === undefined) {
      disabled = true;
    } else if (errors.length > 0) {
      disabled = true;
    } else if (clients.length < 1) {
      disabled = true;
    }

    return (
      <FormControl fullWidth disabled={disabled} aria-disabled={disabled}>
        <TextField
          label="Select a client"
          error={hasError}
          size={size}
          select
          value={clientId}
          onChange={this.handleItemSelect}
        >
          {map(clients, (i) => (
            <MenuItem key={i.id} value={i.id}>
              {i.Name}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  };

  private handleItemSelect = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(Number(event.target.value));
  };
}

export default withProfile(SelectClient);
