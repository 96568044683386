import { ICellRendererParams } from 'ag-grid-enterprise';
import { Box } from '@mui/material';
import React from 'react';

const EditedCellsRenderer = (params: ICellRendererParams) => {
  if (params.data.updatedCells.length > 0) {
    return (
      <Box
        sx={{
          height: 10,
          width: 10,
          backgroundColor: (t) => t.palette.secondary.main,
          borderRadius: '50%',
          display: 'inline-block',
        }}
      />
    );
  }
  return null;
};

export default EditedCellsRenderer;
