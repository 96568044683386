import React, { FunctionComponent } from 'react';
import { Button, ButtonGroup, MenuItem } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

import SelectModel from '../shared/SelectModel';
import SelectModelInstance from '../shared/SelectModelInstance';

import { RequestStateType, SourceDropDown } from '../../types/models';

import StyledMenu from '../shared/StyledMenu';

import useSetState from '../../hooks/useSetState';

import IOSelect from './IOSelect';

interface IOConsolidationNavigationProps {
  clientId: number;
  modelId?: number;
  modelInstanceId?: number;
  source?: SourceDropDown;
  onModelChange: (id: number) => void;
  onModelInstanceChange: (id: number) => void;
  onSourceChange: (source: SourceDropDown) => void;
  onGenerateReport: () => void;
  onGenerateStandardReport: (requestState: RequestStateType) => void;
}

interface IOConsolidationNavigationState {
  isReportPopoverOpen?: boolean;
}

const initialState: IOConsolidationNavigationState = {
  isReportPopoverOpen: false,
};

const IOConsolidationNavigation: FunctionComponent<
  IOConsolidationNavigationProps
> = ({
  clientId,
  modelId,
  modelInstanceId,
  source,
  onModelChange,
  onModelInstanceChange,
  onSourceChange,
  onGenerateReport,
  onGenerateStandardReport,
}) => {
  const [state] = useSetState<IOConsolidationNavigationState>(initialState);

  return (
    <Grid container sx={{ alignItems: 'center' }} spacing={2}>
      <Grid sm={2.5}>
        <SelectModel
          clientId={clientId}
          modelId={modelId}
          onChange={onModelChange}
          size="small"
        />
      </Grid>
      <Grid sm={2.5}>
        <SelectModelInstance
          clientId={clientId}
          modelId={modelId}
          modelInstanceId={modelInstanceId}
          onChange={onModelInstanceChange}
          size="small"
        />
      </Grid>
      <Grid sm={2.5}>
        <IOSelect
          disabled={modelInstanceId === undefined}
          source={source}
          onChange={onSourceChange}
          size="small"
        />
      </Grid>
      <Grid container justifyContent="flex-end" sm={4.5}>
        <ButtonGroup>
          <Button
            variant="contained"
            size="medium"
            onClick={onGenerateReport}
            disabled={!source}
          >
            Generate Report
          </Button>
          <PopupState variant="popover" popupId="demoMenu">
            {(popupState) => (
              <>
                <Button
                  disabled={!source}
                  variant="contained"
                  {...bindTrigger(popupState)}
                >
                  {!state.isReportPopoverOpen ? (
                    <KeyboardArrowDown />
                  ) : (
                    <KeyboardArrowUp />
                  )}
                </Button>
                <StyledMenu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      onGenerateStandardReport('defineStandardReport');
                      popupState.close();
                    }}
                  >
                    Define Standard Report
                  </MenuItem>
                </StyledMenu>
              </>
            )}
          </PopupState>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default IOConsolidationNavigation;
