import React, { FunctionComponent } from 'react';

import GeneratedFileDownload from '../shared/GeneratedFileDownload';
import { GeneratedFile } from '../../types/models';
import PopupDialog from '../shared/PopupDialog';

interface RefreshReportDownloadDialogProps {
  isOpen: boolean;
  onClose: () => void;
  generatedFileId: GeneratedFile['id'] | null;
}

const RefreshReportDownloadDialog: FunctionComponent<
  RefreshReportDownloadDialogProps
> = ({ isOpen, onClose, generatedFileId }) => {
  return (
    <PopupDialog
      open={isOpen}
      close={onClose}
      title="Refresh an Existing Report"
      hideDialogActions
    >
      <GeneratedFileDownload
        progressMsg="Preparing download"
        onClose={onClose}
        generatedFileId={generatedFileId}
      />
    </PopupDialog>
  );
};

export default RefreshReportDownloadDialog;
